import { GET_DEFAULT_PROJECTS_SUCCESS, GET_TASKS, TASK_UPDATE_TIME, EVENTS_TIME_ADD } from "../types"

const taskReducers = (state = {}, actions) => {
    switch (actions.type) {
        case GET_TASKS:
            return { ...state, projects: actions.payload };
        case TASK_UPDATE_TIME:
            const updatedProjects = state.projects.map((project, index) => {
                // If payload contains an ID, match it with the project ID
                if (project.userProjectId === actions.payload.index.id) {
                    const timeChange = actions.payload.time - project.time;
                    return {
                        ...project,
                        time: actions.payload.time,
                        currentHours: project.currentHours + timeChange,
                        userCurrentTime: project.userCurrentTime + timeChange,
                        userTime: project.userTime + timeChange,
                        userProjectId: actions.payload.index.id,
                    };
                }else{
                   if(project.userProjectId == -1){  //If userprojectId is -1 , check it from index
                        if(project.index === actions.payload.index.index){
                            const timeChange = actions.payload.time - project.time;
                            return {
                                ...project,
                                time: actions.payload.time,
                                currentHours: project.currentHours + timeChange,
                                userCurrentTime: project.userCurrentTime + timeChange,
                                userTime: project.userTime + timeChange,
                                userProjectId: actions.payload.index.id,
                            };
                        }
                    }
                }
                return project;
            });                               
            return { ...state, projects: updatedProjects };
        case GET_DEFAULT_PROJECTS_SUCCESS:
            return { ...state, events: actions.payload }
        case EVENTS_TIME_ADD:
            var eventList = state.events;
            eventList[actions.payload.index].time = actions.payload.data.time;
            eventList[actions.payload.index].projectAssignId = actions.payload.data.id;
            return { ...state, events: eventList }
        default:
            return state
    }
}

export default taskReducers;