import React, { Component } from 'react';
import { PieChart, Pie } from 'recharts';
import ReactTable from 'react-table'

//For sub table in user history in users navigation tab
class UserDetails extends Component {
    render() {
        //define the column in react table
        const subColumns = [
            {
                Header: 'Project Name',
                filterable: true, accessor: 'projectName',
                Cell: props => {
                    return (<span style={{ color: "black", fontWeight: "800", paddingLeft: 10 }}>{props.original.projectName}</span>)
                }
            },
            {
                Header: 'Spended Hours',
                filterable: true, accessor: 'userTime',
                Cell: props => {
                    return (<span>{props.original.userTime + "H"}</span>)
                },
                getProps: (state, rowInfo, column) => {
                    return {
                        style: {
                            textAlign: "center"
                        },
                    };
                }
            }
        ]
        return (
            <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ width: 400, fontSize: 10 }}>
                {/* Pie chart for view current selected date value in chart */}
                    <PieChart width={400} height={400}   >
                        <Pie dataKey="userTime"
                            isAnimationActive={true}
                            data={this.props.userAssign}
                            cx={200}
                            cy={200}
                            outerRadius={80}
                            fill="#8884d8"
                            label={(data) => {
                                return (data.projectName)
                            }}
                        />
                    </PieChart>
                </div>
                <div style={{ padding: "20px", width: "100%" }}>
                    <ReactTable
                        data={this.props.userAssign}
                        columns={subColumns}
                        defaultPageSize={5}
                    />
                </div>
            </div>
        );
    }
}

export default UserDetails;