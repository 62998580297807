import React, { useState, useEffect } from 'react';
import { Button, Popconfirm } from 'antd';
import {
    AppstoreAddOutlined,
    DeleteFilled
} from '@ant-design/icons';
import * as Actions from "../../actions/teamsActions"
import { connect } from 'react-redux';
import TableContainer from '../Role/TableContainer';
import AddTeam from './AddTeam';
import AuthContext from '../../../services/Auth'
import Loader from "../../../containers/Loader/Loader"
import Table from '../Role/Table';
const Teams = ({ getTeams, team, addNewTeam, isAdminSuccess, getTeamsDrop }) => {
    const [addData, setAddData] = useState(false);

    useEffect(() => {
        if (isAdminSuccess) {
            getTeamsDrop();
        } else {
            getTeams(AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.oid);
        }
    }, [])

    useEffect(() => {
        if (isAdminSuccess) {
            getTeamsDrop();
        }
    }, [isAdminSuccess]);

    const columns = [{
        Header: 'Team Name',
        accessor: 'teamName',
        onFilter: (value, record) => record.teamName.includes(value),
        sorter: {
            compare: (a, b) => ('' + a.teamName).localeCompare(b.teamName),
            multiple: 3,
        }
    }, {
        Header: 'Department',
        accessor: 'department',
        onFilter: (value, record) => record.department.includes(value),
        sorter: {
            compare: (a, b) => ('' + a.department).localeCompare(b.department),
            multiple: 3,
        }
    }
        // , {
        //     Header: '',
        //     accessor: 'active',
        //     Cell: props => <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
        //         {
        //             props.row.original.isActive ?
        //                 <>

        //                     <Button disabled={true} type="primary" style={{ fontSize: 12, width: 80 }} >Active</Button>
        //                     <Popconfirm
        //                         style={{ marginLeft: 20 }}
        //                         placement="left" title={"Are you sure to deactive this project?"}
        //                         onConfirm={() => {
        //                             // deactivatedNonTimeBoundActivity(props.row.original.projectId, "Department")
        //                         }}
        //                         okText="Yes"
        //                         cancelText="No">
        //                         <Button danger style={{ fontSize: 12, width: 80 }} >Deactive</Button>
        //                     </Popconfirm>
        //                 </>

        //                 :
        //                 <>
        //                     <Popconfirm placement="left" title={"Are you sure to active this project?"} onConfirm={() => {
        //                         // activateNonTimeBoundActivity(props.row.original.projectId, "Department")
        //                     }} okText="Yes" cancelText="No">
        //                         <Button type="primary" style={{ fontSize: 12, width: 80 }} >Active</Button>
        //                     </Popconfirm>

        //                     <Button disabled={true} danger style={{ fontSize: 12, width: 80 }} >Deactive</Button>
        //                 </>

        //         }

        //     </div >
        // }
    ]

    return (
        <div>
            {team.getTeamsLoading &&
                <Loader />
            }
            {isAdminSuccess &&
                <div className="buttonRight">
                    <Button type="primary" danger onClick={() => setAddData(true)}>
                        <AppstoreAddOutlined /> Add Teams
                    </Button>
                </div>
            }
            {/* {team.getTeamsSuccess &&
                <TableContainer
                    data={team.getTeamsSuccess}
                    loading={team.getTeamsLoading}
                    error={team.getTeamsError}
                    title={"Organization Non Time Bound Activity"}
                    columns={columns}
                />} */}

            {team.getTeamsSuccess &&
                <Table
                    columns={columns}
                    data={team.getTeamsSuccess}
                />
            }

            <AddTeam
                visible={addData}
                addNewTeam={(teamName, department, createdBy) => { addNewTeam(teamName, department, createdBy) }}
                addTeamSuccess={team.addTeamSuccess}
                onClose={() => { setAddData(false) }}
                teamList={team.getTeamsSuccess}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        team: state.team,
    }
}

export default connect(mapStateToProps, Actions)(Teams);