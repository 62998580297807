import { Skeleton } from 'antd';
import React, { useEffect, useState } from 'react';
import "./TableSkelton.scss"

const TableSkelton = ({ rows, columns, numberOfRows }) => {
    const [rowArray, setRowArray] = useState([]);
    const [columnArray, setColumnArray] = useState([]);

    useEffect(() => {
        var rowArrayInit = []
        for (var i = 0; i < rows; i++) {
            rowArrayInit.push(i);
        }
        setRowArray(rowArrayInit);
        var columnsArrayInit = []
        for (var i = 0; i < rows; i++) {
            columnsArrayInit.push(i);
        }
        setColumnArray(columnsArrayInit);

    }, [])
    return (<>
        {columnArray.map(() => {
            return <div className="row">
                {rowArray.map(() => {
                    return <div className="col-md-3">
                        <Skeleton active paragraph={{ rows: numberOfRows }} />
                    </div>
                })}
            </div>
        })
        }
    </>
    );
};

export default TableSkelton;