import React, { Component } from 'react'
import "./Navbar.scss"
import { Link, withRouter } from 'react-router-dom';
import AuthContext from '../../services/Auth'
import * as Actions from "../../store/actions/usersActions"
import { connect } from "react-redux"
import { Menu } from 'antd'
import {
  FundProjectionScreenOutlined,
  FileOutlined,
  UserAddOutlined,
  UploadOutlined,
  UsergroupAddOutlined,
  ContainerOutlined,
  ProjectOutlined,
  HomeOutlined,
  AppstoreAddOutlined,
  ReconciliationOutlined,
  PoweroffOutlined, RobotOutlined
} from '@ant-design/icons';

const { SubMenu } = Menu;
class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clickable: "",
      title: "",
      collapsed: false,
      location: []
    }
  }

  toggleCollapsed = () => {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  };

  componentDidMount() {
    this.props.getImage();
    //this.props.getUserDetails();
    var oid = AuthContext.getCachedUser() && AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.oid;
    this.props.isTeamLead(oid);
    this.props.isAdmin(oid);
    this.props.isDepartmentHead(oid);
    this.props.getRole(oid);
    this.props.isProjectManager(oid);
    this.setState({ title: window.location.pathname })
    const pathname = this.props.location.pathname;
    if (pathname === "/AddProject" || pathname === "/Projects") {
      this.setState({ location: "Project" })
    }
    if (pathname === "/UsersAssign" || pathname === "/Upload" || pathname === "/UsersAssignDetails") {
      this.setState({ location: "Users" })
    }
  }

  render() {
    let pathname = this.props.location.pathname;
    return (<>
      <div id="sideNav" style={{ width: 256 }}>
        <Menu
          style={{ position: "fixed", width: 250, overflow: "auto", height: window.innerHeight - 20 }}
          defaultSelectedKeys={[pathname]}
          defaultOpenKeys={[
            (pathname === "/AddProject" || pathname === "/Projects" ? "Project" :
              pathname === "/UsersAssign" || pathname === "/Upload" || pathname === "/UsersAssignDetails" ?
                "AssignUsers" :
                pathname === "/Support" || pathname === "/AssignUsersSupport" || pathname === "/AddSupport" ?
                  "Support" :
                  "")
          ]}
          mode="inline"
          theme="dark"
          className='scroll'
          inlineCollapsed={this.state.collapsed}
        >
          <div className={(this.props.navView ? "sidebar-profile" : "sidebar-profile-hide")} style={{ height: 100 }}>
            <div className="profile-pic">
              <img src={(this.props.navbar.userImage ? "data:image/png;base64," + this.props.navbar.userImage : require("../../assets/images/user.jpg"))} style={{ width: '100%', height: "100%" }} />
            </div>
            <div style={{ height: "100%", width: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
              <p style={{ fontSize: 12, textAlign: "center", width: "100%" }}>{AuthContext.getCachedUser() && AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.name.split(' ').slice(0, 2).join(' ')}</p>
            </div>
          </div>
          <Menu.Item key="/" icon={<HomeOutlined />}>
            <Link to={`/`} activeclassname="active">
              Home
            </Link>
          </Menu.Item>
          <Menu.Item key="/Tasks" icon={<FileOutlined />}>
            <Link to={`/Tasks`} activeclassname="active">
              Tasks
            </Link>
          </Menu.Item>

          <Menu.Item key="/Activity" icon={<ContainerOutlined />}>
            <Link to={`/Activity`} activeclassname="active">
              Activity
            </Link>
          </Menu.Item>
          {(this.props.navbar.role === "SupportUser" || this.props.navbar.isAdminSuccess) &&
            <SubMenu key="Support" icon={<RobotOutlined />} title="Support Contract">
              <Menu.Item key="/Support" icon={<FileOutlined />}>
                <Link to={`/Support`} activeclassname="active">
                  Contracts
                </Link>
              </Menu.Item>
              <Menu.Item key="/AssignUsersSupport" icon={<UserAddOutlined />}>
                <Link to={`/AssignUsersSupport`} activeclassname="active">
                  Assign Users
                </Link>
              </Menu.Item>

              <Menu.Item key="/AddSupport" icon={<AppstoreAddOutlined />}>
                <Link to={`/AddSupport`} activeclassname="active">
                  Add Contract
                </Link>
              </Menu.Item>
            </SubMenu>
          }
          <SubMenu key="Project" icon={<FundProjectionScreenOutlined />} title="Project">
          {(this.props.navbar.isProjectManagerSuccess || this.props.navbar.isAdminSuccess) &&
            <>
            <Menu.Item key="/Projects" icon={<ProjectOutlined />}>
              <Link to={`/Projects`} activeclassname="active">
                Projects
              </Link>
            </Menu.Item>
            </>
            }
            {(this.props.navbar.isProjectManagerSuccess || this.props.navbar.isAdminSuccess) &&
              <>
              <Menu.Item key="/AddProject" icon={<AppstoreAddOutlined />}>
                <Link to={`/AddProject`} activeclassname="active">
                  Add Project
                </Link>
              </Menu.Item><Menu.Item key="/UploadProjectDetails" icon={<ReconciliationOutlined />}>
                  <Link to={`/UploadProjectDetails`} activeclassname="active">
                    Upload Project Details
                  </Link>
                </Menu.Item>
                </>
            }
          </SubMenu>
          {(this.props.navbar.isProjectManagerSuccess || this.props.navbar.isAdminSuccess) &&

            <SubMenu key="AssignUsers" icon={<UsergroupAddOutlined />} title="Project Users">
              <Menu.Item key="/UsersAssign" icon={<UserAddOutlined />}>
                <Link to={`/UsersAssign`} activeclassname="active">
                  Assign Users
                </Link>
              </Menu.Item>
              <Menu.Item key="/Upload" icon={<UploadOutlined />}>
                <Link to={`/Upload`} activeclassname="active">
                  Upload Users
                </Link>
              </Menu.Item>
            </SubMenu>
          }
          {(this.props.navbar.isAdminSuccess) &&

            <Menu.Item key="/Role" icon={<UserAddOutlined />}>
              <Link to={`/Role`} activeclassname="active">
                Role
              </Link>
            </Menu.Item>
          }
          {(this.props.navbar.isTeamLeadSuccess || this.props.navbar.isDepartmentHeadSuccess || this.props.navbar.isAdminSuccess) &&

            <Menu.Item key="/NonBoundaryActivity" icon={<ReconciliationOutlined />}>
              <Link to={`/NonBoundaryActivity`} activeclassname="active">
                Non Time Bound Activity
              </Link>
            </Menu.Item>
          }
          {
            (this.props.navbar.isTeamLeadSuccess || this.props.navbar.isAdminSuccess) &&
            <Menu.Item key="/Teams" icon={<ReconciliationOutlined />}>
              <Link to={`/Teams`} activeclassname="active">
                Teams
              </Link>
            </Menu.Item>
          }
          {(this.props.navbar.isAdminSuccess) &&
            <Menu.Item key="/Report" icon={<ReconciliationOutlined />}>
              <Link to={`/Report`} activeclassname="active">
                Report
              </Link>
            </Menu.Item>
          }

          {this.props.navbar.isTeamLeadSuccess &&
            <Menu.Item key="/TeamLeadReport" icon={<ReconciliationOutlined />}>
              <Link to={`/TeamLeadReport`} activeclassname="active">
                Team Report
              </Link>
            </Menu.Item>
          }
          {(this.props.navbar.isAdminSuccess) &&
          <Menu.Item key="/UploadSalesStage" icon={<ReconciliationOutlined />}>
              <Link to={`/UploadSalesStage`} activeclassname="active">
              Upload Sales Stage
              </Link>
            </Menu.Item>}
          <Menu.Item
            style={{ marginTop: "20%" }}
            onClick={() => {
              this.props.logoutUser();
            }}
            key="Logout" icon={<PoweroffOutlined />}>
            Logout
          </Menu.Item>
        </Menu>
      </div>
    </>)
  }
}

const mapStateToProps = (state) => {
  return {
    navbar: state.usersReducers
  }
}

export default connect(mapStateToProps, Actions)(withRouter(Navbar));