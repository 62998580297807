import React, { Component } from 'react';
import Nav from "../../../containers/Navbar/Navbar"
import { connect } from 'react-redux';
import * as projectActions from "../../actions/projectsActions"
import ReactTable from 'react-table'
// import 'react-table/react-table.css'
import NavbarTop from "../../../containers/Navbar/NavbarTop"
import { Link } from 'react-router-dom'
import ProjectCard from '../../../containers/Project/ProjectCard'
import Loader from "../../../containers/Loader/Loader"
import Alert from '../../../containers/Alert/Alert';
import { Button } from 'antd';

class Projects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            imageURL: "",
            navSide: true,
            modalView: false,
            userModalView: false,
            projectIndexId: 0,
            projectName: "",
            search: "",
            users: [],
            salesStageName: "",
            loader: true,
            projectState: null,
            alertView: false,
            pageId: 1
        }
    }

    componentDidMount() {
        this.props.getProjects(this.state.pageId);
    }

    rowFunction = (state, rowInfo, column) => {
        if (rowInfo != null) {
            return {
                onClick: (e, handleOriginal) => {
                    this.setState({ rowId: rowInfo.original.projectId, actionView: true, rowIndex: rowInfo.index, rowData: rowInfo.original });
                },
                style: {
                    backgroundColor: (rowInfo.original.userTime < rowInfo.original.currentTime ? (this.state.rowIndex == rowInfo.index ? "#bc8bf4" : "#ff9984") : (this.state.rowIndex == rowInfo.index ? "rgba(240, 202, 53, 0.35)" : "")),
                    cursor: "pointer",
                    border: (this.state.rowIndex == rowInfo.index ? "2px solid #dadada" : "")
                }
            }
        } else {
            return {}
        }
    }

    activeProject = (id, name) => {
        var r = window.confirm(`Are you sure you want to active the ${name} project`);
        if (r == true) {
            this.props.activeProject(id)
        } else {

        }
    }
    completeProjects = (id, name) => {
        var r = window.confirm(`Are you sure you want to complete the ${name} project`);
        if (r == true) {
            this.props.completeProject(id)
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.projects.projectStatusChange) {
            return {
                alertView: true
            }
        }
        if (props.projects.projects) {
            return {
                loader: false
            }
        }

    }
    render() {

        return (
            <>
            {
                (!this.props.projects.projects || this.props.projects.projects.length === 0) &&
                <Loader />
            }
                {
                    this.state.userModalView &&
                    <div id="myModal" class="modal">
                        <span style={{ zIndex: 2000, marginTop: 70, fontSize: 25 }} class="close" onClick={() => { this.setState({ userModalView: false }) }}>X</span>
                        <div className="modal-content">
                            <div style={{ padding: 20 }}>
                                <h6>Assigned Users</h6>
                                <div>
                                    {
                                        this.state.users.map((user, index) => {
                                            if (user.userName != null && user.userName != "") {
                                                return (
                                                    <div className="row">
                                                        <div className="col-sm-1">
                                                            <span className="fa fa-user"></span>
                                                        </div>
                                                        <div className="col-sm-4">
                                                            <p>{user.userName}</p>

                                                        </div>
                                                        <div className="col-sm-4">
                                                            {(user.active == 1 ? <>
                                                                <button type="button" className="btn btn-success" style={{ padding: "3px 10px", fontSize: 11, width: 60 }}>Active</button>
                                                                {this.state.projectState == 0 ?
                                                                    <button type="button" disabled
                                                                        className="btn btn-default" style={{ padding: "3px 10px", fontSize: 11, width: 60, marginLeft: 5 }}>Inactive</button>
                                                                    :
                                                                    <button type="button" onClick={() => {
                                                                        var r = window.confirm(`Are you sure to inactive ${user.userName} from ${this.state.projectName} project`);
                                                                        if (r == true) {
                                                                            this.props.deleteUserAssign(user.userProjectId, index, this.state.projectIndexId, 0)
                                                                        }
                                                                    }}
                                                                        className="btn btn-default" style={{ padding: "3px 10px", fontSize: 11, width: 60, marginLeft: 5 }}>Inactive</button>}
                                                            </>
                                                                : (user.active == 0 ? <>
                                                                    {this.state.projectState == 0 ?
                                                                        <button type="button" disabled className="btn btn-default" style={{ padding: "3px 10px", fontSize: 11, width: 60 }}>Active</button>
                                                                        :
                                                                        <button type="button" onClick={() => {
                                                                            var r = window.confirm(`Are you sure to active ${user.userName} from ${this.state.projectName} project`);
                                                                            if (r == true) {
                                                                                this.props.deleteUserAssign(user.userProjectId, index, this.state.projectIndexId, 1)
                                                                            } else {
                                                                            }
                                                                        }} className="btn btn-default" style={{ padding: "3px 10px", fontSize: 11, width: 60 }}>Active</button>}
                                                                    <button type="button" className="btn btn-danger" style={{ padding: "3px 10px", fontSize: 11, width: 60, marginLeft: 5 }}>Inactive</button>
                                                                </>
                                                                    : null
                                                                )
                                                            )}
                                                        </div>
                                                        <div className="col-sm-3">
                                                            {this.state.projectState != 0 ?
                                                                <Link className="btn btn-primary" style={{ padding: "1px 2px", fontSize: 11, width: 60, marginLeft: 3, textDecoration: "none" }}
                                                                    to={{ pathname: `/EditAssign`, state: { rowData: user, rowId: user.userProjectId, index: index, projectName: this.state.projectName } }} >
                                                                    <span style={{ fontSize: 12 }}><icon className="fa fa-edit" style={{ marginTop: 5, marginLeft: 5 }} /> Edit</span>
                                                                </Link> :
                                                                <Link className="btn btn-light" style={{ padding: "1px 2px", fontSize: 11, width: 60, marginLeft: 3, textDecoration: "none", cursor: "not-allowed" }}>
                                                                    <span style={{ fontSize: 12 }}><icon className="fa fa-edit" style={{ marginTop: 5, marginLeft: 5 }} /> Edit</span>
                                                                </Link>
                                                            }
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })
                                    }
                                </div>
                            </div>
                        </div>
                        <div id="caption"></div>
                    </div>
                }
                <div className="table-container" >
                    {this.props.projects.projectStatusChange &&
                        <Alert alertMessage={this.props.projects.projectStatusChange} close={() => { this.props.closeAlert() }} />
                    }

                    <div className="row findbar">
                        <div className="col-md-6">
                            <input className="search_input" onChange={(value) => { 
                                const searchText = value.target.value;
                                this.setState({ search: searchText });
                                 }}
                                onKeyPress={(event) => {
                                    if (event.key === "Enter") {
                                        const searchText = this.state.search;
                                        this.setState({ search: searchText });
                                        this.props.getProjectSearch(this.state.search);
                                    } }}/>
                            <icon className="fa fa-search" style={{ marginLeft: 10 }} />
                        </div>
                        <div  style={{paddingLeft: '20%'}}>
                            <Button  style={{backgroundColor: 'rgb(239, 65, 35)' , color: 'white' , width: '150px' , height: '45px' , marginRight: '10px'}} onClick={() => {
                            if(this.state.pageId != 0){
                            this.setState({
                                pageId: this.state.pageId - 1
                            })
                        }else{
                            this.setState({
                                pageId: 1
                            })
                        }
                            this.props.getProjects(this.state.pageId);

                        }}>Back</Button>
                        </div>
                        <div><Button style={{backgroundColor: 'rgb(239, 65, 35)' , color: 'white' , width: '150px' , height: '45px' , marginRight: '10px'}} onClick={() => {
                            this.setState({
                                pageId: this.state.pageId + 1
                            })
                            this.props.getProjects(this.state.pageId);

                        }}>Next</Button>
                        </div>
                    </div>

                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {
                            this.props.projects && this.props.projects.projects && this.props.projects.projects &&
                            this.props.projects.projects.filter((project) => { return project.projectName })
                                .map((value, index) => {
                                    return <ProjectCard
                                        stateChange={(id, status) => (status == 0 ? this.props.completeProject(id) : this.props.activeProject(id))}
                                        setUserModalView={() => this.setState({
                                            userModalView: true,
                                            projectIndexId: index,
                                            projectName: value.projectName,
                                            projectCode: value.projectCode,
                                            projectState: value.status,
                                            users: value.users,
                                            salesStageName: value.salesStageName
                                        })} project={value} index={index} />
                                })
                        }
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        projects: state.projectReducer
    }
}

export default connect(mapStateToProps, projectActions)(Projects);


