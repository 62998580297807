import React, { useState, useEffect } from 'react';
import { Drawer, Typography, Input, Button, message, Select } from 'antd';
import {
    AppstoreAddOutlined
} from '@ant-design/icons';
import { Formik } from "formik"
import * as Yup from "yup"
import * as Actions from "../../actions/usersActions"
import { connect } from "react-redux"

const key = 'updatable';
const { Title, Text } = Typography;
const { Option } = Select;

const AddTeamMembersForm = ({ addTeamMembers, addTeamMember, teamMemberList, getTeams, getTeamsSuccess, addTeamMembersSuccess, getUsersDropdown, getUsersDropdownSearch, visible, onClose }) => {
    const [formSubmitted, setFormSubmitted] = useState(null)
    const addPMValidationSchema = Yup.object({
        teamId: Yup.string()
            .required('Please select team')
            .test("teamMemberValidate", "Team Member already exists", function (value) {
                if (value) {
                    var ifteamMemberGetIndex = teamMemberList.findIndex((teamMemberValue) => teamMemberValue.userEmail == JSON.parse(this.parent.user).mail && parseInt(value) == teamMemberValue.teamid);
                    if (ifteamMemberGetIndex == -1) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }),
        user: Yup.string()
            .required('Please select user')
    });
    useEffect(() => {
        getUsersDropdown();
        setFormSubmitted(addTeamMembersSuccess);
    }, [])

    useEffect(() => {
        if (addTeamMembersSuccess != formSubmitted) {
            message.success({ content: 'Successfully added team member', key });
            setFormSubmitted(addTeamMembersSuccess);
        }
    }, [addTeamMembersSuccess])

    return (
        <>
            <Drawer
                title="Add Team Members"
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
            >
                <Formik
                    initialValues={{ teamId: '', user: '' }}
                    validationSchema={addPMValidationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        message.loading({ content: 'Loading...', key });
                        var user = JSON.parse(values.user);
                        var team = JSON.parse(values.teamId);
                        addTeamMember(team.teamid, user.label.split("....")[0], user.mail, user.value, team.teamName)
                        setSubmitting(false);
                        resetForm({ teamId: '', user: '' });
                        setFormSubmitted(true);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <form >
                            <Text type="secondary">User</Text>
                            <Select
                                showSearch={true}
                                style={{ width: "100%" }}
                                name="user"
                                onSearch={(val) => getUsersDropdownSearch(val)}
                                onChange={(value) => { setFieldValue("user", value) }}
                                value={values.user}
                            >
                                {addTeamMembers.allUsers &&
                                    addTeamMembers.allUsers.filter((item) => typeof item.mail === 'string' 
                                    && item.mail.includes('millenniumitesp.com')).map((item) => {
                                        const name = item.label.split('.')[0]; 
                                        return <Option value={JSON.stringify(item)}>{name}</Option>
                                    })
                                }
                            </Select>
                            <Text type="danger">{errors.user && touched.user && errors.user}</Text>
                            <br />
                            <Text type="secondary">Team</Text>
                            <Select
                                showSearch={true}
                                style={{ width: "100%" }}
                                name="teamId"
                                onChange={(value) => { setFieldValue("teamId", value) }}
                                value={values.teamId}
                            >
                                {getTeamsSuccess &&
                                    getTeamsSuccess.map((item) => {
                                        return <Option value={JSON.stringify(item)}>{item.teamName}</Option>
                                    })
                                }
                            </Select>
                            <Text type="danger">{errors.teamId && touched.teamId && errors.teamId}</Text>
                            <Button danger type="primary" onClick={handleSubmit} style={{ marginTop: 10 }}>
                                <AppstoreAddOutlined /> Add Member
                            </Button>
                        </form>
                    )}
                </Formik>
            </Drawer>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        addTeamMembers: state.usersReducers
    }
}
export default connect(mapStateToProps, Actions)(AddTeamMembersForm)