import { GET_PROJECTS, EDIT_PROJECT_LOADER, EDIT_PROJECT_SUCCESS, GET_IMAGE_PROJECT_USERS, GET_IMAGE_SUCCESS, DELETE_ASSIGN_USER_SUCCESS, GET_PROJECT_STATUS, ADD_PROJECT_SUCCESS, ADD_PROJECT_ERROR, ACTIVE_PROJECT_SUCCESS, COMPLETE_PROJECT_SUCCESS, GET_COMPLETED_PROJECTS, GET_ACTIVE_PROJECTS, GET_TODAY_PROJECTS, GET_PROJECT, UPDATE_PROJECT, PROJECT_STATUS_CHANGE_LOADING, GET_PROJECTS_LOADING } from "../types"

const initialState = {
    projectStatusChange: false,
    project: [],
    getprojectsLoading: true,
    editProjectLoader: false
}

const projectReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROJECT:
            return { ...state, project: action.payload }
        case GET_IMAGE_PROJECT_USERS:
            var TaskList = state.projects;
            if (TaskList[action.index]) {
                TaskList[action.index].image = action.payload;
            }
            return { ...state, projects: TaskList };
        case GET_PROJECTS_LOADING:
            return { ...state, projects: [], getprojectsLoading: true }
        case GET_PROJECTS:
            return { ...state, projects: action.payload.value, getprojectsLoading: false, projectStatusChange: false, excel: action.payload.excel, taskSummaryArray: action.payload.taskSummaryArray };
        case GET_COMPLETED_PROJECTS:
            return { ...state, completedProjects: action.payload };
        case GET_ACTIVE_PROJECTS:
            return { ...state, activeProjects: action.payload };
        case GET_TODAY_PROJECTS:
            return { ...state, todayProjects: action.payload };
        case UPDATE_PROJECT:
            return { ...state, isUpdated: action.payload }
        case GET_PROJECT_STATUS:
            return { ...state, projectStatus: action.payload }
        case EDIT_PROJECT_LOADER:
            return { ...state, editProjectLoader: true }
        case EDIT_PROJECT_SUCCESS:
            return { ...state, editProjectLoader: false }
        case PROJECT_STATUS_CHANGE_LOADING:
            return { ...state, projectStatusChange: false }
        case COMPLETE_PROJECT_SUCCESS:
            var projectArray = state.projects;
            if (state.projects) {
                var indexProject = state.projects.findIndex((project) => {
                    return project.projectId == action.payload
                })
                if (indexProject != -1) {
                    var projectValue = projectArray[indexProject];
                    projectValue.isActive = false;
                    projectArray[indexProject] = projectValue;
                }
            }
            return { ...state, projects: projectArray, projectStatusChange: "Successfully completed" }
        case ACTIVE_PROJECT_SUCCESS:
            var projectArray = state.projects;
            if (state.projects) {
                var indexProject = state.projects.findIndex((project) => {
                    return project.projectId == action.payload
                })
                if (indexProject != -1) {
                    var projectValue = projectArray[indexProject];
                    projectValue.isActive = true;
                    projectArray[indexProject] = projectValue;
                }
            }
            return { ...state, projects: projectArray, projectStatusChange: "Successfully activated" }
        case ADD_PROJECT_SUCCESS:
            var returnValue = (state.value ? state.value : []);
            returnValue.push(action.payload);
            return { ...state, addProject: true };
        case ADD_PROJECT_ERROR:
            return { ...state, addProject: false };
        case DELETE_ASSIGN_USER_SUCCESS:
            var projectValue = state.projects[action.payload.projectIndex];
            var userValue = projectValue.users;
            userValue[action.payload.index].active = (userValue[action.payload.index].active == 1 ? 0 : 1);
            projectValue.users = userValue;
            state.projects[action.payload.projectIndex] = projectValue
            return { ...state, DeleteUserAssign: true }
        default:
            return state;
    }
}

export default projectReducer;
