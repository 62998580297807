import React, { useEffect } from 'react';
import * as Actions from "../../actions/usersActions"
import { connect } from "react-redux"
import { Layout, Menu, Breadcrumb } from 'antd';
import { Tabs } from 'antd';
import "../../../assets/css/nonBoundaryActivity.scss"
import Teams from './Teams';
import Members from './Members';
import Auth from "../../../services/Auth"

const { TabPane } = Tabs;
const { Content } = Layout;

const Index = ({ getUsersDropdown, getUsersDropdownSearch, user, isAdmin }) => {
    useEffect(() => {
        getUsersDropdown();
    }, [])

    return ((user.isAdminSuccess || user.isTeamLeadSuccess) && <div id="role" className="contain-fluid table-container" style={{ minWidth: "100%", minHeight: window.innerHeight - 200, backgroundColor: "#eeeeee" }}>
        <div className="card-container">
            <Tabs theme="dark" type="card" style={{ height: window.innerHeight - 100 }}>
                <TabPane tab="Teams" key="1" className="scroll" style={{ height: window.innerHeight - 100, overflowY: "scroll", paddingBottom: 50 }} >
                    <Teams isAdminSuccess={user.isAdminSuccess}
                        setAddData={() => { }} />
                </TabPane>
                <TabPane tab="Team Members" key="2" className="scroll" style={{ height: window.innerHeight - 100, overflowY: "scroll", paddingBottom: 50 }}>
                    <Members />
                </TabPane>
            </Tabs>
        </div>
    </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.usersReducers
    }
}

export default connect(mapStateToProps, Actions)(Index)