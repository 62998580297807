import React, { useState, useEffect } from 'react';
import { Button, Popconfirm } from 'antd';
import {
    AppstoreAddOutlined
} from '@ant-design/icons';
import * as Actions from "../../actions/nonBoundaryActions"
import { connect } from 'react-redux';
import TableContainer from '../Role/TableContainer';
import AddDepartment from './AddDepartment';
import Loader from "../../../containers/Loader/Loader"
import Table from '../Role/Table';
import AuthContext from '../../../services/Auth'

const Department = ({ users, isAdminSuccess, getDepartmentNonBoundryActivityForHead, deactivatedNonTimeBoundActivity, activateNonTimeBoundActivity, getDepartmentNonBoundryActivity, departmentNBA, getUsers, addDepartmentNonBoundryActivity }) => {
    const [addData, setAddData] = useState(false);

    useEffect(() => {
        if (isAdminSuccess) {
            getDepartmentNonBoundryActivity();
        } else {
            getDepartmentNonBoundryActivityForHead(AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.oid);
        }
    }, [isAdminSuccess])

    const columns = [{
        Header: 'Activity Name',
        accessor: 'projectName',
        key: 'projectName',
        onFilter: (value, record) => record.projectName.includes(value),
        sorter: {
            compare: (a, b) => ('' + a.projectName).localeCompare(b.projectName),
            multiple: 3,
        }
    }, {
        Header: 'Description',
        accessor: 'message',
        key: 'message',
        onFilter: (value, record) => record.message.includes(value),
        sorter: {
            compare: (a, b) => ('' + a.message).localeCompare(b.message),
            multiple: 3,
        }
    }, {
        Header: 'Department',
        accessor: 'department',
        key: 'department',
        onFilter: (value, record) => record.department.includes(value),
        sorter: {
            compare: (a, b) => ('' + a.department).localeCompare(b.department),
            multiple: 3,
        }
    }, {
        Header: '',
        accessor: 'active',
        Cell: props => <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            {
                props.row.original.isActive ?
                    <>

                        <Button disabled={true} type="primary" style={{ fontSize: 12, width: 80 }} >Active</Button>
                        <Popconfirm
                            style={{ marginLeft: 20 }}
                            placement="left" title={"Are you sure to deactive this project?"}
                            onConfirm={() => {
                                deactivatedNonTimeBoundActivity(props.row.original.projectId, "Department")
                            }}
                            okText="Yes"
                            cancelText="No">
                            <Button danger style={{ fontSize: 12, width: 80 }} >Deactive</Button>
                        </Popconfirm>
                    </>

                    :
                    <>
                        <Popconfirm placement="left" title={"Are you sure to active this project?"} onConfirm={() => {
                            activateNonTimeBoundActivity(props.row.original.projectId, "Department")
                        }} okText="Yes" cancelText="No">
                            <Button type="primary" style={{ fontSize: 12, width: 80 }} >Active</Button>
                        </Popconfirm>

                        <Button disabled={true} danger style={{ fontSize: 12, width: 80 }} >Deactive</Button>
                    </>

            }

        </div>
    }]

    return (
        <div>
            {departmentNBA.getDepartmentNBALoading &&
                <Loader />
            }
            <div className="buttonRight">
                <Button type="primary" danger onClick={() => setAddData(true)}>
                    <AppstoreAddOutlined /> Add Non Time Bound Activity for Department
            </Button>
            </div>
            {/* {departmentNBA.getDepartmentNBASuccess &&
                <TableContainer
                    data={departmentNBA.getDepartmentNBASuccess}
                    loading={departmentNBA.getDepartmentNBALoading}
                    error={departmentNBA.getDepartmentNBError}
                    title={"Organization Non Time Bound Activity"}
                    columns={columns}
                />} */}

            <Table
                columns={columns}
                data={departmentNBA.getDepartmentNBASuccess}
            />
            <AddDepartment
                visible={addData}
                addDepartmentsNBASuccess={departmentNBA.addDepartmentsNBASuccess}
                onClose={() => { setAddData(false) }}
                addDepartment={(projectName, projectDescription, department) => { addDepartmentNonBoundryActivity(projectName, projectDescription, department) }}
            />
        </div>
    );
};
const mapSateToProps = (state) => {
    return {
        departmentNBA: state.nonBoundaryActivity,
        isAdminSuccess: state.usersReducers.isAdminSuccess
    }
}

export default connect(mapSateToProps, Actions)(Department);