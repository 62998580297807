import React, { useState, useEffect } from 'react';
import { Button, Popconfirm } from 'antd';
import "../../../assets/css/nonBoundaryActivity.scss"
import {
    UserAddOutlined,
    DeleteFilled
} from '@ant-design/icons';

import * as Actions from "../../actions/roleActions"
import Loader from "../../../containers/Loader/Loader"
import { connect } from 'react-redux';
import TableContainer from './TableContainer';
import AddTeamLead from './AddTeamLead';
import Table from './Table';

const TeamLead = ({ team, teamLeadState, getUsersDropdown, getDepartment, getTeamLeads, getUsersDropdownSearch, user, deleteTeamLead }) => {
    const [addTeamLead, setAddTeamLead] = useState(false);

    useEffect(() => {
        getTeamLeads();
        getDepartment('');
    }, [])

    const columns = [{
        Header: 'User Email',
        accessor: 'userEmail',
        key: 'userEmail',
        onFilter: (value, record) => record.name.includes(value),
        sorter: {
            compare: (a, b) => ('' + a.userEmail).localeCompare(b.userEmail),
            multiple: 3,
        }
    }, {
        Header: 'User Name',
        accessor: 'userName',
        key: 'userName',
        sorter: {
            compare: (a, b) => ('' + a.userName).localeCompare(b.userName),
            multiple: 3,
        }
    }, {
        Header: 'Team',
        accessor: 'team',
        key: 'team',
        sorter: {
            compare: (a, b) => ('' + a.team).localeCompare(b.team),
            multiple: 3,
        }
    }, {
        Header: 'Department',
        accessor: 'department',
        key: 'department',
        sorter: {
            compare: (a, b) => ('' + a.department).localeCompare(b.department),
            multiple: 3,
        }
    }, {
        Header: '',
        accessor: 'active',
        Cell: props => <Popconfirm placement="left" title={"Are you sure to delete this team lead?"} onConfirm={() => {
            deleteTeamLead(props.row.original.id);
        }} okText="Yes" cancelText="No">
            <Button style={{ fontSize: 12 }} ><DeleteFilled style={{ color: "red" }} /></Button>
        </Popconfirm>
    }];

    return (
        <div>
            { teamLeadState.getTeamLeadsLoading &&
                <Loader />
            }
            <div className="buttonRight">
                <Button type="primary" danger onClick={() => setAddTeamLead(true)}>
                    <UserAddOutlined /> Add Team Lead
                        </Button>
            </div>
            {/* <TableContainer
                data={teamLeadState.getTeamLeadsSuccess}
                loading={teamLeadState.getTeamLeadsLoading}
                error={teamLeadState.getTeamLeadsError}
                title={"Department Heads"}
                columns={columns}
            /> */}
            <Table
                columns={columns}
                data={teamLeadState.getTeamLeadsSuccess}
            />
            <AddTeamLead
                getDepartments={(value) => {
                    if (value.length > 2 & value.length % 2 == 0) {
                        getDepartment(value);
                    }
                }}
                teamLeadLists={teamLeadState.getTeamLeadsSuccess}
                departments={teamLeadState.getDepartmentsSuccess}
                getUsers={(value) => {
                    if (value.length == 2) {
                        getUsersDropdown();
                    } else if (value.length > 2 & value.length % 2 == 0) {
                        getUsersDropdownSearch(value);
                    }
                }}
                teams={team.getAllTeamsSuccess}
                users={user.allUsers}
                visible={addTeamLead}
                onClose={() => { setAddTeamLead(false) }}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        teamLeadState: state.roleReducers
    }
}


export default connect(mapStateToProps, Actions)(TeamLead);
