import React, { useEffect } from 'react';
import { getAllTeams } from "../../actions/teamsActions"
import { getUsersDropdownSearch, getUsersDropdown } from "../../actions/usersActions"
import { connect } from "react-redux"
import { Layout } from 'antd';
import { Tabs } from 'antd';
import "../../../assets/css/nonBoundaryActivity.scss"
import ProjectManagers from './ProjectManagers';
import DepartmentHead from './DepartmentHead';
import TeamLead from './TeamLead';
import Admin from './Admin';
const { TabPane } = Tabs;
const { Content } = Layout;

const RoleAssign = ({ team, user, getAllTeams, getUsersDropdown, getUsersDropdownSearch }) => {

    useEffect(() => {
        getAllTeams();
        getUsersDropdown();
    }, [])


    return (user.isAdminSuccess &&
        <div id="role" className="contain-fluid table-container" style={{ minWidth: "100%", minHeight: window.innerHeight - 200, backgroundColor: "#eeeeee" }}>
            <div className="card-container">
                <Tabs theme="dark" type="card" style={{ height: window.innerHeight - 100 }}>
                    <TabPane className="scroll" tab="Project Managers" key="2" style={{ height: window.innerHeight - 100, overflow: "scroll", paddingBottom: 50 }} >
                        <ProjectManagers
                            team={team}
                            getUsersDropdownSearch={(searchKey) => getUsersDropdownSearch(searchKey)}
                            getUsersDropdown={() => getUsersDropdown()}
                            user={user}
                        />
                    </TabPane>
                    <TabPane tab="Department Heads" key="5" className="scroll" style={{ height: window.innerHeight - 100, overflowY: "scroll", paddingBottom: 50 }} >
                        <DepartmentHead
                            team={team}
                            getUsersDropdownSearch={(searchKey) => getUsersDropdownSearch(searchKey)}
                            getUsersDropdown={() => getUsersDropdown()}
                            user={user}
                        />
                    </TabPane>
                    <TabPane tab="Team Leads" key="4" className="scroll" style={{ height: window.innerHeight - 100, overflowY: "scroll", paddingBottom: 50 }} >
                        <TeamLead
                            team={team}
                            getUsersDropdownSearch={(searchKey) => getUsersDropdownSearch(searchKey)}
                            getUsersDropdown={() => getUsersDropdown()}
                            user={user}
                        />
                    </TabPane>
                    <TabPane tab="Admins" key="1" className="scroll" style={{ height: window.innerHeight - 100, overflowY: "scroll", paddingBottom: 50 }} >
                        <Admin
                            team={team}
                            getUsersDropdownSearch={(searchKey) => getUsersDropdownSearch(searchKey)}
                            getUsersDropdown={() => getUsersDropdown()}
                            user={user}
                        />
                    </TabPane>
                    {/* <TabPane tab="Report Managers" key="3" style={{ height: window.innerHeight - 200 }}>
                        <p>Content of Tab Pane 3</p>
                        <p>Content of Tab Pane 3</p>
                        <p>Content of Tab Pane 3</p>
                    </TabPane> */}
                </Tabs>
            </div>
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        team: state.team,
        user: state.usersReducers
    }
}

export default connect(mapStateToProps, { getAllTeams, getUsersDropdownSearch, getUsersDropdown })(RoleAssign)