import React, { useState, useEffect } from 'react';
import { Drawer, Typography, Input, Button, message, Select } from 'antd';
import {
    AppstoreAddOutlined
} from '@ant-design/icons';
import { Formik } from "formik"
import * as Yup from "yup"
import * as Actions from "../../actions/teamsActions"
import { connect } from "react-redux"
import AuthContext from '../../../services/Auth'

const key = 'updatable';
const { Title, Text } = Typography;
const { Option } = Select;

const AddTeam = ({ onClose, visible, isAdminSuccess, addTeamNBA, getAllTeamsDropdown, addTeamNonBoundaryActivity, getTeams, addTeamNBASuccess }) => {
    const [formSubmitted, setFormSubmitted] = useState(null)
    const addTeamValidationSchema = Yup.object({
        projectName: Yup.string()
            .required('Please enter name'),
        team: Yup.string()
            .required('Please select team'),
        description: Yup.string()
            .required('Please enter description')
    });

    useEffect(() => {
        if (isAdminSuccess) {
            getAllTeamsDropdown();
        } else {
            getTeams(AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.oid);
        }
    }, [])

    useEffect(() => {
        if (addTeamNBASuccess != formSubmitted) {
            message.success({ content: 'Successfully added activity', key });
            setFormSubmitted(addTeamNBASuccess);
        }
    }, [addTeamNBASuccess])

    return (
        <>
            <Drawer
                title="Add Non Time Bound Activity for Team"
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
            >
                <Formik
                    initialValues={{ projectName: '', description: '', team: '' }}
                    validationSchema={addTeamValidationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        message.loading({ content: 'Loading...', key });
                        var team = JSON.parse(values.team);
                        addTeamNonBoundaryActivity(values.projectName, team, values.description);
                        setSubmitting(false);
                        resetForm({ projectName: '', description: '', team: '' });
                        setFormSubmitted(true);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <form >
                            <Text type="secondary">Activity Name</Text>
                            <Input
                                name="projectName"
                                onChange={(value) => { setFieldValue("projectName", value.target.value) }}
                                value={values.projectName}
                            />
                            <Text type="danger">{errors.projectName && touched.projectName && errors.projectName}</Text>

                            <br />

                            <Text type="secondary">Team</Text>
                            <Select showSearch={true} style={{ width: "100%" }}
                                name="team"
                                onChange={(value) => { setFieldValue("team", value) }}
                                // onBlur={handleBlur}
                                value={values.team}
                            >
                                {addTeamNBA.getTeamsSuccess &&
                                    addTeamNBA.getTeamsSuccess.map((item) => {
                                        return <Option value={JSON.stringify(item)}>{item.teamName}</Option>
                                    })
                                }
                            </Select>
                            <Text type="danger">{errors.team && touched.team && errors.team}</Text>
                            <br />
                            <Text type="secondary">Description</Text>
                            <Input
                                name="description"
                                onChange={(value) => { setFieldValue("description", value.target.value) }}
                                value={values.description}
                            />
                            <Text type="danger">{errors.description && touched.description && errors.description}</Text>
                            <Button danger type="primary" onClick={handleSubmit} disabled={isSubmitting} style={{ marginTop: 10 }}>
                                <AppstoreAddOutlined /> Add Activity
                                </Button>
                        </form>
                    )}
                </Formik>
            </Drawer>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        addTeamNBA: state.team,
        isAdminSuccess: state.usersReducers.isAdminSuccess
    }
}
export default connect(mapStateToProps, Actions)(AddTeam)