import {
    GET_ALL_SUPPORT_ERROR,
    GET_ALL_SUPPORT_LOADING,
    GET_ALL_SUPPORT_SUCCESS,
    ASSIGN_USERS_TO_SUPPORT_ERROR,
    ASSIGN_USERS_TO_SUPPORT_LOADING,
    ASSIGN_USERS_TO_SUPPORT_SUCCESS,
    ADD_SUPPORT_ERROR,
    ADD_SUPPORT_LOADING,
    COMPLETE_SUPPORT_SUCCESS,
    ACTIVE_SUPPORT_SUCCESS,
    ADD_SUPPORT_SUCCESS,
    CONFIRM_SUPPORT_SUCCESS,
    GET_SUPPORT_ERROR, GET_SUPPORT_LOADING, GET_SUPPORT_SUCCESS, GET_SUPPORTS_SUCCESS, GET_SUPPORTS_ERROR, GET_SUPPORTS_LOADING,SALES_STAGE_CHANGE_LOADING , SALES_STAGE_CHANGE_SUCCESS, SALES_STAGE_CHANGE_ERROR
} from "../types/support"
//import { GET_ALL_SUPPORT_ERROR, GET_ALL_SUPPORT_LOADING, GET_ALL_SUPPORT_SUCCESS, GET_SUPPORT_ERROR, GET_SUPPORT_LOADING, GET_SUPPORT_SUCCESS } from "../types/support";

const initialState = {
    getAllSupport: [],
    assignSupportUsersList: [],
    supportList: [],
    getAllSupportError: null,
    getAllSupportLoading: false,
    assignUsersToSupportError: null,
    assignUsersToSupportLoading: false,
    addSupportError: null,
    addSupportLoading: false,
    confirmSubmit: false,
    confirmAddSupport: false,
    confirm: false,

    getSupports: [],
    getSupportsLoading: false,
    getSupportsError: null,

    getSupport: null,
    getSupportError: null,
    getSupportLoading: false,

    addSalesStageLoading: false,
    addSalesStage: [],
    addSalesStageError: false
}

const supportReducers = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_SUPPORT_SUCCESS:
            return { ...state, getAllSupport: action.data.data, getAllSupportError: null, getAllSupportLoading: false }
        case GET_ALL_SUPPORT_ERROR:
            return { ...state, getAllSupport: [], getAllSupportError: action.error, getAllSupportLoading: false }
        case GET_ALL_SUPPORT_LOADING:
            return { ...state, getAllSupportLoading: action.loading, getAllSupport: [], getAllSupportError: null }

        case ASSIGN_USERS_TO_SUPPORT_LOADING:
            return { ...state, assignUsersToSupportLoading: action.loading, confirmSubmit: false, assignSupportUsersList: [], assignUsersToSupportError: null }
        case ASSIGN_USERS_TO_SUPPORT_SUCCESS:
            return { ...state, assignUsersToSupportLoading: false, confirmSubmit: true, assignSupportUsersList: action.data, assignUsersToSupportError: null }
        case ASSIGN_USERS_TO_SUPPORT_ERROR:
            return { ...state, assignUsersToSupportLoading: false, confirmSubmit: false, assignSupportUsersList: [], assignUsersToSupportError: action.error }

        case ADD_SUPPORT_LOADING:
            return { ...state, addSupportLoading: action.loading, confirmAddSupport: false, supportList: [], addSupportError: null }
        case ADD_SUPPORT_SUCCESS:
            return { ...state, addSupportLoading: false, supportList: action.data, confirmAddSupport: true, addSupportError: null }
        case ADD_SUPPORT_ERROR:
            return { ...state, addSupportLoading: false, confirmAddSupport: false, supportList: [], addSupportError: action.error }
        case SALES_STAGE_CHANGE_LOADING:
                return { ...state, addSalesStageLoading: action.loading, addSalesStage: null, addSalesStageError: null }
        case SALES_STAGE_CHANGE_SUCCESS:
                return { ...state, addSalesStageLoading: false, addSalesStage: action.data, addSalesStageError: null }
        case SALES_STAGE_CHANGE_ERROR:
                return { ...state, addSalesStageLoading: false, addSalesStage: null, addSalesStageError: action.error }
        case CONFIRM_SUPPORT_SUCCESS:
            return { ...state, confirmAddSupport: false, confirmSubmit: false }
        case COMPLETE_SUPPORT_SUCCESS:
            var indexProject = state.getSupports.findIndex((project) => {
                return project.id == action.payload
            })
            var projectArray = state.getSupports;
            if (indexProject != -1) {
                var projectValue = projectArray[indexProject];
                projectValue.isActive = false;
                projectArray[indexProject] = projectValue;
            }
            return { ...state, getSupports: projectArray, projectStatusChange: "Successfully completed" }
        case ACTIVE_SUPPORT_SUCCESS:
            var indexProject = state.getSupports.findIndex((project) => {
                return project.id == action.payload
            })
            var projectArray = state.getSupports;
            if (indexProject != -1) {
                var projectValue = projectArray[indexProject];
                projectValue.isActive = true;
                projectArray[indexProject] = projectValue;
            }
            return { ...state, getSupports: projectArray, projectStatusChange: "Successfully activated" }
        case GET_SUPPORT_SUCCESS:
            return { ...state, getSupport: action.data, getSupportError: null, getSupportLoading: false }
        case GET_SUPPORT_ERROR:
            return { ...state, getSupport: [], getSupportError: action.error, getSupportLoading: false }
        case GET_SUPPORT_LOADING:
            return { ...state, getSupportLoading: action.loading, getSupport: [], getSupportError: null }

        case GET_SUPPORTS_SUCCESS:
            return { ...state, getSupports: action.data, getSupportsLoading: false, getSupportsError: null }
        case GET_SUPPORTS_ERROR:
            return { ...state, getSupports: [], getSupportsLoading: false, getSupportsError: action.error }
        case GET_SUPPORTS_LOADING:
            return { ...state, getSupports: [], getSupportsLoading: action.loading, getSupportsError: null }

        default:
            return state;
    }
}

export default supportReducers;