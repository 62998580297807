import React, { Component } from "react"
import Nav from "../../../containers/Navbar/Navbar"
import NavbarTop from "../../../containers/Navbar/NavbarTop"
import Select from "react-select"
import * as Actions from "../../actions/usersActions"
import { connect } from "react-redux"
import Alert from "../../../containers/Alert/Alert"

class EditAssign extends Component {
    constructor(props) {
        super(props)
        this.state = {
            navSide: true,
            projectId: 0,
            projectName: "",
            submitData: false,
            selectedOptionProject: {},
            selectedOptionUsers: [],
            projectAssignId: 0,
            viewModal: false,
            viewMessage: false,
            validation: false,
            viewModalStatus: true,
            selectedOptionProjectError: "",
            selectedOptionUsersError: "",
            timeError: "",
            time: 0,
            editUser: false
        }
    }

    componentDidMount() {
        const { rowData, rowId, index, projectName } = this.props.location.state;
        this.setState({ projectName: projectName, index: index, projectAssignId: rowId, time: rowData.userAssignTime, selectedOptionProject: { value: rowData.projectId, label: rowData.projectName }, selectedOptionUsers: [{ value: rowData.userId, label: rowData.userName, mail: rowData.userEmail }] })
        this.setState({ viewMessage: false })
    }


    static getDerivedStateFromProps = (nextProps, state) => {
        if (state.submitData == true && nextProps.UsersAssign.editAssignMessage) {
            setTimeout(() => {
                nextProps.EditAssignAlert()
                nextProps.history.push("/Projects");
                return {
                    submitData: false
                }
            }, [2000])

        }
        if (state.viewModalStatus) {
            if (nextProps.UsersAssign.usersAssignMessage) {
                return {
                    navigationStatus: nextProps.UsersAssign.usersAssignMessage,
                    selectedOptionProject: {},
                    selectedOptionUsers: [],
                    time: 0,
                    viewModalStatus: false
                }
            } else if (nextProps.UsersAssign.usersAssignMessage == false) {
                return {
                    viewMessage: true,
                    viewModalStatus: false
                }
            }
        }

        if (nextProps.UsersAssign.usersAssign) {
            return {
                viewModal: nextProps.UsersAssign.usersAssign,
            }
        } else {
            return {
                nextProps
            }
        }
    }
    handleChangeProject = (selectedOptionProject) => {
        this.setState({ selectedOptionProject });
    }

    handleChangeUser = (selectedOptionUsers) => {
        this.setState({ selectedOptionUsers });
    }

    AssignUser = () => {


        if (!this.state.time || this.state.time == "" || this.state.time < 0) {
            this.setState({ timeError: "Please enter correct time" });
        } else {
            this.setState({ editUser: true, submitData: true, timeError: "" })
            this.props.EditAssignUsers(this.state.time, this.state.projectAssignId, this.state.index)
        }
    }

    isEmpty = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }
    render() {
        return (
            <>

                {this.props.UsersAssign.editAssignUserAlert &&
                    <Alert alertMessage={this.props.UsersAssign.editAssignUserAlert} close={() => this.props.EditAssignAlert()} />
                }

                <div className="container">
                    <div className="card" style={{ padding: 10, paddingTop: 30, paddingBottom: 30 }}>
                        <h2 style={{ fontWeight: 700 }}>Edit Assign User</h2>

                        <div className="row" style={{ padding: 10 }}>

                            <div className="col-md-4">
                                <p>Project Name</p>
                            </div>
                            <div className="col-md-8">
                                <input className="form-control" value={this.state.projectName} disabled={true} />
                            </div>
                            <div className="col-md-12">
                                <p style={{ color: "red" }}>{this.state.selectedOptionProjectError}</p>
                            </div>
                            <div className="col-md-4" style={{ marginTop: 20 }}>
                                <p>Users</p>
                            </div>
                            <div className="col-md-8" style={{ marginTop: 20 }}>
                                <input className="form-control"
                                    value={this.state.selectedOptionUsers.length && this.state.selectedOptionUsers[0].label}
                                    disabled={true} />
                            </div>
                            <div className="col-md-12">
                                <p style={{ color: "red" }}>{this.state.selectedOptionUsersError}</p>
                            </div>
                            <div className="col-md-4" style={{ marginTop: 20 }}>
                                <p>Time <span style={{ color: "red" }}>*</span></p>
                            </div>
                            <div className="col-md-8" style={{ marginTop: 20 }}>
                                <input type="number" min="0" className="form-control" value={this.state.time} onChange={(value) => {
                                    this.setState({ time: value.target.value });
                                }
                                } />
                            </div>
                            <div className="col-md-12">
                                <p style={{ color: "red" }}>{this.state.timeError}</p>
                            </div>
                            <div className="col-md-12" style={{ marginTop: 20 }}>
                                {this.props.UsersAssign.editAssignUserLoader == false ?
                                    <button className="btn-submit" style={{ float: "right" }} onClick={() => { this.AssignUser() }}>Assign Users</button>
                                    :
                                    <button className="btn-submit" style={{ float: "right" }} >
                                        <icon className="fa fa-spinner fa-spin" style={{ color: "white" }} />
                                    </button>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return { UsersAssign: state.usersReducers }
}

export default connect(mapStateToProps, Actions)(EditAssign);



