
import React, { useState, useEffect, useRef } from 'react';
import { connect } from "react-redux"

import { Formik, Form, Field, useFormikContext } from "formik"
import * as Yup from "yup"
import PhotoshopPicker from "react-color"
import AuthContext from '../../../services/Auth'
import { addSupport, confirmSubmit } from "../../actions/supportActions"
import { message, Input } from 'antd';

const AddSupport = (props) => {

    const [color, setColor] = useState("#000000");
    const [imageSize, setImageSize] = useState(0);
    const [imageData, setImageData] = useState("");
    const [initial, setInitial] = useState({ supportCode: '', supportName: '', message: '', image: '', expectedHours: 0 });
    const [pickerView, setPickerView] = useState(false);
    const imageInputRef = React.useRef();
    const formikRef = useRef();

    let loggedUser = AuthContext.getCachedUser().profile.unique_name

    const { addSupport, SupportLoading, confirm, confirmSubmit } = props;



    useEffect(() => {

        if (confirm) {

            message.success("Successfully add support", 2, function () { confirmSubmit() })
            formikRef.current.resetForm();
            setInitial({ supportCode: '', supportName: '', message: '', image: '', expectedHours: '' })
            clearFileInput("imageUpload");
            setColor("#000000")
            setImageSize(0);
            setImageData("");

        }


    }, [confirm])



    const clearFileInput = (id) => {
        // document.getElementById(id).value = "";
        var oldInput = document.getElementById(id);

        var newInput = document.createElement("input");

        newInput.type = "file";
        newInput.id = oldInput.id;
        newInput.name = oldInput.name;
        newInput.value = '';
        newInput.className = oldInput.className;
        newInput.style.cssText = oldInput.style.cssText;
        oldInput.parentNode.replaceChild(newInput, oldInput);

    }

    const imageStateChange = (value) => {
        var arrayBuffer = value.currentTarget.result;
        var imageSplit = arrayBuffer.split(",");
        setImageData(imageSplit[1]);
    }


    const imageUpload = (file) => {
        var reader = new FileReader();
        reader.onloadend = imageStateChange;
        setImageSize(file.size);
        reader.readAsDataURL(file);
    }

    const addSupportValidationSchema = Yup.object().shape({
        supportCode: Yup.string()
            .required('Please enter support code'),
        supportName: Yup.string()
            .required('Please enter support name'),
        message: Yup.string()
            .min(5, 'Description is too short !')
            .required('Please enter support description'),
        image: Yup.string()
            .required('Please select an image'),
        expectedHours: Yup.number()
            .typeError('Please enter Expected Hours')
            .required('Please enter Expected Hours')
            .min(1, 'Please enter Expected Hours')
            .nullable(true),
    });

    return (
        <>
            {
                pickerView &&
                <div id="pickerDiv" className="pickerDiv" >
                    <div style={{ width: "100%", height: "100%", position: "absolute" }} onClick={() => { setPickerView(false) }}>
                    </div>

                    <div className="pickerDivContent">

                        <div style={{ display: "flex", justifyContent: 'flex-end', cursor: "pointer" }} onClick={() => { setPickerView(false) }}>
                            <div style={{ backgroundColor: "black", color: "white", borderRadius: 20, width: 25, paddingLeft: 6 }}>X</div>
                        </div>

                        <PhotoshopPicker
                            color={color}
                            id="colorPickerzviewer"
                            onChangeComplete={(value) => { setColor(value.hex); }}
                        />
                    </div>
                </div>
            }



            <Formik
                initialValues={initial}
                innerRef={formikRef}
                validationSchema={addSupportValidationSchema}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    let color2 = {
                        color: color.replace('#', ''),
                    };
                    let user = {
                        createdBy: loggedUser
                    };

                    let data = Object.assign(values, color2, user);
                    data.imageData = imageData;
                    data.imageSize = imageSize;
                    addSupport(data)

                    setSubmitting(false)


                }}

            >

                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    dirty,
                    isValid,
                    handleReset,
                    isSubmitting,
                    setFieldValue
                }) => (
                    <Form>
                        <div className="content-div">
                            <div className="container-form">
                                <h2 style={{ fontWeight: 700 }}>Add Support Contract</h2>
                                <div className="row">
                                    <div className="col-md-6">
                                        <p>Support Code<span style={{ color: "red" }}>*</span></p>
                                        <Input value={values.supportCode} onChange={(value) => { setFieldValue('supportCode', value.target.value) }} type="text" className="form-control"

                                        />
                                        {errors.supportCode && touched.supportCode ?
                                            (
                                                <span style={{ color: "red" }}>{errors.supportCode}</span>
                                            ) : null}

                                    </div>
                                    <div className="col-md-6">
                                        <p>Support Name<span style={{ color: "red" }}>*</span></p>
                                        <Input value={values.supportName} onChange={(value) => { setFieldValue('supportName', value.target.value) }} type="text" className="form-control" />
                                        {errors.supportName && touched.supportName ?
                                            (
                                                <span style={{ color: "red" }}>{errors.supportName}</span>
                                            ) : null}
                                    </div>
                                    <div className="col-md-8">
                                        <p>Support Description<span style={{ color: "red" }}>*</span></p>
                                        <Input value={values.message} onChange={(value) => { setFieldValue('message', value.target.value) }} type="text" className="form-control" />
                                        {errors.message && touched.message &&

                                            <span style={{ color: "red" }}>{errors.message}</span>
                                        }
                                    </div>

                                    <div className="col-md-8">
                                        <p>Expected Hours<span style={{ color: "red" }}>*</span></p>
                                        <Input value={values.expectedHours} onChange={(value) => { setFieldValue('expectedHours', value.target.valueAsNumber) }} type="number" className="form-control" />
                                        {errors.expectedHours && touched.expectedHours &&

                                            <span style={{ color: "red" }}>{errors.expectedHours}</span>
                                        }
                                    </div>

                                    <div className="col-md-6">
                                        <p>Image<span style={{ color: "red" }}>*</span></p>
                                        <Input id="imageUpload" alt="Submit" accept="image/png, image/jpeg, image/jpg"
                                            onChange={(value) => {
                                                if (value.target.files[0]) {
                                                    const name = value.target.files[0].name;
                                                    const lastDot = name.lastIndexOf('.');
                                                    const ext = name.substring(lastDot + 1).toLowerCase();
                                                    if (ext == "png" || ext == "jpeg" || ext == "jpg") {
                                                        setFieldValue('image', value.target.files[0].name);
                                                        setImageData(value.target.files[0]);
                                                        setImageSize(value.target.files[0].size);
                                                        imageUpload(value.target.files[0]);
                                                    } else {
                                                        clearFileInput("imageUpload");
                                                        alert("Invalid file type")
                                                    }
                                                }

                                            }} type="file" className="form-control" />
                                        {errors.image && touched.image &&

                                            <span style={{ color: "red" }}>{errors.image}</span>
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <p>Color</p>
                                        <div id="colorPickerBtn" style={{ width: 100, backgroundColor: color, height: 20, cursor: "pointer", borderColor: "gray", border: 2 }}
                                            onClick={() => { (pickerView ? setPickerView(false) : setPickerView(true)) }}>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ display: "flex", justifyContent: 'flex-end' }}>

                                    {
                                        (
                                            SupportLoading ?
                                                <div className="btn-submit" ><icon className="fa fa-spinner fa-spin" style={{ color: "white" }} /></div>
                                                :
                                                <button type="submit" className="btn-submit" onClick={handleSubmit}  >Submit</button>

                                        )
                                    }

                                </div>
                            </div>
                        </div>
                    </Form>

                )}

            </Formik>


        </>
    );
}

const mapStateToProps = (state) => {

    return {

        SupportLoading: state.support.addSupportLoading,
        confirm: state.support.confirmAddSupport

    }
}

export default connect(mapStateToProps, { addSupport, confirmSubmit })(AddSupport);


/*{
JSON.parse(localStorage.getItem('Supportstatus')) && confirm?
    message.success("Successfully add support")
   :
  null
}
{confirm?
localStorage.setItem('Supportstatus',false)
:
localStorage.setItem('Supportstatus',true)
}*/