const { GET_ORGANIZATION_NBA_LOADING, ADD_DEPARTMENT_NBA_ERROR, ADD_DEPARTMENT_NBA_SUCCESS, ADD_DEPARTMENT_NBA_LOADING, GET_ORGANIZATION_NBA_SUCCESS, GET_ORGANIZATION_NBA_ERROR, ADD_ORGANIZATION_NBA_LOADING, ADD_ORGANIZATION_NBA_SUCCESS, ADD_ORGANIZATION_NBA_ERROR, GET_DEPARTMENT_NBA_LOADING, GET_DEPARTMENTS_SUCCESS, GET_DEPARTMENT_NBA_ERROR, GET_DEPARTMENT_NBA_SUCCESS, GET_TEAM_NBA_LOADING, GET_TEAM_NBA_SUCCESS, GET_TEAM_NBA_ERROR, ADD_TEAM_NBA_LOADING, ADD_TEAM_NBA_SUCCESS, ADD_TEAM_NBA_ERROR, ACTIVATE_NBA_LOADING, ACTIVATE_NBA_SUCCESS, ACTIVATE_NBA_ERROR, DEACTIVATED_NBA_LOADING, DEACTIVATED_NBA_SUCCESS, DEACTIVATED_NBA_ERROR } = require("../types");

const initialState = {
    getOrganizationNBALoading: false,
    getOrganizationNBASuccess: [],
    getOrganizationNBAerror: null,
    addOrganizationNBALoading: false,
    addOrganizationNBASuccess: null,
    addOrganizationNBAError: null,
    getDepartmentNBALoading: false,
    getDepartmentNBASuccess: [],
    getDepartmentNBAError: null,
    getTeamNBALoading: false,
    getTeamNBASuccess: [],
    getTeamNBAError: null,
    addTeamNBALoading: false,
    addTeamNBASuccess: null,
    addTeamNBAError: null,
    activateTeamNBALoading: false,
    activateTeamNBASuccess: null,
    activateTeamNBAError: null,
    deactivatedTeamNBALoading: false,
    deactivatedTeamNBASuccess: null,
    deactivatesTeamNBAError: null
}

const nonBoundryReducers = (state = initialState, actions) => {
    switch (actions.type) {
        case GET_ORGANIZATION_NBA_LOADING:
            return { ...state, getOrganizationNBALoading: true, getOrganizationNBASuccess: [], getOrganizationNBAerror: null }
        case GET_ORGANIZATION_NBA_SUCCESS:
            return { ...state, getOrganizationNBALoading: false, getOrganizationNBASuccess: actions.data, getOrganizationNBAerror: null }
        case GET_ORGANIZATION_NBA_ERROR:
            return { ...state, getOrganizationNBALoading: false, getOrganizationNBASuccess: [], getOrganizationNBAerror: actions.error }
        case ADD_ORGANIZATION_NBA_LOADING:
            return { ...state, addOrganizationNBALoading: actions.loading, addOrganizationNBASuccess: null, addOrganizationNBAError: null }
        case ADD_ORGANIZATION_NBA_SUCCESS:
            var arrayList = state.getOrganizationNBASuccess;
            arrayList.push(actions.data);
            return { ...state, addOrganizationNBALoading: false, addOrganizationNBASuccess: actions.data, getOrganizationNBASuccess: arrayList, addOrganizationNBAError: null }
        case ADD_ORGANIZATION_NBA_ERROR:
            return { ...state, addOrganizationNBALoading: false, addOrganizationNBASuccess: null, addOrganizationNBAError: actions.error }
        case GET_DEPARTMENT_NBA_LOADING:
            return { ...state, getDepartmentNBALoading: actions.loading, getDepartmentNBASuccess: [], getDepartmentNBAError: null }
        case GET_DEPARTMENT_NBA_SUCCESS:
            return { ...state, getDepartmentNBALoading: false, getDepartmentNBASuccess: actions.data, getDepartmentNBAError: null }
        case GET_DEPARTMENT_NBA_ERROR:
            return { ...state, getDepartmentNBALoading: false, getDepartmentNBASuccess: [], getDepartmentNBAError: actions.error }
        case ADD_DEPARTMENT_NBA_LOADING:
            return { ...state, addDepartmentsNBALoading: actions.loading, addDepartmentsNBASuccess: null, addDepartmentsNBAError: null }
        case ADD_DEPARTMENT_NBA_SUCCESS:
            var arrayList = state.getDepartmentNBASuccess;
            arrayList.push(actions.data)
            return { ...state, addDepartmentsNBALoading: false, addDepartmentsNBASuccess: actions.data, addDepartmentsNBAError: null, getDepartmentNBASuccess: arrayList }
        case ADD_DEPARTMENT_NBA_ERROR:
            return { ...state, addDepartmentsNBAError: actions.error, addDepartmentsNBALoading: false, addDepartmentsNBASuccess: null }
        case GET_TEAM_NBA_LOADING:
            return { ...state, getTeamNBALoading: actions.loading, getTeamNBASuccess: [], getTeamNBAError: null }
        case GET_TEAM_NBA_SUCCESS:
            return { ...state, getTeamNBALoading: false, getTeamNBASuccess: actions.data, getTeamNBAError: null }
        case GET_TEAM_NBA_ERROR:
            return { ...state, getTeamNBALoading: false, getTeamNBASuccess: [], getTeamNBAError: actions.error }
        case ADD_TEAM_NBA_LOADING:
            return { ...state, addTeamNBALoading: actions.loading, addTeamNBASuccess: null, addTeamNBAError: null }
        case ADD_TEAM_NBA_SUCCESS:
            var teamArray = state.getTeamNBASuccess;
            teamArray.push(actions.data);
            return { ...state, addTeamNBALoading: false, getTeamNBASuccess: teamArray, addTeamNBASuccess: actions.data, addTeamNBAError: null }
        case ADD_TEAM_NBA_ERROR:
            return { ...state, addTeamNBALoading: false, addTeamNBASuccess: null, addTeamNBAError: actions.error }
        case ACTIVATE_NBA_LOADING:
            return { ...state, activateTeamNBALoading: actions.loading, activateTeamNBASuccess: null, activateTeamNBAError: null }
        case ACTIVATE_NBA_SUCCESS:
            if (actions.NBAType == "Organization") {
                var organizationList = state.getOrganizationNBASuccess;
                var indexOfOrganization = organizationList.findIndex((NBActivty) => NBActivty.projectId == actions.data);
                organizationList[indexOfOrganization].isActive = true;
                return { ...state, activateTeamNBALoading: false, getOrganizationNBASuccess: organizationList, activateTeamNBASuccess: actions.data, activateTeamNBAError: null }
            } else if (actions.NBAType == "Department") {
                var departmentList = state.getDepartmentNBASuccess;
                var indexOfDepartment = departmentList.findIndex((NBActivty) => NBActivty.projectId == actions.data);
                departmentList[indexOfDepartment].isActive = true;
                return { ...state, deactivatedTeamNBALoading: false, getDepartmentNBASuccess: departmentList, deactivatedTeamNBASuccess: actions.data, deactivatesTeamNBAError: null }
            } else if (actions.NBAType == "Team") {
                var TeamList = state.getTeamNBASuccess;
                var indexOfTeam = TeamList.findIndex((NBActivty) => NBActivty.projectId == actions.data);
                TeamList[indexOfTeam].isActive = true;
                return { ...state, deactivatedTeamNBALoading: false, getTeamNBASuccess: TeamList, deactivatedTeamNBASuccess: actions.data, deactivatesTeamNBAError: null }
            } else {
                return { ...state, activateTeamNBALoading: false, activateTeamNBASuccess: actions.data, activateTeamNBAError: null }
            }
        case ACTIVATE_NBA_ERROR:
            return { ...state, activateTeamNBALoading: false, activateTeamNBASuccess: null, activateTeamNBAError: actions.error }
        case DEACTIVATED_NBA_LOADING:
            return { ...state, deactivatedTeamNBALoading: actions.loading, deactivatedTeamNBASuccess: null, deactivatesTeamNBAError: null }
        case DEACTIVATED_NBA_SUCCESS:
            if (actions.NBAType == "Organization") {
                var organizationList = state.getOrganizationNBASuccess;
                var indexOfOrganization = organizationList.findIndex((NBActivty) => NBActivty.projectId == actions.data);
                organizationList[indexOfOrganization].isActive = false;
                return { ...state, deactivatedTeamNBALoading: false, getOrganizationNBASuccess: organizationList, deactivatedTeamNBASuccess: actions.data, deactivatesTeamNBAError: null }
            } else if (actions.NBAType == "Department") {
                var departmentList = state.getDepartmentNBASuccess;
                var indexOfDepartment = departmentList.findIndex((NBActivty) => NBActivty.projectId == actions.data);
                departmentList[indexOfDepartment].isActive = false;
                return { ...state, deactivatedTeamNBALoading: false, getDepartmentNBASuccess: departmentList, deactivatedTeamNBASuccess: actions.data, deactivatesTeamNBAError: null }
            } else if (actions.NBAType == "Team") {
                var TeamList = state.getTeamNBASuccess;
                var indexOfTeam = TeamList.findIndex((NBActivty) => NBActivty.projectId == actions.data);
                TeamList[indexOfTeam].isActive = false;
                return { ...state, deactivatedTeamNBALoading: false, getTeamNBASuccess: TeamList, deactivatedTeamNBASuccess: actions.data, deactivatesTeamNBAError: null }
            } else {
                return { ...state, deactivatedTeamNBALoading: false, deactivatedTeamNBASuccess: actions.data, deactivatesTeamNBAError: null }
            }
        case DEACTIVATED_NBA_ERROR:
            return { ...state, deactivatedTeamNBALoading: false, deactivatesTeamNBAError: actions.error, deactivatedTeamNBASuccess: null }
        default:
            return state;
    }
}

export default nonBoundryReducers;