import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as usersActions from "../../actions/usersActions"
import * as projectActions from "../../actions/projectsActions"
import AuthContext from "../../../services/Auth"
import RecentActivities from "../../../containers/Activity/RecentActivities"
import ProjectsActivity from "../../../containers/Activity/ProjectsActivity"
import OverallActivities from "../../../containers/Activity/OverallActivities"
import CompletedProjectsActivity from "../../../containers/Activity/CompletedProjectsActivity"
import * as tasksActions from "../../actions/tasksActions"
// import { dispatch } from 'rxjs/internal/observable/pairs';
import Loader from "../../../containers/Loader/Loader"

class Activity extends Component {

    constructor(props) {
        super(props);
        this.state = {
            userId: "0",
            projects: [],
            loader: true
        }
    }

    componentDidMount() {
        var loggedInUser = AuthContext.getCachedUser();
        var dateNow = new Date(Date.now())
        this.setState({ userId: loggedInUser.profile.oid })
        this.props.usersActions.UserActivity(loggedInUser.profile.oid);
        this.props.tasksActions.getTasks(loggedInUser.profile.oid, dateNow.getFullYear() + "-" + (dateNow.getMonth() + 1) + "-" + dateNow.getDate());
        //this.props.projectActions.getProjects();
    }

    static getDerivedStateFromProps(props, state) {
        if (props.activity.usersReducers.projectAssignUsers &&
            props.activity.taskReducers.projects &&
            props.activity.usersReducers.userSpendedTime
            //&&
            // props.activity.projectReducer.projects
        ) {
            var projectList = [];
            for (var index = 0; index < props.activity.taskReducers.projects.length; index++) {
                var indexFind = projectList.findIndex((value) => value.name == props.activity.taskReducers.projects[index].name);
                if (indexFind == -1) {
                    projectList.push(props.activity.taskReducers.projects[index]);
                }
            }

            return {
                loader: false,
                projects: projectList
            }
        }


    }



    render() {
        return (
            <div className="table-container" >
                {
                    this.state.loader &&
                    <Loader />
                }
                <div className="row">
                    <div className="col-md-8">
                        <OverallActivities activity={this.props.activity.usersReducers.projectAssignUsers} />
                    </div>
                    <div className="col-md-4">
                        <ProjectsActivity projectList={this.state.projects} />
                    </div>
                    <div className="col-md-12">
                        <RecentActivities activity={this.props.activity.usersReducers.userSpendedTime} />
                    </div>

                </div>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        activity: state
    }
}

const mapDispatchToProps = dispatch => ({
    usersActions: bindActionCreators(usersActions, dispatch),
    tasksActions: bindActionCreators(tasksActions, dispatch),
    projectActions: bindActionCreators(projectActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Activity);