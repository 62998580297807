import React, { useState, useEffect } from 'react';
import { Button, Popconfirm } from 'antd';
import {
    AppstoreAddOutlined
} from '@ant-design/icons';
import * as Actions from "../../actions/nonBoundaryActions"
import { connect } from 'react-redux';
import TableContainer from '../Role/TableContainer';
import AddOrganization from './AddOrganization';
import Loader from "../../../containers/Loader/Loader"
import Table from '../Role/Table';

const Organization = ({ users, getOrganizationNonBoundaryActivity, organizationNBA, getUsers, addOrganizationNonBoundaryActivity, activateNonTimeBoundActivity, deactivatedNonTimeBoundActivity }) => {
    const [addData, setAddData] = useState(false);

    useEffect(() => {
        getOrganizationNonBoundaryActivity();
    }, [])

    const columns = [{
        Header: 'Activity Name',
        accessor: 'projectName',
        key: 'projectName',
        onFilter: (value, record) => record.projectName.includes(value),
        sorter: {
            compare: (a, b) => ('' + a.projectName).localeCompare(b.projectName),
            multiple: 3,
        }
    }, {
        Header: 'Description',
        accessor: 'message',
        key: 'message',
        onFilter: (value, record) => record.message.includes(value),
        sorter: {
            compare: (a, b) => ('' + a.message).localeCompare(b.message),
            multiple: 3,
        }
    }, {
        Header: '',
        accessor: 'active',
        Cell: props => <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            {
                props.row.original.isActive ?
                    <>

                        <Button disabled={true} type="primary" style={{ fontSize: 12, width: 80 }} >Active</Button>
                        <Popconfirm
                            style={{ marginLeft: 20 }}
                            placement="left" title={"Are you sure to deactive this project?"}
                            onConfirm={() => {
                                deactivatedNonTimeBoundActivity(props.row.original.projectId, "Organization")
                            }}
                            okText="Yes"
                            cancelText="No">
                            <Button danger style={{ fontSize: 12, width: 80 }} >Deactive</Button>
                        </Popconfirm>
                    </>

                    :
                    <>
                        <Popconfirm placement="left" title={"Are you sure to active this project?"} onConfirm={() => {
                            activateNonTimeBoundActivity(props.row.original.projectId, "Organization")
                        }} okText="Yes" cancelText="No">
                            <Button type="primary" style={{ fontSize: 12, width: 80 }} >Active</Button>
                        </Popconfirm>

                        <Button disabled={true} danger style={{ fontSize: 12, width: 80 }} >Deactive</Button>
                    </>

            }

        </div>
    }]

    return (
        <div>
            { organizationNBA.getOrganizationNBALoading &&
                <Loader />
            }
            <div className="buttonRight">
                <Button type="primary" danger onClick={() => setAddData(true)}>
                    <AppstoreAddOutlined /> Add Non Time Bound Activity for Organization
            </Button>
            </div>

            {/* {organizationNBA.getOrganizationNBASuccess &&
                <TableContainer
                    data={organizationNBA.getOrganizationNBASuccess}
                    loading={organizationNBA.getOrganizationNBALoading}
                    error={organizationNBA.getOrganizationNBAerror}
                    title={"Organization Non Time Bound Activity"}
                    columns={columns}
                />} */}

            <Table
                data={organizationNBA.getOrganizationNBASuccess}
                columns={columns}
            />
            <AddOrganization
                visible={addData}
                addOrganizationNBASuccess={organizationNBA.addOrganizationNBASuccess}
                onClose={() => { setAddData(false) }}
                AddOrganization={(projectName, projectDescription) => { addOrganizationNonBoundaryActivity(projectName, projectDescription) }}
            />
        </div>
    );
};
const mapStateToProps = (state) => {
    return {
        organizationNBA: state.nonBoundaryActivity
    }
}

export default connect(mapStateToProps, Actions)(Organization);