export const GET_ALL_SUPPORT_SUCCESS = "GET_ALL_SUPPORT_SUCCESS"
export const GET_ALL_SUPPORT_ERROR = "GET_ALL_SUPPORT_ERROR"
export const GET_ALL_SUPPORT_LOADING = "GET_ALL_SUPPORT_LOADING"

export const ASSIGN_USERS_TO_SUPPORT_SUCCESS = "ASSIGN_USERS_TO__SUPPORT_SUCCESS"
export const ASSIGN_USERS_TO_SUPPORT_ERROR = "ASSIGN_USERS_TO_SUPPORT__ERROR"
export const ASSIGN_USERS_TO_SUPPORT_LOADING = "ASSIGN_USERS_TO_SUPPORT__LOADING"

export const ADD_SUPPORT_SUCCESS = "ADD_SUPPORT_SUCCESS"
export const ADD_SUPPORT_ERROR = "ADD_SUPPORT_ERROR"
export const ADD_SUPPORT_LOADING = "ADD_SUPPORT_LOADING"

export const CONFIRM_SUPPORT_SUCCESS = "CONFIRM_SUPPORT_SUCCESS"
export const CONFIRM_SUPPORT_ERROR = "CONFIRM_SUPPORT_ERROR"
export const CONFIRM_SUPPORT_LOADING = "CONFIRM_SUPPORT_LOADING"

export const GET_SUPPORT_SUCCESS = "GET_SUPPORT_SUCCESS"
export const GET_SUPPORT_ERROR = "GET_SUPPORT_ERROR"
export const GET_SUPPORT_LOADING = "GET_SUPPORT_LOADING"

export const GET_SUPPORTS_SUCCESS = "GET_SUPPORTS_SUCCESS"
export const GET_SUPPORTS_ERROR = "GET_SUPPORTS_ERROR"
export const GET_SUPPORTS_LOADING = "GET_SUPPORTS_LOADING"

export const COMPLETE_SUPPORT_SUCCESS = "COMPLETE_SUPPORT_SUCCESS"
export const ACTIVE_SUPPORT_SUCCESS = "ACTIVE_SUPPORT_SUCCESS"

export const SALES_STAGE_CHANGE_LOADING = "SALES_STAGE_CHANGE_LOADING"
export const SALES_STAGE_CHANGE_SUCCESS = "SALES_STAGE_CHANGE_SUCCESS"
export const SALES_STAGE_CHANGE_ERROR= "SALES_STAGE_CHANGE_ERROR"