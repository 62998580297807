import { Button, Input, Select } from 'antd'
import React from 'react'
import { useTable, usePagination, useRowSelect } from 'react-table'
import "../../../assets/css/table.scss"


function Table({ columns, data }) {
    // Use the state and functions returned from useTable to build your UI
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page, // Instead of using 'rows', we'll use page,
        // which has only the rows for the active page

        // The rest of these things are super handy, too ;)
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize },
    } = useTable(
        {
            columns,
            data,
            initialState: { pageIndex: 0 },
        },
        usePagination
    )

    // Render the UI for your table
    return (
        <div className="scroll" style={{ paddingTop: 5, overflowX: "auto" }}>
            <table className="rt-table" {...getTableProps()}>
                <thead className="rt-thead" >
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>{column.render('Header')}</th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody className="rt-tbody" {...getTableBodyProps()}>
                    {page.map((row, i) => {
                        prepareRow(row)
                        return (
                            <tr className={i % 2 == 0 ? "rt-even" : "rt-odd"} {...row.getRowProps()}>
                                {row.cells.map(cell => {
                                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </table>
            {/* 
          Pagination can be built however you'd like. 
          This is just a very basic UI implementation:
        */}
            <div className="pagination rt-pagination">
                <div>
                    <Button type="primary" onClick={() => gotoPage(0)} disabled={!canPreviousPage}
                        className={!canPreviousPage ? "rt-disbledButton" : "rt-Button"}
                    >
                        {'<<'}
                    </Button>{' '}
                    <Button type="primary" onClick={() => previousPage()} disabled={!canPreviousPage}
                        className={!canPreviousPage ? "rt-disbledButton" : "rt-Button"}
                    >
                        {'<'}
                    </Button>{' '}
                </div>
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <span>
                    Go to page:{' '}
                    <Input
                        type="number"
                        defaultValue={pageIndex + 1}
                        onChange={e => {
                            const page = e ? Number(e) - 1 : 0
                            gotoPage(page)
                        }}
                        style={{ width: '100px' }}
                    />
                </span>{' '}
                {/* <Select className="rt-select"
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e))
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </Select> */}
                <div>
                    <Button type="primary" onClick={() => nextPage()} disabled={!canNextPage}
                        className={!canNextPage ? "rt-disbledButton" : "rt-Button"}

                    >
                        {'>'}
                    </Button>{' '}
                    <Button type="primary" onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}
                        className={!canNextPage ? "rt-disbledButton" : "rt-Button"}>
                        {'>>'}
                    </Button>{' '}
                </div>
            </div>
        </div>
    )
}


export default Table;