import React, { useState, useEffect } from 'react';
import { Button, Popconfirm } from 'antd';
import {
    AppstoreAddOutlined
} from '@ant-design/icons';
import * as Actions from "../../actions/nonBoundaryActions"
import { connect } from 'react-redux';
import TableContainer from '../Role/TableContainer';
import AddTeam from './AddTeam';
import AuthContext from '../../../services/Auth'
import Loader from "../../../containers/Loader/Loader"
import Table from '../Role/Table';

const Teams = ({ teamNBA, addTeamNonBoundaryActivity, getTeamNonBoundaryAllActivity, getTeamNonBoundaryActivity, isAdminSuccess, deactivatedNonTimeBoundActivity, activateNonTimeBoundActivity }) => {
    const [addData, setAddData] = useState(false);

    const columns = [{
        Header: 'Activity Name',
        accessor: 'projectName',
        key: 'projectName',
        onFilter: (value, record) => record.projectName.includes(value),
        sorter: {
            compare: (a, b) => ('' + a.projectName).localeCompare(b.projectName),
            multiple: 3,
        }
    }, {
        Header: 'Description',
        accessor: 'message',
        key: 'message',
        onFilter: (value, record) => record.message.includes(value),
        sorter: {
            compare: (a, b) => ('' + a.message).localeCompare(b.message),
            multiple: 3,
        }
    }, {
        Header: 'Team',
        accessor: 'team',
        key: 'team',
        onFilter: (value, record) => record.team.includes(value),
        sorter: {
            compare: (a, b) => ('' + a.team).localeCompare(b.team),
            multiple: 3,
        }
    }, {
        Header: '',
        accessor: 'active',
        Cell: props => <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
            {
                props.row.original.isActive ?
                    <>

                        <Button disabled={true} type="primary" style={{ fontSize: 12, width: 80 }} >Active</Button>
                        <Popconfirm
                            style={{ marginLeft: 20 }}
                            placement="left" title={"Are you sure to deactive this project?"}
                            onConfirm={() => {
                                deactivatedNonTimeBoundActivity(props.row.original.projectId, "Team")
                            }}
                            okText="Yes"
                            cancelText="No">
                            <Button danger style={{ fontSize: 12, width: 80 }} >Deactive</Button>
                        </Popconfirm>
                    </>

                    :
                    <>
                        <Popconfirm placement="left" title={"Are you sure to active this project?"} onConfirm={() => {
                            activateNonTimeBoundActivity(props.row.original.projectId, "Team")
                        }} okText="Yes" cancelText="No">
                            <Button type="primary" style={{ fontSize: 12, width: 80 }} >Active</Button>
                        </Popconfirm>

                        <Button disabled={true} danger style={{ fontSize: 12, width: 80 }} >Deactive</Button>
                    </>

            }

        </div>
    }]

    useEffect(() => {
        if (isAdminSuccess) {
            getTeamNonBoundaryAllActivity();

        } else {
            getTeamNonBoundaryActivity(AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.oid);
        }
    }, [])

    return (
        <div>
            { teamNBA.getTeamNBALoading &&
                <Loader />
            }
            <div className="buttonRight">
                <Button type="primary" danger onClick={() => setAddData(true)}>
                    <AppstoreAddOutlined /> Add Non Time Bound Activity for Team
            </Button>
            </div>
            {/* {teamNBA.getTeamNBASuccess &&
                <TableContainer
                    data={teamNBA.getTeamNBASuccess}
                    loading={teamNBA.getTeamNBALoading}
                    error={teamNBA.getTeamNBAError}
                    title={"Organization Non Time Bound Activity"}
                    columns={columns}
                />} */}

            <Table
                columns={columns}
                data={teamNBA.getTeamNBASuccess}
            />
            <AddTeam
                visible={addData}
                getTeam={() => { }}
                addTeamNBASuccess={teamNBA.addTeamNBASuccess}
                onClose={() => { setAddData(false) }}
                addTeamNonBoundaryActivity={(projectName, teamName, projectDescription) => { addTeamNonBoundaryActivity(projectName, teamName, projectDescription, AuthContext.getCachedUser().userName) }}
            />
        </div>
    );
};
const mapSateToProps = (state) => {
    return {
        teamNBA: state.nonBoundaryActivity,
        isAdminSuccess: state.usersReducers.isAdminSuccess
    }
}

export default connect(mapSateToProps, Actions)(Teams);