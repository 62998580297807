import React, { Component } from "react"
import Nav from "../../../containers/Navbar/Navbar"
import NavbarTop from "../../../containers/Navbar/NavbarTop"
import Select, { components } from "react-select"
import * as Actions from "../../actions/usersActions"
import { connect } from "react-redux"
import Loader from "../../../containers/Loader/Loader"
import { Button } from "antd"

const CustomMenuList = (props) => {
    return (
        <>
            <components.MenuList {...props}>
                {props.children}
            </components.MenuList>
            <div style={{ padding: '10px', textAlign: 'center' }}>
                <Button style={{backgroundColor: 'rgb(239, 65, 35)' , color: 'white' }} onClick={props.onBackClick}>Back</Button>
                <Button style={{backgroundColor: 'rgb(239, 65, 35)' , color: 'white' }} onClick={props.onNextClick}>Next</Button>
            </div>
        </>
    );
};


class UsersAssign extends Component {
    constructor(props) {
        super(props)
        this.state = {
            navSide: true,
            projectId: 0,
            selectedOptionProject: {},
            selectedOptionUsers: [],
            viewModal: false,
            viewMessage: false,
            validation: false,
            viewModalStatus: true,
            selectedOptionProjectError: "",
            selectedOptionUsersError: "",
            time: 0,
            alertView: false,
            loader: true,
            loaderButton: false,
            alertMessage: "",
            selectedOptionRole: { value: 1, label: "User" },
            prevSelectedUsers: [],
            project: null,
            searchParams: "",
            currentPage: 1,
            isLoading: true, 
            selectedOptionProject: null
        }
    }

    componentDidMount() {
        this.props.getUsersDropdown();
        this.props.getActiveProjects(1);
        this.setState({ viewMessage: false })
        setTimeout(() => {
            this.setState({ isLoading: false });
        }, 2000); 
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.viewModal != prevState.viewModal) {
            // this.setState({ viewModal: true })
            setTimeout(() => {
                this.setState({ viewModal: false, alertView: false, prevState: false })
            }, 2000)
        } else if (this.state.viewMessage != prevState.viewMessage) {
            setTimeout(() => {
                this.setState({ viewMessage: false, alertMessage: "" })
            }, 2000)
        }
    }


    static getDerivedStateFromProps = (nextProps, state) => {
        if (nextProps.UsersAssign.usersAssignSuccess && state.loaderButton && state.alertView) {
            return {
                navigationStatus: nextProps.UsersAssign.usersAssignSuccess,
                selectedOptionProject: {},
                selectedOptionUsers: [],
                time: 0,
                viewModalStatus: false,
                viewModal: true,
                loaderButton: false,
            }
        } else if (nextProps.UsersAssign.usersAssignMessage != state.alertMessage) {
            return {
                viewMessage: true,
                alertView: true,
                alertMessage: nextProps.UsersAssign.usersAssignMessage,
                viewModalStatus: false,
                loaderButton: false,
            }
        }

        if (nextProps.UsersAssign.projects && nextProps.UsersAssign.allUsers) {
            return {
                loader: false
            }
        }

        if (nextProps.UsersAssign.usersAssign) {
            return {
                viewModal: nextProps.UsersAssign.usersAssign,
            }
        } else {
            return {
                nextProps
            }
        }
    }

    handleChangeProject = (selectedOptionProject) => {
        var index = this.props.UsersAssign.projectsAll.findIndex((value) => {
            return value.projectId == selectedOptionProject.value
        })

        this.setState({ prevSelectedUsers: this.props.UsersAssign.projectsAll[index].users, project: this.props.UsersAssign.projectsAll[index] })
        this.setState({ selectedOptionProject, selectedOptionUsers: [] });
    }

    handleChangeUser = (selectedOptionUsers) => {
        this.setState({ selectedOptionUsers });
    }

    handleChangeRole = (selectedOptionRole) => {
        this.setState({ selectedOptionRole });
    }

    handleNextClick = () => {
        const nextPage = this.state.currentPage + 1;
        this.props.getActiveProjects(nextPage);
        this.setState({ currentPage: nextPage });
    }

    handleBackClick = () => {
        if (this.props.currentPage > 1){
            const backPage = this.state.currentPage - 1;
            this.props.getActiveProjects(backPage);
            this.setState({ currentPage: backPage });
        }else{
            this.props.getActiveProjects(this.props.currentPage);
            this.setState({ currentPage: this.props.currentPage });
        }
        
    }

    handleInputChange = (inputValue) => {
        if (inputValue.length > 2) { 
          this.props.getProjectSearch(inputValue);
      };
    }

    AssignUser = () => {
        this.setState({ loaderButton: true });
        var error = false;
        if (this.isEmpty(this.state.selectedOptionProject)) {
            error = true;
            this.setState({ selectedOptionProjectError: "Please select project" });
        } else {
            this.setState({ selectedOptionProjectError: "" });
        }

        if (this.state.selectedOptionUsers.length == 0) {
            this.setState({ selectedOptionUsersError: "Please select user" });
            error = true;
        } else {
            this.setState({ selectedOptionUsersError: "" });
        }

        if (this.state.time < 1) {
            this.setState({ selectedOptionTimeError: "Please enter correct time" });
            error = true;
        } else if (this.state.project && this.state.time > this.state.project.expectedHours - this.state.project.currentHours) {
            this.setState({ selectedOptionTimeError: "Project expected hours exceed." });
            error = true;
        } else {
            this.setState({ selectedOptionTimeError: "" });
        }

        if (!error) {
            this.setState({ alertView: true })
            this.props.AssignUsers(this.state.selectedOptionProject, this.state.selectedOptionUsers, this.state.time, this.state.selectedOptionRole.value)
        } else {
            this.setState({ loaderButton: false });
        }
    }

    isEmpty = (obj) => {
        for (var key in obj) {
            if (obj.hasOwnProperty(key))
                return false;
        }
        return true;
    }


    render() {
        return (
            <>
                {
                    this.state.loader &&
                    <Loader />
                }
                {
                    this.state.viewMessage && this.props.UsersAssign.usersAssignMessage &&
                    <div className="row ml-auto pull-right" style={{ position: "absolute", zIndex: 5000, top: 10, right: 25, width: 400 }}>
                        <div className="alert-group" style={{ width: "100%" }}>
                            <div className="row alert alert-danger alert-dismissable">
                                <div className="col-md-11">
                                    <strong>{this.state.alertMessage}!</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                {
                    this.state.viewModal &&
                    <div className="row ml-auto pull-right" style={{ position: "absolute", zIndex: 5000, top: 10, right: 25, width: 400 }}>
                        <div className="alert-group" style={{ width: "100%" }}>
                            <div className="row alert alert-success">
                                <div className="col-md-11">
                                    <strong>User successfully added!</strong>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                <div className="container userAssignForm">
                    <div style={{ padding: 10, paddingTop: 30, paddingBottom: 30 }}>
                        <div className="row" style={{ padding: 10 }}>
                            {/* {(this.state.viewModal ?
                                <div className="col-md-12" style={{ backgroundColor: "#5bcc02", padding: 6, paddingBottom: 0, color: "#0c6002", borderRadius: 7, zIndex: 3000 }}>
                                    <p>Successfully assign user to project</p>
                                </div> : <></>)} */}
                            <h3 className="col-md-12" style={{ textAlign: "center", marginBottom: 30 }} >Assign Users To Project</h3>
                            <div className="col-md-3">
                                <p>Project Name<span style={{ color: "red" }}>*</span></p>
                            </div>
                            <div className="col-md-9">
                            <Select
                            value={this.state.selectedOptionProject}
                            onChange={this.handleChangeProject}
                            onInputChange={this.handleInputChange}
                            options={this.props.UsersAssign.projects}
                            components={{ MenuList: (props) => <CustomMenuList {...props} onNextClick={this.handleNextClick} onBackClick={this.handleBackClick}/> }}
                            menuPlacement="auto"
                            menuPortalTarget={document.body}
                            maxMenuHeight={200}
                            styles={{
                                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            }}
                             />
                            </div>
                            <div className="col-md-12">
                                <p style={{ color: "red" }}>{this.state.selectedOptionProjectError}</p>
                            </div>
                            <div className="col-md-3" style={{ marginTop: 20 }}>
                                <p>Users<span style={{ color: "red" }}>*</span></p>
                            </div>
                            <div className="col-md-9" style={{ marginTop: 20 }}>
                                <Select
                                value={this.state.selectedOptionUsers}
                                onChange={this.handleChangeUser}
                                // Disable options if they are already in the previous selected users
                                isOptionDisabled={(option) => {
                                    return this.state.prevSelectedUsers.findIndex((user) => {
                                        return option.label === user.userEmail; // compare by userEmail
                                        }) !== -1; // If the user is found, disable the option
                                    }}
                                    onInputChange={(searchText) => {
                                        if (searchText.length === 3) {
                                            this.props.getUsersDropdownSearch(searchText.toLowerCase());
                                        } else if (searchText.length === 1) {
                                            this.props.getUsersDropdown();
                                        }
                                    }}
                                // Check if allUsers is defined before filtering and mapping
                                options={this.props.UsersAssign.allUsers && Array.isArray(this.props.UsersAssign.allUsers)
                                    ? this.props.UsersAssign.allUsers
                                        .filter((user) => typeof user.mail === 'string' && user.mail.includes('millenniumitesp.com'))
                                        .map((user) => ({
                                            ...user,
                                            label: user.label.split('.')[0] // Extract the name before the dots
                                        })) : []
                                }
                                isMulti={true}
                                />
                            </div>
                            <div className="col-md-12">
                                <p style={{ color: "red" }}>{this.state.selectedOptionUsersError}</p>
                            </div>
                            <div className="col-md-3" style={{ marginTop: 20 }}>
                                <p>Role<span style={{ color: "red" }}>*</span></p>
                            </div>
                            <div className="col-md-9" style={{ marginTop: 20 }}>
                                <Select
                                    value={this.state.selectedOptionRole}
                                    onChange={this.handleChangeRole}
                                    options={[{ value: 1, label: "User" }, { value: 2, label: "Admin" }]}
                                />
                            </div>

                            <div className="col-md-3" style={{ marginTop: 20 }}>
                                <p>Time<span style={{ color: "red" }}>*</span></p>
                            </div>
                            <div className="col-md-9" style={{ marginTop: 20 }}>
                                <input type="number" min="0" className="form-control" value={this.state.time} onChange={(value) => {
                                    this.setState({ time: parseInt(value.target.value) });
                                }
                                } />
                            </div>
                            <div className="col-md-12">
                                <p style={{ color: "red" }}>{this.state.selectedOptionTimeError}</p>
                            </div>
                            <div className="col-md-12" style={{ marginTop: 20, display: "flex", flexDirection: "row-reverse" }}>
                                {(this.props.UsersAssign.usersAssginLoader ?
                                    <button className="btn-submit" style={{ width: 100 }}>

                                        <i class="fa fa-circle-o-notch fa-spin fa-1x fa-fw" style={{ color: "gray" }}></i>
                                    </button>
                                    :
                                    <button className="btn-submit" style={{ width: 100, cursor: "pointer" }} onClick={() => { this.AssignUser() }} >
                                        Assign Users
                                </button>

                                )}

                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return { UsersAssign: state.usersReducers }
}

export default connect(mapStateToProps, Actions)(UsersAssign);



