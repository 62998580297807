export const GET_PROJECTS = "GET_PROJECTS"
export const GET_PROJECTS_LOADING = "GET_PROJECTS_LOADING"


export const GET_ACTIVE_PROJECTS = "GET_ACTIVE_PROJECTS"
export const GET_COMPLETED_PROJECTS = "GET_COMPLETED_PROJECTS"
export const GET_TODAY_PROJECTS = "GET_TODAY_PROJECTS"
export const GET_USERS = "GET_USERS"
export const GET_ACTIVE_PROJECTS_ASSIGN = "GET_ACTIVE_PROJECTS_ASSIGN"


export const ASSIGN_USERS = "ASSIGN_USERS"
export const ASSIGN_USERS_EROR = "ASSIGN_USERS_EROR"
export const ASSIGN_USERS_LOADER = "ASSIGN_USERS_LOADER"

export const GET_PROJECT = "GET_PROJECT"
export const UPDATE_PROJECT = "UPDATE_PROJECT"
export const GET_PROJECT_STATUS = "GET_PROJECT_STATUS"
export const GET_IMAGE = "GET_IMAGE"
export const GET_IMAGE_ERROR = "GET_IMAGE_ERROR"
export const GET_USER_DETAILS = "GET_USER_DETAILS"
export const GET_USER_DETAILS_ERROR = "GET_USER_DETAILS_ERROR"

export const PROJECT_STATUS_CHANGE_LOADING = "PROJECT_STATUS_CHANGE_LOADING"
export const ACTIVE_PROJECT_SUCCESS = "ACTIVE_PROJECT_SUCCESS"
export const COMPLETE_PROJECT_SUCCESS = "COMPLETE_PROJECT_SUCCESS"


export const ADD_PROJECT_SUCCESS = "ADD_PROJECT_SUCCESS"
export const ADD_PROJECT_ERROR = "ADD_PROJECT_ERROR"
export const DELETE_ASSIGN_USER_SUCCESS = "DELETE_ASSIGN_USER_SUCCESS"
export const ASSIGN_USERS_UPLOAD = "ASSIGN_USERS_UPLOAD"
export const ASSIGN_USERS_UPLOAD_ERROR = "ASSIGN_USERS_UPLOAD_ERROR"
export const GET_ASSIGN_USERS = "GET_ASSIGN_USERS"

//Edit Assign User
export const EDIT_ASSIGN_USERS_ALERT = "EDIT_ASSIGN_USERS_ALERT"
export const EDIT_ASSIGN_USERS_ERROR = "EDIT_ASSIGN_USERS_ERROR"
export const EDIT_ASSIGN_USERS = "EDIT_ASSIGN_USERS"
export const EDIT_ASSIGN_USER_LOADER = "EDIT_ASSIGN_USER_LOADER"


export const DELETE_ASSIGN_USER_TABLE_SUCCESS = "DELETE_ASSIGN_USER_TABLE_SUCCESS"
export const PROJECT_ASSIGN_USERS = "PROJECT_ASSIGN_USERS"
export const PROJECT_ASSIGN_USERS_ERROR = "PROJECT_ASSIGN_USERS_ERROR"
export const USER_ACTIVITY = "USER_ACTIVITY"
export const GET_DEFAULT_PROJECTS_SUCCESS = "GET_DEFAULT_PROJECTS_SUCCESS"
export const LOGOUT_USER = "LOGOUT_USER"

export const GET_ALL_USERS = "GET_ALL_USERS"
export const GET_ALL_USERS_LOADING = "GET_ALL_USERS_LOADING"

export const EDIT_PROJECT_LOADER = "EDIT_PROJECT_LOADER"
export const EDIT_PROJECT_SUCCESS = "EDIT_PROJECT_SUCCESS"
//task types
export const GET_TASKS = "GET_TASKS"
export const TASK_UPDATE_TIME = "TASK_UPDATE_TIME"
export const EVENTS_TIME_ADD = "EVENTS_TIME_ADD"
export const GET_IMAGE_PROJECT_USERS = "GET_IMAGE_PROJECT_USERS"
export const GET_IMAGE_ASSIGN_USER = "GET_IMAGE_ASSIGN_USER"

//home
export const GET_IMAGE_USER = "GET_IMAGE_USER"

//role
export const GET_PROJECT_MANAGERS_SUCCESS = "GET_PROJECT_MANAGERS_SUCCESSS"
export const GET_PROJECT_MANAGERS_ERROR = "GET_PROJECT_MANAGERS_ERROR"
export const GET_PROJECT_MANAGERS_LOADING = "GET_PROJECT_MANAGERS_LOADING"

export const GET_ROLE_SUCCESS = "GET_ROLE_SUCCESS"
export const GET_ROLE_ERROR = "GET_ROLE_ERROR"
export const GET_ROLE_LOADING = "GET_ROLE_LOADING"

export const ADD_PROJECT_MANAGER_SUCCESS = "ADD_PROJECT_MANAGER_SUCCESS"
export const ADD_PROJECT_MANAGER_ERROR = "ADD_PROJECT_MANAGER_ERROR"
export const ADD_PROJECT_MANAGER_LOADING = "ADD_PROJECT_MANAGER_LOADING"

export const DELETE_PROJECT_MANAGER_SUCCESS = "DELETE_PROJECT_MANAGER_SUCCESS"
export const DELETE_PROJECT_MANAGER_ERROR = "DELETE_PROJECT_MANAGER_ERROR"
export const DELETE_PROJECT_MANAGER_LOADING = "DELETE_PROJECT_MANAGER_LOADING"

export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS"
export const GET_DEPARTMENTS_ERROR = "GET_DEPARTMENTS_ERROR"
export const GET_DEPARTMENTS_LOADING = "GET_DEPARTMENTS_LOADING"

export const GET_USER_DEPARTMENTS_SUCCESS = "GET_USER_DEPARTMENTS_SUCCESS"
export const GET_USER_DEPARTMENTS_ERROR = "GET_USER_DEPARTMENTS_ERROR"
export const GET_USER_DEPARTMENTS_LOADING = "GET_USER_DEPARTMENTS_LOADING"

export const GET_DEPARTMENT_HEAD_SUCCESS = "GET_DEPARTMENT_HEAD_SUCCESS"
export const GET_DEPARTMENT_HEAD_ERROR = "GET_DEPARTMENT_HEAD_ERROR"
export const GET_DEPARTMENT_HEAD_LOADING = "GET_DEPARTMENT_HEAD_LOADING"

export const ADD_DEPARTMENT_HEAD_SUCCESS = "ADD_DEPARTMENT_HEAD_SUCCESS"
export const ADD_DEPARTMENT_HEAD_ERROR = "ADD_DEPARTMENT_HEAD_ERROR"
export const ADD_DEPARTMENT_HEAD_LOADING = "ADD_DEPARTMENT_HEAD_LOADING"

export const DELETE_DEPARTMENT_HEAD_SUCCESS = "DELETE_DEPARTMENT_HEAD_SUCCESS"
export const DELETE_DEPARTMENT_HEAD_ERROR = "DELETE_DEPARTMENT_HEAD_ERROR"
export const DELETE_DEPARTMENT_HEAD_LOADING = "DELETE_DEPARTMENT_HEAD_LOADING"

export const GET_TEAM_LEADS_SUCCESS = "GET_TEAM_LEADS_SUCCESS"
export const GET_TEAM_LEADS_ERROR = "GET_TEAM_LEADS_ERROR"
export const GET_TEAM_LEADS_LOADING = "GET_TEAM_LEADS_LOADING"

export const ADD_TEAM_LEAD_SUCCESS = "ADD_TEAM_LEAD_SUCCESS"
export const ADD_TEAM_LEAD_ERROR = "ADD_TEAM_LEAD_ERROR"
export const ADD_TEAM_LEAD_LOADING = "ADD_TEAM_LEAD_LOADING"

export const DELETE_TEAM_LEAD_SUCCESS = "DELETE_TEAM_LEAD_SUCCESS"
export const DELETE_TEAM_LEAD_ERROR = "DELETE_TEAM_LEAD_ERROR"
export const DELETE_TEAM_LEAD_LOADING = "DELETE_TEAM_LEAD_LOADING"

export const GET_ADMIN_USERS_LOADING = "GET_ADMIN_USERS_LOADING"
export const GET_ADMIN_USERS_SUCCESS = "GET_ADMIN_USERS_SUCCESS"
export const GET_ADMIN_USERS_ERROR = "GET_ADMIN_USERS_ERROR"

export const ADD_ADMIN_SUCCESS = "ADD_ADMIN_SUCCESS"
export const ADD_ADMIN_ERROR = "ADD_ADMIN_ERROR"
export const ADD_ADMIN_LOADING = "ADD_ADMIN_LOADING"

export const DELETE_ADMIN_SUCCESS = "DELETE_ADMIN_SUCCESS";
export const DELETE_ADMIN_LOADING = "DELETE_ADMIN_LODAING";
export const DELETE_ADMIN_ERROR = "DELETE_ADMIN_ERROR";

//organization
export const GET_ORGANIZATION_NBA_SUCCESS = "GET_ORGANIZATION_NBA_SUCCESS"
export const GET_ORGANIZATION_NBA_ERROR = "GET_ORGANIZATION_NBA_ERROR"
export const GET_ORGANIZATION_NBA_LOADING = "GET_ORGANIZATION_NBA_LOADING"

export const ADD_ORGANIZATION_NBA_SUCCESS = "ADD_ORGANIZATION_NBA_SUCCESS"
export const ADD_ORGANIZATION_NBA_ERROR = "ADD_ORGANIZATION_NBA_ERROR"
export const ADD_ORGANIZATION_NBA_LOADING = "ADD_ORGANIZATION_NBA_LOADING"

export const GET_DEPARTMENT_NBA_SUCCESS = "GET_DEPARTMENT_NBA_SUCCESSS"
export const GET_DEPARTMENT_NBA_ERROR = "GET_DEPARTMENT_NBA_ERROR"
export const GET_DEPARTMENT_NBA_LOADING = "GET_DEPARTMENT_NBA_LOADING"

export const ADD_DEPARTMENT_NBA_SUCCESS = "ADD_DEPARTMENT_NBA_SUCCESS"
export const ADD_DEPARTMENT_NBA_ERROR = "ADD_DEPARTMENT_NBA_ERROR"
export const ADD_DEPARTMENT_NBA_LOADING = "ADD_DEPARTMENT_NBA_LOADING"

export const GET_TEAM_NBA_SUCCESS = "GET_TEAM_NBA_SUCCESS"
export const GET_TEAM_NBA_ERROR = "GET_TEAM_NBA_ERROR"
export const GET_TEAM_NBA_LOADING = "GET_TEAM_NBA_LOADING"

export const ADD_TEAM_NBA_SUCCESS = "ADD_TEAM_NBA_SUCCESS"
export const ADD_TEAM_NBA_ERROR = "ADD_TEAM_NBA_ERROR"
export const ADD_TEAM_NBA_LOADING = "ADD_TEAM_NBA_LOADING"

export const ACTIVATE_NBA_SUCCESS = "ACTIVATE_NBA_SUCCESS"
export const ACTIVATE_NBA_ERROR = "ACTIVATE_NBA_ERROR"
export const ACTIVATE_NBA_LOADING = "ACTIVATE_NBA_LOADING"

export const DEACTIVATED_NBA_SUCCESS = "DEACTIVATED_NBA_SUCCESS"
export const DEACTIVATED_NBA_ERROR = "DEACTIVATED_NBA_ERROR"
export const DEACTIVATED_NBA_LOADING = "DEACTIVATED_NBA_LOADING"

//team
export const GET_TEAMS_SUCCESS = "GET_TEAMS_SUCCESS"
export const GET_TEAMS_ERROR = "GET_TEAMS_ERROR"
export const GET_TEAMS_LOADING = "GET_TEAMS_LOADING"

export const GET_ALL_TEAMS_SUCCESS = "GET_ALL_TEAMS_SUCCESS"
export const GET_ALL_TEAMS_ERROR = "GET_ALL_TEAMS_ERROR"
export const GET_ALL_TEAMS_LOADING = "GET_ALL_TEAMS_LOADING"

export const ADD_TEAM_SUCCESS = "ADD_TEAM_SUCCESS"
export const ADD_TEAM_ERROR = "ADD_TEAM_ERROR"
export const ADD_TEAM_LOADING = "ADD_TEAM_LOADING"

export const GET_TEAM_MEMBERS_SUCCESS = "GET_TEAM_MEMBERS_SUCCESS"
export const GET_TEAM_MEMBERS_ERROR = "GET_TEAM_MEMBERS_ERROR"
export const GET_TEAM_MEMBERS_LOADING = "GET_TEAM_MEMBERS_LOADING"

export const ADD_TEAM_MEMBER_SUCCESS = "ADD_TEAM_MEMBER_SUCCESS"
export const ADD_TEAM_MEMBER_ERROR = "ADD_TEAM_MEMBER_ERROR"
export const ADD_TEAM_MEMBER_LOADING = "ADD_TEAM_MEMBER_LOADING"

export const IS_TEAM_LEAD_SUCCESS = "IS_TEAM_LEAD_SUCCESS"
export const IS_TEAM_LEAD_ERROR = "IS_TEAM_LEAD_ERROR"
export const IS_TEAM_LEAD_LOADING = "IS_TEAM_LEAD_LOADING"

export const IS_ADMIN_SUCCESS = "IS_ADMIN_SUCCESS"
export const IS_ADMIN_ERROR = "IS_ADMIN_ERROR"
export const IS_ADMIN_LOADING = "IS_ADMIN_LOADING"

export const IS_PROJECT_MANAGER_SUCCESS = "IS_PROJECT_MANAGER_SUCCESS"
export const IS_PROJECT_MANAGER_ERROR = "IS_PROJECT_MANAGER_ERROR"
export const IS_PROJECT_MANAGER_LOADING = "IS_PROJECT_MANAGER_LOADING"

export const IS_DEPARTMENT_HEAD_SUCCESS = "IS_DEPARTMENT_HEAD_SUCCESS"
export const IS_DEPARTMENT_HEAD_ERROR = "IS_DEPARTMENT_HEAD_ERROR"
export const IS_DEPARTMENT_HEAD_LOADING = "IS_DEPARTMENT_HEAD_LOADING"

//Report
export const REPORT_SUCCESS = "REPORT_SUCCESS"
export const REPORT_ERROR = "REPORT_ERROR"
export const REPORT_LOADING = "REPORT_LOADING"

export const PROJECT_SEARCH_SUCCESS = "PROJECT_SEARCH_SUCCESS"
export const PROJECT_SEARCH_ERROR = "PROJECT_SEARCH_ERROR"
export const PROJECT_SEARCH_LOADING = "PROJECT_SEARCH_LOADING"
