import React, { Component } from 'react';
import Nav from "../../../containers/Navbar/Navbar"
import { connect } from 'react-redux';
import * as projectActions from "../../actions/projectsActions"
import ReactTable from 'react-table'
// import 'react-table/react-table.css'
import NavbarTop from "../../../containers/Navbar/NavbarTop";


class CompletedProjects extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            navSide: true,
            imageURL: "",
            modalView: false
        }
    }

    componentDidMount() {
        this.props.getCompletedProjects();
    }

    activeProject = (id, name) => {
        var r = window.confirm(`Are you sure you want to active the ${name} project`);
        if (r == true) {
            this.props.activeProject(id)
        } else {

        }
    }
    render() {
        const columns = [
            {
                Header: 'Project Name',
                filterable: true, accessor: 'projectName',
                Cell: props => {
                    return (<span style={{ color: "black", fontWeight: "800", paddingLeft: 10 }}>{props.original.projectName}</span>)
                }
            },
            {
                Header: 'Message',
                filterable: true, accessor: 'message'
            },
            {
                Header: 'Status', accessor: 'status',
                Cell: props => {
                    var date = new Date(props.original.startTime);
                    return (<span>{(props.original.status == "1" ? <span style={{ color: "white", backgroundColor: "green", borderRadius: 10, padding: 2, paddingLeft: 10, paddingRight: 10 }}>Active</span> : <span style={{ color: "white", backgroundColor: "red", borderRadius: 10, padding: 2, paddingLeft: 10, paddingRight: 10 }}>Completed</span>)}</span>)
                }
            },
            {
                Header: 'Current Hours',
                filterable: true, accessor: 'currentHours',
                Cell: props => {
                    return (<span>{props.original.currentHours + "H"}</span>)
                }
            },
            {
                Header: 'Expected Hours',
                filterable: true, accessor: 'expectedHours',
                Cell: props => {
                    return (<span>{props.original.expectedHours + "H"}</span>)
                }
            },
            {
                Header: 'Start Date',
                filterable: true, accessor: 'startTime',
                Cell: props => {
                    var date = new Date(props.original.startTime);
                    return (<span>{date.getFullYear() + "/ " + (date.getMonth() + 1) + "/ " + date.getDate()}</span>)
                }
            },
            {
                Header: 'End Date',
                filterable: true, accessor: 'endDate',
                Cell: props => {
                    var date = new Date(props.original.endDate);
                    return (<span>{date.getFullYear() + "/ " + (date.getMonth() + 1) + "/ " + date.getDate()}</span>)
                }
            },
            {
                Header: 'Color', accessor: 'color',
                Cell: props => {
                    return (<div style={{ height: "100%", width: "100%", backgroundColor: "#" + props.original.color.substring(0, 6) }}></div>)
                }
            },
            {
                Header: 'Image', accessor: 'image',
                Cell: props => {
                    return (<button onClick={() => {
                        this.setState({ modalView: true, imageURL: props.original.image })
                    }} style={{ height: "100%", width: "100%", backgroundColor: "black", color: "white", textAlign: "center", padding: 2, fontSize: 13, borderColor: "gray" }}>View Image</button>)
                }
            }
            , {
                Header: '', accessor: '',
                Cell: props => {
                    return (<button onClick={() => {
                        this.activeProject(props.original.projectId)
                    }} style={{ height: "100%", width: "100%", backgroundColor: "green", color: "white", textAlign: "center", padding: 2, fontSize: 13, borderColor: "gray" }}>Active</button>)
                }
            }

        ]
        return (
            <>
                {this.state.modalView &&
                    <div id="myModal" class="modal">
                        <span class="close" onClick={() => { this.setState({ modalView: false }) }}>X</span>
                        <img src={this.state.imageURL} class="modal-content" id="img01" />
                        <div id="caption"></div>
                    </div>}
                <div className="table-container">
                    <ReactTable
                        data={(this.props.projects == null ? [] : this.props.projects.completedProjects)}
                        columns={columns}
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())}

                        defaultPageSize={10}
                        sortable='true'
                    />
                </div>


            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        projects: state.projectReducer
    }
}

export default connect(mapStateToProps, projectActions)(CompletedProjects);