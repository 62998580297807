import React, { useState, useEffect } from 'react';
import { Button, Popconfirm } from 'antd';
import {
    AppstoreAddOutlined,
    DeleteFilled
} from '@ant-design/icons';
import * as Actions from "../../actions/teamsActions"
import { connect } from 'react-redux';
import AddTeamMembers from './AddTeamMembers';
import TableContainer from '../Role/TableContainer';
import AuthContext from '../../../services/Auth'
import Loader from "../../../containers/Loader/Loader"
import Table from '../Role/Table';

const Members = ({ members, getTeamMembers, getTeams, addTeamMember, getAllTeamMembers, isAdminSuccess, getTeamsDrop }) => {
    useEffect(() => {
        if (isAdminSuccess) {
            getTeamsDrop();
            getAllTeamMembers();
        } else {
            getTeamMembers(AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.oid);
            getTeams(AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.oid);
        }
    }, [])

    // useEffect(() => {
    //     if (isAdminSuccess) {
    //         getTeamsDrop();
    //     }
    // }, [isAdminSuccess]);

    const [addData, setAddData] = useState(false);

    const columns = [{
        Header: 'Team Name',
        accessor: 'teamName',
        key: 'teamName',
        onFilter: (value, record) => record.teamName.includes(value),
        sorter: {
            compare: (a, b) => ('' + a.teamName).localeCompare(b.teamName),
            multiple: 3,
        }
    }, {
        Header: 'User Email',
        accessor: 'userEmail',
        key: 'userEmail',
        onFilter: (value, record) => record.userEmail.includes(value),
        sorter: {
            compare: (a, b) => ('' + a.userEmail).localeCompare(b.userEmail),
            multiple: 3,
        }
    }, {
        Header: 'User Name',
        accessor: 'userName',
        key: 'userName',
        onFilter: (value, record) => record.userName.includes(value),
        sorter: {
            compare: (a, b) => ('' + a.userName).localeCompare(b.userName),
            multiple: 3,
        }
        // }, {
        //     Header: '',
        //     accessor: 'active',
        //     Cell: props => <Popconfirm placement="left" title={"Are you sure to delete this member?"} onConfirm={() => {

        //     }} okText="Yes" cancelText="No">
        //         <Button style={{ fontSize: 12 }} ><DeleteFilled style={{ color: "red" }} /></Button>
        //     </Popconfirm>
    }]

    return (
        <div>
            { members.getTeamMembersLoading &&
                <Loader />
            }
            <div className="buttonRight">
                <Button type="primary" danger onClick={() => setAddData(true)}>
                    <AppstoreAddOutlined /> Add Team Members
            </Button>
            </div>
            <AddTeamMembers
                visible={addData}
                onClose={() => { setAddData(false) }}
                getTeamsSuccess={members.getTeamsSuccess}
                teamMemberList={members.getTeamMembersSuccess}
                addTeamMembersSuccess={members.addTeamMembersSuccess}
                addTeamMember={(teamid, userName, userEmail, userID, teamName) => { addTeamMember(teamid, userName, userEmail, userID, teamName) }}
            />
            {/* <TableContainer
                columns={columns}
                data={members.getTeamMembersSuccess}
                loading={members.getTeamMembersLoading}
                error={members.getTeamMembersError}
            /> */}

            <Table
                columns={columns}
                data={members.getTeamMembersSuccess} />

        </div>
    );
};


const mapStateToProps = (state) => {
    return {
        members: state.team,
        isAdminSuccess: state.usersReducers.isAdminSuccess

    }
}

export default connect(mapStateToProps, Actions)(Members);