const { REPORT_LOADING, REPORT_SUCCESS, REPORT_ERROR, PROJECT_SEARCH_SUCCESS, PROJECT_SEARCH_LOADING, PROJECT_SEARCH_ERROR } = require("../types");

const initialState = {
    generateReportSuccess: [],
    generateReportError: null,
    generateReportLoading: false,
    projectSearchSuccess: [],
    projectSearchError: null,
    projectSearchLoading: false
}

const reportReducers = (state = initialState, actions) => {
    switch (actions.type) {
        case REPORT_LOADING:
            return { ...state, generateReportLoading: actions.loading, generateReportError: null, generateReportSuccess: [] }
        case REPORT_SUCCESS:
            return { ...state, generateReportLoading: false, generateReportSuccess: actions.data, generateReportError: null }
        case REPORT_ERROR:
            return { ...state, generateReportLoading: false, generateReportSuccess: [], generateReportError: actions.error }
        case PROJECT_SEARCH_SUCCESS:
            return { ...state, projectSearchLoading: false, projectSearchSuccess: actions.data, projectSearchError: null }
        case PROJECT_SEARCH_LOADING:
            return { ...state, projectSearchLoading: actions.loading, projectSearchSuccess: [], projectSearchError: null }
        case PROJECT_SEARCH_ERROR:
            return { ...state, projectSearchLoading: false, projectSearchSuccess: [], projectSearchError: actions.error }
        default:
            return state;
    }
}

export default reportReducers;