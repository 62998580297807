import axios from "axios"
import { GET_ORGANIZATION_NBA_URL, GET_DEPARTMENT_NBA_FOR_HEAD_URL, ADD_ORGANIZATION_NBA_URL, GET_DEPARTMENT_NBA_URL, ADD_DEPARTMENT_NBA_URL, GET_TEAM_NBA_URL, ADD_TEAM_NBA_URL, GET_ALL_TEAM_NBA_URL, ACTIVATE_NON_TIME_BOUND_ACTIVITY_URL, DEACTIVATED_NON_TIME_BOUND_ACTIVITY_URL } from "../../constant"
import { GET_ORGANIZATION_NBA_SUCCESS, GET_ORGANIZATION_NBA_LOADING, GET_ORGANIZATION_NBA_ERROR, ADD_ORGANIZATION_NBA_SUCCESS, ADD_ORGANIZATION_NBA_LOADING, ADD_ORGANIZATION_NBA_ERROR, 
    GET_DEPARTMENT_NBA_SUCCESS, GET_DEPARTMENT_NBA_LOADING, GET_DEPARTMENT_NBA_ERROR, ADD_DEPARTMENT_NBA_LOADING, ADD_DEPARTMENT_NBA_SUCCESS, ADD_DEPARTMENT_NBA_ERROR, GET_TEAM_NBA_LOADING, GET_TEAM_NBA_SUCCESS, 
    GET_TEAM_NBA_ERROR, ADD_TEAM_NBA_LOADING, ADD_TEAM_NBA_SUCCESS, ADD_TEAM_NBA_ERROR, ACTIVATE_NBA_SUCCESS, ACTIVATE_NBA_LOADING, ACTIVATE_NBA_ERROR, DEACTIVATED_NBA_LOADING, DEACTIVATED_NBA_ERROR, DEACTIVATED_NBA_SUCCESS } from "../types"

export const getOrganizationNonBoundaryActivity = () => dispatch => {
    getOrganizationNonBoundaryActivityLoading(dispatch, true)
    axios.get(GET_ORGANIZATION_NBA_URL)
        .then(response => {
            getOrganizationNonBoundaryActivitySuccess(dispatch, response.data)
        }).catch(error => {
            getOrganizationNonBoundaryActivityError(dispatch, error)
        })
}

const getOrganizationNonBoundaryActivitySuccess = (dispatch, data) => dispatch({ type: GET_ORGANIZATION_NBA_SUCCESS, data })
const getOrganizationNonBoundaryActivityLoading = (dispatch, loading) => dispatch({ type: GET_ORGANIZATION_NBA_LOADING, loading })
const getOrganizationNonBoundaryActivityError = (dispatch, error) => dispatch({ type: GET_ORGANIZATION_NBA_ERROR, error })

export const addOrganizationNonBoundaryActivity = (projectName, projectDescription) => dispatch => {
    addOrganizationNonBoundaryActivityLoading(dispatch, true);
    axios.post(ADD_ORGANIZATION_NBA_URL, JSON.stringify({ projectName: projectName, message: projectDescription }))
        .then(response => {
            addOrganizationNonBoundaryActivitySuccess(dispatch, response.data);
        }).catch(error => {
            addOrganizationNonBoundaryActivityError(dispatch, error);
        })
}

const addOrganizationNonBoundaryActivitySuccess = (dispatch, data) => dispatch({ type: ADD_ORGANIZATION_NBA_SUCCESS, data: data })
const addOrganizationNonBoundaryActivityLoading = (dispatch, loading) => dispatch({ type: ADD_ORGANIZATION_NBA_LOADING, loading })
const addOrganizationNonBoundaryActivityError = (dispatch, error) => dispatch({ type: ADD_ORGANIZATION_NBA_ERROR, error })


export const activateNonTimeBoundActivity = (id, NBAType) => dispatch => {
    activateNonTimeBoundActivityLoading(dispatch, true);
    axios.put(`${ACTIVATE_NON_TIME_BOUND_ACTIVITY_URL}&id=${id}`)
        .then(response => {
            activateNonTimeBoundActivitySuccess(dispatch, id, NBAType);
        }).catch(error => {
            activateNonTimeBoundActivityError(dispatch, error);
        })
}

const activateNonTimeBoundActivitySuccess = (dispatch, data, NBAType) => dispatch({ type: ACTIVATE_NBA_SUCCESS, data: data, NBAType })
const activateNonTimeBoundActivityLoading = (dispatch, loading) => dispatch({ type: ACTIVATE_NBA_LOADING, loading })
const activateNonTimeBoundActivityError = (dispatch, error) => dispatch({ type: ACTIVATE_NBA_ERROR, error })

export const deactivatedNonTimeBoundActivity = (id, NBAType) => dispatch => {
    deactivatedNonTimeBoundActivityLoading(dispatch, true);
    axios.put(`${DEACTIVATED_NON_TIME_BOUND_ACTIVITY_URL}&id=${id}`)
        .then(response => {
            deactivatedNonTimeBoundActivitySuccess(dispatch, id, NBAType);
        }).catch(error => {
            deactivatedNonTimeBoundActivityError(dispatch, error)
        })
}


const deactivatedNonTimeBoundActivitySuccess = (dispatch, data, NBAType) => dispatch({ type: DEACTIVATED_NBA_SUCCESS, data: data, NBAType })
const deactivatedNonTimeBoundActivityLoading = (dispatch, loading) => dispatch({ type: DEACTIVATED_NBA_LOADING, loading })
const deactivatedNonTimeBoundActivityError = (dispatch, error) => dispatch({ type: DEACTIVATED_NBA_ERROR, error })


export const getDepartmentNonBoundryActivity = () => dispatch => {
    getDepartmentNonBoundryActivityLoading(dispatch, true);
    axios.get(GET_DEPARTMENT_NBA_URL)
        .then(response => {
            getDepartmentNonBoundryActivitySuccess(dispatch, response.data);
        }).catch(error => {
            getDepartmentNonBoundryActivityError(dispatch, error)
        })
}

export const getDepartmentNonBoundryActivityForHead = (id) => dispatch => {
    getDepartmentNonBoundryActivityLoading(dispatch, true);
    axios.get(GET_DEPARTMENT_NBA_FOR_HEAD_URL + "&id=" + id)
        .then(response => {
            getDepartmentNonBoundryActivitySuccess(dispatch, response.data);
        }).catch(error => {
            getDepartmentNonBoundryActivityError(dispatch, error)
        })
}

const getDepartmentNonBoundryActivitySuccess = (dispatch, data) => dispatch({ type: GET_DEPARTMENT_NBA_SUCCESS, data })
const getDepartmentNonBoundryActivityLoading = (dispatch, loading) => dispatch({ type: GET_DEPARTMENT_NBA_LOADING, loading })
const getDepartmentNonBoundryActivityError = (dispatch, error) => dispatch({ type: GET_DEPARTMENT_NBA_ERROR, error })

export const addDepartmentNonBoundryActivity = (activityName, description, department) => dispatch => {
    addDepartmentNonBoundryActivityLoading(dispatch, true);
    axios.post(ADD_DEPARTMENT_NBA_URL, JSON.stringify({ projectName: activityName, message: description, department: department }))
        .then(response => {
            addDepartmentNonBoundryActivitySuccess(dispatch, response.data);
        }).catch(error => {
            addDepartmentNonBoundryActivityError(dispatch, error)
        })
}

const addDepartmentNonBoundryActivityLoading = (dispatch, loading) => dispatch({ type: ADD_DEPARTMENT_NBA_LOADING, loading })
const addDepartmentNonBoundryActivitySuccess = (dispatch, data) => dispatch({ type: ADD_DEPARTMENT_NBA_SUCCESS, data })
const addDepartmentNonBoundryActivityError = (dispatch, error) => dispatch({ type: ADD_DEPARTMENT_NBA_ERROR, error })


export const getTeamNonBoundaryActivity = (id) => dispatch => {
    getTeamNonBoundaryActivityLoading(dispatch, true);
    axios.get(GET_TEAM_NBA_URL + "&id=" + id)
        .then(response => {
            getTeamNonBoundaryActivitySuccess(dispatch, response.data)
        }).catch(error => {
            getTeamNonBoundaryActivityError(dispatch, error)
        })
}

export const getTeamNonBoundaryAllActivity = () => dispatch => {
    getTeamNonBoundaryActivityLoading(dispatch, true);
    axios.get(GET_ALL_TEAM_NBA_URL)
        .then(response => {
            getTeamNonBoundaryActivitySuccess(dispatch, response.data)
        }).catch(error => {
            getTeamNonBoundaryActivityError(dispatch, error)
        })
}
const getTeamNonBoundaryActivityLoading = (dispatch, loading) => dispatch({ type: GET_TEAM_NBA_LOADING, loading })
const getTeamNonBoundaryActivitySuccess = (dispatch, data) => dispatch({ type: GET_TEAM_NBA_SUCCESS, data })
const getTeamNonBoundaryActivityError = (dispatch, error) => dispatch({ type: GET_TEAM_NBA_ERROR, error })

export const addTeamNonBoundaryActivity = (projectName, team, message, crearedBy) => dispatch => {
    addTeamNonBoundaryActivityLoading(dispatch, true);
    axios.post(ADD_TEAM_NBA_URL, JSON.stringify({ projectName, team: team.teamid, message, crearedBy }))
        .then(response => {
            addTeamNonBoundaryActivitySuccess(dispatch, { projectName, team: team.teamName, message, crearedBy });
        }).catch(error => {
            addTeamNonBoundaryActivityError(dispatch, error)
        })
}

const addTeamNonBoundaryActivityLoading = (dispatch, loading) => dispatch({ type: ADD_TEAM_NBA_LOADING, loading })
const addTeamNonBoundaryActivitySuccess = (dispatch, data) => dispatch({ type: ADD_TEAM_NBA_SUCCESS, data })
const addTeamNonBoundaryActivityError = (dispatch, error) => dispatch({ type: ADD_TEAM_NBA_ERROR, error })