import React, { Component } from 'react';
import "../../assets/css/Activity/CompletedProjectsActivity.scss"
class CompletedProjectsActivity extends Component {
    render() {
        return (
            <div className="CompletedProjectsActivity">
                <h6>Completed Projects</h6>
                <div className="CompletedProjectsActivity_card scroll">
                    {(this.props.projectList && this.props.projectList.length !== 0 ? this.props.projectList.map((value, index) => {
                        var index = (value.users ? value.users.findIndex(user => user.userId === this.props.index) : -1)
                        if (index !== -1 && value.status === 0) {
                            return <div className="ProjectsActivity_card_tab_projectContent">
                                <div className="ProjectsActivity_card_tab_Title">
                                    {value.projectName}
                                </div>
                                <div className="ProjectsActivity_card_tab_content" >
                                    <div className="ProjectsActivity_card_tab_tap">
                                        <h6 className="ProjectsActivity_card_tab_tap_title">Spend Time</h6>
                                        <h6>{value.users[index].usedTime}H</h6>
                                    </div>
                                    <div className="ProjectsActivity_card_tab_tap">
                                        <h6 className="ProjectsActivity_card_tab_tap_title">Overall Expected Time</h6>
                                        <h6>{value.users[index].userAssignTime}H</h6>
                                    </div>
                                </div>
                            </div>
                        }
                    }) : <p>Not Available</p>)}
                </div>
            </div>
        );
    }
}

export default CompletedProjectsActivity;