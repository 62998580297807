import React, { Component } from 'react';
import { AreaChart, Area, PieChart, Pie, Cell, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts"

class ProjectTasks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: ""
        }
    }
    render() {
        const COLORS = ['#ef4123', '#0D0496', '#00C49F', '#FFBB28', '#FF8042'];
        const RADIAN = Math.PI / 180;

        const renderCustomizedLabel = ({
            cx, cy, midAngle, innerRadius, outerRadius, percent, index,
        }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${((percent < 0 ? 0 : percent) * 100).toFixed(1)}%`}
                </text>
            );
        };
        var pieChartData = [];
        if (this.props.project.graph) {
            if (this.props.project.graph[1].value < 0) {
                pieChartData.push({ "name": "Extended Hours", "value": this.props.project.graph[1].value * -1 });
            } else {
                pieChartData.push({ "name": "Available Hours", "value": this.props.project.graph[1].value });
                pieChartData.push({ "name": "Current Hours", "value": this.props.project.graph[0].value });

            }
        }

        return (
            <div className="row" style={{ width: "100%" }}>
                <div className="col-md-6 row">
                    <div className="col-md-6" style={{ height: 250 }}>
                        {this.props.project && this.props.project.graph &&

                            <PieChart width={400} height={400}>
                                <Pie
                                    data={pieChartData}
                                    cx={100}
                                    cy={100}
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {
                                        this.props.project.graph.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                    }
                                </Pie>
                                <Tooltip />
                            </PieChart>

                        }
                    </div>
                    <div className="col-md-6">
                        <h1 style={{ fontSize: 50, fontWeight: 500, color: (this.props.project.currentHours < this.props.project.expectedHours ? "#0D0496" : "#ef4123") }}>{(this.props.project && this.props.project.expectedHours ? (this.props.project.currentHours * 100 / this.props.project.expectedHours).toFixed(1) : "0")}%</h1>
                        <h6 style={{ fontWeight: 500, color: (this.props.project.currentHours < this.props.project.expectedHours ? "#0D0496" : "#ef4123") }}>Completed</h6>
                    </div>
                    <div className="col-md-12 scroll">
                        {this.props.projectAssignUsersAll.length !== 0 ? <AreaChart
                            width={500}
                            height={250}
                            data={this.props.areaChartData}
                            margin={{ top: 0 }}
                        >
                            <CartesianGrid strokeDasharray="100 10" />
                            <XAxis dataKey="date" />
                            <YAxis />
                            <Tooltip />
                            <Area type="monotone" dataKey="time" stroke="#ef4123" fill="#ef4123" />
                        </AreaChart> :
                            <div style={{ width: "100%" }}><p style={{ textAlign: "center", color: "gray" }}>No Data Found</p></div>
                        }
                    </div>
                </div>
                {/* this.state.timeView */}
                <div className="col-md-6" >
                    <div style={{ display: "flex", flexDirection: "row", overflow: "hidden", padding: 10 }}>
                        <div className={(this.props.timeView ? "pc_sub_user_main_hidden_timeView" : "pc_sub_user_main_timeView scroll")}
                            style={{ height: window.innerHeight - 200 }}>
                            {this.props.projectAssignUsers && this.props.projectAssignUsers.length !== 0 ? this.props.projectAssignUsers.map((value, index) => {
                                return <div className="pc_sub_user"
                                    onClick={() => { this.props.setUserEmail(value.userEmail, value); this.props.selectedIndex(index) }}
                                >
                                    <div className="pc_sub_pic">
                                        {(value.image == "loader" ?
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: 50 }}>
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%" }}>
                                                    <i class="fa fa-circle-o-notch fa-spin fa-2x fa-fw" style={{ color: "gray" }}></i>
                                                </div>
                                            </div>
                                            :
                                            <img className="pc_sub_pic_image" src={(value.image ? "data:image/png;base64," + value.image : require("../../assets/images/user.jpg"))} style={{ width: '100%', height: '100%' }} />
                                        )}
                                    </div>
                                    <div className="pc_sub_userDetails">
                                        <div className="pc_sub_userDetails_content">
                                            <h6 style={{ fontSize: 14 }}>{value.userName}</h6>
                                            <h6 style={{ fontSize: 13 }} className="pc_email">{value.userEmail}</h6>

                                            <AreaChart
                                                width={200}
                                                height={60}

                                                data={this.props.projectAssignUsersAll.filter(rowValue => rowValue.userEmail == value.userEmail)[0].timeList}
                                                margin={{
                                                    top: 5, right: 0, left: 0, bottom: 5,
                                                }}>

                                                <Area type="monotone" dataKey="time" stroke="#ef4123" fill="#ef4123" />
                                            </AreaChart>
                                        </div>
                                    </div>
                                    <div className="pc_sub_rate">
                                        <h4 style={{ fontSize: 30, fontWeight: 100, color: "#ffffff" }}>
                                            {(value.currentTime * 100 / value.userTime).toFixed(0)}%
                                        </h4>
                                        <h7 style={{ fontSize: 13, color: "#bbbbbb" }}  >Completed</h7>
                                    </div>
                                </div>
                            })
                                :
                                <div style={{ width: "100%" }}><p style={{ textAlign: "center", color: "gray" }}>No Users Found</p></div>

                            }
                        </div>
                        <div className={(this.props.timeView ? "pc_sub_user_main scroll" : "pc_sub_user_main_hidden")} >
                            {this.props.projectAssignUsersAll && this.props.projectAssignUsersAll.filter(rowValue => rowValue.userEmail == this.state.userEmail).map((value, index) => {
                                return <div style={{
                                    borderBottom: "1px solid gray",
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: 10
                                }}>
                                    <view style={{ width: "80%" }}>
                                        <h5>{new Date(value.date).getMonth() + "-" + new Date(value.date).getDate() + "-" + new Date(value.date).getFullYear()}</h5>
                                    </view>
                                    <view style={{ width: "20%" }}>
                                        <h3 style={{ color: "green" }}>{value.userTime} H</h3>
                                    </view>
                                </div>
                            })}
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectTasks;