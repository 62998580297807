import React, { Component } from 'react';

class Loader extends Component {
    render() {
        return (
            <div className="loaderMain" style={{}}>
            <div className="loaderContent" >
                <div className="loader">
                <div className="row">
	<div className="cssload-thecube">
	<div className="cssload-cube cssload-c1">
        <div className="cssload-square">
        
        </div>
    </div>
	<div className="cssload-cube cssload-c2"><div className="cssload-round">
        
        </div></div>
	<div className="cssload-cube cssload-c4"><div className="cssload-round">
        
        </div></div>
	<div className="cssload-cube cssload-c3 "><div className="cssload-round">
        
        </div></div>
</div>
	</div>
                    {/* <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span>
                    <span></span> */}
                </div>
            </div>
        </div>
        );
    }
}

export default Loader;