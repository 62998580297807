import React, { Component } from "react"
import * as Actions from "../../actions/supportActions"
import { connect } from "react-redux"
import Loader from "../../../containers/Loader/Loader"
import Table from "../Role/Table"
import { Alert } from 'antd';


class UploadSalesStage extends Component {
    constructor(props) {
        super(props);
        this.reactTable = React.createRef();
        this.state = {
            upload: [],
            loaderButton: false,
            error: "",
            loading: false
        }
    }

    // componentDidMount() {
    //     this.props.getUsersDropdown();
    //    this.props.getActiveProjects();
    // }

    // uploadFile = (file) => {
    //     var studentOutputArray = [];
    //     this.setState({ upload: [] })
    //     var reader = new FileReader();
    //     reader.onloadend = (valueRead) => {

    //         var arrayBuffer = valueRead.currentTarget.result;
    //         if (arrayBuffer) {
    //             var projectAssignArray = arrayBuffer.split("\n");
    //             for (var index = 1; index < projectAssignArray.length; index++) {
    //                 var projectDetailsArray = projectAssignArray[index].split(",");
    //                 console.log('projectDetailsArray' , projectDetailsArray)
    //                 if (projectDetailsArray[0] != "") {
    //                     // var projectIndexArray = this.props.upload.projects.findIndex((value) => {
    //                     //     return value.code == projectDetailsArray[0]
    //                     // })

    //                     var ProjectJsonValue = {
    //                         projectCode: projectDetailsArray[0],
    //                         SalesStageName: projectDetailsArray[1],
    //                     }
    //                    // if (projectIndexArray != -1) {
    //                         this.props.addSalesStage(ProjectJsonValue)
    //                     // } else if (projectIndexArray == -1) {
    //                     //     var errorUploadList = this.state.upload;
    //                     //     ProjectJsonValue["status"] = 2;
    //                     //     errorUploadList.push(ProjectJsonValue);
    //                     //     this.setState({ upload: errorUploadList })
    //                     // } else {
    //                     //     var errorUploadList = this.state.upload;
    //                     //     ProjectJsonValue["status"] = false;
    //                     //     errorUploadList.push(ProjectJsonValue);
    //                     //     this.setState({ upload: errorUploadList })
    //                     // }
    //                 }
    //                 if (projectAssignArray.length - 2 == this.state.upload.length) {
    //                     this.setState({ loaderButton: false, viewTable: true })

    //                 }
    //             }
    //         }



    //     }
    //     reader.readAsBinaryString(file)

    // }
    uploadFile = (file) => {
        let projectArray = {
            projects: []
        };
    
        this.setState({ upload: [] });
        
        let reader = new FileReader();
        
        reader.onloadend = (valueRead) => {
            let arrayBuffer = valueRead.currentTarget.result;
            if (arrayBuffer) {
                let projectAssignArray = arrayBuffer.split("\n");
    
                for (let index = 1; index < projectAssignArray.length; index++) {
                    let projectDetailsArray = projectAssignArray[index].split(",");
    
                    // If projectCode exists (assuming it's the first item in the row)
                    if (projectDetailsArray[0] !== "") {
                        let projectJsonValue = {
                            projectCode: projectDetailsArray[0],
                            salesStageName: projectDetailsArray[1] ? projectDetailsArray[1].trim() : "Unknown"  // Trim any \r or other whitespace
                        };
    
                        // Add the project to the array of projects
                        projectArray.projects.push(projectJsonValue);
                    }
                }
    
                // Pass the full array to the prop function or state
                this.props.addSalesStage(projectArray);
                this.setState({upload: projectArray.projects})
            }
        };
    
        // Read the file as a binary string
        reader.readAsBinaryString(file);
        this.setState({ loaderButton: false, viewTable: true })
    };
    
    
    onTableViewChange = () => {
        const current = this.reactTable.current;
        if (current) {
            const page = current.state.page;
            const pageSize = current.state.pageSize;
            const allData = current.getResolvedState().sortedData;
            const startIdx = page * pageSize;
            const currentData = allData.map((item) => item._original);
            this.setState({ studentsDowload: currentData })

        }
    }

    download = (items) => {
        var outputArray = [{ projectCode: "Project Code", SalesStageName: "Sales Stage"}];
        for (var index in items) {
            outputArray.push({
                projectCode: items[index].projectCode,
                SalesStageName: items[index].salesStageName
            })
        }
        // var jsonObject = JSON.stringify(outputArray);
        var csv = this.convertToCSV(outputArray);
        var exportedFilename = 'Project Sales Stage CSV Upload Template.csv';
        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilename);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilename);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    convertToCSV = (array) => {
        var str = '';
        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line != '') line += ',';
                line += array[i][index];
            }
            str += line + '\n';
        }
        return str;
    }

    static getDerivedStateFromProps = (nextProps, state) => {
        if (nextProps.upload) {
            return {
                loaderButton: false,
                viewTable: true,
            }
        }

    }
    render() {
        const columns = [
            { Header: 'Project Code', filterable: true, accessor: 'projectCode' },
            { Header: 'Sales Stage', filterable: true, accessor: 'salesStageName' },
            {
                Header: 'Status', filterable: true, accessor: 'status',
                Cell: props => {
                    return (
                        props.value ?
                            (props.value == 2 ? <div className="status" style={{ backgroundColor: "red", borderRadius: 5, color: "white", paddingLeft: 5, paddingRight: 5 }}>You haven't permission </div> :
                                (props.value == 1 ? <div className="status" style={{ backgroundColor: "red", borderRadius: 5, color: "white", paddingLeft: 5, paddingRight: 5 }}> email is incorrect</div> :
                                    (props.value == 4 ? <div className="status" style={{ backgroundColor: "green", borderRadius: 5, color: "white", paddingLeft: 5, paddingRight: 5 }}>Success</div> :
                                        null)))
                            :
                            <div className="status" style={{ backgroundColor: "green", borderRadius: 5, color: "white", paddingLeft: 5, paddingRight: 5 }}>Success</div>
                    )
                }
            }
        ]
        return (
            <>
                {this.props.loading &&
                    <Loader />
                }
                <div className="container csv_form">
                    <div className="col-md-18 row">
                    <div className="col-md-6" style={{ marginTop: 75 }}>
                    <h2 style={{ fontWeight: 700 }}>Upload Sales Stage</h2>
                    </div>
                        <div className="col-md-6" style={{ marginBottom: 20 }}>
                            <h6>Please Select a CSV File</h6>
                        </div>
                        <div className="col-md-6">
                            <a href={require("../../../assets/salesStage.csv")} >
                                Template for CSV
                            </a>
                        </div>
                        <div className="col-md-9" style={{ marginBottom: 20 }}>
                            <input type="file" className="form-control" accept=".csv" onChange={(e) => {
                                if (e.target.files[0]) {
                                    const name = e.target.files[0].name;
                                    const lastDot = name.lastIndexOf('.');
                                    const ext = name.substring(lastDot + 1);
                                    if (ext == "csv") {
                                        this.setState({ file: e.target.files[0] });
                                    } else {
                                        alert("Please upload csv File")
                                    }
                                }
                            }} />
                            <p style={{ color: "red" }}>{this.state.error}</p>
                        </div>

                        <div className="col-md-9" style={{ display: "flex", flexDirection: "row-reverse" }}>
                            {(this.state.loaderButton ?
                                <button className="btn-submit" style={{ width: 150 }}>

                                    <i className="fa fa-circle-o-notch fa-spin fa-1x fa-fw" style={{ color: "gray" }}></i>
                                </button>
                                :
                                <button className="btn-submit" onClick={() => {
                                    if (this.state.file) {
                                        this.setState({ loaderButton: true, error: "" });
                                        this.uploadFile(this.state.file);
                                    } else {
                                        this.setState({ error: "Please upload file" })
                                    }
                                    this.setState({ loaderButton: false, error: "" });
                                }} style={{ width: 150, height: 40, cursor: "pointer" }}>
                                    Upload</button>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12 row">
                        {this.props.upload && (this.state.upload.length != 0 || this.props.upload.uploaded) &&
                            <>
                                <div class="col-md-12" style={{ paddingTop: 20 }}>
                                    <button className="btn-danger" onClick={() => {
                                        if (this.props.upload.uploaded) {
                                            this.download(this.props.upload.uploaded.concat(this.state.upload))
                                        } else {
                                            this.download(this.state.upload)
                                        }


                                    }} style={{ width: 150, borderColor: "#555555", backgroundColor: "#555555", cursor: "pointer" }}>
                                        Download csv</button>
                                </div>
                                <Table
                                   data={(this.props.upload.uploaded ? this.props.upload.uploaded.concat(this.state.upload) : this.state.upload)}
                                   columns={columns}
                                />
                            </> 
                         } 
                    <div class="col-md-9" style={{ paddingTop: 20 }}>
                    {this.props.error &&  
                        <Alert
                            message="Invalid Details"
                            description= {
                                Array.isArray(this.props.error.response.data.errors) ? (
                                    <div> 
                                        {this.props.error.response.data.errors.map((error, index) => (
                                            <div key={index}>{error}</div>
                                        ))}
                                    </div>
                                ) : (this.props.error.response.data.errors)
                            }
                            type="error"
                            />}
                    </div>
                    </div>
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    
    return {
        upload: state.support.addSalesStage,
        error: state.support.addSalesStageError,
        loading: state.support.addSalesStageLoading
    }
}


export default connect(mapStateToProps, Actions)(UploadSalesStage);