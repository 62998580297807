import React, { Component } from "react";
import * as Actions from "../../actions/usersActions"
import { connect } from 'react-redux';
import Nav from "../../../containers/Navbar/Navbar"
import { Link } from "react-router-dom"
import NavbarTop from "../../../containers/Navbar/NavbarTop"
import ReactTable from 'react-table'
import Loader from "../../../containers/Loader/Loader"


class UsersAssignDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navSide: true,
            loader: true
        }
    }

    componentDidMount() {
        this.props.getAssignUsers();

    }

    rowFunction = (state, rowInfo, column) => {

        if (rowInfo != null) {
            return {
                onClick: (e, handleOriginal) => {
                    this.setState({ activeType: rowInfo.original.active, rowId: rowInfo.original.userProjectId, actionView: true, rowIndex: rowInfo.index, rowData: rowInfo.original });
                },
                style: {
                    backgroundColor: (rowInfo.original.userTime < rowInfo.original.currentTime ? (this.state.rowIndex == rowInfo.index ? "#bc8bf4" : "#ff9984") : (this.state.rowIndex == rowInfo.index ? "#8bb3f4" : "")),
                    cursor: "pointer",
                    border: (this.state.rowIndex == rowInfo.index ? "2px solid blue" : "")

                }
            }
        } else {
            return {}
        }
    }

    static getDerivedStateFromProps(props, state) {
        if (props.users.assignUsers) {
            return {
                loader: false
            }
        }
    }

    render() {
        const columns = [
            {
                Header: 'Project Name',
                filterable: true, accessor: 'projectName'
            },
            {
                Header: 'User Email',
                filterable: true, accessor: 'userEmail'
            },
            {
                Header: 'User Name',
                filterable: true, accessor: 'userName'
            },
            {
                Header: 'Assign Time',
                filterable: true, accessor: 'userTime'
            },
            {
                Header: 'Current Time',
                filterable: true, accessor: 'currentTime'
            },
            {
                Header: 'Active',
                filterable: true,
                Cell: props => {
                    return (<div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: "100%" }}>{(props.original.active == 1 ? <span style={{ backgroundColor: "green", color: "white", paddingRight: 10, paddingLeft: 10, borderRadius: 5 }}>Active</span> : <span style={{ backgroundColor: "#c10606", color: "white", paddingRight: 10, paddingLeft: 10, borderRadius: 5 }}>Inactive</span>)}</div>)
                },
                accessor: 'active'
            }
        ]
        return (
            <div>
                {
                    this.state.loader &&
                    <Loader />
                }
                <div className="table-container">
                    {
                        this.state.actionView &&
                        <div style={{ display: "flex", flexDirection: "row" }}>
                            <Link to={{ pathname: `/EditAssign`, state: { rowData: this.state.rowData, rowId: this.state.rowId, index: this.state.rowIndex } }} >
                                <div className="btn-submit" style={{ cursor: "pointer", color: "black", height: 30, backgroundColor: "lightgray", borderRadius: 10, justifyContent: "center" }}>
                                    <span style={{ fontSize: 12 }}><icon className="fa fa-edit" style={{ marginTop: 5, marginLeft: 5 }} /> Edit</span>
                                </div>
                            </Link>
                            {(this.state.activeType == 1 ?
                                <div onClick={() => {
                                    this.props.deleteUserAssign(this.state.rowId, this.state.rowIndex, 0);
                                }} className="btn-submit" style={{ cursor: "pointer", height: 30, marginLeft: 20, backgroundColor: "red", flexWrap: "wrap", borderRadius: 10, color: "white", alignContent: "center", justifyContent: "center" }}>
                                    <span style={{ fontSize: 12 }}><icon className="fa fa-user-edit" style={{ marginTop: 5, marginLeft: 5 }} /> Inactive</span>
                                </div> :
                                <div onClick={() => {
                                    this.props.deleteUserAssign(this.state.rowId, this.state.rowIndex, 1);
                                }} className="btn-submit" style={{ cursor: "pointer", height: 30, marginLeft: 20, backgroundColor: "green", flexWrap: "wrap", borderRadius: 10, color: "white", alignContent: "center", justifyContent: "center" }}>
                                    <span style={{ fontSize: 12 }}><icon className="fa fa-user-edit" style={{ marginTop: 5, marginLeft: 5 }} /> Active</span>
                                </div>
                            )
                            }
                        </div>
                    }
                    {this.props.users && <ReactTable
                        data={(this.props.users.assignUsers == null ? [] : this.props.users.assignUsers)}
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())}
                        getTrProps={this.rowFunction}
                        columns={columns}
                        className="-striped -highlight"
                        sortable='true'
                        defaultPageSize={10}
                    />}</div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { users: state.usersReducers }
}

export default connect(mapStateToProps, Actions)(UsersAssignDetails);