import React, { useEffect, useState } from 'react';
import { Tabs, Button, Popconfirm } from 'antd';
import AddRole from "./AddRole"
import {
    UserAddOutlined,
    DeleteFilled
} from '@ant-design/icons';
import * as Actions from "../../actions/roleActions"
import "../../../assets/css/nonBoundaryActivity.scss"
import TableContainer from './TableContainer';
import Loader from "../../../containers/Loader/Loader"
import { connect } from 'react-redux';
import Table from './Table';

const ProjectManagers = ({ team, getProjectManager, getUsersDropdown, getUsersDropdownSearch, deleteProjectManager, user, pMState }) => {
    const [addProjectManager, setAddProjectManager] = useState(false);

    useEffect(() => {
        getProjectManager()
    }, [])

    const columns = [{
        Header: 'User Email',
        accessor: 'userEmail',
        key: 'userEmail',
        onFilter: (value, record) => record.name.includes(value),
        sorter: {
            compare: (a, b) => ('' + a.userEmail).localeCompare(b.userEmail),
            multiple: 3,
        }
    }, {
        Header: 'User Name',
        accessor: 'userName',
        key: 'userName',
        sorter: {
            compare: (a, b) => ('' + a.userName).localeCompare(b.userName),
            multiple: 3,
        }
    }, {
        Header: '',
        accessor: 'active',
        Cell: props => <Popconfirm placement="left" title={"Are you sure to delete this project manager?"} onConfirm={() => {
            deleteProjectManager(props.row.original.id);
        }} okText="Yes" cancelText="No">
            <Button style={{ fontSize: 12 }} ><DeleteFilled style={{ color: "red" }} /></Button>
        </Popconfirm>
    }];

    return (
        <div>
            { pMState.getProjectManagersLoading &&
                <Loader />
            }
            <div className="buttonRight">
                <Button type="primary" danger onClick={() => setAddProjectManager(true)}>
                    <UserAddOutlined /> Add Project Manager
                        </Button>
            </div>
            {/* {pMState.getProjectManagers &&
                <TableContainer
                    data={pMState.getProjectManagers}
                    loading={pMState.getProjectManagersLoading}
                    error={pMState.getProjectManagersError}
                    title={"Project Managers"}
                    columns={columns}
                />
            } */}

            <Table
                data={pMState.getProjectManagers}
                columns={columns}

            />

            <AddRole
                getUsers={(value) => {
                    if (value.length == 2) {
                        getUsersDropdown();
                    } else if (value.length > 2 & value.length % 2 == 0) {
                        getUsersDropdownSearch(value);
                    }
                }}
                projectManagerList={pMState.getProjectManagers}
                teams={team.getAllTeamsSuccess}
                users={user.allUsers}
                visible={addProjectManager}
                onClose={() => { setAddProjectManager(false) }}
            />

        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        pMState: state.roleReducers
    }
}

export default connect(mapStateToProps, Actions)(ProjectManagers);