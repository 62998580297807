import { GET_TEAMS_LOADING, GET_TEAMS_SUCCESS, GET_TEAMS_ERROR, GET_ALL_TEAMS_ERROR, GET_ALL_TEAMS_LOADING, GET_ALL_TEAMS_SUCCESS, ADD_TEAM_LOADING, ADD_TEAM_SUCCESS, ADD_TEAM_ERROR, GET_TEAM_MEMBERS_LOADING, GET_TEAM_MEMBERS_ERROR, GET_TEAM_MEMBERS_SUCCESS, ADD_TEAM_MEMBER_LOADING, ADD_TEAM_MEMBER_SUCCESS, ADD_TEAM_MEMBER_ERROR } from "../types"


const initialState = {
    getTeamsLoading: false,
    getTeamsSuccess: [],
    getTeamsError: null,
    addTeamLoading: false,
    addTeamSuccess: null,
    addTeamError: null,
    getTeamMembersLoading: false,
    getTeamMembersSuccess: [],
    getTeamMembersError: null,
    getAllTeamsSuccess: [],
    getAllTeamsError: null,
    getAllTeamsLoading: false
}

const teamReducers = (state = initialState, actions) => {
    switch (actions.type) {
        case GET_TEAMS_LOADING:
            return { ...state, getTeamsLoading: actions.loading, getTeamsSuccess: [], getTeamsError: null }
        case GET_TEAMS_SUCCESS:
            return { ...state, getTeamsLoading: false, getTeamsSuccess: actions.data, getTeamsError: null }
        case GET_TEAMS_ERROR:
            return { ...state, getTeamsLoading: false, getTeamsSuccess: [], getTeamsError: actions.error }
        case ADD_TEAM_LOADING:
            return { ...state, addTeamLoading: actions.loading, addTeamSuccess: null, addTeamError: null }
        case ADD_TEAM_SUCCESS:
            var teamArray = state.getTeamsSuccess;
            teamArray.push(actions.data);
            return { ...state, addTeamLoading: false, getTeamsSuccess: teamArray, addTeamSuccess: actions.data, addTeamError: null }
        case ADD_TEAM_ERROR:
            return { ...state, addTeamLoading: false, addTeamSuccess: null, addTeamError: actions.error }
        case GET_TEAM_MEMBERS_LOADING:
            return { ...state, getTeamMembersLoading: actions.loading, getTeamMembersSuccess: [], getTeamMembersError: null }
        case GET_TEAM_MEMBERS_ERROR:
            return { ...state, getTeamMembersLoading: false, getTeamMembersSuccess: [], getTeamMembersError: actions.error }
        case GET_TEAM_MEMBERS_SUCCESS:
            return { ...state, getTeamMembersLoading: actions.loading, getTeamMembersSuccess: actions.data, getTeamMembersError: null }
        case ADD_TEAM_MEMBER_LOADING:
            return { ...state, addTeamMembersSuccess: null, addTeamMembersLoading: actions.loading, addTeamMembersError: null }
        case ADD_TEAM_MEMBER_SUCCESS:
            var teamMembersArray = state.getTeamMembersSuccess;
            teamMembersArray.push(actions.data);
            return { ...state, getTeamMembersSuccess: teamMembersArray, addTeamMembersSuccess: actions.data, addTeamMembersLoading: false, addTeamMembersError: null }
        case ADD_TEAM_MEMBER_ERROR:
            return { ...state, addTeamMembersSuccess: null, addTeamMembersLoading: false, addTeamMembersError: actions.error }
        case GET_ALL_TEAMS_LOADING:
            return { ...state, getAllTeamsLoading: actions.loading, getAllTeamsSuccess: [], getAllTeamsError: null }
        case GET_ALL_TEAMS_SUCCESS:
            return { ...state, getAllTeamsLoading: false, getAllTeamsSuccess: actions.data, getAllTeamsError: null }
        case GET_ALL_TEAMS_ERROR:
            return { ...state, getAllTeamsLoading: false, getAllTeamsSuccess: [], getAllTeamsError: actions.error }
        default:
            return state
    }
}

export default teamReducers