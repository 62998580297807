import React, { Component } from 'react';
import Nav from "../../../containers/Navbar/Navbar"
import { connect } from 'react-redux';
import * as usersActions from "../../actions/usersActions"
import ReactTable from 'react-table'
// import 'react-table/react-table.css'
import NavbarTop from "../../../containers/Navbar/NavbarTop"
import { Link } from 'react-router-dom'
import { AreaChart, Area, PieChart, Pie, Cell, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts"
import ProjectTasks from "../../../containers/Tasks/ProjectTasks"
import ProjectTasksUser from "../../../containers/Tasks/ProjectTasksUser"
import Loader from "../../../containers/Loader/Loader"

class HistoryProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            imageURL: "",
            navSide: true,
            modalView: false,
            userModalView: false,
            projectIndexId: 0,
            projectName: "",
            users: [],
            timeView: false,
            userEmail: "",
            user: "",
            loader: true,
            selectedIndex: null
        }
    }

    componentDidMount() {
        const { match: { params: { id, name, index } } } = this.props;

        this.setState({ projectName: name, index: index })
        this.props.ProjectAssignUsers(id);
    }


    static getDerivedStateFromProps(props, state) {
        if (props.userAssign.projectAssignUsersAll && props.userAssign.project && props.userAssign.projectAssignUsers) {
            return {
                loader: false
            }
        }
    }
    render() {
        const subColumns = [

            {
                Header: 'Start Date',
                filterable: true, accessor: 'date',
                Cell: props => {
                    var date = new Date(props.original.date);
                    return (<span>{date.getFullYear() + "/ " + (date.getMonth() + 1) + "/ " + date.getDate()}</span>)
                }
            },
            {
                Header: 'Current Hours',
                filterable: true, accessor: 'userTime',
                Cell: props => {
                    return (<span>{props.original.userTime + "H"}</span>)
                }
            }
        ]
        const columns = [
            {
                Header: 'Employee Name', accessor: 'userName',
                filterable: true,
                Cell: props => {
                    return (<span style={{ color: "black", fontWeight: "800", paddingLeft: 10 }}>{props.original.userName}</span>)
                }
            },
            {
                Header: 'User Email',
                filterable: true, accessor: 'userEmail'
            },
            {
                Header: 'Current Hours',
                filterable: true, accessor: 'userTime',
                Cell: props => {
                    return (<span>{props.original.userTime + "H"}</span>)
                }
            },
            {
                Header: 'Expected Hours',
                filterable: true, accessor: 'currentTime',
                Cell: props => {
                    return (<span>{props.original.currentTime + "H"}</span>)
                }
            },
            {
                Header: 'Start Date',
                filterable: true, accessor: 'date',
                Cell: props => {
                    var date = new Date(props.original.date);
                    return (<span>{date.getFullYear() + "/ " + (date.getMonth() + 1) + "/ " + date.getDate()}</span>)
                }
            }
        ]

        return (
            <>{
                this.state.loader &&
                <Loader />
            }
                <div className="table-container">
                    <div className="row project_card_details" >
                        <div className="col-md-12">
                            <h2>{this.state.projectName}</h2>
                        </div>
                        <div className="col-md-12 row project_card_nav" >
                            <div className="col-md-6 pc_tab_content" >
                                <div onClick={() => { this.setState({ timeView: false, userEmail: "" }) }} className={(this.state.timeView ? "pc_tab" : "pc_tab_active")}>
                                    Percentage
                                </div>
                                {this.state.userEmail != "" &&
                                    <div onClick={() => { this.setState({ timeView: true, userEmail: "" }) }} className={(this.state.timeView ? "pc_tab_active" : "pc_tab")}>
                                        TimeLine
                                </div>
                                }
                            </div>
                        </div>
                        {this.props.userAssign && this.props.userAssign.projectAssignUsersAll
                            && this.props.userAssign && this.props.userAssign.projectAssignUsers
                            && this.props.userAssign && this.props.userAssign.project ?
                            (this.state.timeView ?
                                <ProjectTasksUser timeView={this.state.timeView} userEmail={this.state.userEmail} user={this.state.user}
                                    projectAssignUsersAll={this.props.userAssign.projectAssignUsersAll}
                                    projectAssignUsers={this.props.userAssign.projectAssignUsers}
                                    project={this.props.userAssign.project}
                                    selectedIndex={this.state.selectedIndex}
                                /> :
                                <ProjectTasks
                                    timeView={this.state.timeView}
                                    setUserEmail={(email, user) => { this.setState({ user: user, userEmail: email, timeView: true }) }}
                                    projectAssignUsersAll={this.props.userAssign.projectAssignUsersAll}
                                    projectAssignUsers={this.props.userAssign.projectAssignUsers}
                                    areaChartData={this.props.userAssign.areaChartData}
                                    project={this.props.userAssign.project}
                                    selectedIndex={(index) => this.setState({ selectedIndex: index })}
                                />)
                            :
                            <div>no data</div>
                        }
                    </div>

                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userAssign: state.usersReducers,
        projectstate: state.projectReducer
    }
}

export default connect(mapStateToProps, usersActions)(HistoryProject);