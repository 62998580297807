import axios from "axios"
import { GET_TEAMS, ADD_TEAM, GET_TEAM_MEMBERS, ADD_TEAM_MEMBER, GET_ALL_TEAMS_URL, GET_ALL_TEAM_MEMBERS_URL, ACTIVATE_TEAM_URL } from "../../constant"
import { GET_TEAMS_SUCCESS, GET_TEAMS_ERROR, GET_TEAMS_LOADING, ADD_TEAM_SUCCESS, ADD_TEAM_ERROR, ADD_TEAM_LOADING, GET_TEAM_MEMBERS_SUCCESS, GET_TEAM_MEMBERS_ERROR, GET_TEAM_MEMBERS_LOADING, ADD_TEAM_MEMBER_SUCCESS, ADD_TEAM_MEMBER_ERROR, ADD_TEAM_MEMBER_LOADING, GET_ALL_TEAMS_SUCCESS, GET_ALL_TEAMS_ERROR, GET_ALL_TEAMS_LOADING } from "../types";
export const getTeams = (id) => dispatch => {
    getTeamsLoading(dispatch, true);
    axios.get(GET_TEAMS + "&userId=" + id)
        .then(response => {
            getTeamsSuccess(dispatch, response.data);
        }).catch(error => {
            getTeamsError(dispatch, error);
        })
}

export const getAllTeamsDropdown = () => dispatch => {
    getTeamsLoading(dispatch, true);
    axios.get(GET_ALL_TEAMS_URL)
        .then(response => {
            getTeamsSuccess(dispatch, response.data);
        }).catch(error => {
            getTeamsError(dispatch, error);
        })
}

const getTeamsSuccess = (dispatch, data) => dispatch({ type: GET_TEAMS_SUCCESS, data })
const getTeamsError = (dispatch, error) => dispatch({ type: GET_TEAMS_ERROR, error })
const getTeamsLoading = (dispatch, loading) => dispatch({ type: GET_TEAMS_LOADING, loading })

export const addNewTeam = (teamName, department, createdBy) => dispatch => {
    addTeamLoading(dispatch, true);
    axios.post(ADD_TEAM, JSON.stringify({ teamName, department, createdBy }))
        .then((response) => {
            addTeamSuccess(dispatch, response.data)
        })
        .catch(error => {
            addTeamError(dispatch, error)
        })
}

const addTeamSuccess = (dispatch, data) => dispatch({ type: ADD_TEAM_SUCCESS, data })
const addTeamError = (dispatch, error) => dispatch({ type: ADD_TEAM_ERROR, error })
const addTeamLoading = (dispatch, loading) => dispatch({ type: ADD_TEAM_LOADING, loading })

export const getTeamMembers = (id) => dispatch => {
    getTeamMembersLoading(dispatch, true);
    axios.get(GET_TEAM_MEMBERS + "&Id=" + id)
        .then(response => {
            getTeamMembersSuccess(dispatch, response.data);
        })
        .catch(error => {
            getTeamMembersError(dispatch, error)
        })
}

export const getAllTeamMembers = () => dispatch => {
    getTeamMembersLoading(dispatch, true);
    axios.get(GET_ALL_TEAM_MEMBERS_URL)
        .then(response => {
            getTeamMembersSuccess(dispatch, response.data);
        })
        .catch(error => {
            getTeamMembersError(dispatch, error)
        })
}


const getTeamMembersSuccess = (dispatch, data) => dispatch({ type: GET_TEAM_MEMBERS_SUCCESS, data })
const getTeamMembersError = (dispatch, error) => dispatch({ type: GET_TEAM_MEMBERS_ERROR, error })
const getTeamMembersLoading = (dispatch, loading) => dispatch({ type: GET_TEAM_MEMBERS_LOADING, loading })

export const addTeamMember = (teamid, userName, userEmail, userId, teamName) => dispatch => {
    addTeamMemberLoading(dispatch, true);
    axios.post(ADD_TEAM_MEMBER, JSON.stringify({ teamid, userName, userEmail, userId }))
        .then(response => {
            addTeamMemberSuccess(dispatch, { teamName, userName, userEmail, userId })
        }).catch(error => {
            addTeamMemberError(dispatch, error)
        })
}

const addTeamMemberSuccess = (dispatch, data) => dispatch({ type: ADD_TEAM_MEMBER_SUCCESS, data })
const addTeamMemberError = (dispatch, error) => dispatch({ type: ADD_TEAM_MEMBER_ERROR, error })
const addTeamMemberLoading = (dispatch, loading) => dispatch({ type: ADD_TEAM_MEMBER_LOADING, loading })

export const getAllTeams = () => dispatch => {
    getAllTeamsLoading(dispatch, true);
    axios.get(GET_ALL_TEAMS_URL)
        .then(response => {
            getAllTeamsSuccess(dispatch, response.data);
        }).catch(error => {
            getAllTeamsError(dispatch, error)
        })
}

const getAllTeamsSuccess = (dispatch, data) => dispatch({ type: GET_ALL_TEAMS_SUCCESS, data })
const getAllTeamsError = (dispatch, error) => dispatch({ type: GET_ALL_TEAMS_ERROR, error })
const getAllTeamsLoading = (dispatch, loading) => dispatch({ type: GET_ALL_TEAMS_LOADING, loading })

export const getTeamsDrop = () => dispatch => {
    getTeamsLoading(dispatch, true);
    axios.get(GET_ALL_TEAMS_URL)
        .then(response => {
            getTeamsSuccess(dispatch, response.data);
        }).catch(error => {
            getTeamsError(dispatch, error);
        })
}

export const deactivateTeam = (id) => dispatch => {
    axios.get(ACTIVATE_TEAM_URL + "&id=" + id)
        .then(data => {

        }).catch(error => {

        })
}

// const deactivate