//const URL = "https://millenniumitespprotime20200423092152.azurewebsites.net/api/";
//const URL = "http://localhost:7071/api/";

// //Prod
// const URL = "https://protime.azure-api.net/proTimeTenant/"
// export const subscribeKey = "&subscription-key=614202fbdd424f7d895edd5eafa6b046"

///Test Tenant
// const URL = "https://protime.azure-api.net/MillenniumITESPProTime/"
// const URL2 = "https://millenniumitespprotime20200423092152.azurewebsites.net/api/"
// export const subscribeKey = "&subscription-key=7a5cfbace002476f903041cd35486e1b"

///Staging
// const URL = "https://protime.azure-api.net/protime/mit-protime-staging/"
// export const subscribeKey = "&subscription-key=8fab37f7fbc44ac1acc8cfe0806f6ae7"
// https://protime.azurewebsites.net/api/ActivatedNonBoundryActivity?code=hp11h1RVEsKA5vrrY9jlm09PDqkA0vFIXxyvvD6K59TDIsunIEBubw==

// export const ALL_PROJECTS_URL = URL + "GetProjects?code=aZDcB0kiermRqFjouGC5NAkTimnatDVgfI9u2WK0VtDTeTIQwii4Qg==" + subscribeKey
// export const ADD_PROJECTS_URL = URL + "AddProjects?code=/SCUK2kkP7rCiqxk2Bj5ZVL9YvHCGsZjd82tjEL9uY/hXWk1lkY3/Q==" + subscribeKey
// export const ASSIGN_USERS_URL = URL + "usersAssign?code=4w/13O6zU7SGDe0VQzFKxhZTNNNzrGToJbpRBEScmnySXWvfj8BoZA==" + subscribeKey
// export const ACTIVE_PROJECTS_URL = URL + "GetActiveProjects?code=NKj6RcC7/ZOrWsWhzYbMMe0y2GKD/br7Qb5NS59xviqNRNYoqV1qog==" + subscribeKey
// export const COMPLETED_PROJECT_URL = URL + "GetCompletedProjects?code=BMYk7cygLwYUsHFgeT6lqibaGllQJft9bu4APLPLXpk1llllmm8IEA==" + subscribeKey
// export const TODAY_PROJECT_URL = URL + "GetTodayProjects?code=yDczG4j8Y2eTQnvgtDdlEaRa749ynJcQBEdpz8ckyjiDfNEXXktsnw==" + subscribeKey
// export const ADD_TIME_URL = URL + "AddTime?code=wep3NV3kmMmFleEFbETAz9pBgMgjPTx0mDDE1i4pRbu4uxsDS6ahYw==" + subscribeKey
// export const GET_PROJECT_URL = URL + "GetProject?code=Qg6yzRqoGsZLLYjaihbR0eOFK0NibKhRptxxTLYhWjgvQz/HnOpdBw==" + subscribeKey
// export const REDUCE_TIME_URL = URL + "ReduceTime?code=Ou2r3I8auDWIIlvV9J5nG0QAz95HRaBDUzmoFa7/fPpKjML/Y2LUaw==" + subscribeKey
// export const UPDATE_PROJECT_URL = URL + "UpdateProject?code=kl3qpcKA6IgLJXDAYCByQumlRroBgzi8xj6EJdDKM18Gv2A8dwkAIg==" + subscribeKey
// export const USER_BASE_PROJECTS = URL + "UserBaseProjects?code=ahCcrxY4i5x3Z/9J5dkrtSX9/AvfQ4UDm48qyqiS8yh27SJ7t2Cibg==" + subscribeKey
// export const PROJECT_STATUS_URL = URL + "ProjectStatus?code=Vxji/TZOK8CZqScoWMWjXPaSgALOaNCg4N5425gTj8jmXF/pqMFYUQ==" + subscribeKey
// export const COMPLETE_PROJECT_URL = URL + "CompleteProject?code=YZ3TL3SjaKLg8SA4aR18bBCRF66qy6B6NF/qT4FddBbpiHvR9XQ7Aw==" + subscribeKey
// export const ACTIVE_PROJECT_URL = URL + "ActiveProject?code=CZaDVsERZEAhYi8kUXx6EtVG5m5NFxpi6XkHDOJOYOEleCAR82MRNQ==" + subscribeKey
// export const DELETE_ASSIGN_USER_URL = URL + "deleteAssignUsers?code=60I54Oiat6YTaPrihjrEHKWApBwnV9borhntsTDNfYh4PIDfBVT9ug==" + subscribeKey
// export const GET_ASSIGN_USERS_URL = URL + "getAssignUsers?code=mTWTMkxL2jhNYRaJOofZ2DwJDKeH1JTr2rfb/swQP6yw7N03PyIpdA==" + subscribeKey
// export const UPDATE_ASSIGN_USER_URL = URL + "editAssignUsers?code=3avFmZlJGRJEJYv7DynA2o58cUgmU30TBb1LYhqnq7X4i9NF9bN5hA==" + subscribeKey
// export const GET_PROJECT_ASSIGN_USERS_URL = URL + "GetProjectAssignUsers?code=kJyqPhaKtFxJs1Eo8YQtWLZF5kaR1Tb9OLMakyNZfv7qF63c4RmHPQ==" + subscribeKey
// export const GET_PROJECT_PARTICULAR_USER_URL = URL + "GetUserAssignProjects?code=y3UDygw1G6TifTNiBbytETJo61o1FOfRJKVyGVyKfay2zRXm/YBJUA==" + subscribeKey

// //Task
// export const GET_TASKS_URL = URL + "UserBaseProjects?code=ahCcrxY4i5x3Z/9J5dkrtSX9/AvfQ4UDm48qyqiS8yh27SJ7t2Cibg==" + subscribeKey;
// export const TASK_UPDATE_TIME_URL = URL + "AddTime?code=wep3NV3kmMmFleEFbETAz9pBgMgjPTx0mDDE1i4pRbu4uxsDS6ahYw==" + subscribeKey;
// export const TASK_REDUCE_TIME_URL = URL + "ReduceTime?code=VJn1CvXXi9iYaQjmLrHX195kHvCR3j8b6koEZDJrIPDPDAW96Fpi8w==" + subscribeKey;
// export const GET_DEFAULT_PROJECT_URL = URL + "GetDefaultProjects?code=qiwltCn7K0qrS36sIv9vajQ1VDXfYavnToL0E/TScqwaL8sDLF73Sw==" + subscribeKey;
// export const ADD_EVENT_URL = URL + "AddTimeDefaultProjects?code=IW0WPuPZaFp26Bj44vO/UbNao7AOsblfdB0Z6rWRFNG8MVP27jPr/Q==" + subscribeKey;
// export const REDUCE_EVENT_URL = URL + "ReduceTimeDefaultProjects?code=FqU5hvJMvjI2EfXodlFlhVCfB9fotqEk3k/IgckgGfLzhj6q/wF35Q==" + subscribeKey;

// //Project
// export const GET_FOLLOWERS_TIME_USING_DATE_RANGE_URL = URL + "getFollowersTimeUsingDateRange?code=NN5XJJrO7hYQY2ZCFvpv1HtgQOfmMdhiMpdyCD1GBmI9ha4jM5oGWA==" + subscribeKey

// //Role
// export const GET_PROJECT_MANAGERS_URL = URL + "getProjectManagers?code=jKCc2HctaIfCqxYz8OBIsupFY8yBga5Dx9emhM8fVdNMn7jHpiTXUA==" + subscribeKey
// export const ADD_PROJECT_MANAGERS_URL = URL + "AddProjectManager?code=rBMvVmgcPXLUBub7A6ZNa/5lVRchOfs66M0LvzhN8WIgS/UFKUP3zg==" + subscribeKey
// export const GET_ROLE_URL = URL + "GetRole?code=3T3nX/SIHxXbOJB6EaU5PHMiw1OilpiNGYK3YaFSN6lPFJiie6rurQ==" + subscribeKey


// export const GET_DEPARTMENT_HEAD_URL = URL + "GetDepartmentHead?code=IlzXDEuJt0FNtz1k46duiEbcqhCGhrcRfEHcD247G1jTisWear11lQ==" + subscribeKey
// export const ADD_DEPARTMENT_HEAD_URL = URL + "AddDepartmentHead?code=asLa8Cao2SKsvdr3hZpIc3bPKXqWiNVoSPrF2aOJlawvpagMGGnJrw==" + subscribeKey
// export const GET_TEAM_LEADS_URL = URL + "GetTeamLeads?code=0fdaUkE4vTPUuM85LWsqv2IaoDIXRMRlIaWbWya1hIYD3Hm37cr04w==" + subscribeKey
// export const ADD_TEAM_LEAD_URL = URL + "AddTeamLead?code=xFsm/ZGuSVsyGRHTHidCbyalmlcHpAX2kp2Dw9SO1DdJeEi15QDvJw==" + subscribeKey
// export const DELETE_TEAM_LEAD_URL = URL + "DeleteTeamLeadUser?code=smO1rynYJ9o0uKeD52jeqY6vYnmZW32AMJrbi5GdQw3aISOeg/VDOg==" + subscribeKey
// export const GET_ADMINS_URL = URL + "GetAdmin?code=pUZiFIBNazpDSaHHqYsPLiHiSjrs65bqASpeXH3XAZ1wEFRPxj1P5Q==" + subscribeKey
// export const ADD_ADMIN_URL = URL + "AddAdmin?code=a3Y7HtANPHw9UcCsqfpw3HYZ9olwilRP0AscW9hCCeOdBtbioM2kPA==" + subscribeKey
// export const DELETE_ADMIN_URL = URL + "DeleteAdminUser?code=W9WfVy0ULAIUqsvznXn8M7iXGg0S7ZjsD3Seji2/i4j33LwXaUYsng==" + subscribeKey
// export const DELETE_DEPARTMENT_HEAD_URL = URL + "DeleteDepartmentHeadUser?code=E99otuAEsKWs2igKpg6eGf0A/mCILKuCm2k4LkL97jNUle8G0aw0ZQ==" + subscribeKey
// export const DELETE_PROJECT_MANAGER_URL = URL + "DeleteProjectManagerUser?code=5zA2PnEFDjuyOTyZdqxqO2JhEAv1Tts3LUIxf/C89idlROIn6vvtiA==" + subscribeKey

// //NonBoundayActivity
// export const GET_ORGANIZATION_NBA_URL = URL + "organizationNonBoundryActivity?code=MKFH0xqiMgjc6SnnEMdu11Zra8GZIoKklVFXHcG7NrUGfZaNAnhNsQ==" + subscribeKey
// export const ADD_ORGANIZATION_NBA_URL = URL + "addOrganizationNonBoundryActivity?code=8mgQwDjquekevkZHla494mSTn4GTNxBqYK9Ubao28tGEH8OPO6x5Cg==" + subscribeKey
// export const GET_DEPARTMENT_NBA_URL = URL + "departmentNonBoundryActivity?code=aEGbX7HZ53teaaYhaZAA2QX7ecAkaoJYasZPK/eFY2ChY4NZDAYfUw==" + subscribeKey
// export const ADD_DEPARTMENT_NBA_URL = URL + "addDepartmentNonBoundryActivity?code=Ksz6L6KmfqBQUszndctIOx9k5OQ9dHORpzgkq2ojufTwopPhu28WSQ==" + subscribeKey
// export const GET_TEAM_NBA_URL = URL + "teamsNonBoundaryActivity?code=hGhQ6sa7loEfpSuwiTpFg6lAutMBIpMLQZ3Duwfos89KvKuyRS6/dw==" + subscribeKey
// export const GET_ALL_TEAM_NBA_URL = URL + "teamsNonBoundaryAllActivity?code=PtJEMearS81TscJ45CQk1JjBJccFapwBB0K3CyXEWSNILB9uQBqBLA==" + subscribeKey
// export const ADD_TEAM_NBA_URL = URL + "addTeamsNonBoundaryActivity?code=Re3xahkkXgHx4j7Tqxi0FIOt2zla5c2fPaShxvlq92xdOjCeAUGvAQ==" + subscribeKey
// export const GET_DEPARTMENT_NBA_FOR_HEAD_URL = URL + "departmentNonBoundryActivityForHead?code=6dAbmSprOVcWQEQancGuxtUvFpOt5wLGzEBunvoj2IhPvKrDgDebww==" + subscribeKey
// export const ACTIVATE_NON_TIME_BOUND_ACTIVITY_URL = URL + "ActivatedNonBoundryActivity?code=MYqRM16ckzUTUY0dubNNiYCmHz1XeSatIoSmFgfqGHS8yDktsKceLQ==" + subscribeKey
// export const DEACTIVATED_NON_TIME_BOUND_ACTIVITY_URL = URL + "DeactivatedNonBoundryActivity?code=7jPO5ERbPIZmjflUCGfztWqLI6aMKxnpM7ysfni0XM2PnmfOCDHXGw==" + subscribeKey

// //Team
// export const GET_TEAMS = URL + "getTeams?code=pruN72p980i7X40Ov9ajLIyyohkuNoBOtutMtNeIxg2ujnw3r/8jtg==" + subscribeKey
// export const ADD_TEAM = URL + "addTeam?code=7tqJIHaaa71O8PPzuZyLOl73aXJgVq55selWjMx7JsXu7Vc1qXy8Pw==" + subscribeKey
// export const ADD_TEAM_MEMBER = URL + "addTeamMember?code=8OKqYcClCNf5JY6atoaTBleOqfmebI1VGRIkQV3YjAOLYG0iOx8F5A==" + subscribeKey
// export const GET_TEAM_MEMBERS = URL + "getTeamMembers?code=qYHvU71467rLByjrHjHiAedsiKOoP3AMDSxDTvsRqFicZH8bVhMOig==" + subscribeKey
// export const GET_ALL_TEAMS_URL = URL + "getAllTeams?code=e0uvaHYqzNgu0aaADuhQMLOW1bHCAJF9cgC2ivmrb9CDaG/DR5ppJA==" + subscribeKey
// export const GET_ALL_TEAM_MEMBERS_URL = URL + "getAllTeamMembers?code=UrTS9sFYzOfIbvDVG1OPLGMc2It8hxTpVHZ3T92yLvMffbaz3QM2MQ==" + subscribeKey
// export const ACTIVATE_TEAM_URL = URL + "activateTeam?code=BAnYKzD45j7L4PxfSaX6J0PbvuMnqZbR1JDpm5OfgWUINwUVYP6zIg==" + subscribeKey
// export const DEACTIVATE_TEAM_URL = URL + "deactivateTeam?code=vSabfFPafvFJVxQO/gBLxH2MFZB5Rg7bStRXmAz9vUf02ABG3G437A==" + subscribeKey

// //Role
// export const IS_ADMIN_URL = URL + "isAdmin?code=Yq2WSeitgQiiWhCh6WEIsF5LpHbVbH9rUm/SY6o913YYetHNRsaTyQ==" + subscribeKey
// export const IS_TEAM_LEAD = URL + "isTeamLead?code=6YIa8rYDQ/WPI/OZuc12rHP/d5xndaG66gbXlLGqaja2rq/pOj0FiQ==" + subscribeKey
// export const IS_PROJECT_MANAGER_ULR = URL + "isProjectManager?code=qnatXVbvGdR2TcbavsdZLvLpS5ZxBPGpha/mVzZ9bUFcv9F8gL0XRg==" + subscribeKey
// export const IS_DEPARTMENT_HEAD_URL = URL + "isDepartmentHead?code=4ZMf6GdQgbIk3pJN/L7SISKlXF5njGuCJUtAHEMr932jizHV6ja7fw==" + subscribeKey

// //Report
// export const REPORT_URL = URL + "Report?code=CtPhuA16idmVKRRahV2yhNg/LoJz8CgPOxqflRmYCsWA5yiJa8KYMw==" + subscribeKey
// export const TEAMLEAD_REPORT_URL = URL + "TeamLeadReport?code=T7czMD0wJSy2Tf2eNz3fK/70anTQEC6CB9oAGmWUzg9fqftTSA3Iwg==" + subscribeKey
// export const GET_ALL_PROJECT = URL + "GetAllProjects?code=eDYtxNV/Ggo5Zkf2/n9Xp28MzCuJVFhLN0/sunrKSgBM6iZmEB2GNw==" + subscribeKey

// //Support
// export const GET_SUPPORT_ALL_URL = URL + "GetSupports?code=X0A38alvmZb4BwacxDOMwMrTYcOX9KJ0aZhyVbzFavs5hVg8XKuS8w==" + subscribeKey
// export const GET_SUPPORT_URL = URL + "GetSupport?code=tFOnkhZZELZOjoFfH9NC/0YZhmncPs2QAAIDNWsos1t30D06a1EMZg==" + subscribeKey

// //Support
// export const GET_ALL_SUPPORTS = URL + "GetSupportsForDropDown?code=MPXsHJt96RIcuvqBUSW2zLGpkWBNE3FVjzuS24VgTQCYMOw1aWaQPA==" + subscribeKey
// export const ASSIGN_USERS_TO_SUPPORT = URL + "AddSupportUser?code=vyRFZ6ZKnAQlF2ESES8daJZ9/SPmabK85ie29Gpa6EwBxI6vo20MoQ==" + subscribeKey
// export const ADD_SUPPORT = URL + "AddSupport?code=lhiwyNCk2Gt1Ch/DCdDaaW2m2WlJPW/DTOj0diWRU4sAatJmH1q6Yg==" + subscribeKey


///HNB
// const URL = "https://protime.azure-api.net/HNBProtime/"
// const subscribeKey = "&subscription-key=883ae32720854828a736be7fbcb370e2"

// export const ALL_PROJECTS_URL = URL + "GetProjects?code=VEJYzzVbs9KziEZ9DQT/S5643jesDEgf4zlLJtJhJmZQLlv0iUAoSQ==" + subscribeKey
// export const ADD_PROJECTS_URL = URL + "AddProjects?code=QUaMowdUjaapZ2oNjsxYc3z71FRfaIfY7QSGoqmzru2CnonfF97lgg==" + subscribeKey
// export const ASSIGN_USERS_URL = URL + "usersAssign?code=z3x1debkhvqcIyzePg7aIt1pOsyfkNnWwNBeGLsAAcG65db3b9VFmQ==" + subscribeKey
// export const ACTIVE_PROJECTS_URL = URL + "GetActiveProjects?code=WsW4Pa76OqcxNyAHwog5ETtGXyICq0td0RODi/Pq39yFRw6ZkxaR8g==" + subscribeKey
// export const COMPLETED_PROJECT_URL = URL + "GetCompletedProjects?code=u3cago5jDmupl5at1zEQdJJyfQdJVLcw0nc4tsFC7JNRi0IhJbcg3Q==" + subscribeKey
// export const TODAY_PROJECT_URL = URL + "GetTodayProjects?code=lqeQSY3LQMtPzAKh8T8/x3NluL1d0qWe9ZvHFEPPLi474OxCvecNRA==" + subscribeKey
// export const ADD_TIME_URL = URL + "AddTime?code=0m3g3iLqKCAB6jPTz5OmHitqX4r5A6AU9ofyUv/VDyZJWTIfVnPGMA==" + subscribeKey
// export const GET_PROJECT_URL = URL + "GetProject?code=A9RQLsuS4/IFzJX68QCHR42BVtzaElvpYKESdcw/dMihz4dPnpO7tQ==" + subscribeKey
// export const REDUCE_TIME_URL = URL + "ReduceTime?code=stM/NhQ4NX0ytaFUzpihGvzs05KTcziotMt/wpN7WettYBzq2MxPDQ==" + subscribeKey
// export const UPDATE_PROJECT_URL = URL + "UpdateProject?code=y3pNJrmgaoTR5yLlMMA5oiPRJAk9RP7A3mdPRLir/oESKl5G9sbc/g==" + subscribeKey
// export const USER_BASE_PROJECTS = URL + "UserBaseProjects?code=t1SyJCf1CMBA4u9PvuS2UFqFxjCqoGrLTMlkkIraMtylpywo8wM4FQ==" + subscribeKey
// export const PROJECT_STATUS_URL = URL + "ProjectStatus?code=87/5lmIpTBwK7WP8Q2819iJYUTWvpq0X5FsDVODe2eFibcmImtHylg==" + subscribeKey
// export const COMPLETE_PROJECT_URL = URL + "CompleteProject?code=eOjlMN/Wwt5IIiVBdKk0iLZLd1tSE0xV6HPvpduEhAPqNo/XCUA64A==" + subscribeKey
// export const ACTIVE_PROJECT_URL = URL + "ActiveProject?code=0uoCXYVQFLZ0x3oMXUAV7i2qb9lVcG8Xeu6EmR/uZYOL/R1VHMNE8A==" + subscribeKey
// export const DELETE_ASSIGN_USER_URL = URL + "deleteAssignUsers?code=ui3rqj2EtgazE8crAt8c01MvTA7XVPxNatoVYSfrznA3fgdlqMq8YA==" + subscribeKey
// export const GET_ASSIGN_USERS_URL = URL + "getAssignUsers?code=ht/vdAnmMODdR4T235jaqaUejqEgtdgZ/Ea/Getrcuxk0Ntc8sTYHA==" + subscribeKey
// export const UPDATE_ASSIGN_USER_URL = URL + "editAssignUsers?code=5A9MsfPYAQJz3UjpWJFCyv9TaDBeaa2eRvHfH5v8zE0PyQsvABCohA==" + subscribeKey
// export const GET_PROJECT_ASSIGN_USERS_URL = URL + "GetProjectAssignUsers?code=CyWzeszyps4bHtN1qzDrJDkUapuUVPHuZRGq4b2JragLqGeKYMyn9A==" + subscribeKey
// export const GET_PROJECT_PARTICULAR_USER_URL = URL + "GetUserAssignProjects?code=J3Q1hfXVIdC7j7359Na4mJqIbsfwg/O/Tgig9CJYfBUtxadguOXmzQ==" + subscribeKey
//Task
// export const GET_TASKS_URL = URL + "UserBaseProjects?code=t1SyJCf1CMBA4u9PvuS2UFqFxjCqoGrLTMlkkIraMtylpywo8wM4FQ==" + subscribeKey;
// export const TASK_UPDATE_TIME_URL = URL + "AddTime?code=0m3g3iLqKCAB6jPTz5OmHitqX4r5A6AU9ofyUv/VDyZJWTIfVnPGMA==" + subscribeKey;
// export const TASK_REDUCE_TIME_URL = URL + "ReduceTime?code=stM/NhQ4NX0ytaFUzpihGvzs05KTcziotMt/wpN7WettYBzq2MxPDQ==" + subscribeKey;
// export const GET_DEFAULT_PROJECT_URL = URL + "GetDefaultProjects?code=fS0gERCVNOaRO8vvBU7a50SPygFeyL9lNzA5EfUVykG74dqEF9xSRQ==" + subscribeKey;
// export const ADD_EVENT_URL = URL + "AddTimeDefaultProjects?code=bkKLC33kzPs8qooybdcwM9stZGSOvWheu0iN5f0m8xOzFhdeIqKN9w==" + subscribeKey;
// export const REDUCE_EVENT_URL = URL + "ReduceTimeDefaultProjects?code=s39B8MxchyfXxZUjbwvY8g0GUvdsBAabmFHaLP1y1PlLaEhA7WaC8g==" + subscribeKey;

// //Project
// export const GET_FOLLOWERS_TIME_USING_DATE_RANGE_URL = URL + "getFollowersTimeUsingDateRange?code=xBOmEzZbcpC10aLYC7VI2eZObmaSLKXUCYLe9EGr1NMkyVGdCSmUuw==" + subscribeKey

// MIT
const URL = "https://protime.azurewebsites.net/api/"
const subscribeKey = "&subscription-key=614202fbdd424f7d895edd5eafa6b046"
const masterHostKeyCode = "8FsP1y2e029O-3PaNntcOhu8CV2RUJ6wOQV6xlu6bk0VAzFu9sIP3Q%3D%3D"

export const ALL_PROJECTS_URL = URL + "GetProjects?code=" + masterHostKeyCode + subscribeKey
export const ADD_PROJECTS_URL = URL + "AddProjects?code=" + masterHostKeyCode + subscribeKey
export const ASSIGN_USERS_URL = URL + "usersAssign?code=" + masterHostKeyCode + subscribeKey
export const ASSIGN_SINGLE_USER_URL = URL + "singleUserAssign?code=" + masterHostKeyCode + subscribeKey
export const SEARCH_PROJECTS = URL + "SearchProjects?code=" +masterHostKeyCode + subscribeKey
export const ACTIVE_PROJECTS_URL = URL + "GetActiveProjects?code=" + masterHostKeyCode + subscribeKey
export const COMPLETED_PROJECT_URL = URL + "GetCompletedProjects?code=" + masterHostKeyCode + subscribeKey
export const TODAY_PROJECT_URL = URL + "GetTodayProjects?code=" + masterHostKeyCode + subscribeKey
export const ADD_TIME_URL = URL + "AddTime?code=" + masterHostKeyCode + subscribeKey
export const GET_PROJECT_URL = URL + "GetProject?code=" + masterHostKeyCode + subscribeKey
export const REDUCE_TIME_URL = URL + "ReduceTime?code=" + masterHostKeyCode + subscribeKey
export const UPDATE_PROJECT_URL = URL + "UpdateProject?code=" + masterHostKeyCode + subscribeKey
export const USER_BASE_PROJECTS = URL + "UserBaseProjects?code=" + masterHostKeyCode + subscribeKey
export const PROJECT_STATUS_URL = URL + "ProjectStatus?code=" + masterHostKeyCode + subscribeKey
export const COMPLETE_PROJECT_URL = URL + "CompleteProject?code=" + masterHostKeyCode + subscribeKey
export const ACTIVE_PROJECT_URL = URL + "ActiveProject?code=" + masterHostKeyCode + subscribeKey
export const DELETE_ASSIGN_USER_URL = URL + "deleteAssignUsers?code=" + masterHostKeyCode + subscribeKey
export const GET_ASSIGN_USERS_URL = URL + "getAssignUsers?code=" + masterHostKeyCode + subscribeKey
export const UPDATE_ASSIGN_USER_URL = URL + "editAssignUsers?code=" + masterHostKeyCode + subscribeKey
export const GET_PROJECT_ASSIGN_USERS_URL = URL + "GetProjectAssignUsers?code=" + masterHostKeyCode + subscribeKey
export const GET_PROJECT_PARTICULAR_USER_URL = URL + "GetUserAssignProjects?code=" + masterHostKeyCode + subscribeKey
export const UPDATE_USER_DETAILS_URL = URL + "updateUserDetails?code=" + masterHostKeyCode + subscribeKey

//Task
export const GET_TASKS_URL = URL + "UserBaseProjects?code=" + masterHostKeyCode + subscribeKey;
export const TASK_UPDATE_TIME_URL = URL + "AddTime?code=" + masterHostKeyCode + subscribeKey;
export const TASK_REDUCE_TIME_URL = URL + "ReduceTime?code=" + masterHostKeyCode + subscribeKey;
export const GET_DEFAULT_PROJECT_URL = URL + "GetDefaultProjects?code=" + masterHostKeyCode + subscribeKey;
export const ADD_EVENT_URL = URL + "AddTimeDefaultProjects?code=" + masterHostKeyCode + subscribeKey;
export const REDUCE_EVENT_URL = URL + "ReduceTimeDefaultProjects?code=" + masterHostKeyCode + subscribeKey;


//Project
export const GET_FOLLOWERS_TIME_USING_DATE_RANGE_URL = URL + "getFollowersTimeUsingDateRange?code=" + masterHostKeyCode + subscribeKey

//Role
export const GET_PROJECT_MANAGERS_URL = URL + "getProjectManagers?code=" + masterHostKeyCode + subscribeKey
export const ADD_PROJECT_MANAGERS_URL = URL + "AddProjectManager?code=" + masterHostKeyCode + subscribeKey
export const GET_ROLE_URL = URL + "GetRole?code=" + masterHostKeyCode + subscribeKey
export const GET_DEPARTMENT_HEAD_URL = URL + "GetDepartmentHead?code=" + masterHostKeyCode + subscribeKey
export const ADD_DEPARTMENT_HEAD_URL = URL + "AddDepartmentHead?code=" + masterHostKeyCode + subscribeKey
export const GET_TEAM_LEADS_URL = URL + "GetTeamLeads?code=" + masterHostKeyCode + subscribeKey
export const ADD_TEAM_LEAD_URL = URL + "AddTeamLead?code=" + masterHostKeyCode + subscribeKey
export const DELETE_TEAM_LEAD_URL = URL + "DeleteTeamLeadUser?code=" + masterHostKeyCode + subscribeKey
export const GET_ADMINS_URL = URL + "GetAdmin?code=" + masterHostKeyCode + subscribeKey
export const ADD_ADMIN_URL = URL + "AddAdmin?code=" + masterHostKeyCode + subscribeKey
export const DELETE_ADMIN_URL = URL + "DeleteAdminUser?code=" + masterHostKeyCode + subscribeKey
export const DELETE_DEPARTMENT_HEAD_URL = URL + "DeleteDepartmentHeadUser?code=" + masterHostKeyCode + subscribeKey
export const DELETE_PROJECT_MANAGER_URL = URL + "DeleteProjectManagerUser?code=" + masterHostKeyCode + subscribeKey

//NonBoundayActivity
export const GET_ORGANIZATION_NBA_URL = URL + "organizationNonBoundryActivity?code=" + masterHostKeyCode + subscribeKey
export const ADD_ORGANIZATION_NBA_URL = URL + "addOrganizationNonBoundryActivity?code=" + masterHostKeyCode + subscribeKey
export const GET_DEPARTMENT_NBA_URL = URL + "departmentNonBoundryActivity?code=" + masterHostKeyCode + subscribeKey
export const ADD_DEPARTMENT_NBA_URL = URL + "addDepartmentNonBoundryActivity?code=" + masterHostKeyCode + subscribeKey
export const GET_TEAM_NBA_URL = URL + "teamsNonBoundaryActivity?code=" + masterHostKeyCode + subscribeKey
export const GET_ALL_TEAM_NBA_URL = URL + "teamsNonBoundaryAllActivity?code=" + masterHostKeyCode + subscribeKey
export const ADD_TEAM_NBA_URL = URL + "addTeamsNonBoundaryActivity?code=" + masterHostKeyCode + subscribeKey
export const GET_DEPARTMENT_NBA_FOR_HEAD_URL = URL + "departmentNonBoundryActivityForHead?code=" + masterHostKeyCode + subscribeKey
export const ACTIVATE_NON_TIME_BOUND_ACTIVITY_URL = URL + "ActivatedNonBoundryActivity?code=" + masterHostKeyCode + subscribeKey
export const DEACTIVATED_NON_TIME_BOUND_ACTIVITY_URL = URL + "DeactivatedNonBoundryActivity?code=" + masterHostKeyCode + subscribeKey

//Team
export const GET_TEAMS = URL + "getTeams?code=" + masterHostKeyCode + subscribeKey
export const ADD_TEAM = URL + "addTeam?code=" + masterHostKeyCode + subscribeKey
export const ADD_TEAM_MEMBER = URL + "addTeamMember?code=" + masterHostKeyCode + subscribeKey
export const GET_TEAM_MEMBERS = URL + "getTeamMembers?code=" + masterHostKeyCode + subscribeKey
export const GET_ALL_TEAMS_URL = URL + "getAllTeams?code=" + masterHostKeyCode + subscribeKey
export const GET_ALL_TEAM_MEMBERS_URL = URL + "getAllTeamMembers?code=" + masterHostKeyCode + subscribeKey
export const ACTIVATE_TEAM_URL = URL + "activateTeam?code=" + masterHostKeyCode + subscribeKey
export const DEACTIVATE_TEAM_URL = URL + "deactivateTeam?code=" + masterHostKeyCode + subscribeKey

//Role
export const IS_ADMIN_URL = URL + "isAdmin?code=" + masterHostKeyCode + subscribeKey
export const IS_TEAM_LEAD = URL + "isTeamLead?code=" + masterHostKeyCode + subscribeKey
export const IS_PROJECT_MANAGER_ULR = URL + "isProjectManager?code=" + masterHostKeyCode + subscribeKey
export const IS_DEPARTMENT_HEAD_URL = URL + "isDepartmentHead?code=" + masterHostKeyCode + subscribeKey

//Report
export const REPORT_URL = URL + "Report?code=" + masterHostKeyCode + subscribeKey
export const TEAMLEAD_REPORT_URL = URL + "TeamLeadReport?code=" + masterHostKeyCode + subscribeKey
export const GET_ALL_PROJECT = URL + "GetAllProjects?code=" + masterHostKeyCode + subscribeKey

//Support
export const GET_SUPPORT_ALL_URL = URL + "GetSupports?code=" + masterHostKeyCode + subscribeKey
export const GET_SUPPORT_URL = URL + "GetSupport?code=" + masterHostKeyCode + subscribeKey

//Support
export const GET_ALL_SUPPORTS = URL + "GetSupportsForDropDown?code=" + masterHostKeyCode + subscribeKey
export const ASSIGN_USERS_TO_SUPPORT = URL + "AddSupportUser?code=" + masterHostKeyCode + subscribeKey
export const ADD_SUPPORT = URL + "AddSupport?code=" + masterHostKeyCode + subscribeKey

//Sales Stage Uploads
export const ADD_SALES_STAGE = URL + "updateProjectSalesStageByStageName?code=" + masterHostKeyCode + subscribeKey

export const ACTIVE_PROJECTS = 1

export const TASK_CARD_STATUS = 3



