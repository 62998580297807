import React, { Component } from 'react';
import "./projectCard.scss"
import { Link } from "react-router-dom"
import {ACTIVE_PROJECTS} from '../../constant';

class ProjectCard extends Component {
    render() {
        const totalUserAssignTime = this.props.project.users.reduce((sum, user) => sum + (user.userAssignTime || 0), 0);
        const totalUnallocatedHours = (this.props.project.expectedHours - totalUserAssignTime)
        return (
            <div className="project_card">
                <div className="project_card_image_tab">
                    {this.props.project.status === ACTIVE_PROJECTS &&
                        <Link to={`/EditProject/${this.props.project.projectId}`} style={{ border: "none", appearance: "none", textDecoration: "unset" }}>
                            <div className="project_card_image_tab_edit">
                                <icon className="fa fa-edit" />
                            </div>
                        </Link>
                    }
                    <img alt="" src={require("./projectCard.png")} className="project_card_image" />
                </div>
                <div className="project_card_log_tab">
                    <div className="project_card_log">
                        <img alt="" src={(this.props.project.image !== "https://protimestorage.blob.core.windows.net/protimer/" ? this.props.project.image : require("../../assets/images/temp_logo.png"))} className="project_image" />
                    </div>
                    <div
                        onClick={() => {
                            if (!this.props.project.isActive) {
                                var confirmation = window.confirm("Are you sure to Activate Project '" + this.props.project.projectName + "'")
                                if (confirmation) {
                                    this.props.stateChange(this.props.project.projectId, 1)
                                }
                            }
                        }
                        }
                        className={(this.props.project.isActive ? "project_card_status_active" : "project_card_status_inactive")}>
                        <span className="project_card_status_word">Active</span>
                    </div>
                    <div
                        onClick={() => {
                            if (this.props.project.isActive) {
                                var confirmation = window.confirm("Are you sure to Complete Project '" + this.props.project.projectName + "'")
                                if (confirmation) {
                                    this.props.stateChange(this.props.project.projectId, 0)
                                }
                            }
                        }
                        }
                        className={(this.props.project.isActive ? "project_card_status_inactive" : "project_card_status_completed")}>
                        <span className="project_card_status_word">Completed</span>
                    </div>
                </div>
                <div className="project_card_header_tab">
                    <h5 className="project_card_header_header">{this.props.project.projectCode}</h5>
                    <h6 className="project_card_header_header">{this.props.project.projectName}</h6>
                    <h7 className="project_card_header_decription">{this.props.project.message}</h7>
                    <br/>
                    <h7 className="project_card_values_value">Unallocated Hours : {totalUnallocatedHours}</h7>
                    <br/>
                    <h7 className="project_card_values_value">Sales Stage : {this.props.project.salesStageName !== "" ? this.props.project.salesStageName : " -"}</h7>
                </div>
                <div className="row">
                    <div className="col-md-6 project_card_values_content">
                        <h8 className="project_card_values_header">Current Hours </h8>
                        <h8 className="project_card_values_value">{this.props.project.currentHours}</h8>
                    </div>
                    <div className="col-md-6 project_card_values_content">
                        <h8 className="project_card_values_header">Expected Hours </h8>
                        <h8 className="project_card_values_value">{this.props.project.expectedHours}</h8>
                    </div>
                    <div className="col-md-6 project_card_values_content">
                        <h8 className="project_card_values_header">Start Date </h8>
                        <h8 className="project_card_values_value">{new Date(this.props.project.startTime).getDate() + "/" + (new Date(this.props.project.startTime).getMonth() + 1) + "/" + new Date(this.props.project.startTime).getFullYear()}</h8>
                    </div>
                    <div className="col-md-6 project_card_values_content">
                        <h8 className="project_card_values_header">End Date </h8>
                        <h8 className="project_card_values_value">{new Date(this.props.project.endDate).getDate() + "/" + (new Date(this.props.project.endDate).getMonth() + 1) + "/" + new Date(this.props.project.endDate).getFullYear()}</h8>
                    </div>
                    <div className="col-md-6 project_card_values_content" style={{ padding: 0 }}>
                        <div className="btn btn_project_card" onClick={() => this.props.setUserModalView()}>
                            View Users
                        </div>
                    </div>
                    <div className="col-md-6 project_card_values_content" style={{ padding: 0 }}>
                    <Link to={`/HistoryProject/${this.props.project.projectId}/${this.props.project.projectName.split(' | ')[0]}/${this.props.index}`}style={{ border: "none", appearance: "none", textDecoration: "unset" }}>
                            <div className="btn btn_project_card">
                                View Details
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectCard;