import { combineReducers, createStore } from 'redux'
import { reducer as formReducer } from 'redux-form';
import usersReducers from "./usersReducers"
import projectReducer from "./projectReducer"
import roleReducers from "./roleReducer"
import taskReducers from "./taskReducers"
import nonBoundaryReducers from "./nonBoundaryReducers"
import support from "./supportReducers"
import team from "./teamReducers"
import report from "./reportReducers"


const rootReducer = combineReducers({
    roleReducers,
    projectReducer,
    usersReducers,
    taskReducers,
    support,
    form: formReducer,
    nonBoundaryActivity: nonBoundaryReducers,
    team,
    report
})
export default rootReducer;
