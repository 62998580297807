import React, { useState, useEffect } from 'react';
import "../../../assets/css/report.scss"
import { generateReport, getAllProjectSearch, JSONToCSVConvertor } from "../../actions/reportActions"
import { getUsersDropdown, getUsersDropdownSearch } from "../../actions/usersActions"
import { getAllTeamsDropdown } from "../../actions/teamsActions"
import { connect } from 'react-redux';
import { Button, Select, Collapse, Tag, Spin, DatePicker } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import Table from '../Role/Table';
const { Option } = Select;
const { Panel } = Collapse;
const { RangePicker } = DatePicker;

const Index = ({ generateReport, report, team, getAllProjectSearch, getAllTeamsDropdown, getUsersDropdown, getUsersDropdownSearch, users }) => {
    const [type, setType] = useState("Date");
    const [generatedType, setGeneratedType] = useState("Date");
    const [generatedateRangeType, setGenerateDateRangeType] = useState(1);
    const [dateRangeType, setDateRangeType] = useState(1);
    const [dateFrom, setDateFrom] = useState('');
    const [dateTo, setDateTo] = useState('');
    const [column, setColumn] = useState([]);
    const [usersList, setUsersList] = useState([]);
    const [usersGeneratedList, setUsersGeneratedList] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [generatedProjectList, setGeneratedProjectList] = useState([])
    const [teamList, setTeamList] = useState([]);
    const [generatedTeamList, setGeneratedTeamList] = useState([])

    useEffect(() => {
        var dateNow = new Date();
        var changeDate = new Date();
        changeDate.setDate(changeDate.getDate() + 1);
        generateReport("Date", dateNow.toLocaleDateString(), changeDate.toLocaleDateString(), usersList, projectList, teamList);
        setDateFrom(dateNow.toLocaleDateString());
        setDateTo(changeDate.toLocaleDateString());
        getUsersDropdown();
        getAllProjectSearch('');
        getAllTeamsDropdown();
    }, [])

    useEffect(() => {
        var columns = [];
        if (generatedType == "Project") {
            columns = [
                {
                    Header: 'Project Name',
                    accessor: 'projectName'
                },
                {
                    Header: 'Project code',
                    accessor: 'projectCode'
                }
            ]
        } else if (generatedType == "User") {
            columns = [
                {
                    Header: 'Project Name',
                    accessor: 'projectName'
                },
                {
                    Header: 'Project code',
                    accessor: 'projectCode'
                },
                {
                    Header: 'User Name',
                    accessor: 'userName'
                },
                {
                    Header: 'User Email',
                    accessor: 'userEmail'
                },
                {
                    Header: 'Time',
                    accessor: 'time',
                    Cell: cellInfor => {
                        return <p>{parseInt(cellInfor.value)}H {(parseFloat(cellInfor.value) % 1) * 60}min</p>
                    }
                }
            ]
        } else if (generatedType == "Date") {
            columns = [
                {
                    Header: 'Project Name',
                    accessor: 'projectName'
                },
                {
                    Header: 'Project code',
                    accessor: 'projectCode'
                },
                {
                    Header: 'User Name',
                    accessor: 'userName'
                },
                {
                    Header: 'User Email',
                    accessor: 'userEmail'
                },
                {
                    Header: 'Date',
                    accessor: 'date',
                    Cell: cellInfor => {
                        var date = new Date(cellInfor.value);
                        return <p>{date.toDateString()}</p>
                    }
                },
                {
                    Header: 'Time',
                    accessor: 'time',
                    Cell: cellInfor => {
                        return <p>{parseInt(cellInfor.value)}H {(parseFloat(cellInfor.value) % 1) * 60}min</p>
                    }
                }
            ]
            setTeamList([]);
        } else if (generatedType == "Team") {
            columns = [
                {
                    Header: 'Project Name',
                    accessor: 'projectName'
                },
                {
                    Header: 'Project code',
                    accessor: 'projectCode'
                },
                {
                    Header: 'User Name',
                    accessor: 'userName'
                },
                {
                    Header: 'User Email',
                    accessor: 'userEmail'
                },
                {
                    Header: 'Team Name',
                    accessor: 'teamName'
                },
                {
                    Header: 'Date',
                    accessor: 'date',
                    Cell: cellInfor => {
                        var date = new Date(cellInfor.value);
                        return <p>{date.toDateString()}</p>
                    }
                },
                {
                    Header: 'Time',
                    accessor: 'time',
                    Cell: cellInfor => {
                        return <p>{parseInt(cellInfor.value)}H {(parseFloat(cellInfor.value) % 1) * 60}min</p>
                    }
                }
            ]
        }

        setColumn(columns);

    }, [generatedType])

    const changeDate = (value) => {
        var dateNow = new Date();
        dateNow.setDate(dateNow.getDate() + 1);
        if (value == 1) {
            var changeDate = new Date();
            setDateFrom(changeDate.toLocaleDateString());
            setDateTo(dateNow.toLocaleDateString());
        } else if (value == 2) {
            var changeDate = new Date();
            changeDate.setDate(changeDate.getDate() - 7);
            var toDate = new Date();
            toDate.setDate(toDate.getDate() + 1)
            setDateFrom(changeDate.toLocaleDateString());
            setDateTo(toDate.toLocaleDateString());
        } else if (value == 3) {
            var changeDate = new Date();
            changeDate.setDate(changeDate.getDate() - changeDate.getDate());
            setDateFrom(changeDate.toLocaleDateString());
            setDateTo(dateNow.toLocaleDateString());
        } else if (value == 4) {
            var changeDate = new Date();
            changeDate.setDate(changeDate.getDate() - changeDate.getDate() + 1);
            changeDate.setMonth(changeDate.getMonth() - 1);
            var changeDateTo = new Date();
            changeDateTo.setDate(changeDateTo.getDate() - changeDateTo.getDate() + 1);
            setDateFrom(changeDate.toLocaleDateString());
            setDateTo(changeDateTo.toLocaleDateString());
        } else if (value == 5) {
            setDateFrom("");
            setDateTo("");
        }
    }

    return (
        <div className="rm">
            <div className="rm-menu">
                <div className="rm-title">
                    <h6>REPORT BUILDER</h6>
                    <Collapse defaultActiveKey={[]} >
                        <Panel className="rm-collapsePanel" header="Advance Filtering" key="1">
                            <div className="row" >
                                <div className="col-md-1">
                                    Projects
                                    </div>
                                <div className="col-md-5">
                                    <Select
                                        mode="multiple"
                                        labelInValue
                                        value={projectList}
                                        placeholder="Select projects"
                                        notFoundContent={report.projectSearchLoading ? <Spin size="small" /> : null}
                                        filterOption={false}
                                        onSearch={(value) => { getAllProjectSearch(value) }}
                                        onChange={(valueList) => { setProjectList(valueList) }}
                                        style={{ width: '100%' }}
                                    >
                                        {report && report.projectSearchSuccess && report.projectSearchSuccess.map(d => (
                                            <Option key={d.projectId}>{d.projectName}</Option>
                                        ))}
                                    </Select>
                                </div>
                                {type != "Project" && <>
                                    <div className="col-md-1">
                                        Users
                                    </div>
                                    <div className="col-md-5">
                                        <Select
                                            mode="multiple"
                                            labelInValue
                                            value={usersList}
                                            placeholder="Select users"
                                            notFoundContent={users.allUserLoading ? <Spin size="small" /> : null}
                                            filterOption={false}
                                            onSearch={(value) => { getUsersDropdownSearch(value) }}
                                            onChange={(valueList) => { setUsersList(valueList) }}
                                            style={{ width: '100%' }}
                                        >
                                            {users && users.allUsers && users.allUsers.map(d => (
                                                <Option key={d.value}>{d.label}</Option>
                                            ))}
                                        </Select>
                                    </div>
                                </>
                                }

                                {type == "Team" && <>
                                    <div className="col-md-1">
                                        Teams
                                    </div>
                                    <div className="col-md-5">
                                        <Select
                                            mode="multiple"
                                            labelInValue
                                            value={teamList}
                                            placeholder="Select team"
                                            notFoundContent={users.getTeamsLoading ? <Spin size="small" /> : null}
                                            filterOption={false}
                                            onSearch={(value) => { }}
                                            onChange={(valueList) => { setTeamList(valueList) }}
                                            style={{ width: '100%' }}
                                        >
                                            {team && team.getTeamsSuccess && team.getTeamsSuccess.map(d => (
                                                <Option key={d.teamid}>{d.teamName}</Option>
                                            ))}
                                        </Select>
                                    </div>
                                </>
                                }
                            </div>
                        </Panel>
                    </Collapse>
                    <div className="row">
                        <div className="col-md-2">
                            <p>Report type</p>
                        </div>
                        <div className="col-md-3">
                            <Select className="rm-input" value={type} onChange={(value) => {
                                setType(value);
                            }}>
                                <Option value={"Project"}><span className="rm-option">Project</span></Option>
                                <Option value={"User"}><span className="rm-option">User</span></Option>
                                <Option value={"Date"}><span className="rm-option">Date</span></Option>
                                <Option value={"Team"}><span className="rm-option">Team</span></Option>
                            </Select>
                        </div>
                        {type == "Date" ? <>
                            <div className="col-md-1">
                                <p className="rm-option">Date Range</p>
                            </div>
                            <div className="col-md-4">
                                <Select className="rm-input" defaultValue={1} onChange={(value) => {
                                    changeDate(value);
                                    setDateRangeType(value);
                                }}>
                                    <Option value={1}><span className="rm-option">Today</span></Option>
                                    <Option value={2}><span className="rm-option">Last 7 days</span></Option>
                                    <Option value={3}><span className="rm-option">This month</span></Option>
                                    <Option value={4}><span className="rm-option">Last month</span></Option>
                                    <Option value={5}><span className="rm-option">Life time</span></Option>
                                    <Option value={6}><span className="rm-option">Custom range</span></Option>
                                </Select>
                                {dateRangeType == 6 && <RangePicker
                                    onChange={(value) => {
                                        setDateFrom(new Date(value[0]).toLocaleDateString());
                                        setDateTo(new Date(value[1]).toLocaleDateString());
                                    }}
                                />}
                            </div>
                        </>
                            :
                            <div className="col-md-5">
                            </div>
                        }
                        <div className="col-md-1">
                            <Button danger type="primary"
                                onClick={() => {
                                    generateReport(type, dateFrom, dateTo, usersList, projectList, teamList);
                                    setGenerateDateRangeType(dateRangeType);
                                    setGeneratedType(type);
                                    setUsersGeneratedList(usersList);
                                    setGeneratedProjectList(projectList);
                                    setGeneratedTeamList(teamList);
                                }}>
                                <span className="rm-option">Generate</span>
                            </Button>
                        </div>
                    </div>

                    <div>
                        <div>
                            <p style={{ fontWeight: "600" }}>Filter By</p>
                            <Tag color="#fc4103">{generatedType}</Tag>
                            {generatedType == "Date" &&
                                <Tag color="#fc4103">{generatedateRangeType == 1 ? "Today" : generatedateRangeType == 2 ? "Last 7 days" : generatedateRangeType == 3 ? "This month" : generatedateRangeType == 4 ? "Last month" : generatedateRangeType == 5 ? "Life time" : dateFrom + " - " + dateTo}</Tag>
                            }
                        </div>
                        <div className="row">
                            {(usersGeneratedList.length != 0 && generatedType != "Project") ?
                                <div className="col-md-4">
                                    <p >Users</p>
                                    {usersGeneratedList.map((user) => {
                                        return <Tag color="#fc8403">{user.label.split("...")[0]}</Tag>
                                    })
                                    }
                                </div> :
                                <div className="col-md-4">
                                </div>
                            }
                            {generatedProjectList.length != 0 ?
                                <div className="col-md-4">
                                    <p >Projects</p>
                                    {generatedProjectList.map((project) => {
                                        return <Tag color="#7703fc">{project.label}</Tag>
                                    })
                                    }
                                </div>
                                :

                                <div className="col-md-4">
                                </div>
                            }

                            {generatedTeamList.length != 0 ?
                                <div className="col-md-3">
                                    <p >Teams</p>
                                    {generatedTeamList.map((team) => {
                                        return <Tag color="#009440">{team.label}</Tag>
                                    })
                                    }
                                </div> :
                                <div className="col-md-3">
                                </div>
                            }

                            <div className="col-md-1">
                                <Button type="primary" onClick={() => {
                                    JSONToCSVConvertor(report.generateReportSuccess, "Report-Protime", column)
                                }}>
                                    <DownloadOutlined style={{ fontSize: 17, cursor: "pointer" }} />
                                </Button>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rm-reportData">
                {report.generateReportSuccess && report.generateReportSuccess.length != 0 ?
                    <Table
                        columns={column}
                        data={report.generateReportSuccess}
                    />
                    :
                    <div className="noData">
                        <p className="rm-noData">No Data Found</p>
                    </div>
                }
            </div>
        </div>
    );
};

const mapSateToProps = (state) => {
    return {
        report: state.report,
        users: state.usersReducers,
        team: state.team
    }
}

export default connect(mapSateToProps, { generateReport, getAllTeamsDropdown, getAllProjectSearch, getUsersDropdown, getUsersDropdownSearch })(Index);