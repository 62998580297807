import React, { useState, useEffect } from 'react';
import { Drawer, Typography, Input, Button, message, Select } from 'antd';
import {
    AppstoreAddOutlined
} from '@ant-design/icons';
import { Formik } from "formik"
import * as Yup from "yup"
import * as Actions from "../../actions/roleActions"
import { connect } from "react-redux"
import AuthContext from '../../../services/Auth'

const key = 'updatable';
const { Title, Text } = Typography;
const { Option } = Select;

const AddTeam = ({ addTeams, addNewTeam, getDepartmentFollowers, addTeamSuccess, getDepartment, visible, teamList, onClose }) => {
    const [formSubmitted, setFormSubmitted] = useState(null)
    const addPMValidationSchema = Yup.object({
        teamName: Yup.string()
            .required('Please enter team name')
        ,
        department: Yup.string()
            .required('Please select department')

    });
    useEffect(() => {
        // getDepartmentFollowers('');
        getDepartment('');
        setFormSubmitted(addTeamSuccess);
    }, [])

    useEffect(() => {
        if (addTeamSuccess != formSubmitted) {
            message.success({ content: 'Successfully added team', key });
            setFormSubmitted(addTeamSuccess);
        }
    }, [addTeamSuccess])

    return (
        <>
            <Drawer
                title="Add Team"
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
            >
                <Formik
                    initialValues={{ teamName: '', department: '' }}
                    validationSchema={addPMValidationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        var ifTeamNameGetIndex = teamList.findIndex((teamDetails) => teamDetails.teamName.trim().toLowerCase() == values.teamName.trim().toLowerCase())
                        if (ifTeamNameGetIndex == -1) {
                            message.loading({ content: 'Loading...', key });
                            addNewTeam(values.teamName, values.department, AuthContext.getCachedUser().userName);
                            setSubmitting(false);
                            resetForm({ teamName: '', department: '' });
                            setFormSubmitted(true);
                        } else {
                            message.error({ content: "Team name already exists", duration: 2 })
                            setSubmitting(false);
                        }
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue,
                        setErrors
                    }) => (
                        <form >
                            <Text type="secondary">Team Name</Text>
                            <Input
                                name="teamName"
                                onChange={(value) => {


                                    setFieldValue("teamName", value.target.value);

                                }}
                                value={values.teamName}
                            />
                            <Text type="danger">{errors.teamName && touched.teamName && errors.teamName}</Text>

                            <br />
                            <Text type="secondary">Department</Text>
                            <Select showSearch={true} style={{ width: "100%" }}
                                name="department"
                                onChange={(value) => { setFieldValue("department", value) }}
                                onSearch={(value) => {
                                    if (value.length > 2 & value.length % 2 == 0) {
                                        getDepartmentFollowers(value);
                                    }
                                }}
                                // onBlur={handleBlur}
                                value={values.department}
                            >
                                {addTeams.getDepartmentsSuccess &&
                                    addTeams.getDepartmentsSuccess.map((item) => {
                                        const value = item.department
                                        return <Option value={value}>{value}</Option>
                                    })
                                }
                            </Select>
                            <Text type="danger">{errors.department && touched.department && errors.department}</Text>
                            <Button danger type="primary" onClick={handleSubmit} disabled={isSubmitting} style={{ marginTop: 10 }}>
                                <AppstoreAddOutlined /> Add Team
                            </Button>
                        </form>
                    )}
                </Formik>
            </Drawer>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        addTeams: state.roleReducers
    }
}
export default connect(mapStateToProps, Actions)(AddTeam)