
import React, { Component } from 'react';
import { connect } from "react-redux"
import Piechart from "../../containers/HomeCharts/Piechart"
import "../../assets/css/home.scss"
import * as Actions from "../actions/projectsActions"
import * as usersActions from "../actions/usersActions"
import AuthContext from '../../services/Auth'
import { bindActionCreators } from 'redux';
import { PieChart, Pie, Cell, Tooltip } from "recharts";
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import Loader from "../../containers/Loader/Loader"
import { message, Skeleton } from 'antd';
const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
    '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF',
    '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
    '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF',
    '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
    '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF',
    '#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#FF6633', '#FFB399', '#FF33FF', '#FFFF99', '#00B3E6',
    '#E6B333', '#3366E6', '#999966', '#99FF99', '#B34D4D',
    '#80B300', '#809900', '#E6B3B3', '#6680B3', '#66991A',
    '#FF99E6', '#CCFF1A', '#FF1A66', '#E6331A', '#33FFCC',
    '#66994D', '#B366CC', '#4D8000', '#B33300', '#CC80CC',
    '#66664D', '#991AFF', '#E666FF', '#4DB3FF', '#1AB399',
    '#E666B3', '#33991A', '#CC9999', '#B3B31A', '#00E680',
    '#4D8066', '#809980', '#E6FF80', '#1AFF33', '#999933',
    '#FF3380', '#CCCC00', '#66E64D', '#4D80CC', '#9900B3',
    '#E64D66', '#4DB380', '#FF4D4D', '#99E6E6', '#6666FF'];


const CustomTooltip = ({ active, payload, label }) => {
    if (active) {
        return (
            <div className="custom-tooltip">
                <p className="desc">Name : {payload[0].payload.userName}</p>
                <h6>Total Time: {payload[0].payload.totalTime}H</h6>
            </div>
        );
    }

    return null;
};

const CustomTooltipUser = ({ active, payload, label }) => {
    if (active) {
        return (
            <div className="custom-tooltip">
                <p className="desc">Name : {payload[0].payload.name}</p>
                <h6>Total Time: {payload[0].payload.userTime}H</h6>
            </div>
        );
    }

    return null;
};

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navSide: true,
            duration: "Daily",
            viewTasks: false,
            loader: true,
            datePickerView: false,
            datePicker: false
        }

    }

    static defaultProps = {
        numberOfMonths: 2,
    };

    handleDayClick = (day) => {
        const range = DateUtils.addDayToRange(day, this.state);
        this.setState(range);
    }

    componentDidMount() {
        this.props.projectsActions.getActiveProjects();
        this.props.projectsActions.getProjectsStatus();
        var startDate = new Date(Date.now());
        var endDate = new Date(Date.now());
        startDate.setDate(startDate.getDate());
        endDate.setDate(endDate.getDate());
        this.props.projectsActions.getFollowersTimeUsingDateRange(startDate.getFullYear() + "/" + (startDate.getMonth() + 1) + "/" + (startDate.getDate()), endDate.getFullYear() + "/" + (endDate.getMonth() + 1) + "/" + (endDate.getDate()));
        this.props.usersActions.getUsers();
        this.setState({
            selectedUser:
            {
                value: AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.aud,
                label: AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.name,
                mail: AuthContext.getCachedUser() && AuthContext.getCachedUser().userName,
                userPrincipalName: AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.unique_name
            }
        })
    }

    componentWillReceiveProps = (nextProps) => {
        if (nextProps.home.usersReducers && (nextProps.home.usersReducers.users == 0 || this.props.home.usersReducers.users != nextProps.home.usersReducers.users)) {
            this.setState({ loader: false });
        }
    }
    getMonday = (d) => {
        d = new Date(d);
        var day = d.getDay(),
            diff = d.getDate() - day + (day == 0 ? -6 : 1);
        return new Date(d.setDate(diff));
    }

    pageScroll = () => {
        window.scrollTo(0, document.body.scrollHeight);
    }

    JSONToCSVConvertor = (JSONData, ReportTitle, ShowLabel) => {
        //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
        var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
        var CSV = '';
        //Set Report title in first row or line

        CSV += ReportTitle + '\r\n\n';
        //This condition will generate the Label/Header
        if (ShowLabel) {
            var row = "";
            //This loop will extract the label from 1st index of on array
            for (var index in arrData[0]) {
                //Now convert each value to string and comma-seprated
                if (index == 'userTime') {
                } else if (index == 'userCurrentTime') {
                    // row += 'User Expected Time,';
                } else if (index == 'userName') {
                    row += 'User Name,';
                } else if (index == 'userEmail') {
                    row += 'User Email,';
                } else if (index == 'timeString') {
                    row += 'User Spent Time,';
                } else {
                    row += index + ',';
                }
            }
            row = row.slice(0, -1);
            //append Label row with line break
            CSV += row + '\r\n';
        }
        //1st loop is to extract each row
        for (var i = 0; i < arrData.length; i++) {
            var row = "";
            //2nd loop will extract each column and convert it in string comma-seprated
            if (arrData[i]["timeString"] != 0) {

                for (var index in arrData[i]) {
                    if (index == "date") {
                        var date = new Date(arrData[i][index]);
                        row += `"${date.toLocaleDateString()}",`
                    } else if (index != "userTime" && index != 'userCurrentTime') {
                        row += '"' + (arrData[i][index] == 0 ? "-" : arrData[i][index]) + '",';
                    }
                }
                row.slice(0, row.length - 1);
                //add a line break after each row
                CSV += row + '\r\n';
            }
        }

        if (CSV == '') {
            message.error("Invalid data");
            return;
        }

        //Generate a file name
        var fileName = "MyReport_";
        //this will remove the blank-spaces from the title and replace it with an underscore
        fileName += ReportTitle.replace(/ /g, "_");

        //Initialize file format you want csv or xls
        var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

        // Now the little tricky part.
        // you can use either>> window.open(uri);
        // but this will not work in some browsers
        // or you will not get the correct file extension

        //this trick will generate a temp <a /> tag
        var link = document.createElement("a");
        link.href = uri;

        //set the visibility hidden so it will not effect on your web-layout
        link.style = "visibility:hidden";
        link.download = fileName + ".csv";

        //this part will append the anchor tag and remove it after automatic click
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    getTasks = (duration, mail) => {
        this.setState({ loader: true })
        var startDate = new Date(Date.now());
        var endDate = new Date(Date.now());
        startDate.setDate(startDate.getDate());
        endDate.setDate(endDate.getDate() + 1);
        var startDateString = startDate.getFullYear() + "/" + (startDate.getMonth() + 1) + "/" + (startDate.getDate());
        var endDateString = endDate.getFullYear() + "/" + (endDate.getMonth() + 1) + "/" + (endDate.getDate());
        if (duration == "Weekly") {
            startDate = this.getMonday(Date.now());
            endDate = this.getMonday(Date.now());
            endDate.setDate(endDate.getDate() + 8);
            endDateString = endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + (endDate.getDate());
            startDate.setDate(startDate.getDate() - 1);
            startDateString = startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + (startDate.getDate());
        } else if (duration == "Last 2 Weeks") {
            startDate = this.getMonday(Date.now());
            endDate = this.getMonday(Date.now());
            endDate.setDate(endDate.getDate() + 8);
            endDateString = endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + (endDate.getDate());
            startDate.setDate(startDate.getDate() - 8);
            startDateString = startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + (startDate.getDate());
        } else if (duration == "Last Week") {
            startDate = this.getMonday(Date.now());
            endDate = this.getMonday(Date.now());
            endDate.setDate(endDate.getDate());
            endDateString = endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + (endDate.getDate() - 1);
            startDate.setDate(startDate.getDate() - 8);
            startDateString = startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + (startDate.getDate());
        }
        this.props.usersActions.getUsersUsingMail(mail);
        this.props.projectsActions.getFollowersTimeUsingDateRangeUsingMail(startDateString, endDateString, mail);
    }

    render() {
        const { from, to } = this.state;
        const modifiers = { start: from, end: to };
        if (document.getElementById("pie_chart_div")) {
            var style = window.getComputedStyle(document.getElementById("pie_chart_div"));
            var pieChartWidth = style.getPropertyValue("width");
            pieChartWidth = (parseInt(pieChartWidth.replace(/px/, "")) * 2 / 5) + "px";
            var innerpieChartWidth = (parseInt(pieChartWidth.replace(/px/, "")) - 30);
            var outerpieChartWidth = (parseInt(pieChartWidth.replace(/px/, "")));
            var cxWidth = (parseInt(pieChartWidth.replace(/px/, "")) / 2);

        }
        return (
            <>
                {
                    this.state.loader &&
                    <Loader />
                }
                <div id="home_main" className={(this.state.navSide ? "content-div row" : "content-div-full row")} >
                    <div className="col-md-9">
                        <div className="row">
                            <div id="pie_chart_div" className="col-md-4" >
                                {((this.state.selectedUser && AuthContext.getCachedUser() && this.state.selectedUser.label != AuthContext.getCachedUser().profile.name) ?
                                    <>  <div className="card-home ongoingCard" style={{ backgroundColor: "#d12304", cursor: "pointer" }}
                                        onClick={() => {
                                            this.getTasks(this.state.duration, AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.unique_name);
                                            this.setState({
                                                selectedUser: {
                                                    value: AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.aud,
                                                    label: AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.name,
                                                    mail: AuthContext.getCachedUser() && AuthContext.getCachedUser().userName,
                                                    userPrincipalName: AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.unique_name
                                                },
                                                datePicker: null
                                            })
                                        }}
                                    >
                                        <span style={{ color: "white", fontSize: 15 }}>MyActivity</span>
                                    </div>
                                        <div className="col-md-12 cart-content" style={{ padding: 0 }}>
                                            <div style={{ display: "flex", flexDirection: "row", padding: 10, marginBottom: 5, cursor: "pointer", borderRadius: 5, boxShadow: "0px 2px 2px 0px #b6b6b6" }} >
                                                <div style={{ width: 75 }}>

                                                    <img src={(this.state.selectedUser.image ? "data:image/png;base64," + this.state.selectedUser.image : require("../../assets/images/user.jpg"))} style={{ width: 70, height: 70, borderRadius: 70 }} />

                                                </div>
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                                    <h6 style={{ color: "black" }}>{this.state.selectedUser.label}</h6>
                                                    <span style={{ color: "gray", width: "100%", fontSize: 12 }}>{this.state.selectedUser.mail}</span>
                                                </div>

                                            </div>
                                        </div></> : <div className="project-status">
                                        <div className="col-md-12 cart-content" style={{ padding: 0 }}>
                                            <div className="card-home ongoingCard" style={{ backgroundColor: "#23a588" }}
                                            >

                                                <div className="row">
                                                    <div className="col-md-9 card-values" >
                                                        <h6>Ongoing Projects</h6>
                                                    </div>
                                                    <div className="col-md-3" >
                                                        <div className="card-presentage">
                                                            <h5>{this.props.home && this.props.home.projectReducer.projectStatus && this.props.home.projectReducer.projectStatus.currentProjects.length}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 cart-content" style={{ padding: 0, paddingTop: 10 }}>
                                            <div className="card-home ongoingCard" style={{ backgroundColor: "#4a90e2" }}>
                                                <div className="row">
                                                    <div className="col-md-9 card-values">
                                                        <h6>Completed Projects</h6>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="card-presentage">
                                                            <h5>{this.props.home && this.props.home.projectReducer.projectStatus && this.props.home.projectReducer.projectStatus.completedProjects}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-12 cart-content" style={{ padding: 0, paddingTop: 10, paddingBottom: 10 }}>
                                            <div className="card-home ongoingCard" style={{ cursor: "pointer", backgroundColor: "#ef4123" }}
                                                onClick={() => { this.pageScroll() }}

                                            >
                                                <div className="row">
                                                    <div className="col-md-9 card-values">
                                                        <h6>Delayed Projects</h6>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="card-presentage">
                                                            <h5>{this.props.home && this.props.home.projectReducer.projectStatus && this.props.home.projectReducer.projectStatus.delayProjects.length}</h5>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                <div className="task_card_home">
                                    <h4 style={{ textAlign: "center" }}>{this.state.duration}</h4>
                                    <div className="scroll" style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: window.innerHeight - 300, overflowX: "scroll" }}
                                    >
                                        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: "100%", width: "100%" }}>
                                            {(this.props.home && this.props.home.projectReducer &&
                                                this.props.home.projectReducer.projects && this.props.home.projectReducer.projects.length != 0 ?


                                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                                    <div style={{ width: pieChartWidth, height: 200 }}>
                                                        {pieChartWidth &&
                                                            <PieChart width={200} height={200}>
                                                                <Pie
                                                                    data={this.props.home.projectReducer.projects && this.props.home.projectReducer.taskSummaryArray && this.props.home.projectReducer.projects.length != 0 && (this.props.home.projectReducer.taskSummaryArray.length == 0 ? this.props.home.projectReducer.projects : this.props.home.projectReducer.taskSummaryArray)}
                                                                    cx={cxWidth}
                                                                    cy={100}
                                                                    labelLine={false}
                                                                    //label={this.props.data}
                                                                    outerRadius={outerpieChartWidth}
                                                                    innerRadius={innerpieChartWidth}
                                                                    dataKey={this.props.home.projectReducer.taskSummaryArray && (this.props.home.projectReducer.taskSummaryArray.length == 0 ? "totalTime" : "userTime")}>
                                                                    {this.props.home && this.props.home.projectReducer && this.props.home.projectReducer.taskSummaryArray && this.props.home.projectReducer.projects != null &&
                                                                        this.props.home.projectReducer.projects && this.props.home.projectReducer.projects.length != 0 && (this.props.home.projectReducer.taskSummaryArray.length == 0 ?
                                                                            this.props.home.projectReducer.projects.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                                                            : this.props.home.projectReducer.taskSummaryArray.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                                                        )
                                                                    }
                                                                </Pie>
                                                                <Tooltip content={this.props.home.projectReducer.taskSummaryArray && (this.props.home.projectReducer.taskSummaryArray.length == 0 ? <CustomTooltip /> : <CustomTooltipUser />)} />
                                                            </PieChart>
                                                        }
                                                    </div>
                                                </div>
                                                :
                                                <span style={{ color: "gray", width: "100%", fontSize: 12, textAlign: "center" }}>No Time Spend</span>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-8" style={{ display: "flex", flexDirection: "row", height: window.innerHeight - 60 }} >
                                <div className={(this.state.viewTasks ? "home_dailyTask_content_hidden scroll " : "home_dailyTask_content scroll task_card_home")} >
                                    <div className="row">
                                        <div className="col-md-6">
                                            <select className="form-control" value={this.state.duration} onChange={(value) => { this.setState({ duration: value.target.value, datePicker: false }); this.getTasks(value.target.value, this.state.selectedUser.userPrincipalName) }}>
                                                <option value="Daily">Today</option>
                                                <option value="Weekly">This Week</option>
                                                <option value="Last Week">Last Week</option>
                                                <option value="Last 2 Weeks">Last 2 Weeks</option>
                                            </select>
                                        </div>
                                        <div className="col-md-6" style={{ display: "flex", flexDirection: "row", justifyContent: "center" }}>
                                            <div className="btn-pro" onClick={() => { this.setState({ datePickerView: !this.state.datePickerView, duration: "Customize" }) }}>
                                                <span>Customize</span>
                                            </div>
                                            <div className="btn-pro-excel" style={{ marginLeft: 10 }} onClick={() => { this.JSONToCSVConvertor(this.props.home.projectReducer.excel, "Time Report", true); }}>
                                                <span>Export to CSV</span>
                                            </div>

                                        </div>
                                        {this.state.datePickerView &&
                                            <div className="col-md-12 datePicker mfp-move-from-top" >
                                                <DayPicker
                                                    className="Selectable"
                                                    numberOfMonths={this.props.numberOfMonths}
                                                    selectedDays={[from, { from, to }]}
                                                    modifiers={modifiers}
                                                    onDayClick={this.handleDayClick}
                                                />
                                                <div className="close_button" onClick={() => { this.setState({ datePickerView: !this.state.datePickerView }) }}>
                                                    <span>X</span>
                                                </div>
                                                <div className="datePickerButton">
                                                    <div className="btn-pro" onClick={() => {
                                                        if (this.state.from || this.state.to) {
                                                            var startDate = new Date(this.state.from ? this.state.from : this.state.to);
                                                            var startDateString = startDate.getFullYear() + "-" + (startDate.getMonth() + 1) + "-" + (startDate.getDate())
                                                            var endDate = new Date(this.state.to ? this.state.to : this.state.from);
                                                            var endDateString = endDate.getFullYear() + "-" + (endDate.getMonth() + 1) + "-" + (endDate.getDate())
                                                            this.setState({ datePickerView: false, duration: "Customize", datePicker: true, datepickerSelectValue: startDateString + " to " + endDateString })
                                                            //this.getTasks(this.state.duration,this.state.selectedUser.userPrincipalName)
                                                            this.props.projectsActions.getFollowersTimeUsingDateRangeUsingMail(startDateString, endDateString, this.state.selectedUser.userPrincipalName);
                                                        } else {
                                                            message.error("Please select date range");
                                                        }
                                                    }}>
                                                        <span>Apply</span>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                    {
                                        this.state.datePicker &&
                                        <h6 style={{ backgroundColor: "#d12304", color: "white", padding: 5, fontSize: 13, textAlign: "center" }}>{this.state.datepickerSelectValue}</h6>
                                    }
                                    <div className="home_dailyTask_card_data scroll" style={{ height: window.innerHeight - 150 }}>
                                        <Skeleton active loading={this.props.home.projectReducer.getprojectsLoading} avatar paragraph={{ rows: 1 }} />
                                        <Skeleton active loading={this.props.home.projectReducer.getprojectsLoading} avatar paragraph={{ rows: 1 }} />
                                        <Skeleton active loading={this.props.home.projectReducer.getprojectsLoading} avatar paragraph={{ rows: 1 }} />
                                        <Skeleton active loading={this.props.home.projectReducer.getprojectsLoading} avatar paragraph={{ rows: 1 }} />
                                        {(this.props.home && this.props.home.projectReducer &&
                                            this.props.home.projectReducer.projects && this.props.home.projectReducer.projects.length != 0 ?
                                            this.props.home.projectReducer.projects.sort((prevValue, currentValue) => { return new Date(prevValue.date) < new Date(currentValue.date) }).map((dailyTask, index) => {
                                                return <div className="home_dailyTask_card" onClick={() => this.setState({ clickTasks: dailyTask.tasks, clickUser: dailyTask.userName, viewTasks: true })}>
                                                    <div className="home_dailyTask_card_content">
                                                        {(dailyTask.image == "loader" ?
                                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: 50 }}>
                                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: 50 }}>
                                                                    <i className="fa fa-circle-o-notch fa-spin fa-2x fa-fw" style={{ color: "gray" }}></i>
                                                                </div>
                                                            </div>
                                                            :
                                                            <img src={(dailyTask.image ? "data:image/png;base64," + dailyTask.image : require("../../assets/images/user.jpg"))} style={{ width: 50, height: 50, borderRadius: 50 }} />
                                                        )}
                                                        <div className="home_dailyTask_card_content_2">
                                                            <div>{dailyTask.userName}</div>
                                                            <div>{dailyTask.userEmail}</div>
                                                        </div>
                                                        {dailyTask.totalTime &&
                                                            <div className="home_dailyTask_card_content_1">
                                                                <h5 style={{ textAlign: "center" }}>{parseInt(dailyTask.totalTime)}H <span style={{ fontSize: 15 }}>{(dailyTask.totalTime % 1) * 60}min</span></h5>
                                                                <div style={{ textAlign: "center", fontSize: 12, color: "gray", width: "100%" }}>Total Hours</div>
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            })
                                            :
                                            <div style={{ height: "100%", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                                <span style={{ color: "gray", width: "100%", fontSize: 12, textAlign: "center" }}>No Time Spend</span>
                                            </div>)
                                        }
                                    </div>
                                </div>
                                <div className={(this.state.viewTasks ? "home_dailyTask_content_task_hidden scroll   task_card_home" : "home_dailyTask_content_task scroll")}>
                                    <div className="home_back" onClick={() => { this.setState({ viewTasks: false }) }}>X</div>
                                    <h6>{this.state.clickUser}</h6>
                                    <div className="home_dailyTask_card_data scroll" style={{ height: window.innerHeight - 150 }}>
                                        {this.state.clickTasks &&
                                            this.state.clickTasks.sort((prevValue, currentValue) => { return new Date(currentValue.date) - new Date(prevValue.date) }).map((dailyTask, index) => {
                                                return <div className="home_dailyTask_card" style={{ cursor: "default" }}>
                                                    {/* onClick={() => this.setState({ clickTasks: dailyTask.tasks, viewTasks: true })}> */}
                                                    <div className="home_dailyTask_card_content">
                                                        <div className="home_dailyTask_date">{(new Date(dailyTask.date).getMonth() + 1) + "/" + new Date(dailyTask.date).getDate() + "/" + new Date(dailyTask.date).getFullYear()}</div>

                                                        <div className="home_dailyTask_card_content_2">
                                                            <div style={{ textAlign: "center" }}>{dailyTask.name}</div>
                                                        </div>
                                                        <div className="home_dailyTask_card_content_1">
                                                            <h4 style={{ textAlign: "center" }}>{parseInt(dailyTask.timeString)}H <span style={{ fontSize: 15 }}>{(parseFloat(dailyTask.userTime) % 1) * 60}min </span></h4>
                                                            <p style={{ textAlign: "center", fontSize: 12, color: "gray", width: "100%" }}>Total Hours</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 scroll follower_card" style={{ overflowY: "scroll", height: window.innerHeight - 60 }}>
                        <h6>Direct Report Users</h6>
                        <Skeleton active loading={!this.props.home.usersReducers.users} avatar paragraph={{ rows: 1 }} />
                        <Skeleton active loading={!this.props.home.usersReducers.users} avatar paragraph={{ rows: 1 }} />
                        <Skeleton active loading={!this.props.home.usersReducers.users} avatar paragraph={{ rows: 1 }} />
                        <Skeleton active loading={!this.props.home.usersReducers.users} avatar paragraph={{ rows: 1 }} />
                        {
                            this.props.home &&
                            this.props.home.usersReducers &&
                            this.props.home.usersReducers.users &&
                            this.props.home.usersReducers.users.map((value, index) => {
                                return <div key={index} style={{ display: "flex", flexDirection: "row", padding: 10, marginBottom: 5, cursor: "pointer", borderRadius: 5, boxShadow: "0px 2px 2px 0px #b6b6b6" }} onClick={() => { this.getTasks(this.state.duration, value.userPrincipalName); this.setState({ selectedUser: value }) }}>
                                    <div style={{ width: 55 }}>
                                        {(value.image == "loader" ?
                                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", width: 50 }}>
                                                <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", height: 50 }}>
                                                    <i className="fa fa-circle-o-notch fa-spin fa-2x fa-fw" style={{ color: "gray" }}></i>

                                                </div>
                                            </div>
                                            :
                                            <img src={(value.image ? "data:image/png;base64," + value.image : require("../../assets/images/user.jpg"))} style={{ width: 50, height: 50, borderRadius: 50 }} />
                                        )}
                                    </div>
                                    <div style={{ display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                        <h6 style={{ color: "black" }}>{value.label}</h6>
                                        <span style={{ color: "gray", width: "100%", fontSize: 12 }}>{value.mail}</span>
                                    </div>

                                </div>
                            })
                        }
                        {this.props.home &&
                            this.props.home.usersReducers &&
                            (this.props.home.usersReducers.users && this.props.home.usersReducers.users.length == 0) &&
                            <div style={{ height: "10%", overflow: "hidden", display: "flex", flexDirection: "column", justifyContent: "center" }}>
                                <span style={{ color: "gray", width: "100%", fontSize: 12, textAlign: "center" }}>No Followers Found</span>
                            </div>
                        }
                    </div>
                    <div className="col-md-12" style={{ padding: 5, height: window.innerHeight - 100 }}>
                        <div className="col-md-12 cart-content" >
                            <div className=" ongoingCardDeplayed">
                                <div className="row" style={{ width: "100%" }}>
                                    <div className="col-md-7 card-values-delay">
                                        <h6>Delayed Projects</h6>
                                    </div>
                                    <div className="col-md-5" style={{ justifyContent: "center", alignSelf: "center", alignSelf: "center", display: "flex", alignContent: "center" }}>
                                        <div className="card-presentage-delay">
                                            <h6>{this.props.home && this.props.home.projectReducer.projectStatus && this.props.home.projectReducer.projectStatus.delayProjects.length}</h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="scroll" style={{ overflowY: "auto", height: window.innerHeight - 70 }}>
                                {
                                    this.props.home &&
                                    this.props.home.projectReducer.projectStatus && (
                                        this.props.home.projectReducer.projectStatus.delayProjects.length == 0 ?
                                            <div style={{ height: "50%", display: "flex", flexDirection: "column", justifyContent: "center" }} >
                                                <p style={{ color: "gray", margin: "auto", verticalAlign: "middle", width: "50%", textAlign: "center" }}>
                                                    No delayed projects found
                                                </p>
                                            </div>
                                            :
                                            this.props.home.projectReducer.projectStatus.delayProjects.map((project, index) => {
                                                return <div className="col-md-10 projectCard">
                                                    <div className="projectCardHeader">
                                                        <h6>{project.projectName}</h6>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-md-8 " style={{ zIndex: 1000 }}>
                                                            <Piechart
                                                                hours={[{ name: "Expected Hours", value: project.expectedHours },
                                                                { name: "Extended Hours", value: (project.currentHours - project.expectedHours) }]} />
                                                        </div>
                                                        <div className="col-md-4">
                                                            <div className="cardTime" style={{ borderLeftColor: "#dda900" }}>
                                                                <h7>Expected Hours</h7>
                                                                <br />
                                                                <h7>{project.expectedHours}H</h7>
                                                            </div>
                                                            <div className="cardTime" style={{ borderLeftColor: "#a6a6a6" }}>
                                                                <h7>Current Hours</h7>
                                                                <br />
                                                                <h7>{project.currentHours}H</h7>
                                                            </div>
                                                            <div className="cardTime" style={{ borderLeftColor: "#ed1c24" }}>
                                                                <h7>Extended Hours</h7>
                                                                <br />
                                                                <h7>{project.currentHours - project.expectedHours}H</h7>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
};



const mapStateToProps = (state) => {
    return {
        home: state
    }
}

const mapDispatchToProps = dispatch => ({
    projectsActions: bindActionCreators(Actions, dispatch),
    usersActions: bindActionCreators(usersActions, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(Home);