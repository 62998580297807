import { GET_PROJECT_MANAGERS_LOADING, GET_PROJECT_MANAGERS_SUCCESS, GET_PROJECT_MANAGERS_ERROR, ADD_PROJECT_MANAGER_LOADING, ADD_PROJECT_MANAGER_SUCCESS, ADD_PROJECT_MANAGER_ERROR, GET_DEPARTMENTS_LOADING, GET_DEPARTMENTS_SUCCESS, GET_DEPARTMENTS_ERROR, ADD_DEPARTMENT_NBA_ERROR, ADD_DEPARTMENT_NBA_LOADING, ADD_DEPARTMENT_NBA_SUCCESS, GET_DEPARTMENT_HEAD_LOADING, GET_DEPARTMENT_HEAD_ERROR, ADD_DEPARTMENT_HEAD_SUCCESS,
     ADD_DEPARTMENT_HEAD_ERROR, ADD_DEPARTMENT_HEAD_LOADING, GET_DEPARTMENT_HEAD_SUCCESS, GET_TEAM_LEADS_LOADING, GET_TEAM_LEADS_SUCCESS, GET_TEAM_LEADS_ERROR, ADD_TEAM_LEAD_LOADING, ADD_TEAM_LEAD_SUCCESS, ADD_TEAM_LEAD_ERROR, GET_ADMIN_USERS_LOADING, GET_ADMIN_USERS_ERROR, GET_ADMIN_USERS_SUCCESS, ADD_ADMIN_LOADING, ADD_ADMIN_ERROR, ADD_ADMIN_SUCCESS, DELETE_ADMIN_LOADING, DELETE_ADMIN_SUCCESS, DELETE_DEPARTMENT_HEAD_LOADING, DELETE_DEPARTMENT_HEAD_SUCCESS, 
     DELETE_DEPARTMENT_HEAD_ERROR, DELETE_PROJECT_MANAGER_LOADING, DELETE_PROJECT_MANAGER_SUCCESS, DELETE_PROJECT_MANAGER_ERROR, DELETE_TEAM_LEAD_LOADING, DELETE_TEAM_LEAD_SUCCESS, DELETE_TEAM_LEAD_ERROR, GET_ROLE_SUCCESS, GET_ROLE_ERROR, GET_ROLE_LOADING , GET_USER_DEPARTMENTS_SUCCESS , GET_USER_DEPARTMENTS_ERROR , GET_USER_DEPARTMENTS_LOADING} from "../types";
import actions from "redux-form/lib/actions";

const initialValue = {
    getProjectManagersLoading: false,
    getProjectManagers: [],
    getProjectManagersError: null,
    addProjectManagerLoading: false,
    addProjectManagerSuccess: null,
    addProjectManagerError: null,
    deleteProjectMangerLoading: false,
    deleteProjectManagerSuccess: null,
    deleteProjectManagerError: null,
    getDepartmentsLoading: false,
    getDepartmentsSuccess: [],
    getDepartmentsError: null,
    getUserDepartmentsLoading: false,
    getUserDepartmentsSuccess: [],
    getUserDepartmentsError: null,
    addDepartmentsNBAError: null,
    addDepartmentsNBALoading: false,
    addDepartmentsNBASuccess: null,
    getDepartmentHeadsLoading: false,
    getDepartmentHeadsSuccess: [],
    getDepartmentHeadsError: null,
    addDepartmentHeadLoading: false,
    addDepartmentHeadSuccess: null,
    addDepartmentHeadError: null,
    getTeamLeadsSuccess: [],
    getTeamLeadsError: null,
    getTeamLeadsLoading: false,
    addTeamLeadLoading: false,
    addTeamLeadSuccess: null,
    addTeamLeadError: null,
    getAdminUsersSuccess: [],
    getAdminUsersLoading: false,
    getAdminUsersError: null,
    addAdminSuccess: null,
    addAdminLoading: false,
    addAdminError: null,
    deleteAdminUserLoading: false,
    deleteAdminUserSuccess: null,
    deleteAdminUserError: null,
    deleteDepartmentHeadSuccess: null,
    deleteDepartmentHeadError: null,
    deleteDepartmentHeadLoading: false,
    deleteTeamLeadSuccess: null,
    deleteTeamLeadError: null,
    deleteTeamLeadLoading: false

}

const roleReducer = (state = initialValue, action) => {
    switch (action.type) {
        case GET_PROJECT_MANAGERS_LOADING:
            return { ...state, getProjectManagersLoading: true, getProjectManagers: [], getProjectManagersError: null }
        case GET_PROJECT_MANAGERS_SUCCESS:
            return { ...state, getProjectManagersLoading: false, getProjectManagers: action.data, getProjectManagersError: null }
        case GET_PROJECT_MANAGERS_ERROR:
            return { ...state, getProjectManagersLoading: false, getProjectManagers: [], getProjectManagersError: action.error }
        case ADD_PROJECT_MANAGER_LOADING:
            return { ...state, addProjectManagerLoading: true, addProjectManagerError: null, addProjectManagerSuccess: null }
        case ADD_PROJECT_MANAGER_SUCCESS:
            var pmarray = state.getProjectManagers;
            pmarray.push(action.data);
            return { ...state, addProjectManagerSuccess: actions.data, getProjectManagers: pmarray, addProjectManagerLoading: false, addProjectManagerError: null }
        case ADD_PROJECT_MANAGER_ERROR:
            return { ...state, addProjectManagerError: action.error, addProjectManagerLoading: false, addProjectManagerSuccess: null }
        case DELETE_PROJECT_MANAGER_LOADING:
            return { ...state, deleteProjectMangerLoading: action.loading, deleteProjectManagerSuccess: null, deleteProjectManagerError: null }
        case DELETE_PROJECT_MANAGER_SUCCESS:
            var projectManagerList = state.getProjectManagers;
            var deleteItemIndex = projectManagerList.findIndex((data) => data.id == action.data);
            projectManagerList.splice(deleteItemIndex, 1);
            return { ...state, deleteProjectMangerLoading: false, deleteProjectManagerSuccess: action.data, getProjectManagers: projectManagerList, deleteProjectManagerError: null }
        case DELETE_PROJECT_MANAGER_ERROR:
            return { ...state, deleteProjectMangerLoading: false, deleteProjectManagerSuccess: null, deleteProjectManagerError: action.error }
        case GET_DEPARTMENTS_LOADING:
            return { ...state, getDepartmentsLoading: action.loading, getDepartmentsSuccess: [], getDepartmentsError: null }
        case GET_DEPARTMENTS_SUCCESS:
            return { ...state, getDepartmentsLoading: false, getDepartmentsSuccess: action.data, getDepartmentsError: null }
        case GET_DEPARTMENTS_ERROR:
            return { ...state, getDepartmentsLoading: false, getDepartmentsSuccess: [], getDepartmentsError: action.error }
        case GET_DEPARTMENT_HEAD_LOADING:
            return { ...state, getDepartmentHeadsLoading: action.loading, getDepartmentHeadsSuccess: [], getDepartmentHeadsError: null }
        case GET_DEPARTMENT_HEAD_SUCCESS:
            return { ...state, getDepartmentHeadsLoading: false, getDepartmentHeadsSuccess: action.data, getDepartmentHeadsError: null }
        case GET_DEPARTMENT_HEAD_ERROR:
            return { ...state, getDepartmentHeadsLoading: false, getDepartmentHeadsSuccess: [], getDepartmentHeadsError: action.error }
        case GET_USER_DEPARTMENTS_LOADING:
                return { ...state, getUserDepartmentsLoading: action.loading, getUserDepartmentsSuccess: [], getDepartmentsError: null }
        case GET_USER_DEPARTMENTS_SUCCESS:
                return { ...state, getUserDepartmentsLoading: false, getUserDepartmentsSuccess: action.data, getUserDepartmentsError: null }
        case GET_USER_DEPARTMENTS_ERROR:
                return { ...state, getUserDepartmentsLoading: false, getUserDepartmentsSuccess: [], getUserDepartmentsError: action.error }
        case ADD_DEPARTMENT_HEAD_LOADING:
            return { ...state, addDepartmentHeadLoading: action.loading, addDepartmentHeadSuccess: null, addDepartmentHeadError: null }
        case ADD_DEPARTMENT_HEAD_SUCCESS:
            var departmentHeadArray = state.getDepartmentHeadsSuccess;
            departmentHeadArray.push(action.data);
            return { ...state, getDepartmentHeadsSuccess: departmentHeadArray, addDepartmentHeadLoading: false, addDepartmentHeadSuccess: action.data, addDepartmentHeadError: null }
        case ADD_DEPARTMENT_HEAD_ERROR:
            return { ...state, addDepartmentHeadLoading: false, addDepartmentHeadSuccess: null, addDepartmentHeadError: action.error }
        case DELETE_DEPARTMENT_HEAD_LOADING:
            return { ...state, deleteDepartmentHeadLoading: action.loading, deleteDepartmentHeadSuccess: null, deleteDepartmentHeadError: null }
        case DELETE_DEPARTMENT_HEAD_SUCCESS:
            var DepartmentHeadList = state.getDepartmentHeadsSuccess;
            var index = DepartmentHeadList.findIndex((data) => data.id == action.data);
            DepartmentHeadList.splice(index, 1);
            return { ...state, deleteDepartmentHeadLoading: false, deleteDepartmentHeadSuccess: action.data, deleteDepartmentHeadError: null }
        case DELETE_DEPARTMENT_HEAD_ERROR:
            return { ...state, deleteDepartmentHeadLoading: false, deleteDepartmentHeadSuccess: null, deleteDepartmentHeadError: action.error }
        case GET_TEAM_LEADS_LOADING:
            return { ...state, getTeamLeadsLoading: action.loading, getTeamLeadsSuccess: [], getTeamLeadsError: null }
        case GET_TEAM_LEADS_ERROR:
            return { ...state, getTeamLeadsLoading: false, getTeamLeadsSuccess: [], getTeamLeadsError: action.error }
        case GET_TEAM_LEADS_SUCCESS:
            return { ...state, getTeamLeadsLoading: false, getTeamLeadsSuccess: action.data, getTeamLeadsError: null }
        case ADD_TEAM_LEAD_LOADING:
            return { ...state, addTeamLeadLoading: action.loading, addTeamLeadSuccess: null, addTeamLeadError: null }
        case ADD_TEAM_LEAD_SUCCESS:
            var teamLeadArray = state.getTeamLeadsSuccess;
            teamLeadArray.push(action.data);
            return { ...state, getTeamLeadsSuccess: teamLeadArray, addTeamLeadLoading: false, addTeamLeadSuccess: action.data, addTeamLeadError: null }
        case ADD_TEAM_LEAD_ERROR:
            return { ...state, addTeamLeadLoading: false, addTeamLeadSuccess: null, addTeamLeadError: action.error }
        case DELETE_TEAM_LEAD_LOADING:
            return { ...state, deleteTeamLeadLoading: action.loading, deleteTeamLeadError: null, deleteTeamLeadSuccess: null }
        case DELETE_TEAM_LEAD_SUCCESS:
            var teamLeadUserList = state.getTeamLeadsSuccess;
            var teamLeadIndex = teamLeadUserList.findIndex((data) => data.id == action.data);
            teamLeadUserList.splice(teamLeadIndex, 1);
            return { ...state, deleteTeamLeadLoading: false, deleteTeamLeadSuccess: action.data, getTeamLeadsSuccess: teamLeadUserList, deleteTeamLeadError: null }
        case DELETE_TEAM_LEAD_ERROR:
            return { ...state, deleteTeamLeadLoading: false, deleteTeamLeadError: action.error, deleteTeamLeadSuccess: null }
        case GET_ADMIN_USERS_LOADING:
            return { ...state, getAdminUsersLoading: action.loading, getAdminUsersError: null, getAdminUsersSuccess: [] }
        case GET_ADMIN_USERS_SUCCESS:
            return { ...state, getAdminUsersLoading: false, getAdminUsersError: null, getAdminUsersSuccess: action.data }
        case GET_ADMIN_USERS_ERROR:
            return { ...state, getAdminUsersLoading: false, getAdminUsersError: action.error, getAdminUsersSuccess: [] }
        case ADD_ADMIN_LOADING:
            return { ...state, addAdminLoading: action.loading, addAdminError: null, addAdminSuccess: null }
        case ADD_ADMIN_SUCCESS:
            var adminList = state.getAdminUsersSuccess;
            adminList.push(action.data);
            return { ...state, getAdminUsersSuccess: adminList, addAdminLoading: false, addAdminError: null, addAdminSuccess: action.data }
        case ADD_ADMIN_ERROR:
            return { ...state, addAdminLoading: false, addAdminError: action.error, addAdminSuccess: null }
        case DELETE_ADMIN_LOADING:
            return { ...state, deleteAdminUserLoading: action.loading, deleteAdminUserSuccess: null, deleteAdminUserError: null }
        case DELETE_ADMIN_SUCCESS:
            var adminUserList = state.getAdminUsersSuccess;
            var index = adminUserList.findIndex((data) => data.id == action.data);
            adminUserList.splice(index, 1);
            return { ...state, deleteAdminUserLoading: false, deleteAdminUserSuccess: action.data, deleteAdminUserError: null, getAdminUsersSuccess: adminUserList }

        default:
            return state;
    }
}

export default roleReducer;