import React, { Component } from 'react'
import "./NavbarTop.scss"
import logo from "../../assets/images/logo.png"

class NavbarTop extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navStatus: true
        }
    }

    render() {
        return (
            <div className="navbartop-main">
                {/* <div className="navbartop-sidenav-icon" onClick={()=>{
                    if(this.state.navStatus){
                        this.setState({navStatus:false});
                        this.props.sidenavChange(false)
                    }else{
                        this.setState({navStatus:true})
                        this.props.sidenavChange(true)

                }}}>
                    {(this.state.navStatus?<icon className="fa fa-bars"/>:<icon className="fa fa-ellipsis-v"/>)}
                </div> */}
                <div className="navbartop-header" style={{ padding: 8, width: "100%", display: "flex", flexDirection: "row", justifyContent: "left" }}>
                    <div style={{ width: 50 }}>
                        <img src={logo} style={{ height: 20, width: 20 }} />
                    </div>
                    <div>
                        <h5>ProTime</h5>
                    </div>
                </div>
            </div>
        )
    }
}

export default NavbarTop;