import React, { Component } from 'react';
import { AreaChart, Area, PieChart, Pie, Cell, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts"
var monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"];
class ProjectTasksUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            userEmail: ""
        }
    }
    render() {
        const COLORS = ['#ef4123', '#0D0496', '#FFBB28', '#FF8042'];
        const RADIAN = Math.PI / 180;

        const renderCustomizedLabel = ({
            cx, cy, midAngle, innerRadius, outerRadius, percent, index,
        }) => {
            const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
            const x = cx + radius * Math.cos(-midAngle * RADIAN);
            const y = cy + radius * Math.sin(-midAngle * RADIAN);

            return (
                <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                    {`${(percent * 100).toFixed(1)}%`}
                </text>
            );
        };
        return (
            <div className="row" style={{ width: "100%", padding: 5 }}>

                <div className="col-md-6 row">
                    <div className="col-md-12">
                        <div className="user_task_details">
                            <div className="pc_sub_pic">
                                <img src={(this.props.user.image ? "data:image/png;base64," + this.props.user.image : require("../../assets/images/user.jpg"))} style={{ width: 70, height: 70, borderRadius: 130 }} />
                            </div>
                            <div >
                                <h5>{this.props.user.userName}</h5>
                                <h6>{this.props.user.userEmail}</h6>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6" style={{ height: 200 }}>
                        {this.props.project && this.props.project.graph &&
                            <PieChart width={400} height={400}>
                                <Pie
                                    data={this.props.user.userTime > this.props.user.currentTime ?
                                        [{ name: "Spent Hours", value: this.props.user.currentTime },
                                        { name: "Available Hours", value: this.props.user.userTime - this.props.user.currentTime }]
                                        :
                                        [{ name: "Exceed Hours", value: this.props.user.currentTime - this.props.user.userTime },
                                        { name: "Expected Hours", value: this.props.user.userTime }]}
                                    cx={100}
                                    cy={100}
                                    labelLine={false}
                                    label={renderCustomizedLabel}
                                    outerRadius={80}
                                    fill="#8884d8"
                                    dataKey="value"
                                >
                                    {
                                        this.props.project.graph.map((entry, index) => <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />)
                                    }
                                </Pie>
                                <Tooltip />
                            </PieChart>


                        }
                    </div>
                    <div className="col-md-6">

                        {this.props.user.userTime < this.props.user.currentTime ? <>
                            <h3 style={{ fontSize: 40, fontWeight: 500, color: "#ef4123" }}>{this.props.user.currentTime - this.props.user.userTime} H</h3>
                            <h6 style={{ color: "#ef4123" }}>Exceed Hours
                                    {/* <span style={{color:"green"}}>(12%+)</span> */}
                            </h6>
                            <h3 style={{ fontSize: 40, fontWeight: 500, color: "#0D0496" }}>{this.props.user.userTime} H</h3>
                            <h6 style={{ color: "#0D0496" }}>Expected Hours
                                    {/* <span style={{color:"green"}}>(12%+)</span> */}
                            </h6>

                        </>
                            :
                            <>
                                <h3 style={{ fontSize: 40, fontWeight: 500, color: "#0D0496" }}>{this.props.user.currentTime} H</h3>
                                <h6 style={{ color: "#0D0496" }}>Current Hours
                                    {/* <span style={{color:"green"}}>(12%+)</span> */}
                                </h6>
                                <h3 style={{ fontSize: 40, fontWeight: 500, color: "#ef4123" }}>{this.props.user.userTime} H</h3>
                                <h6 style={{ color: "#ef4123" }}>Expected Hours
                                    {/* <span style={{color:"green"}}>(12%+)</span> */}
                                </h6>
                            </>}
                    </div>
                    <div className="col-md-12 scroll">
                        <AreaChart
                            width={500}
                            height={150}
                            data={this.props.projectAssignUsersAll[this.props.selectedIndex].timeList}
                            margin={{ top: 0 }}
                        >
                            <CartesianGrid strokeDasharray="100 10" />
                            <XAxis dataKey="name" />
                            <YAxis />
                            <Tooltip />
                            <Area type="monotone" dataKey="time" stroke="#007bff" fill="#007bff" />
                        </AreaChart>
                    </div>
                </div>
                <div className="col-md-6" >
                    <div style={{ display: "flex", flexDirection: "row", overflow: "hidden" }}>
                        <div className="pc_sub_user_main scroll"
                            style={{ height: window.innerHeight - 200 }}>
                            {this.props.projectAssignUsersAll && this.props.projectAssignUsersAll[this.props.selectedIndex].timeList.map((value, index) => {
                                return <div style={{
                                    borderBottom: "1px solid gray",
                                    display: "flex",
                                    flexDirection: "row",
                                    marginTop: 10
                                }}>
                                    <view style={{ width: "80%" }}>
                                        <h4>{new Date(value.date).getDate()} <span style={{ fontSize: 14, color: "gray" }}>{" " + monthNames[new Date(value.date).getMonth()] + " " + new Date(value.date).getFullYear()} </span></h4>
                                    </view>
                                    <view style={{ width: "20%" }}>
                                        <h3 style={{ color: "#ef4123" }}>{parseInt(value.time)}H <span style={{ fontSize: 15 }}>{(parseFloat(value.time) % 1) * 60}min</span></h3>
                                    </view>
                                </div>
                            })}
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectTasksUser;