import React, { useState, useEffect } from 'react';
import { Drawer, Typography, Select, Button, message } from 'antd';
import {
    UserAddOutlined
} from '@ant-design/icons';
import { Formik } from "formik"
import * as Yup from "yup"
import * as Actions from "../../actions/roleActions"
import { connect } from "react-redux"
import { reset } from 'redux-form';
const key = 'updatable';
const { Option } = Select;
const { Title, Text } = Typography;

const AddRole = ({ visible, onClose, users, addProjectManager, getUsers, addRoleProps, teams, projectManagerList }) => {
    const [user, setUser] = useState();
    const [alertView, setAlertView] = useState(null);

    useEffect(() => {
        if (addRoleProps.addProjectManagerSuccess != alertView) {
            message.success({ content: 'Successfully added', key, duration: 2 });
            setAlertView(addRoleProps.addProjectManagerSuccess);
        }
    }, [addRoleProps.addProjectManagerSuccess])


    const addPMValidationSchema = Yup.object({
        user: Yup.string()
            .required('Please select user')
            .test("nameValidate", "Project manager already exists", function (value) {
                if (value) {
                    var ifProjectMangerGetIndex = projectManagerList.findIndex((teamDetails) => teamDetails.userEmail == JSON.parse(value).mail)
                    if (ifProjectMangerGetIndex == -1) {
                        return true;
                    } else {
                        return false;
                    }
                }
            }),
        team: Yup.string()
            .required('Please select team')
    });

    return (
        <>

            <Drawer
                title="Add Project Manager"
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}>
                <hr />
                <Formik
                    initialValues={{ user: '', team: '' }}
                    validationSchema={addPMValidationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        message.loading({ content: 'Loading...', key });
                        var user = JSON.parse(values.user);
                        setAlertView(true);
                        addProjectManager(user.mail, user.value, user.label.split("...")[0], values.team);
                        resetForm({ user: '', team: '' });
                        setSubmitting(false);
                    }}>
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <form >
                            <Text type="secondary">User</Text>
                            <Select showSearch={true} style={{ width: "100%" }}
                                name="user"
                                onChange={(value) => { setFieldValue("user", value) }}
                                onSearch={(value) => {
                                    getUsers(value);
                                }}
                                value={values.user}
                            >
                                {users && users
                                .filter((item) => typeof item.mail === 'string' && item.mail.includes('millenniumitesp.com'))
                                .map((item) => {
                                    const name = item.label.split('.')[0]; 
                                    return <Option value={JSON.stringify(item)}>{name}</Option>;
                                })
                        }
                            </Select>
                            <Text type="danger">{errors.user && touched.user && errors.user}</Text>
                            <br />
                            <Text type="secondary">Team</Text>
                            <Select showSearch={true} style={{ width: "100%" }}
                                name="team"
                                onChange={(value) => { setFieldValue("team", value) }}
                                value={values.team}
                            >
                                {teams &&
                                    teams.map((item) => {
                                        return <Option value={item.teamid}>{item.teamName}</Option>
                                    })
                                }
                            </Select>
                            <Text type="danger">{errors.team && touched.team && errors.team}</Text>
                            <Button danger type="primary" onClick={handleSubmit} disabled={isSubmitting} style={{ marginTop: 10 }}>
                                <UserAddOutlined /> Add Project Manager
                                </Button>
                        </form>
                    )}
                </Formik>
            </Drawer>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        addRoleProps: state.roleReducers
    }
}
export default connect(mapStateToProps, Actions)(AddRole)