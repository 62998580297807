import React, { Component, useEffect } from 'react';
import { AreaChart, Area, PieChart, Pie, Cell, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts"

const SupportTasks = ({ supportDetails, timeView, setTicket }) => {



    const COLORS = ['#ef4123', '#0D0496', '#00C49F', '#FFBB28', '#FF8042'];
    const RADIAN = Math.PI / 180;

    const renderCustomizedLabel = ({
        cx, cy, midAngle, innerRadius, outerRadius, percent, index,
    }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.4;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(1)}%`}
            </text>
        );
    };
    return (
        <div className="row" style={{ width: "100%" }}>
            <div className="col-md-6 row">
                <div className="col-md-6" style={{ height: 250 }}>
                    {supportDetails && <PieChart width={400} height={400}>
                        <Pie
                            cx={100}
                            cy={100}
                            data={

                                [{ name: "Spent Time", value: supportDetails.time }, { name: (supportDetails.expectedHours - supportDetails.time < 0 ? "Extended Hours" : "Available Hours"), value: (supportDetails.expectedHours - supportDetails.time < 0 ? (supportDetails.expectedHours - supportDetails.time) * -1 : (supportDetails.expectedHours - supportDetails.time)) }
                                ]
                            }
                            labelLine={false}
                            label={renderCustomizedLabel}
                            outerRadius={80}
                            fill="#8884d8"
                            dataKey="value"
                        >
                            <Cell key={`cell-${1}`} fill={"#ef4123"} />)
                            <Cell key={`cell-${2}`} fill={"#FAC0B7"} />)
                        </Pie>
                        <Tooltip />
                    </PieChart>
                    }
                </div>
                <div className="col-md-6">
                    <h1 style={{ fontSize: 50, fontWeight: 500, color: (supportDetails.currentHours < supportDetails.expectedHours ? "#0D0496" : "#ef4123") }}>{(supportDetails && supportDetails.expectedHours != 0 ? (supportDetails.time * 100 / supportDetails.expectedHours).toFixed(1) : "0")}%</h1>
                    <h6 style={{ fontWeight: 500, color: (supportDetails.currentHours < supportDetails.expectedHours ? "#0D0496" : "#ef4123") }}>Completed</h6>
                </div>
                <div className="col-md-12 scroll">
                    {/* {supportDetailsAssignUsersAll.length != 0 ? <AreaChart
                        width={500}
                        height={250}
                        data={this.props.areaChartData}
                        margin={{ top: 0 }}
                    >
                        <CartesianGrid strokeDasharray="100 10" />
                        <XAxis dataKey="date" />
                        <YAxis />
                        <Tooltip />
                        <Area type="monotone" dataKey="time" stroke="#ef4123" fill="#ef4123" />
                    </AreaChart> :
                        <div style={{ width: "100%" }}><p style={{ textAlign: "center", color: "gray" }}>No Data Found</p></div>
                    } */}
                </div>
            </div>
            {/* this.state.timeView */}
            <div className="col-md-6" >
                <div style={{ display: "flex", flexDirection: "row", overflow: "hidden", padding: 10 }}>
                    <div className={(timeView ? "pc_sub_user_main_hidden_timeView" : "pc_sub_user_main_timeView scroll")}
                        style={{ height: window.innerHeight - 200 }}>
                        {supportDetails.tickets && supportDetails.tickets.length != 0 ? supportDetails.tickets.map((value, index) => {
                            return <div className="pc_sub_user"
                                onClick={() => { setTicket(value); }}
                            >

                                <div className="pc_sub_userDetails">
                                    <div className="pc_sub_userDetails_content">
                                        <h4 style={{ fontSize: 20 }}>{value.ticketName}</h4>
                                        <p style={{ fontSize: 13, color: "gray" }}>Support Ticket</p>

                                        {/* <AreaChart
                                            width={200}
                                            height={60}

                                            data={supportDetailsAssignUsersAll.filter(rowValue => rowValue.userEmail == value.userEmail)[0].timeList}
                                            margin={{
                                                top: 5, right: 0, left: 0, bottom: 5,
                                            }}>

                                            <Area type="monotone" dataKey="time" stroke="#ef4123" fill="#ef4123" />
                                        </AreaChart> */}
                                    </div>
                                </div>
                                <div className="pc_sub_rate">
                                    <h4 style={{ fontSize: 30, fontWeight: 100, color: "#ffffff" }}>
                                        {(supportDetails.time == 0 ? 0 : value.time * 100 / supportDetails.time).toFixed(0)}%
                                    </h4>
                                    <h7 style={{ fontSize: 13, color: "#bbbbbb" }}>Completed</h7>
                                </div>
                            </div>
                        })
                            :
                            <div style={{ width: "100%" }}><p style={{ textAlign: "center", color: "gray" }}>No Tickets Found</p></div>

                        }
                    </div>
                    <div className={(timeView ? "pc_sub_user_main scroll" : "pc_sub_user_main_hidden")} >
                        {/* {this.props.projectAssignUsersAll && this.props.projectAssignUsersAll.filter(rowValue => rowValue.userEmail == this.state.userEmail).map((value, index) => {
                            return <div style={{
                                borderBottom: "1px solid gray",
                                display: "flex",
                                flexDirection: "row",
                                marginTop: 10
                            }}>
                                <view style={{ width: "80%" }}>
                                    <h5>{new Date(value.date).getMonth() + "-" + new Date(value.date).getDate() + "-" + new Date(value.date).getFullYear()}</h5>
                                </view>
                                <view style={{ width: "20%" }}>
                                    <h3 style={{ color: "green" }}>{value.userTime} H</h3>
                                </view>
                            </div>
                        })} */}
                    </div>

                </div>
            </div>
        </div>
    );
}

export default SupportTasks;