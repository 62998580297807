import React, { useEffect, useState, useRef } from 'react';

import { Formik, Form, Field, useFormikContext } from "formik"
import * as Yup from "yup"

import { getUsersDropdown, getUsersDropdownSearch } from "../../actions/usersActions";
import { getAllSupport, assignUsersToSupport, confirmSubmit } from "../../actions/supportActions"
import { connect } from "react-redux"
import { DatePicker, Input, message } from 'antd';
import Loader from "../../../containers/Loader/Loader"
import Select from "react-select"

const { Option } = Select;
const AssignUsersSupport = (props) => {
    const [initial, setInitial] = useState({ supportTicketName: '', startDate: '', userId: '', supportId: '' });
    const { UsersAssign, getAllSupports, getUsersDropdownSearch, getUsersDropdown, getAllSupport, assignUsersToSupport, assignLoading, UsersLoading, supportLoading, confirm, confirmSubmit } = props;
    const formikRef = useRef();
    let x;

    useEffect(() => {
        getUsersDropdown()
        getAllSupport()

    }, []);

    useEffect(() => {

        if (confirm) {
            setInitial({ supportTicketName: '', startDate: '', userId: '', supportId: null })
            message.success("Successfully assigned user", 2, function () { confirmSubmit() })
            formikRef.current.resetForm();
        }

    }, [confirm]);


    const assignUsersValidationSchema = Yup.object().shape({
        supportTicketName: Yup.string()
            .required('Please enter support ticket'),
        startDate: Yup.string()
            .required('Please select start date'),
        userId: Yup.string()
            .required('Please select a user'),
        supportId: Yup.string()
            .required('Please select a support name')
            .nullable(true),

    });

    return (
        <>
            {supportLoading &&

                <Loader />
            }

            <Formik
                initialValues={initial}
                innerRef={formikRef}
                validationSchema={assignUsersValidationSchema}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    values.supportId = values.supportId.Key;
                    values.userId = values.userId.value;
                    assignUsersToSupport(values)


                }}

            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    handleReset,
                    dirty,
                    isValid,
                    isSubmitting,
                    setFieldValue
                }) => (
                    <Form>
                        <div className="container userAssignForm">
                            <div style={{ padding: 10, paddingTop: 30, paddingBottom: 30 }}>
                                <div className="row" style={{ padding: 10 }}>

                                    <h3 className="col-md-12" style={{ textAlign: "center", marginBottom: 30 }} >Assign Users To Support</h3>
                                    <div className="col-md-3">
                                        <p>Support Name<span style={{ color: "red" }}>*</span></p>
                                    </div>
                                    <div className="col-md-9" >
                                        <Select
                                            name="supportId"
                                            value={values.supportId}
                                            showSearch={true}
                                            options={getAllSupports}
                                            getOptionValue={(value) => value.Key}
                                            getOptionLabel={(value) => value.Value}
                                            // getOptionLabel="Value"
                                            style={{ maxWidth: 450 }}
                                            onChange={(value) => { setFieldValue("supportId", value); }
                                            }
                                        />
                                    </div>
                                    <div className="col-md-12">
                                        {errors.supportId && touched.supportId &&
                                            <p style={{ color: "red" }}>{errors.supportId}</p>}
                                    </div>
                                    <div className="col-md-3" style={{ marginTop: 20 }}>
                                        <p>Users<span style={{ color: "red" }}>*</span></p>
                                    </div>
                                    <div className="col-md-9" style={{ marginTop: 20 }}>
                                     <Select
                                        showSearch={true}
                                        name="userId"
                                        
                                        // Check if allUsers is defined and is an array before assigning to options
                                        options={props.users.allUsers && Array.isArray(props.users.allUsers)
                                            ? props.users.allUsers
                                                .filter((user) => typeof user.mail === 'string' && user.mail.includes('millenniumitesp.com'))
                                                .map((user) => ({
                                                    ...user,
                                                    label: user.label.split('.')[0] // Extract the name before the dots
                                                }))
                                            : []
                                        }

                                        onSearch={(searchText) => {
                                            if (searchText.length > 2) {
                                                getUsersDropdownSearch(searchText.toLowerCase());
                                            } else if (searchText.length < 3) {
                                                getUsersDropdown();
                                            }
                                        }}

                                        value={values.userId}
                                        style={{ maxWidth: 450 }}

                                        onChange={(value) => { setFieldValue("userId", value); }}
                                    />

                                    </div>
                                    <div className="col-md-12">
                                        {errors.userId && touched.userId &&
                                            <p style={{ color: "red" }}>{errors.userId}</p>}
                                    </div>
                                    <div className="col-md-3" style={{ marginTop: 20 }}>
                                        <p>Support Ticket<span style={{ color: "red" }}>*</span></p>
                                    </div>
                                    <div className="col-md-9" style={{ marginTop: 20 }}>
                                        <Input value={values.supportTicketName} style={{ width: 450 }} type="text" onChange={(value) => {
                                            setFieldValue("supportTicketName", value.target.value)

                                        }} className="form-control" />
                                    </div>
                                    <div className="col-md-12">
                                        {errors.supportTicketName && touched.supportTicketName &&
                                            <p style={{ color: "red" }}>{errors.supportTicketName}</p>}
                                    </div>

                                    <div className="col-md-3" style={{ marginTop: 20 }}>
                                        <p>Start Date <span style={{ color: "red" }}>*</span></p>
                                    </div>
                                    <div className="col-md-9" style={{ marginTop: 20 }}>
                                        <Input value={values.startDate} style={{ width: 450 }} onChange={(value) => { setFieldValue("startDate", value.target.value) }} type="date" className="form-control" />
                                    </div>

                                    <div className="col-md-12">
                                        {errors.startDate && touched.startDate &&
                                            <p style={{ color: "red" }}>{errors.startDate}</p>}
                                    </div>

                                    <div className="col-md-12" style={{ marginTop: 20, display: "flex", flexDirection: "row-reverse" }}>

                                        {
                                            (
                                                assignLoading ?
                                                    <div className="btn-submit" ><icon className="fa fa-spinner fa-spin" style={{ color: "white" }} /></div>
                                                    :
                                                    <button type="submit" className="btn-submit" onClick={handleSubmit} style={{ width: 100, cursor: "pointer" }}> Assign Users</button>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                        </div>

                    </Form>

                )}


            </Formik>

        </>
    );
}

const mapStateToProps = (state) => {
    return {
        users: state.usersReducers,
        UsersAssign: state.usersReducers.allUsers,
        UsersLoading: state.usersReducers.allUserLoading,
        getAllSupports: state.support.getAllSupport,
        supportLoading: state.support.getAllSupportLoading,
        assignLoading: state.support.assignUsersToSupportLoading,
        confirm: state.support.confirmSubmit
    }
}

export default connect(mapStateToProps, { getUsersDropdown, getUsersDropdownSearch, getAllSupport, assignUsersToSupport, confirmSubmit })(AssignUsersSupport);


