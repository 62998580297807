import { message } from "antd";
import axios from "axios"
import { GET_ALL_PROJECT, REPORT_URL, TEAMLEAD_REPORT_URL } from "../../constant"
import { PROJECT_SEARCH_ERROR, PROJECT_SEARCH_LOADING, PROJECT_SEARCH_SUCCESS, REPORT_ERROR, REPORT_LOADING, REPORT_SUCCESS } from "../types";

export const generateReport = (type, dateFrom, dateTo, users, projects, teamList) => dispatch => {
    generateReportLoading(dispatch, true);
    var userString = "";
    var projectString = "";
    var teamString = "";
    users.forEach((element, index) => {
        userString += `'${element.value}'` + (index == users.length - 1 ? "" : ",");
    });
    projects.forEach((element, index) => {
        projectString += `${element.value}` + (index == projects.length - 1 ? "" : ",");
    });
    teamList.forEach((element, index) => {
        teamString += `${element.value}` + (index == teamList.length - 1 ? "" : ",");
    });
    axios.get(REPORT_URL + `&type=${type}&dateFrom=${dateFrom}&dateTo=${dateTo}&users=(${userString})&projects=(${projectString})&teams=(${teamString})`)
        .then(response => {
            generateReportSuccess(dispatch, response.data);
        }).catch(error => {
            generateReportError(dispatch, error)
        })
}

export const generateTeamLeadReport = (type, dateFrom, dateTo, users, projects, teamList, userId) => dispatch => {
    generateReportLoading(dispatch, true);
    var userString = "";
    var projectString = "";
    var teamString = "";
    users.forEach((element, index) => {
        userString += `'${element.value}'` + (index == users.length - 1 ? "" : ",");
    });
    projects.forEach((element, index) => {
        projectString += `${element.value}` + (index == projects.length - 1 ? "" : ",");
    });
    teamList.forEach((element, index) => {
        teamString += `${element.value}` + (index == teamList.length - 1 ? "" : ",");
    });
    axios.get(TEAMLEAD_REPORT_URL + `&type=${type}&dateFrom=${dateFrom}&dateTo=${dateTo}&users=(${userString})&projects=(${projectString})&teams=(${teamString})&userId=${userId}`)
        .then(response => {
            generateReportSuccess(dispatch, response.data);
        }).catch(error => {
            generateReportError(dispatch, error)
        })
}


const generateReportSuccess = (dispatch, data) => dispatch({ type: REPORT_SUCCESS, data })
const generateReportError = (dispatch, error) => dispatch({ type: REPORT_ERROR, error })
const generateReportLoading = (dispatch, loading) => dispatch({ type: REPORT_LOADING, loading })

export const getAllProjectSearch = (stringSearch) => dispatch => {
    getAllProjectSearchLoading(dispatch, true);
    axios.get(GET_ALL_PROJECT + `&searchString=${stringSearch}`)
        .then(response => {
            getAllProjectSearchSuccess(dispatch, response.data);
        }).catch(error => {
            getAllProjectSearchError(dispatch, error);
        })
}

const getAllProjectSearchSuccess = (dispatch, data) => dispatch({ type: PROJECT_SEARCH_SUCCESS, data })
const getAllProjectSearchError = (dispatch, error) => dispatch({ type: PROJECT_SEARCH_ERROR, error })
const getAllProjectSearchLoading = (dispatch, loading) => dispatch({ type: PROJECT_SEARCH_LOADING, loading })


export const JSONToCSVConvertor = (JSONData, ReportTitle, column) => {
    //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
    var arrData = typeof JSONData != 'object' ? JSON.parse(JSONData) : JSONData;
    var CSV = '';
    //Set Report title in first row or line
    //This condition will generate the Label/Header
    var row = "";
    //This loop will extract the label from 1st index of on array
    for (var index in arrData[0]) {
        //Now convert each value to string and comma-seprated
        var columnIndex = column.findIndex((value) => { return value.accessor == index });
        if (columnIndex != -1) {
            row += column[columnIndex].Header + ',';
        }
    }

    row = row.slice(0, -1);
    //append Label row with line break
    CSV += row + '\r\n';

    //1st loop is to extract each row
    for (var i = 0; i < arrData.length; i++) {
        var row = "";
        //2nd loop will extract each column and convert it in string comma-seprated
        for (var index in arrData[i]) {

            if (arrData[i]["timeString"] != 0) {
                if (column.findIndex((value) => { return value.accessor == index }) != -1) {
                    if (index == "date") {
                        var date = new Date(arrData[i][index]);
                        row += `"${date.toLocaleDateString()}",`
                    } else if (index != "userTime" && index != 'userCurrentTime') {
                        row += '"' + (arrData[i][index] == 0 ? "-" : arrData[i][index]) + '",';
                    }
                }
            }

        }
        row.slice(0, row.length - 1);
        //add a line break after each row
        CSV += row + '\r\n';
    }

    if (CSV == '') {
        message.error("Invalid data");
        return;
    }

    //Generate a file name
    //this will remove the blank-spaces from the title and replace it with an underscore
    var fileName = ReportTitle.replace(/ /g, "_");

    //Initialize file format you want csv or xls
    var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);

    // Now the little tricky part.
    // you can use either>> window.open(uri);
    // but this will not work in some browsers
    // or you will not get the correct file extension    

    //this trick will generate a temp <a /> tag
    var link = document.createElement("a");
    link.href = uri;

    //set the visibility hidden so it will not effect on your web-layout
    link.style = "visibility:hidden";
    link.download = fileName + ".csv";

    //this part will append the anchor tag and remove it after automatic click
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
}
