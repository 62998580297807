import React, { useState, useEffect } from 'react';
import { Drawer, Typography, Input, Button, message } from 'antd';
import {
    AppstoreAddOutlined
} from '@ant-design/icons';
import { Formik } from "formik"
import * as Yup from "yup"
import * as Actions from "../../actions/roleActions"
import { connect } from "react-redux"

const key = 'updatable';
const { Title, Text } = Typography;

const AddOrganization = ({ onClose, visible, AddOrganization, addOrganizationNBASuccess }) => {
    const [formSubmitted, setFormSubmitted] = useState(false)
    const addPMValidationSchema = Yup.object({
        projectName: Yup.string()
            .required('Please enter activity name'),
        description: Yup.string()
            .required('Please enter description')
    });

    useEffect(() => {
        if (addOrganizationNBASuccess && formSubmitted) {
            message.success({ content: 'Successfully added activity', key });
            setFormSubmitted(false);
        }
    }, [addOrganizationNBASuccess])

    return (
        <>
            <Drawer
                title="Add Non Time Bound Activity for Organization"
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
            >
                <Formik
                    initialValues={{ projectName: '', description: '' }}
                    validationSchema={addPMValidationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        message.loading({ content: 'Loading...', key });
                        AddOrganization(values.projectName, values.description);
                        setSubmitting(false);
                        resetForm({ projectName: '', description: '' });
                        setFormSubmitted(true);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <form >
                            <Text type="secondary">Activity Name</Text>
                            <Input
                                name="projectName"
                                onChange={(value) => { setFieldValue("projectName", value.target.value) }}
                                value={values.projectName}
                            />
                            <Text type="danger">{errors.projectName && touched.projectName && errors.projectName}</Text> <br />
                            <Text type="secondary">Description</Text>
                            <Input
                                name="description"
                                onChange={(value) => { setFieldValue("description", value.target.value) }}
                                value={values.description}
                            />
                            <Text type="danger">{errors.description && touched.description && errors.description}</Text> <br />
                            <Button danger type="primary" onClick={handleSubmit} disabled={isSubmitting} style={{ marginTop: 10 }}>
                                <AppstoreAddOutlined /> Add Activity
                                </Button>
                        </form>
                    )}
                </Formik>
            </Drawer>
        </>
    );
};

export default AddOrganization;