// src/config/AdalConfig.js
export default {
  //clientId: 'aae0dd7c-5947-4b8a-8f13-e753c971c481',

  //proten12
  //clientId: 'a97753f6-8489-4aba-8e9d-5fcf59fd5865',
  //MIT
  clientId: 'eefc95a7-1ce7-4d13-9af2-6b2d20836e98',
  //HNB
  //clientId: '4ba8daba-7383-4971-ba92-90cbf6d0a6a4',
  endpoints: {
    api: "e1fe6dd8-ba31-4d61-89e7-88639da4683d" // Necessary for CORS requests, for more info see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/CORS-usage
  },
  // MIT.
  tenant: '97424943-93ed-421f-81ce-f245fd53a717',
  // proten12.
  //tenant: '6dee75f2-ac6c-47fd-ac00-89ef89c9e8e4',
  //HNB
  //tenant: 'b7880ad3-042e-45eb-b22a-264e54f98749',
  // 'cacheLocation' is set to 'sessionStorage' by default, for more info see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/Config-authentication-context#configurable-options
  // We change it to'localStorage' because 'sessionStorage' does not work when our app is served on 'localhost' in development.
  cacheLocation: 'localStorage'
}

// src/config/AdalConfig.js
// export default {
//   clientId: '',
//   endpoints: {
//     api: "e1fe6dd8-ba31-4d61-89e7-88639da4683d"
//   },

//   tenant: '',
//   cacheLocation: 'localStorage'
// }