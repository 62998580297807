import axios from "axios"
import {
    GET_ALL_SUPPORT_ERROR,
    GET_ALL_SUPPORT_LOADING,
    GET_ALL_SUPPORT_SUCCESS,
    ASSIGN_USERS_TO_SUPPORT_ERROR,
    ASSIGN_USERS_TO_SUPPORT_LOADING,
    ASSIGN_USERS_TO_SUPPORT_SUCCESS,
    ADD_SUPPORT_ERROR,
    ADD_SUPPORT_LOADING,
    ADD_SUPPORT_SUCCESS,
    CONFIRM_SUPPORT_ERROR,
    CONFIRM_SUPPORT_LOADING,
    CONFIRM_SUPPORT_SUCCESS, GET_SUPPORT_ERROR, GET_SUPPORT_LOADING, GET_SUPPORT_SUCCESS, GET_SUPPORTS_SUCCESS, GET_SUPPORTS_ERROR, GET_SUPPORTS_LOADING,
    SALES_STAGE_CHANGE_LOADING , SALES_STAGE_CHANGE_SUCCESS, SALES_STAGE_CHANGE_ERROR
} from "../types/support"

import { GET_ALL_SUPPORTS, ASSIGN_USERS_TO_SUPPORT, ADD_SUPPORT, GET_SUPPORT_URL, GET_SUPPORT_ALL_URL , ADD_SALES_STAGE} from "../../constant";

export const getAllSupport = () => dispatch => {
    getAllSupportLoading(dispatch, true);
    axios.get(GET_ALL_SUPPORTS)
        .then(data => {
            getAllSupportSuccess(dispatch, data);
        }).catch((error) => {
            getAllSupportError(dispatch, error)
        })
}
export const getSupports = (searchKey) => dispatch => {
    getSupportsLoading(dispatch, true);
    axios.get(GET_SUPPORT_ALL_URL, { params: { searchKey } })
        .then(data => {
            getSupportsSuccess(dispatch, data.data);
        }).catch(error => {
            getSupportsError(dispatch, error.message)
        })
}

const getSupportsSuccess = (dispatch, data) => dispatch({ type: GET_SUPPORTS_SUCCESS, data })
const getSupportsError = (dispatch, error) => dispatch({ type: GET_SUPPORTS_ERROR, error })
const getSupportsLoading = (dispatch, loading) => dispatch({ type: GET_SUPPORTS_LOADING, loading })

export const assignUsersToSupport = (user) => dispatch => {
    assignUsersToSupportLoading(dispatch, true);
    axios.post(ASSIGN_USERS_TO_SUPPORT, JSON.stringify(user))
        .then(data => {
            assignUsersToSupportSuccess(dispatch, user);
        }).catch((error) => {
            assignUsersToSupportError(dispatch, error)
        })
}

export const addSupport = (support) => dispatch => {
    addSupportLoading(dispatch, true);
    axios.post(ADD_SUPPORT, support)
        .then(data => {
            addSupportSuccess(dispatch, support);

        }).catch((error) => {
            addSupportError(dispatch, error)
        })
}


export const confirmSubmit = () => dispatch => {
    confirmSupportSuccess(dispatch, true);
}



export const getSupport = (id) => dispatch => {
    getSupportLoading(dispatch, true);
    axios.get(GET_SUPPORT_URL, { params: { id } })
        .then(data => {
            getSupportSuccess(dispatch, data.data);
        }).catch(error => {
            getSupportError(dispatch, error);
        })
}

export const addSalesStage = (salesStageArray) => dispatch => {
    addSalesStageLoading(dispatch, true);
    axios.post(ADD_SALES_STAGE, salesStageArray)
        .then(data => {
            addSalesStageSuccess(dispatch, salesStageArray);

        }).catch((error) => {
            addSalesStageError(dispatch, error)
        })
}

const getAllSupportSuccess = (dispatch, data) => dispatch({ type: GET_ALL_SUPPORT_SUCCESS, data })
const getAllSupportError = (dispatch, error) => dispatch({ type: GET_ALL_SUPPORT_ERROR, error })
const getAllSupportLoading = (dispatch, loading) => dispatch({ type: GET_ALL_SUPPORT_LOADING, loading })

const assignUsersToSupportSuccess = (dispatch, data) => dispatch({ type: ASSIGN_USERS_TO_SUPPORT_SUCCESS, data })
const assignUsersToSupportError = (dispatch, error) => dispatch({ type: ASSIGN_USERS_TO_SUPPORT_ERROR, error })
const assignUsersToSupportLoading = (dispatch, loading) => dispatch({ type: ASSIGN_USERS_TO_SUPPORT_LOADING, loading })

const addSupportSuccess = (dispatch, data) => dispatch({ type: ADD_SUPPORT_SUCCESS, data })
const addSupportError = (dispatch, error) => dispatch({ type: ADD_SUPPORT_ERROR, error })
const addSupportLoading = (dispatch, loading) => dispatch({ type: ADD_SUPPORT_LOADING, loading })

const addSalesStageSuccess = (dispatch, data) => dispatch({ type: SALES_STAGE_CHANGE_SUCCESS, data })
const addSalesStageError = (dispatch, error) => dispatch({ type: SALES_STAGE_CHANGE_ERROR, error })
const addSalesStageLoading = (dispatch, loading) => dispatch({ type: SALES_STAGE_CHANGE_LOADING, loading })

const confirmSupportSuccess = (dispatch, loading) => dispatch({ type: CONFIRM_SUPPORT_SUCCESS, loading })

const getSupportSuccess = (dispatch, data) => dispatch({ type: GET_SUPPORT_SUCCESS, data })
const getSupportLoading = (dispatch, loading) => dispatch({ type: GET_SUPPORT_LOADING, loading })
const getSupportError = (dispatch, error) => dispatch({ type: GET_SUPPORT_ERROR, error })
