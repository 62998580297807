import { PROJECT_ASSIGN_USERS, GET_ROLE_ERROR, GET_ROLE_SUCCESS, GET_ROLE_LOADING, IS_PROJECT_MANAGER_ERROR, IS_PROJECT_MANAGER_LOADING, IS_PROJECT_MANAGER_SUCCESS, GET_ALL_USERS, EDIT_ASSIGN_USERS_ALERT, GET_IMAGE_ASSIGN_USER, IS_TEAM_LEAD_SUCCESS, IS_TEAM_LEAD_LOADING, IS_TEAM_LEAD_ERROR, GET_IMAGE_USER, GET_IMAGE, DELETE_ASSIGN_USER_TABLE_SUCCESS, GET_IMAGE_ERROR, EDIT_ASSIGN_USERS, EDIT_ASSIGN_USERS_ERROR, GET_ASSIGN_USERS, GET_USERS, GET_ACTIVE_PROJECTS_ASSIGN, ASSIGN_USERS, ASSIGN_USERS_EROR, ASSIGN_USERS_UPLOAD, ASSIGN_USERS_UPLOAD_ERROR, USER_ACTIVITY, EDIT_ASSIGN_USER_LOADER, ASSIGN_USERS_LOADER, IS_ADMIN_LOADING, IS_ADMIN_SUCCESS, IS_ADMIN_ERROR, IS_DEPARTMENT_HEAD_LOADING, IS_DEPARTMENT_HEAD_SUCCESS, IS_DEPARTMENT_HEAD_ERROR, GET_ALL_USERS_LOADING, GET_USER_DETAILS, GET_USER_DETAILS_ERROR } from "../types"
const initalValue = {
    editAssignUserLoader: false,
    editAssignMessage: null,
    assignUsers: [],
    usersAssginLoader: false,
    usersAssignSuccess: null,
    usersAssignMessage: null,
    isTeamLeadLoading: false,
    isTeamLeadSuccess: null,
    isTeamLeadError: null,
    isAdminSuccess: null,
    isAdminLoading: false,
    isAdminError: null,
    isProjectManagerSuccess: null,
    isProjectManagerLoading: false,
    isProjectManagerError: null,
    isDepartmentHeadLoading: false,
    isDepartmentHeadSuccess: null,
    isDepartmentHeadError: null,
    allUserLoading: false,
    role: null,
    roleError: null,
    roleLoading: false,
    userDepartment: null,
    userDesignation: null,
    userEmployeeLevel: null,
    userManager: null
}
const usersReducers = (state = initalValue, actions) => {
    switch (actions.type) {
        case GET_USERS:
            return { ...state, users: actions.payload };
        case GET_IMAGE_USER:
            var userList = state.users;
            userList[actions.index].image = actions.payload;
            return { ...state, users: userList };
        case GET_ALL_USERS:
            return { ...state, allUsers: actions.payload, allUserLoading: false };
        case GET_ALL_USERS_LOADING:
            return { ...state, allUserLoading: true }
        case GET_ACTIVE_PROJECTS_ASSIGN:
            return { ...state, projects: actions.payload, projectsAll: actions.projects };
        case ASSIGN_USERS_LOADER:
            return { ...state, usersAssignSuccess: null, usersAssignMessage: null, usersAssginLoader: true };
        case ASSIGN_USERS:
            return { ...state, usersAssignSuccess: actions.payload, usersAssignMessage: null, usersAssginLoader: false };
        case ASSIGN_USERS_EROR:
            return { ...state, usersAssignMessage: actions.payload, usersAssignSuccess: null, usersAssginLoader: false };
        case EDIT_ASSIGN_USERS:
            var assignUserList = state.assignUsers;
            if (assignUserList[actions.payload.index]) {
                assignUserList[actions.payload.index].userTime = actions.payload.time;
            }
            return { ...state, assignUsers: assignUserList, editSuccess: true, editAssignUserError: null, editAssignUserLoader: false, editAssignMessage: actions.payload, editAssignUserAlert: "Successfully edited" };
        case EDIT_ASSIGN_USERS_ERROR:
            return { ...state, editAssignMessage: null, editAssignUserError: actions.error, editAssignUserLoader: false, editAssignUserAlert: "Something went wrong" };
        case EDIT_ASSIGN_USER_LOADER:
            return { ...state, editAssignMessage: null, editAssignUserError: null, editAssignUserLoader: true, editAssignUserAlert: false }
        case EDIT_ASSIGN_USERS_ALERT:
            return { ...state, editAssignUserAlert: false }
        case DELETE_ASSIGN_USER_TABLE_SUCCESS:
            var assignUsersList = state.assignUsers;
            assignUsersList[actions.payload.index].active = actions.payload.type;
            return { ...state, assignUsers: assignUsersList };
        case ASSIGN_USERS_UPLOAD:
            var projectListArray = (state.uploaded ? state.uploaded : []);
            var project = actions.payload;
            project["status"] = 4
            projectListArray.push(project);
            return { ...state, uploaded: projectListArray }
        case ASSIGN_USERS_UPLOAD_ERROR:
            var projectListArray = (state.uploaded ? state.uploaded : []);
            var project = actions.payload;
            project["status"] = actions.status;
            projectListArray.push(project)
            return { ...state, uploaded: projectListArray }
        case PROJECT_ASSIGN_USERS:
            return { ...state, projectAssignUsers: actions.payload.filterData, projectAssignUsersAll: actions.payload.data, project: actions.payload.project, areaChartData: actions.payload.areaChartData }
        case GET_IMAGE_ASSIGN_USER:
            var userList = state.projectAssignUsers
            // var userList = state.users;
            userList[actions.index].image = actions.payload;
            return { ...state, projectAssignUsers: userList };
        case USER_ACTIVITY:
            return { ...state, projectAssignUsers: actions.payload.filterData, userSpendedTime: actions.payload.data, project: actions.payload.project }
        case GET_IMAGE:
            return { ...state, userImage: actions.payload };
        case GET_USER_DETAILS:
            return { ...state, userDepartment: actions?.payload?.department, userDesignation: actions?.payload?.designation, userEmployeeLevel: actions?.payload?.employeeLevel, userManager: actions?.payload?.manager };
        case GET_USER_DETAILS_ERROR:
            return { ...state, userDepartment: actions?.payload, userDesignation: actions?.payload, userEmployeeLevel: actions?.payload, userManager: actions?.payload };
        case GET_ASSIGN_USERS:
            return { ...state, assignUsers: actions.payload };
        case GET_IMAGE_ERROR:
            return { ...state, userImage: actions.payload };
        case IS_TEAM_LEAD_LOADING:
            return { ...state, isTeamLeadLoading: actions.loading, isTeamLeadSuccess: null, isTeamLeadError: null }
        case IS_TEAM_LEAD_SUCCESS:
            return { ...state, isTeamLeadLoading: false, isTeamLeadSuccess: actions.data, isTeamLeadError: null }
        case IS_TEAM_LEAD_ERROR:
            return { ...state, isTeamLeadLoading: false, isTeamLeadSuccess: null, isTeamLeadError: actions.error }
        case IS_ADMIN_LOADING:
            return { ...state, isAdminLoading: actions.loading, isAdminSuccess: null, isAdminError: null }
        case IS_ADMIN_SUCCESS:
            return { ...state, isAdminLoading: false, isAdminSuccess: actions.data, isAdminError: null }
        case IS_ADMIN_ERROR:
            return { ...state, isAdminLoading: false, isAdminSuccess: null, isAdminError: actions.error }
        case IS_PROJECT_MANAGER_LOADING:
            return { ...state, isProjectManagerLoading: actions.loading, isProjectManagerError: null, isProjectManagerSuccess: false }
        case IS_PROJECT_MANAGER_SUCCESS:
            return { ...state, isProjectManagerLoading: false, isProjectManagerError: null, isProjectManagerSuccess: actions.data }
        case IS_PROJECT_MANAGER_ERROR:
            return { ...state, isProjectManagerLoading: false, isProjectManagerSuccess: false, isProjectManagerError: actions.error }
        case IS_DEPARTMENT_HEAD_LOADING:
            return { ...state, isDepartmentHeadLoading: actions.loading, isDepartmentHeadSuccess: null, isDepartmentHeadError: null }
        case IS_DEPARTMENT_HEAD_SUCCESS:
            return { ...state, isDepartmentHeadLoading: false, isDepartmentHeadSuccess: actions.data, isDepartmentHeadError: null }
        case IS_DEPARTMENT_HEAD_ERROR:
            return { ...state, isDepartmentHeadLoading: false, isDepartmentHeadSuccess: null, isDepartmentHeadError: actions.error }
        case GET_ROLE_SUCCESS:
            return { ...state, role: actions.data, roleError: null, roleLoading: false }
        case GET_ROLE_ERROR:
            return { ...state, role: null, roleError: actions.error, roleLoading: false }
        case GET_ROLE_LOADING:
            return { ...state, role: null, roleError: null, roleLoading: true }
        default:
            return state;
    }
}

export default usersReducers;
