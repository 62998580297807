import { GET_PROJECTS, EDIT_PROJECT_SUCCESS, GET_IMAGE_PROJECT_USERS, DELETE_ASSIGN_USER_SUCCESS, GET_PROJECT_STATUS, ACTIVE_PROJECT_SUCCESS, COMPLETE_PROJECT_SUCCESS, GET_COMPLETED_PROJECTS, GET_ACTIVE_PROJECTS, ADD_PROJECT_SUCCESS, GET_TODAY_PROJECTS, GET_PROJECT, EDIT_PROJECT_LOADER, PROJECT_STATUS_CHANGE_LOADING, GET_PROJECTS_LOADING } from "../types"
import { DELETE_ASSIGN_USER_URL, ALL_PROJECTS_URL, GET_FOLLOWERS_TIME_USING_DATE_RANGE_URL, PROJECT_STATUS_URL, ACTIVE_PROJECT_URL, ADD_PROJECTS_URL, ACTIVE_PROJECTS_URL, COMPLETE_PROJECT_URL, COMPLETED_PROJECT_URL, TODAY_PROJECT_URL, GET_PROJECT_URL, UPDATE_PROJECT_URL , SEARCH_PROJECTS} from "../../constant"
import history from '../../history'
import axios from "axios"
import AuthContext from '../../services/Auth'
import { ACTIVE_SUPPORT_SUCCESS, COMPLETE_SUPPORT_SUCCESS } from "../types/support"

export const getProjects = (pageId) => {
    return dispatch => {
        dispatch({
            type: GET_PROJECTS_LOADING,
            payload: true
        });
        var EmailString = "('" + AuthContext.getCachedUser().userName + "'"
        AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) {///me/photo/$value
            axios.get("https://graph.microsoft.com/v1.0/me/directReports",
                {
                    headers: {
                        "Authorization": "Bearer " + token,
                    }
                })
                .then((usersList) => {
                    EmailString = EmailString + ")"
                    axios.post(ALL_PROJECTS_URL, JSON.stringify({
                        emails: EmailString,
                        page: pageId
                    }))
                        .then((data) => {
                            return dispatch({
                                type: GET_PROJECTS,
                                payload: { value: data.data, excel: data.data }
                            })
                        }).catch(() => {
                            return dispatch({
                                type: GET_PROJECTS,
                                payload: { value: [], excel: [] }
                            })
                        });
                }).catch(() => {
                    EmailString = EmailString + ")"
                    axios.post(ALL_PROJECTS_URL, JSON.stringify({
                        emails: EmailString,
                        page: pageId
                    }))
                        .then((data) => {
                            return dispatch({
                                type: GET_PROJECTS,
                                payload: { value: data.data, excel: data.data }
                            })
                        }).catch(() => {
                            return dispatch({
                                type: GET_PROJECTS,
                                payload: { value: [], excel: [] }
                            })
                        });
                })
        });
    }
}

export const getProjectSearch = (searchText) => {
    const payload = {
      searchKeyword: searchText,
    };
  
    return async (dispatch) => {
      try {
        const response = await axios.post(SEARCH_PROJECTS, payload, {
          headers: {
            'Content-Type': 'application/json',
          },
        });
  
        dispatch({
          type: GET_PROJECTS,
          payload: { value: response.data, excel: response.data },
        });

      } catch (error) {
        dispatch({
          type: GET_PROJECTS,
          payload: { value: [], excel: [] },
        });
      }
    };
  };
  
export const getProject = (id) => {
    return dispatch => {
        axios.get(`${GET_PROJECT_URL}&id=${id}`)
            .then((data) => {
                return dispatch({
                    type: GET_PROJECT,
                    payload: data.data
                })
            }).catch((err) => {

            })
    }
}

export const addProjects = (value) => {
    return async dispatch => {
        axios.post(ADD_PROJECTS_URL, JSON.stringify(value), {
            headers: {
                'Content-Type': 'application/json'
            }
        })
        .then((data) => {
            return dispatch({
                type: ADD_PROJECT_SUCCESS,
                payload: value
            });
        })
        .catch((error) => {
            if (error.response) {
            } else if (error.request) {
                console.log('Error request:', error.request);
            } else {
                console.log('Error message:', error.message);
            }
        });
    };
}

export const getActiveProjects = () => {
    return dispatch => {
        var EmailString = "('" + (AuthContext.getCachedUser() ? AuthContext.getCachedUser().profile.unique_name : null) + "'"
        AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) {///me/photo/$value
            axios.get("https://graph.microsoft.com/v1.0/me/directReports", { headers: { "Authorization": "Bearer " + token } })
                .then((usersList) => {
                    for (var index in usersList.data.value) {
                        EmailString = EmailString + ",'" + usersList.data.value[index].mail + "'";
                    }
                    EmailString = EmailString + ")"
                    axios.post(ACTIVE_PROJECTS_URL, JSON.stringify({
                        emails: EmailString

                    }))
                        .then((response) => {
                            var data = response.data;
                            return dispatch({
                                type: GET_ACTIVE_PROJECTS,
                                payload: data
                            }
                            )
                        }).catch(() => {
                            return dispatch({
                                type: GET_PROJECTS,
                                payload: []
                            })
                        });
                });
        })
    }
}

export const updateProject = (id, project) => {
    project.id = id
    return dispatch => {
        dispatch({
            type: EDIT_PROJECT_LOADER
        })
        axios.post(UPDATE_PROJECT_URL, JSON.stringify(project)
        )
            .then((data) => {
                history.push("/Projects")
                dispatch({
                    type: EDIT_PROJECT_SUCCESS
                })
            });
    }
}

export const getCompletedProjects = () => {
    return dispatch => {
        axios.get(COMPLETED_PROJECT_URL)
            .then((response) => {
                var data = response.data;
                return dispatch({
                    type: GET_COMPLETED_PROJECTS,
                    payload: data
                }
                )
            }).catch(() => {
                return dispatch({
                    type: GET_PROJECTS,
                    payload: []
                })
            });
    }
}

export const getTodayProjects = () => {
    return dispatch => {
        axios.get(TODAY_PROJECT_URL)
            .then((response) => {
                var data = response.data;

                return dispatch({
                    type: GET_TODAY_PROJECTS,
                    payload: data
                }
                )
            });
    }
}

export const getProjectsStatus = () => {
    return dispatch => {
        AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) {///me/photo/$value
            axios.get("https://graph.microsoft.com/v1.0/me/directReports", { headers: { "Authorization": "Bearer " + token } })
                .then((usersList) => {
                    axios.get(`${PROJECT_STATUS_URL}&emails='${AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.unique_name}'`)
                        .then((data) => {
                            return dispatch({
                                type: GET_PROJECT_STATUS,
                                payload: data.data
                            }
                            )
                        });
                });
        });
    }
}

export const completeProject = (id) => {
    return dispatch => {
        dispatch({
            type: PROJECT_STATUS_CHANGE_LOADING
        })
        axios.post(`${COMPLETE_PROJECT_URL}&id=${id}`, JSON.stringify({}))
            .then((response) => {
                var data = response.data;
                dispatch({
                    type: COMPLETE_PROJECT_SUCCESS,
                    payload: id
                })
                dispatch({
                    type: COMPLETE_SUPPORT_SUCCESS,
                    payload: id
                })
            })
    }
}

export const closeAlert = () => {
    return dispatch => {
        dispatch({
            type: PROJECT_STATUS_CHANGE_LOADING
        })
    }
}

export const activeProject = (id) => {
    return dispatch => {
        dispatch({
            type: PROJECT_STATUS_CHANGE_LOADING
        })
        axios.post(`${ACTIVE_PROJECT_URL}&id=${id}`)
            .then((data) => {
                dispatch({
                    type: ACTIVE_PROJECT_SUCCESS,
                    payload: id
                })

                dispatch({
                    type: ACTIVE_SUPPORT_SUCCESS,
                    payload: id
                })
            })
    }
}


export const deleteUserAssign = (id, index, projectIndex, type) => {
    return dispatch => {
        axios.get(`${DELETE_ASSIGN_USER_URL}&id=${id}&type=${type}`)
            .then((value) => {
                return dispatch({
                    type: DELETE_ASSIGN_USER_SUCCESS,
                    payload: { index: index, projectIndex: projectIndex, type: type }
                })
            })
    }
}

export const getFollowersTimeUsingDateRange = (startDate, endDate) => {
    return dispatch => {
        var EmailString = "('" + (AuthContext.getCachedUser() ? AuthContext.getCachedUser().userName : null) + "'"
        AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) {///me/photo/$value
            axios.get("https://graph.microsoft.com/v1.0/me/directReports", { headers: { "Authorization": "Bearer " + token } })
                .then((usersList) => {
                    for (var index in usersList.data.value) {
                        EmailString = EmailString + ",'" + usersList.data.value[index].mail + "'"
                    }
                    EmailString = EmailString + ")"
                    axios.get(GET_FOLLOWERS_TIME_USING_DATE_RANGE_URL + "&emails=" + EmailString + "&startDate='" + startDate + "'&endDate='" + endDate + "'")
                        .then((response) => {
                            var data = response.data;
                            var userDetails = [];
                            var taskArray = [];
                            for (var index in data) {
                                var indexInArray = userDetails.indexOf(data[index].userEmail);
                                if (data[index].userTime !== 0) {
                                    if (indexInArray === -1) {
                                        getImageUsers(dispatch, data[index].userEmail, token, userDetails.length);
                                        userDetails.push(data[index].userEmail);
                                        var taskData = {};
                                        taskData["userEmail"] = data[index].userEmail;
                                        taskData["userName"] = data[index].userName;
                                        taskData["tasks"] = [data[index]];
                                        taskData["totalTime"] = parseFloat(data[index].timeString);
                                        taskData["image"] = "loader";
                                        taskArray.push(taskData);
                                    } else {
                                        taskArray[indexInArray]["totalTime"] += parseFloat(data[index].timeString);
                                        taskArray[indexInArray]["tasks"].push(data[index]);
                                    }
                                }
                            }
                            var taskSummaryArray = []
                            var taskSummaryIndexCheckArray = [];
                            if (usersList.data.value.length == 0) {
                                for (var index in data) {
                                    var indexOfSummary = taskSummaryIndexCheckArray.indexOf(data[index].name)
                                    if (indexOfSummary == -1) {
                                        taskSummaryArray.push(data[index]);
                                        taskSummaryIndexCheckArray.push(data[index].name)
                                    } else {
                                        var taskDetails = taskSummaryArray[indexOfSummary];
                                        taskDetails.userTime += data[index].userTime
                                        taskSummaryArray[indexOfSummary] = taskDetails;
                                    }
                                }

                            }
                            return dispatch({
                                type: GET_PROJECTS,
                                payload: { value: taskArray, excel: data, taskSummaryArray: taskSummaryArray }
                            })
                        }).catch(() => {


                            return dispatch({
                                type: GET_PROJECTS,
                                payload: { value: [], excel: [] }
                            })
                        });
                }).catch(() => {
                    EmailString = EmailString + ")"
                    axios.get(GET_FOLLOWERS_TIME_USING_DATE_RANGE_URL + "&emails=" + EmailString + "&startDate='" + startDate + "'&endDate='" + endDate + "'")
                        .then((response) => {
                            var data = response.data;
                            var userDetails = [];
                            var taskArray = [];
                            for (var index in data) {
                                var indexInArray = userDetails.indexOf(data[index].userEmail);
                                if (data[index].userTime !== 0) {
                                    if (indexInArray === -1) {
                                        getImageUsers(dispatch, data[index].userEmail, token, userDetails.length);
                                        userDetails.push(data[index].userEmail);
                                        var taskData = {};
                                        taskData["userEmail"] = data[index].userEmail;
                                        taskData["userName"] = data[index].userName;
                                        taskData["tasks"] = [data[index]];
                                        taskData["totalTime"] = parseFloat(data[index].userTime);
                                        taskData["image"] = "loader";
                                        taskArray.push(taskData);
                                    } else {
                                        taskArray[indexInArray]["totalTime"] += parseFloat(data[index].userTime);
                                        taskArray[indexInArray]["tasks"].push(data[index]);

                                    }
                                }
                            }
                            var taskSummaryArray = []
                            var taskSummaryIndexCheckArray = [];
                            for (var index in data) {
                                var indexOfSummary = taskSummaryIndexCheckArray.indexOf(data[index].name)
                                if (indexOfSummary == -1) {
                                    taskSummaryArray.push(data[index]);
                                    taskSummaryIndexCheckArray.push(data[index].name)
                                } else {
                                    var taskDetails = taskSummaryArray[indexOfSummary];
                                    taskDetails.userTime += data[index].userTime
                                    taskSummaryArray[indexOfSummary] = taskDetails;
                                }
                            }

                            return dispatch({
                                type: GET_PROJECTS,
                                payload: { value: taskArray, excel: data, taskSummaryArray: taskSummaryArray }
                            })
                        }).catch(() => {


                            return dispatch({
                                type: GET_PROJECTS,
                                payload: { value: [], excel: [] }
                            })
                        });
                });
        });
    }
}


export const getFollowersTimeUsingDateRangeUsingMail = (startDate, endDate, mail) => {
    return dispatch => {
        var EmailString = "('" + mail + "'"
        AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) {///me/photo/$value
            axios.get("https://graph.microsoft.com/v1.0/users/" + mail + "/directReports", { headers: { "Authorization": "Bearer " + token } })
                .then((usersList) => {
                    for (var index in usersList.data.value) {
                        EmailString = EmailString + ",'" + usersList.data.value[index].mail + "'"
                    }
                    EmailString = EmailString + ")"
                    axios.get(GET_FOLLOWERS_TIME_USING_DATE_RANGE_URL + "&emails=" + EmailString + "&startDate='" + startDate + "'&endDate='" + endDate + "'")
                        .then((response) => {
                            var data = response.data;
                            var userDetails = [];
                            var taskArray = [];
                            for (var index in data) {
                                var indexInArray = userDetails.indexOf(data[index].userEmail);
                                if (data[index].userTime !== 0) {
                                    if (indexInArray === -1) {
                                        getImageUsers(dispatch, data[index].userEmail, token, userDetails.length);
                                        userDetails.push(data[index].userEmail);
                                        var taskData = {};
                                        taskData["userEmail"] = data[index].userEmail;
                                        taskData["userName"] = data[index].userName;
                                        taskData["tasks"] = [data[index]];
                                        var spentTime = parseFloat(data[index].userTime);
                                        taskData["totalTime"] = spentTime;
                                        taskData["image"] = "loader";
                                        taskArray.push(taskData);

                                    } else {
                                        taskArray[indexInArray]["totalTime"] = parseFloat(taskArray[indexInArray]["totalTime"]) + parseFloat(data[index].userTime);
                                        taskArray[indexInArray]["tasks"].push(data[index]);

                                    }
                                }
                            }
                            var taskSummaryArray = []
                            var taskSummaryIndexCheckArray = [];
                            if (usersList.data.value.length == 0) {
                                for (var index in data) {
                                    var indexOfSummary = taskSummaryIndexCheckArray.indexOf(data[index].name)
                                    if (indexOfSummary == -1) {
                                        taskSummaryArray.push(data[index]);
                                        taskSummaryIndexCheckArray.push(data[index].name)
                                    } else {
                                        var taskDetails = taskSummaryArray[indexOfSummary];
                                        taskDetails.userTime += data[index].userTime
                                        taskSummaryArray[indexOfSummary] = taskDetails;
                                    }
                                }

                            }

                            return dispatch({
                                type: GET_PROJECTS,
                                payload: { value: taskArray, excel: data, taskSummaryArray: taskSummaryArray }
                            })
                        }).catch(() => {
                            return dispatch({
                                type: GET_PROJECTS,
                                payload: { value: [], excel: [] }
                            })
                        });
                });
        });
    }
}


const getImageUsers = (dispatch, userPrincipalName, token, index) => {

    return axios.get("https://graph.microsoft.com/v1.0/users/" + userPrincipalName + "/photo/$value", { headers: { "Authorization": "Bearer " + token }, responseType: 'arraybuffer' })
        .then((response) => {
            var base64Array = new Buffer(response.data, 'binary').toString('base64')
            //document.getElementById(userPrincipalName).src="data:image/png;base64,"+base64Array;
            return dispatch({
                type: GET_IMAGE_PROJECT_USERS,
                payload: base64Array,
                index: index
            })
            // return base64Array
        }).catch((err) => {
            return dispatch({
                type: GET_IMAGE_PROJECT_USERS,
                payload: null,
                index: index
            })
        })
}

