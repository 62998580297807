import React, { useEffect } from 'react';
import * as Actions from "../../actions/usersActions"
import { connect } from "react-redux"
import { Layout, Menu, Breadcrumb } from 'antd';
import { Tabs } from 'antd';
import "../../../assets/css/nonBoundaryActivity.scss"
import Organization from './Organization';
import Department from './Department';
import Teams from './Teams';
import Auth from "../../../services/Auth"
const { TabPane } = Tabs;
const { Content } = Layout;

const Index = ({ getUsersDropdown, getUsersDropdownSearch, user, isTeamLead, isAdmin }) => {
    useEffect(() => {
        getUsersDropdown();
    }, [])

    return (<div id="role" className="contain-fluid table-container" style={{ minWidth: "100%", minHeight: window.innerHeight - 200, backgroundColor: "#eeeeee" }}>
        <div className="card-container">
            <Tabs theme="dark" type="card" defaultActiveKey="Organization" style={{ height: window.innerHeight - 150 }}>
                {user.isAdminSuccess && <>
                    <TabPane className="scroll" tab="Organization" key="1" style={{ height: window.innerHeight - 200, overflowY: "auto" }} >
                        <Organization getUsers={(value) => {
                            if (value.length == 2) {
                                getUsersDropdown();
                            } else if (value.length > 2 & value.length % 2 == 0) {
                                getUsersDropdownSearch(value);
                            }
                        }}
                            users={user.allUsers} />
                    </TabPane>
                </>
                }
                {
                    (user.isAdminSuccess || user.isDepartmentHeadSuccess) &&
                    <TabPane className="scroll" tab="Department" key="2" style={{ height: window.innerHeight - 200, overflowY: "auto" }}>
                        <Department getUsers={(value) => {
                            if (value.length == 2) {
                                getUsersDropdown();
                            } else if (value.length > 2 & value.length % 2 == 0) {
                                getUsersDropdownSearch(value);
                            }
                        }}
                            users={user.allUsers} />
                    </TabPane>
                }
                {(user.isTeamLeadSuccess || user.isAdminSuccess) &&
                    <TabPane className="scroll" tab="Team" key="3" style={{ height: window.innerHeight - 200, overflowY: "auto" }}>
                        <Teams getUsers={(value) => {
                            if (value.length == 2) {
                                getUsersDropdown();
                            } else if (value.length > 2 & value.length % 2 == 0) {
                                getUsersDropdownSearch(value);
                            }
                        }}
                            users={user.allUsers} />
                    </TabPane>
                }
            </Tabs>
        </div>
    </div>
    );
};

const mapStateToProps = (state) => {
    return {
        user: state.usersReducers
    }
}

export default connect(mapStateToProps, Actions)(Index)