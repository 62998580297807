import React, { useEffect, useState } from 'react';
import { Tabs, Button, Popconfirm } from 'antd';
import {
    UserAddOutlined,
    DeleteFilled
} from '@ant-design/icons';
import * as Actions from "../../actions/roleActions"
import "../../../assets/css/nonBoundaryActivity.scss"
import TableContainer from './TableContainer';
import Loader from "../../../containers/Loader/Loader"
import { connect } from 'react-redux';
import AddAdmin from './AddAdmin';
import Table from './Table';

const Admin = ({ team, getAdmin, getUsersDropdown, getUsersDropdownSearch, user, adminState, deleteAdminUser }) => {
    const [addProjectManager, setAddProjectManager] = useState(false);

    useEffect(() => {
        getAdmin()
    }, [])

    const columns = [{
        Header: 'User Email',
        accessor: 'userEmail',
        key: 'userEmail',
        onFilter: (value, record) => record.name.includes(value),
        sorter: {
            compare: (a, b) => ('' + a.userEmail).localeCompare(b.userEmail),
            multiple: 3,
        }
    }, {
        Header: 'User Name',
        accessor: 'userName',
        key: 'userName',
        sorter: {
            compare: (a, b) => ('' + a.userName).localeCompare(b.userName),
            multiple: 3,
        }
    }, {
        Header: '',
        accessor: 'active',
        Cell: props => <Popconfirm placement="left" title={"Are you sure to delete this admin?"} onConfirm={() => {
            deleteAdminUser(props.row.original.id);
        }} okText="Yes" cancelText="No">
            <Button style={{ fontSize: 12 }} ><DeleteFilled style={{ color: "red" }} /></Button>
        </Popconfirm>
    }];

    return (
        <div>
            { adminState.getAdminUsersLoading &&
                <Loader />
            }
            <div className="buttonRight">
                <Button type="primary" danger onClick={() => setAddProjectManager(true)}>
                    <UserAddOutlined /> Add Admin
                        </Button>
            </div>

            {/* <TableContainer
                data={adminState.getAdminUsersSuccess}
                loading={adminState.getAdminUsersLoading}
                error={adminState.getAdminUsersError}
                title={"Project Managers"}
                columns={columns}
            /> */}

            <Table
                columns={columns}
                data={adminState.getAdminUsersSuccess}
            />
            <AddAdmin
                getUsers={(value) => {
                    if (value.length == 2) {
                        getUsersDropdown();
                    } else if (value.length > 2 & value.length % 2 == 0) {
                        getUsersDropdownSearch(value);
                    }
                }}
                adminList={adminState.getAdminUsersSuccess}
                teams={team.getAllTeamsSuccess}
                users={user.allUsers}
                visible={addProjectManager}
                onClose={() => { setAddProjectManager(false) }}
            />

        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        adminState: state.roleReducers
    }
}

export default connect(mapStateToProps, Actions)(Admin);
