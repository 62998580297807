
export const NON_TIME_BOUND_PROJECT_DETAILS = {
    "Research and Development": "Researching project related activities, such as POCs or domain areas, to build knowledge and support quality project outcomes.",  
    "Learning": "Learning for certifications or work-related training.",
    "Business Development" : "Showcasing MIT's capabilities, products, and services to potential customers.",
    "Leads Not Created": "Leads or opportunities that are not yet entered into the CRM.",
    "Leave": "Log 8 hours for a full day of leave and 4 hours for a half day of leave.",
    "Non-project - External ": "Representing MIT in external events that are not project-related, e.g., conferences or interviews." , 
    "Non-project - Internal": "Tasks that are not related to a specific department or team, e.g., Town Halls, CEO updates, or Conferences.",   
    "Support": "AMC or support activities that are not listed as projects." 
}