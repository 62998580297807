import { GET_IMAGE, GET_ROLE_ERROR, GET_ROLE_SUCCESS, GET_ROLE_LOADING, GET_IMAGE_USER, GET_ALL_USERS, EDIT_ASSIGN_USERS_ALERT, LOGOUT_USER, GET_IMAGE_ASSIGN_USER, USER_ACTIVITY, GET_IMAGE_ERROR, PROJECT_ASSIGN_USERS, PROJECT_ASSIGN_USERS_ERROR, DELETE_ASSIGN_USER_TABLE_SUCCESS, GET_USERS, EDIT_ASSIGN_USERS_ERROR, EDIT_ASSIGN_USERS, GET_ACTIVE_PROJECTS_ASSIGN, ASSIGN_USERS, GET_ASSIGN_USERS, ASSIGN_USERS_EROR, ASSIGN_USERS_UPLOAD, ASSIGN_USERS_UPLOAD_ERROR, EDIT_ASSIGN_USER_LOADER, GET_PROJECT_MANAGERS_SUCCESS, GET_PROJECT_MANAGERS_ERROR, GET_PROJECT_MANAGERS_LOADING, IS_TEAM_LEAD_ERROR, IS_TEAM_LEAD_LOADING, IS_TEAM_LEAD_SUCCESS, IS_ADMIN_LOADING, IS_ADMIN_SUCCESS, IS_ADMIN_ERROR, IS_PROJECT_MANAGER_LOADING, IS_PROJECT_MANAGER_SUCCESS, IS_PROJECT_MANAGER_ERROR, IS_DEPARTMENT_HEAD_SUCCESS, IS_DEPARTMENT_HEAD_ERROR, IS_DEPARTMENT_HEAD_LOADING, GET_ALL_USERS_LOADING, GET_USER_DETAILS, GET_USER_DETAILS_ERROR , GET_ACTIVE_PROJECTS, GET_PROJECTS } from "../types"
import { GET_PROJECT_ASSIGN_USERS_URL, GET_ROLE_URL, GET_PROJECT_MANAGERS_URL, GET_PROJECT_URL, GET_PROJECT_PARTICULAR_USER_URL, ASSIGN_USERS_URL, DELETE_ASSIGN_USER_URL, UPDATE_ASSIGN_USER_URL, ACTIVE_PROJECTS_URL, GET_ASSIGN_USERS_URL, IS_TEAM_LEAD, IS_ADMIN_URL, IS_PROJECT_MANAGER_ULR, IS_DEPARTMENT_HEAD_URL, UPDATE_USER_DETAILS_URL , ASSIGN_SINGLE_USER_URL , SEARCH_PROJECTS} from "../../constant"
import AuthContext from '../../services/Auth'
import axios from "axios"
import history from '../../history'
import React from "react"
import { message } from "antd"

export const getUsers = () => {
    return dispatch => {
        AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) {///me/photo/$value
            axios.get("https://graph.microsoft.com/v1.0/me/directReports", { headers: { "Authorization": "Bearer " + token } })
                // axios.get("https://graph.microsoft.com/v1.0/users?$top=900", { headers: { "Authorization": "Bearer " + token } })
                .then(async (data) => {
                    var userArray = [];
                    for (var index in data.data.value) {
                        var userImage = "";
                        if (data.data.value[index].mail) {
                            userImage = getImageUsers(dispatch, data.data.value[index].mail, token, index);
                        } else {
                            userImage = getImageUsers(dispatch, data.data.value[index].userPrincipalName, token, index);
                        }
                        var userJson = {
                            value: data.data.value[index].id,
                            label: data.data.value[index].displayName,
                            mail: data.data.value[index].mail,
                            userPrincipalName: data.data.value[index].userPrincipalName,
                            image: "loader"
                        }
                        userArray.push(userJson);
                    }
                    return dispatch({
                        type: GET_USERS,
                        payload: userArray
                    })
                }).catch(err => {
                    return dispatch({
                        type: GET_USERS,
                        payload: []
                    })
                });
        }
        )
    }
}
//me/directReports
export const getUsersUsingMail = (mail) => {
    return dispatch => {
        AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) {///me/photo/$value
            ///me/photo/$value
            axios.get("https://graph.microsoft.com/v1.0/users/" + mail + "/directReports", { headers: { "Authorization": "Bearer " + token } })

                //     .then((data) => {
                //     });
                // axios.get("https://graph.microsoft.com/v1.0/users", { headers: { "Authorization": "Bearer " + token } })
                .then(async (data) => {
                    var userArray = [];
                    for (var index in data.data.value) {
                        var userImage = "";
                        if (data.data.value[index].mail) {
                            userImage = getImageUsers(dispatch, data.data.value[index].mail, token, index);
                        } else {
                            userImage = getImageUsers(dispatch, data.data.value[index].userPrincipalName, token, index);
                        }
                        var userJson = {
                            value: data.data.value[index].id,
                            label: data.data.value[index].displayName,
                            mail: data.data.value[index].mail,
                            userPrincipalName: data.data.value[index].userPrincipalName,
                            image: "loader"
                        }
                        userArray.push(userJson);
                    }
                    return dispatch({
                        type: GET_USERS,
                        payload: userArray
                    })
                }).catch(err => {
                    return dispatch({
                        type: GET_USERS,
                        payload: []
                    })
                });
            // axios.get("https://graph.microsoft.com/v1.0/users/sujeewae@millenniumitesp.com/directReports", { headers: { "Authorization": "Bearer " + token } })
            // .then(async(data)=>{
            // }).catch(err => {

            // });
        }
        )
    }
}
export const getUsersDropdown = () => {
    return dispatch => {
        getUsersLoading(dispatch, true);
        AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) {///me/photo/$value
            ///me/photo/$value
            //axios.get("https://graph.microsoft.com/v1.0/me/directReports", { headers: { "Authorization": "Bearer " + token } })
            //     .then((data) => {
            //     });
            //console.log(token);
            console.log(error);
            axios.get("https://graph.microsoft.com/v1.0/users?$select=id,userPrincipalName,displayName&$top=999", { headers: { "Authorization": "Bearer " + token } })
                .then((data) => {
                    var userArray = [];
                    for (var index in data.data.value) {
                        var userJson = {
                            value: data.data.value[index].id,
                            label: data.data.value[index].displayName + "................." + data.data.value[index].userPrincipalName,
                            mail: data.data.value[index].userPrincipalName
                        }
                        userArray.push(userJson);
                    }
                    return dispatch({

                        type: GET_ALL_USERS,
                        payload: userArray

                    })
                }).catch(err => {
                });
        }
        )
    }
}


export const getDirectReportUsersDropdown = () => {
    return dispatch => {
        getUsersLoading(dispatch, true);
        AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) {///me/photo/$value
            ///me/photo/$value
            //axios.get("https://graph.microsoft.com/v1.0/me/directReports", { headers: { "Authorization": "Bearer " + token } })
            //     .then((data) => {
            //     });
            axios.get("https://graph.microsoft.com/v1.0/me/directReports?$select=id,userPrincipalName,displayName&$top=999", { headers: { "Authorization": "Bearer " + token } })
                .then((data) => {
                    var userArray = [];
                    for (var index in data.data.value) {
                        var userJson = {
                            value: data.data.value[index].id,
                            label: data.data.value[index].displayName + "................." + data.data.value[index].userPrincipalName,
                            mail: data.data.value[index].userPrincipalName
                        }
                        userArray.push(userJson);
                    }
                    return dispatch({
                        type: GET_ALL_USERS,
                        payload: userArray
                    })
                }).catch(err => {
                });
        }
        )
    }
}

export const getUsersDropdownSearch = (name) => {
    return dispatch => {
        getUsersLoading(dispatch, true);
        AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) {///me/photo/$value
            ///me/photo/$value
            //axios.get("https://graph.microsoft.com/v1.0/me/directReports", { headers: { "Authorization": "Bearer " + token } })
            //     .then((data) => {
            //     });
            axios.get("https://graph.microsoft.com/v1.0/users?$select=id,userPrincipalName,displayName&$top=999&$filter=startswith(displayName,'" + name + "')", { headers: { "Authorization": "Bearer " + token } })
                //axios.get("https://graph.microsoft.com/v1.0/me/directReports", { headers: { "Authorization": "Bearer " + token } })
                .then((data) => {
                    var userArray = [];
                    for (var index in data.data.value) {
                        var userJson = {
                            value: data.data.value[index].id,
                            label: data.data.value[index].displayName + "................." + data.data.value[index].userPrincipalName,
                            mail: data.data.value[index].userPrincipalName
                        }
                        userArray.push(userJson);
                    }
                    return dispatch({
                        type: GET_ALL_USERS,
                        payload: userArray
                    })
                }).catch(err => {
                });
        }
        )
    }
}

export const getProjectSearch = (searchText) => {
    const payload = {
        "searchKeyword" : searchText
    };
    return async (dispatch) => {
        try{
        const response = axios.post(SEARCH_PROJECTS , payload , {
            headers: {
                'Content-Type': 'application/json',
              },
        }).then((data) => {
            var projectArray = [];
            for (var index in data.data) {
                var jsonValue = {
                    value: data.data[index].projectId,
                    label: data.data[index].projectName,
                    code: data.data[index].projectCode
                }
                projectArray.push(jsonValue);
            }
        dispatch({
            type: GET_ACTIVE_PROJECTS_ASSIGN,
            payload: projectArray,
            projects: data.data
            })
        })
    }catch (error){
        
        }
    }
}

export const getDirectReportUsersDropdownSearch = (name) => {
    return dispatch => {
        getUsersLoading(dispatch, true);
        AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) {///me/photo/$value
            ///me/photo/$value
            //axios.get("https://graph.microsoft.com/v1.0/me/directReports", { headers: { "Authorization": "Bearer " + token } })
            //     .then((data) => {
            //     });
            axios.get("https://graph.microsoft.com/v1.0/me/directReports?$select=id,userPrincipalName,displayName&$top=999&$filter=startswith(displayName,'" + name + "')", { headers: { "Authorization": "Bearer " + token } })
                //axios.get("https://graph.microsoft.com/v1.0/me/directReports", { headers: { "Authorization": "Bearer " + token } })
                .then((data) => {
                    var userArray = [];
                    for (var index in data.data.value) {
                        var userJson = {
                            value: data.data.value[index].id,
                            label: data.data.value[index].displayName + "................." + data.data.value[index].userPrincipalName,
                            mail: data.data.value[index].userPrincipalName
                        }
                        userArray.push(userJson);
                    }
                    return dispatch({
                        type: GET_ALL_USERS,
                        payload: userArray
                    })
                }).catch(err => {
                });
        }
        )
    }
}

const getUsersLoading = (dispatch, loading) => dispatch({ type: GET_ALL_USERS_LOADING, loading })

//GET_ASSIGN_USERS_URL
export const getAssignUsers = () => {
    return dispatch => {
        var EmailString = "('" + AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.unique_name + "'"
        AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) {///me/photo/$value
            axios.get("https://graph.microsoft.com/v1.0/me/directReports", { headers: { "Authorization": "Bearer " + token } })
                .then((usersList) => {
                    for (var index in usersList.data.value) {
                        EmailString = EmailString + ",'" + usersList.data.value[index].mail + "'"
                    }
                    EmailString = EmailString + ")"
                    axios.get(GET_ASSIGN_USERS_URL + "?user=" + EmailString,
                        {
                            headers: {
                                "x-functions-key": "0XHQ3lN5cB5/r/NQl3rUaImokTZtQYYy/wIuzaQBiOxXgVaXHYJx5Q=="
                            }
                        })
                        .then((data) => {
                            return dispatch({
                                type: GET_ASSIGN_USERS,
                                payload: data.data
                            })
                        }).catch((data) => {
                        });
                });
        });
    }
}

export const getActiveProjects = (pageId) => {
    return dispatch => {
        var EmailString = "('" + AuthContext.getCachedUser().userName + "'"
        // AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) {///me/photo/$value
        //     axios.get("https://graph.microsoft.com/v1.0/me/directReports", { headers: { "Authorization": "Bearer " + token } })
               // .then((usersList) => {
                    // for (var index in usersList.data.value) {
                    //     EmailString = EmailString + ",'" + usersList.data.value[index].mail + "'"
                    // }
                    EmailString = EmailString + ")"
                    axios.post(ACTIVE_PROJECTS_URL, JSON.stringify({
                        emails: EmailString,
                        page: pageId
                    })
                    )
                        .then((data) => {
                            var projectArray = [];
                            for (var index in data.data) {
                                var jsonValue = {
                                    value: data.data[index].projectId,
                                    label: data.data[index].projectName,
                                    code: data.data[index].projectCode
                                }
                                projectArray.push(jsonValue);
                            }
                            return dispatch({
                                type: GET_ACTIVE_PROJECTS_ASSIGN,
                                payload: projectArray,
                                projects: data.data
                            })
                        }).catch((data) => {
                        });
               // });
       // });
    }
}

export const AssignUsers = (projectDetails, usersArray, time, role) => {
    return dispatch => {
        for (var index in usersArray) {
            var userId = usersArray[index].value;
            var projectId = projectDetails.value;
            var userName = usersArray[index].label.split(".................")[0];
            var userEmail = usersArray[index].mail;
            // fetch(ASSIGN_USERS_URL + "&userEmail='" + userEmail + "'&userName='" + userName + "'&projectId=" + projectId + "&userId='" + userId + "'&userTime=" + time + "&assignedBy='" + AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.unique_name + "'")
            axios.post(ASSIGN_SINGLE_USER_URL,
                JSON.stringify({
                    "userEmail": userEmail,
                    "userName": userName,
                    "projectId": projectId,
                    "userId": userId,
                    "userTime": time,
                    "role": role,
                    "assignedBy": AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.unique_name
                }))
                .then((data) => {
                    if (data.status == 200) {
                        return dispatch({
                            type: ASSIGN_USERS,
                            payload: true
                        }
                        )
                    } else {
                        return dispatch({
                            type: ASSIGN_USERS_EROR,
                            payload: `${userName} has been already added to project`
                        });
                    }
                }).catch((err, errmessage) => {
                    return dispatch({
                        type: ASSIGN_USERS_EROR,
                        payload: `${userName} has been already added to project`
                    });
                })
        }
    }
}

//GET_PROJECT_ASSIGN_USERS_URL
export const ProjectAssignUsers = (id) => {
    return dispatch => {
        AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) {///me/photo/$value
            axios.get(`${GET_PROJECT_URL}&id=${id}`)
                .then((projectList) => {
                    var projectData = projectList.data;
                    var projectDataArray = [{ name: "Current Hours", value: parseFloat(projectData.currentHours) }]
                    projectDataArray.push({ name: "Available Hours", value: projectData.expectedHours - parseFloat(projectData.currentHours) })
                    projectData["graph"] = projectDataArray
                    axios.get(GET_PROJECT_ASSIGN_USERS_URL + "&id=" + id)
                        .then(async (responsedata) => {
                            var data = responsedata.data;
                            var projectAssignData = data;
                            // .sort((a, b) => {
                            //     return new Date(b.date).getTime() - new Date(a.date).getTime();
                            // });
                            var areaChartData = {};


                            var projectAssignFilterObject = {};
                            for (var index in data) {
                                if (projectAssignFilterObject[data[index].userId] == null) {
                                    projectAssignFilterObject[data[index].userId] = { userTime: parseFloat(data[index].userTime), userName: data[index].userName, userId: data[index].userId, date: data[index].date, currentTime: parseFloat(data[index].currentTime), userEmail: data[index].userEmail, image: "loader" }
                                } else {
                                    projectAssignFilterObject[data[index].userId].userTime += parseFloat(data[index].userTime);
                                }
                                for (var timeIndex in data[index].timeList) {
                                    var datevalue = new Date(data[index].timeList[timeIndex]["date"]);
                                    if (areaChartData[datevalue.toLocaleDateString()] == null) {
                                        areaChartData[datevalue.toLocaleDateString()] = { date: datevalue.toLocaleDateString(), time: parseFloat(data[index].timeList[timeIndex]["time"]) };
                                    } else {
                                        areaChartData[datevalue.toLocaleDateString()].time += parseFloat(data[index].timeList[timeIndex]["time"]);
                                    }
                                }
                            }
                            areaChartData = Object.values(areaChartData)
                            areaChartData = areaChartData.sort((a, b) => {
                                return new Date(b.date).getTime() - new Date(a.date).getTime();
                            });
                            var projectAssignFilterArray = [];
                            var indexInt = 0;
                            for (var index in projectAssignFilterObject) {
                                projectAssignFilterArray.push(projectAssignFilterObject[index]);
                                getImageUsersAssign(dispatch, projectAssignFilterObject[index].userEmail, token, indexInt);
                                indexInt += 1;
                            }
                            return dispatch({
                                type: PROJECT_ASSIGN_USERS,
                                payload: { filterData: projectAssignFilterArray, data: projectAssignData, project: projectData, areaChartData: areaChartData }
                            })

                        }).catch((err, errmessage) => {
                            return dispatch({
                                type: PROJECT_ASSIGN_USERS_ERROR,
                                payload: false
                            });
                        })
                })
        });
    }
}



export const ProjectsTimeForUser = (id) => {
    return dispatch => {
        axios.get(GET_PROJECT_PARTICULAR_USER_URL + "&id='" + id + "'")
            .then((response) => {
                var data = response.data;
                var projectAssignData = data;
                var projectAssignFilterObject = {};
                for (var index in data.data) {
                    if (projectAssignFilterObject[data[index].date] == null) {
                        projectAssignFilterObject[data[index].date] = { projectName: data[index].projectName, name: data[index].projectName, userTime: parseFloat(data[index].userTime), userName: data[index].userName, userId: data[index].userId, date: data[index].date, currentTime: parseFloat(data[index].currentTime), userEmail: data[index].userEmail }
                    } else {
                        projectAssignFilterObject[data[index].date].userTime += parseFloat(data[index].userTime);
                    }
                }
                var projectAssignFilterArray = [];
                for (var index in projectAssignFilterObject) {
                    projectAssignFilterArray.push(projectAssignFilterObject[index])
                }
                return dispatch({
                    type: PROJECT_ASSIGN_USERS,
                    payload: { filterData: projectAssignFilterArray, data: projectAssignData }
                })

            }).catch((err, errmessage) => {
                return dispatch({
                    type: PROJECT_ASSIGN_USERS_ERROR,
                    payload: false
                });
            })
    }
}

export const UserActivity = (id) => {
    return dispatch => {
        axios.get(GET_PROJECT_PARTICULAR_USER_URL + "&id='" + id + "'")
            .then((responsedata) => {
                var data = responsedata.data;
                var projectAssignData = data;
                var changedDateArray = [];
                var projectAssignFilterObject = {};
                var dataArray = [];
                for (var index in data) {
                    var dataCurrent = data[index];
                    var dateChanged = new Date(dataCurrent.date);
                    dateChanged.setMonth(dateChanged.getMonth());
                    var dateString = (new Date(data[index].date).getMonth() + 1) + "/" + new Date(data[index].date).getDate() + "/" + new Date(data[index].date).getFullYear();
                    if (projectAssignFilterObject[data[index].date] == null) {
                        projectAssignFilterObject[data[index].date] = { projectName: data[index].projectName, name: data[index].projectName, userTime: data[index].userTime, userName: data[index].userName, userId: data[index].userId, date: dateString, currentTime: parseInt(data[index].currentTime), userEmail: data[index].userEmail }
                    } else {
                        projectAssignFilterObject[data[index].date].userTime += parseInt(data[index].userTime);
                    }
                    var projectDateChanged = data[index];
                    projectDateChanged.date = dateString;
                    changedDateArray.push(projectDateChanged);

                    dataCurrent.date = dateChanged.toString();
                    dataArray.push(dataCurrent);
                }

                var projectAssignFilterArray = [];
                for (var index in projectAssignFilterObject) {
                    projectAssignFilterArray.push(projectAssignFilterObject[index])
                }
                return dispatch({
                    type: USER_ACTIVITY,
                    payload: { filterData: projectAssignFilterArray, data: dataArray }
                })

            }).catch((err, errmessage) => {
                return dispatch({
                    type: PROJECT_ASSIGN_USERS_ERROR,
                    payload: false
                });
            })
    }
}

export const EditAssignAlert = () => {
    return dispatch => {
        dispatch({ type: EDIT_ASSIGN_USERS_ALERT });
    }
}

export const EditAssignUsers = (time, id, index) => {
    return dispatch => {
        dispatch({ type: EDIT_ASSIGN_USER_LOADER });
        axios.post(UPDATE_ASSIGN_USER_URL + "&userTime=" + time + "&id=" + id)
            .then((data) => {
                return dispatch({
                    type: EDIT_ASSIGN_USERS,
                    payload: { index: index, time: time }
                })
            }).catch((error) => {
                if (error.response) {
                    return dispatch({
                        type: EDIT_ASSIGN_USERS_ERROR,
                        error: error.response.error
                    });
                } else {
                    return dispatch({
                        type: EDIT_ASSIGN_USERS_ERROR,
                        error: "Something went wrong"
                    });
                }
            })
    }
}

export const AssignUsersUpload = (projectObjectsArray) => {
    return async dispatch => {
        if (projectObjectsArray && projectObjectsArray.projectAssigns.length > 0) {
            try {
                await axios.post(ASSIGN_USERS_URL , projectObjectsArray);
                // Dispatch success action
                dispatch({
                    type: ASSIGN_USERS_UPLOAD,
                    payload: projectObjectsArray
                });
            } catch (postError) {
                // Dispatch error action
                dispatch({
                    type: ASSIGN_USERS_UPLOAD_ERROR,
                    payload: projectObjectsArray,
                    status: false
                });
            }
        } else {
            dispatch({
                type: ASSIGN_USERS_UPLOAD_ERROR,
                payload: projectObjectsArray,
                status: 2
            });
        }
    };
};


export const getImage = () => {
    return dispatch => {
        AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) {///me/photo/$value
            axios.get("https://graph.microsoft.com/v1.0/me/photo/$value", { headers: { "Authorization": "Bearer " + token }, responseType: 'arraybuffer' })
                .then((response) => {
                    var base64Array = new Buffer(response.data, 'binary').toString('base64')

                    return dispatch({
                        type: GET_IMAGE,
                        payload: base64Array
                    });
                }).catch((err) => {
                    return dispatch({
                        type: GET_IMAGE_ERROR,
                        payload: null
                    });
                })
        })
    }
}

export const getUserDetails = () => {
    return dispatch => {
        AuthContext.acquireToken("https://graph.microsoft.com", function (error, token) { // me?$select
            axios.get("https://graph.microsoft.com/v1.0/me?$select=displayName,userPrincipalName,department,jobTitle,level&$expand=manager($select=id,displayName)", { headers: { "Authorization": "Bearer " + token } })
                .then((response) => {
                    var userDetails = {
                        userId: response?.data?.id,
                        userName: response?.data?.displayName,
                        userEmail: response?.data?.userPrincipalName,
                        department: response?.data?.department,
                        designation: response?.data?.jobTitle,
                        employeeLevel: response?.data?.level,
                        manager: response?.data?.manager?.displayName
                    }
                    dispatch(updateUserDetails(userDetails));

                    return dispatch({
                        type: GET_USER_DETAILS,
                        payload: userDetails
                    });
                }).catch((err) => {
                    return dispatch({
                        type: GET_USER_DETAILS_ERROR,
                        payload: null
                    });
                })
        })
    }
}

export const updateUserDetails = (userDetails) => dispatch => {
    axios.post(UPDATE_USER_DETAILS_URL, JSON.stringify(userDetails))
        .then(response => {
            message.success({ content: 'Successfully synced profile details!' });
        }).catch(error => {
            //message.success({ content: 'Failed to sync profile details!' });
        })
}

const getImageUsersAssign = (dispatch, userPrincipalName, token, index) => {
    return axios.get("https://graph.microsoft.com/v1.0/users/" + userPrincipalName + "/photo/$value", { headers: { "Authorization": "Bearer " + token }, responseType: 'arraybuffer' })
        .then((response) => {
            var base64Array = new Buffer(response.data, 'binary').toString('base64')
            //document.getElementById(userPrincipalName).src="data:image/png;base64,"+base64Array;
            return dispatch({
                type: GET_IMAGE_ASSIGN_USER,
                payload: base64Array,
                index: index
            });
            // return base64Array
        }).catch((err) => {
            return dispatch({
                type: GET_IMAGE_ASSIGN_USER,
                payload: null,
                index: index
            });
            // return dispatch({
            //     type: GET_IMAGE_ERROR,
            //     payload: null
            // });
        })


}

const getImageUsers = (dispatch, userPrincipalName, token, index) => {

    return axios.get("https://graph.microsoft.com/v1.0/users/" + userPrincipalName + "/photo/$value", { headers: { "Authorization": "Bearer " + token }, responseType: 'arraybuffer' })
        .then((response) => {
            var base64Array = new Buffer(response.data, 'binary').toString('base64')
            //document.getElementById(userPrincipalName).src="data:image/png;base64,"+base64Array;
            return dispatch({
                type: GET_IMAGE_USER,
                payload: base64Array,
                index: index
            });
            // return base64Array
        }).catch((err) => {
            return dispatch({
                type: GET_IMAGE_USER,
                payload: null,
                index: index
            });
            // return dispatch({
            //     type: GET_IMAGE_ERROR,
            //     payload: null
            // });
        })


}

export const deleteUserAssign = (id, index, type) => {
    return dispatch => {
        var confirmation = window.confirm("Are you sure to update assign user state")
        if (confirmation) {
            axios.get(`${DELETE_ASSIGN_USER_URL}&id=${id}&type=${type}`)
                .then((value) => {

                    return dispatch({
                        type: DELETE_ASSIGN_USER_TABLE_SUCCESS,
                        payload: { index: index, type: type }
                    })

                })
        }

    }
}


export function logoutUser() {
    return dispatch => {
        AuthContext.logOut()
        dispatch({
            type: LOGOUT_USER,
            payload: "success"
        })
    }
}

export const isTeamLead = (id) => dispatch => {
    isTeamLeadLoading(dispatch, true);
    axios.get(IS_TEAM_LEAD + "&id=" + id)
        .then(response => {
            isTeamLeadSuccess(dispatch, response.data)
        }).catch(error => {
            isTeamLeadError(dispatch, error)
        })
}

const isTeamLeadSuccess = (dispatch, data) => dispatch({ type: IS_TEAM_LEAD_SUCCESS, data })
const isTeamLeadLoading = (dispatch, loading) => dispatch({ type: IS_TEAM_LEAD_LOADING, loading })
const isTeamLeadError = (dispatch, error) => dispatch({ type: IS_TEAM_LEAD_ERROR, error })

export const isAdmin = (id) => dispatch => {
    isAdminLoading(dispatch, true);
    axios.get(IS_ADMIN_URL + "&id=" + id)
        .then(response => {
            isAdminSuccess(dispatch, response.data)
        }).catch(error => {
            isAdminError(dispatch, error)
        })
}

const isAdminLoading = (dispatch, loading) => dispatch({ type: IS_ADMIN_LOADING, loading })
const isAdminSuccess = (dispatch, data) => dispatch({ type: IS_ADMIN_SUCCESS, data })
const isAdminError = (dispatch, error) => dispatch({ type: IS_ADMIN_ERROR, error })

export const isProjectManager = (id) => dispatch => {
    isProjectManagerLoading(dispatch, true);
    axios.get(IS_PROJECT_MANAGER_ULR + "&id=" + id)
        .then(response => {
            isProjectManagerSuccess(dispatch, response.data)
        }).catch(error => {
            isProjectManagerError(dispatch, error)
        })
}

const isProjectManagerLoading = (dispatch, loading) => dispatch({ type: IS_PROJECT_MANAGER_LOADING, loading })
const isProjectManagerSuccess = (dispatch, data) => dispatch({ type: IS_PROJECT_MANAGER_SUCCESS, data })
const isProjectManagerError = (dispatch, error) => dispatch({ type: IS_PROJECT_MANAGER_ERROR, error })

export const isDepartmentHead = (id) => dispatch => {
    isDepartmentHeadLoading(dispatch, true);
    axios.get(IS_DEPARTMENT_HEAD_URL + "&id=" + id)
        .then(response => {
            isDepartmentHeadSuccess(dispatch, response.data);
        }).catch(error => {
            isDepartmentHeadError(dispatch, error);
        })
}

const isDepartmentHeadLoading = (dispatch, loading) => dispatch({ type: IS_DEPARTMENT_HEAD_LOADING, loading })
const isDepartmentHeadSuccess = (dispatch, data) => dispatch({ type: IS_DEPARTMENT_HEAD_SUCCESS, data })
const isDepartmentHeadError = (dispatch, error) => dispatch({ type: IS_DEPARTMENT_HEAD_ERROR, error })



export const getRole = (id) => dispatch => {
    getRoleLoading(dispatch, true);
    axios.get(GET_ROLE_URL, { params: { id: id } })
        .then(data => {
            getRoleSuccess(dispatch, data.data);
        }).catch(error => {
            getRoleError(dispatch, error)
        })
}

const getRoleSuccess = (dispatch, data) => dispatch({ type: GET_ROLE_SUCCESS, data })
const getRoleError = (dispatch, error) => dispatch({ type: GET_ROLE_ERROR, error })
const getRoleLoading = (dispatch, loading) => dispatch({ type: GET_ROLE_LOADING, loading })