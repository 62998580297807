import React, { useState, useEffect } from 'react';
import { Button, Popconfirm } from 'antd';
import "../../../assets/css/nonBoundaryActivity.scss"
import {
    UserAddOutlined,
    DeleteFilled
} from '@ant-design/icons';

import AddDepartmentHeadRole from './AddDepartmentHeadRole';
import * as Actions from "../../actions/roleActions"
import Loader from "../../../containers/Loader/Loader"
import { connect } from 'react-redux';
import TableContainer from './TableContainer';
import Table from './Table';


const DepartmentHead = ({ team, user, getUsersDropdown, getDepartmentHeads, deleteDepartmentHead, departmentHeadState, getUsersDropdownSearch, getDepartment }) => {
    const [addDepartmentHead, setAddDepartmentHead] = useState(false);

    useEffect(() => {
        getDepartmentHeads();
        getDepartment('');
    }, [])

    const columns = [{
        Header: 'User Email',
        accessor: 'userEmail',
        key: 'userEmail',
        onFilter: (value, record) => record.name.includes(value),
        sorter: {
            compare: (a, b) => ('' + a.userEmail).localeCompare(b.userEmail),
            multiple: 3,
        }
    }, {
        Header: 'User Name',
        accessor: 'userName',
        key: 'userName',
        sorter: {
            compare: (a, b) => ('' + a.userName).localeCompare(b.userName),
            multiple: 3,
        }
    }, {
        Header: 'Department',
        accessor: 'department',
        key: 'department',
        sorter: {
            compare: (a, b) => ('' + a.department).localeCompare(b.department),
            multiple: 3,
        }
    }, {
        Header: '',
        accessor: 'active',
        Cell: props => <Popconfirm placement="left" title={"Are you sure to delete this department head?"} onConfirm={() => {
            deleteDepartmentHead(props.row.original.id);
        }} okText="Yes" cancelText="No">
            <Button style={{ fontSize: 12 }} ><DeleteFilled style={{ color: "red" }} /></Button>
        </Popconfirm>
    }];

    return (
        <div>
            {departmentHeadState.getDepartmentHeadsLoading &&
                <Loader />
            }
            <div className="buttonRight">
                <Button type="primary" danger onClick={() => setAddDepartmentHead(true)}>
                    <UserAddOutlined /> Add Department Head
                </Button>
            </div>
            {/* {departmentHeadState.getDepartmentHeadsSuccess &&
                <TableContainer
                    data={departmentHeadState.getDepartmentHeadsSuccess}
                    loading={departmentHeadState.getDepartmentHeadsLoading}
                    error={departmentHeadState.getDepartmentHeadsError}
                    title={"Department Heads"}
                    columns={columns}
                />
            } */}

            <Table
                columns={columns}
                data={departmentHeadState.getDepartmentHeadsSuccess}
                active={() => { alert("active") }}
                deActive={() => { alert("deActive") }}
            />
            <AddDepartmentHeadRole
                getDepartments={(value) => {
                    if (value.length > 2 & value.length % 2 == 0) {
                        getDepartment(value);
                    }
                }}
                departments={departmentHeadState.getDepartmentsSuccess}
                getUsers={(value) => {
                    if (value.length == 2) {
                        getUsersDropdown();
                    } else if (value.length > 2 & value.length % 2 == 0) {
                        getUsersDropdownSearch(value);
                    }
                }}
                teams={team.getAllTeamsSuccess}
                users={user.allUsers}
                departmentHeadList={departmentHeadState.getDepartmentHeadsSuccess}
                visible={addDepartmentHead}
                onClose={() => { setAddDepartmentHead(false) }}
            />
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        departmentHeadState: state.roleReducers
    }
}


export default connect(mapStateToProps, Actions)(DepartmentHead);