import React, { useState, useEffect } from 'react';
import Nav from "../../../containers/Navbar/Navbar"
import { connect } from 'react-redux';
import * as Actions from "../../actions/supportActions"
import ReactTable from 'react-table'
// import 'react-table/react-table.css'
import NavbarTop from "../../../containers/Navbar/NavbarTop"
import { Link } from 'react-router-dom'
import { AreaChart, Area, PieChart, Pie, Cell, CartesianGrid, XAxis, YAxis, Tooltip } from "recharts"
import SupportTask from "./SupportTasks"
import ProjectTasksUser from "../../../containers/Tasks/ProjectTasksUser"
import Loader from "../../../containers/Loader/Loader"
import SupportTicketUser from './SupportTicketUser';

const HistorySupport = ({ getSupport, match, supportDetails }) => {
    const [timeView, setTimeView] = useState(false);
    const [supportTicket, setSupportTicket] = useState("");


    useEffect(() => {

        getSupport(match.params.id);

    }, [])


    // static getDerivedStateFromProps(props, state) {
    //     if (props.userAssign.projectAssignUsersAll && props.userAssign.project && props.userAssign.projectAssignUsers) {
    //         return {
    //             loader: false
    //         }
    //     }
    // }
    const subColumns = [

        {
            Header: 'Start Date',
            filterable: true, accessor: 'date',
            Cell: props => {
                var date = new Date(props.original.date);
                return (<span>{date.getFullYear() + "/ " + (date.getMonth() + 1) + "/ " + date.getDate()}</span>)
            }
        },
        {
            Header: 'Current Hours',
            filterable: true, accessor: 'userTime',
            Cell: props => {
                return (<span>{props.original.userTime + "H"}</span>)
            }
        }
    ]
    const columns = [
        {
            Header: 'Employee Name', accessor: 'userName',
            filterable: true,
            Cell: props => {
                return (<span style={{ color: "black", fontWeight: "800", paddingLeft: 10 }}>{props.original.userName}</span>)
            }
        },
        {
            Header: 'User Email',
            filterable: true, accessor: 'userEmail'
        },
        {
            Header: 'Current Hours',
            filterable: true, accessor: 'userTime',
            Cell: props => {
                return (<span>{props.original.userTime + "H"}</span>)
            }
        },
        {
            Header: 'Expected Hours',
            filterable: true, accessor: 'currentTime',
            Cell: props => {
                return (<span>{props.original.currentTime + "H"}</span>)
            }
        },
        {
            Header: 'Start Date',
            filterable: true, accessor: 'date',
            Cell: props => {
                var date = new Date(props.original.date);
                return (<span>{date.getFullYear() + "/ " + (date.getMonth() + 1) + "/ " + date.getDate()}</span>)
            }
        }
    ]

    return (
        <>
            {/* {
            this.state.loader &&
            <Loader />
        } */}
            <div className="table-container">
                <div className="row project_card_details" >
                    <div className="col-md-12">
                        {/* <h2>{this.state.projectName}</h2> */}
                    </div>
                    <div className="col-md-12 row project_card_nav" >
                        <div className="col-md-6 pc_tab_content" >
                            <div onClick={() => { setTimeView(false); setSupportTicket("") }} className={(timeView ? "pc_tab" : "pc_tab_active")}>
                                Percentage
                            </div>
                            {supportTicket != "" &&
                                <div onClick={() => { setTimeView(true); setSupportTicket("") }} className={(timeView ? "pc_tab_active" : "pc_tab")}>
                                    TimeLine
                                </div>
                            }
                        </div>
                    </div>
                    {supportDetails ?
                        (timeView ?
                            <SupportTicketUser timeView={timeView}
                                supportTicket={supportTicket}
                            /> :
                            <SupportTask
                                timeView={timeView}
                                setTicket={(ticket) => { setSupportTicket(ticket); setTimeView(true) }}
                                supportDetails={supportDetails}
                            />)
                        :
                        <div>no data</div>
                    }
                </div>

            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        supportDetails: state.support.getSupport
    }
}

export default connect(mapStateToProps, Actions)(HistorySupport);