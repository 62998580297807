import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as usersActions from "../../actions/usersActions"
import ReactTable from 'react-table'
// import 'react-table/react-table.css'
import { Link } from 'react-router-dom'
import { PieChart, Pie } from 'recharts';
import UserDetails from '../../../containers/HistoryUsers/UserDetails'
class HistoryUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            imageURL: "",
            navSide: true,
            modalView: false,
            userModalView: false,
            projectIndexId: 0,
            projectName: "",
            users: []
        }
    }

    componentDidMount() {
        const { match: { params: { id, name } } } = this.props;
        this.setState({ projectName: name })
        this.props.ProjectsTimeForUser(id);
    }

    render() {

        const columns = [
            {
                Header: 'Date', accessor: 'date',
                filterable: true,
                Cell: props => {
                    var date = new Date(props.original.date);
                    return (<span style={{ color: "black", fontWeight: "800", paddingLeft: 10 }}>{date.toLocaleDateString()}</span>)
                }
            },
            {
                Header: 'Total Hours',
                filterable: true, accessor: 'currentTime',
                Cell: props => {
                    return (<span>{props.original.userTime + "H"}</span>)
                },
                getProps: (state, rowInfo, column) => {
                    return {
                        style: {
                            textAlign: "center"
                        },
                    };
                }
            }
        ]

        return (
            <>

                <div className="table-container">
                    <ReactTable
                        data={(this.props.userAssign == null ? [] : this.props.userAssign.projectAssignUsers)}
                        columns={columns}
                        getTrProps={this.rowFunction}
                        // pivotBy={["userName"]}
                        defaultFilterMethod={(filter, row) =>
                            String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())}
                        defaultSorted={[{
                            id: "date",
                            desc: false
                        }
                        ]}
                        defaultSortMethod={(datePreview, dateNext) => {
                            var datePreviewValue = new Date(datePreview);
                            var dateNextValue = new Date(dateNext);
                            if (datePreviewValue < dateNextValue) {
                                return 1;
                            } else if (datePreviewValue > dateNextValue) {
                                return -1;
                            }
                            return 0;

                        }}
                        defaultPageSize={10}
                        sortable='true'
                        SubComponent={row => {
                            return (
                                <UserDetails userAssign={this.props.userAssign.projectAssignUsersAll.filter(rowValue => rowValue.date == row.original.date)} />

                            );
                        }}
                    />
                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        userAssign: state.usersReducers
    }
}

export default connect(mapStateToProps, usersActions)(HistoryUser);