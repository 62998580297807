import React, { Component } from 'react';
import Nav from "../../../containers/Navbar/Navbar";
import NavbarTop from "../../../containers/Navbar/NavbarTop";
import moment from 'moment'
import { connect } from 'react-redux';
import * as projectActions from "../../actions/projectsActions"
import ReactTable from 'react-table'
import PhotoshopPicker from "react-color"
import Loader from "../../../containers/Loader/Loader"
import TextArea from 'antd/lib/input/TextArea';
import AuthContext from "../../../services/Auth"

// import 'react-table/react-table.css'


class EditProject extends Component {
    constructor(props) {
        super(props);
        this.state = {
            project: null,
            projectCode: "",
            value: "",
            imageURL: "",
            modalView: false,
            navSide: true,
            projectName: "",
            startDate: "",
            endDate: "",
            currentHours: 0,
            expectedHours: 0,
            message: "",
            image: "",
            imageType: "",
            color: "#000000",
            pickerView: false,
            imageSize: 0,
            imageError: "",
            error: {},
            projectNameError: "",
            messageError: "",
            startDateError: "",
            endDateError: "",
            reason: "",
            reasonError: "",
            loader: true,
            expectedHoursError: ""

        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.project != prevState.project) {

            return {
                project: nextProps.project,
                projectCode: nextProps.project.code,
                projectName: nextProps.project.projectName,
                color: `#${nextProps.project.color}`,
                loader: false,
                currentHours: nextProps.project.currentHours,
                endDate: nextProps.project.endDate,
                expectedHours: nextProps.project.expectedHours,
                imageURL: nextProps.project.image,
                message: nextProps.project.message,
                projectId: nextProps.project.projectId,
                startDate: nextProps.project.startTime,
            }
        }
        return null;
    }

    submitForm = () => {
        var validationStatus = true;
        var stringRegex = new RegExp("(^[a-z A-Z0-9@_./-]*)$");
        //Project Name Validation
        if (this.state.projectName.trim() == "") {
            this.setState({ projectNameError: "Enter Project Name" });
            validationStatus = false;
        } else if (!stringRegex.test(this.state.projectName)) {
            this.setState({ projectNameError: "Enter Valid Project Name (using a-z ,0-9 ,@ or $  )" });
            validationStatus = false;
        } else {
            this.setState({ projectNameError: "" });
        }

        //Project Description Validation
        if (this.state.message.trim() == "") {
            this.setState({ messageError: "Enter Project Description" });
            validationStatus = false;
        } else if (!stringRegex.test(this.state.message)) {
            this.setState({ messageError: "Enter Valid Project Description (using a-z ,0-9 ,@ or $  )" });
            validationStatus = false;
        } else {
            this.setState({ messageError: "" });
        }

        //Start date validation
        if (this.state.startDate.trim() == "") {
            this.setState({ startDateError: "Enter Start Date" });
            validationStatus = false;
        } else {
            this.setState({ startDateError: "" });
        }

        //End Date Validation
        if (this.state.endDate.trim() == "") {
            this.setState({ endDateError: "Enter End Date" });
            validationStatus = false;
        } else if (new Date(this.state.endDate) < new Date(this.state.startDate)) {
            this.setState({ endDateError: "Enter Valid End Date" });
            validationStatus = false;
        } else {
            this.setState({ endDateError: "" });
        }

        //Current Hours Validation
        if (this.state.currentHours == "") {
            this.setState({ currentHoursError: "Enter Current Hours" });
            validationStatus = false;
        } else if (this.state.currentHours < -1) {
            this.setState({ currentHoursError: "Enter Valid Current Hours" });
            validationStatus = false;
        } else if (this.state.expectedHours != 0 && this.state.currentHours > this.state.expectedHours) {
            this.setState({ currentHoursError: "Enter Valid Current Hours. Current Hours cannot be greater than Expected Hours" });
            validationStatus = false;
        } else {
            this.setState({ currentHoursError: "" });
        }

        //Expected Hours Validation
        if (!this.state.expectedHours || this.state.expectedHours == 0) {
            this.setState({ expectedHoursError: "Enter Expected Hours" });
            validationStatus = false;
        } else if (this.state.expectedHours < 0) {
            this.setState({ expectedHoursError: "Enter Valid Expected Hours" });
            validationStatus = false;
        } else if (this.state.currentHours > this.state.expectedHours) {
            this.setState({ expectedHoursError: "Enter Valid Expected Hours. Expected Hours cannot be less than Current Hours" });
            validationStatus = false;
        } else {
            this.setState({ expectedHoursError: "" });
        }

        //Expected Hours Validation
        if (this.state.image || this.state.imageURL) {
            this.setState({ imageError: "" });
        } else {
            this.setState({ imageError: "Upload Image" });
            validationStatus = false;
        }

        if (this.state.reason === "") {
            this.setState({ reasonError: "Please enter a comment" })
            validationStatus = false;

        } else {
            this.setState({ reasonError: "" })
        }

        for (var validationItem in this.state.error) {
            if (this.state.error[validationItem] == false) {
                validationStatus = false;
            }
        }


        if (validationStatus) {
            var value = {
                projectName: this.state.projectName,
                startTime: this.state.startDate,
                endDate: this.state.endDate,
                currentHours: this.state.currentHours,
                expectedHours: this.state.expectedHours,
                message: this.state.message,
                color: (this.state.color.substring(1) + "a1").slice(0, 8),
                status: "1",
                image: this.state.image,
                imageName: this.state.imageType,
                imageSize: this.state.imageSize,
                reason: this.state.reason,
                modifyBy: AuthContext.getCachedUser().userName
            }
            // reason: JSON.stringify({ "reason": this.state.reason, "modifyBy": AuthContext.getCachedUser().userName, "date": new Date() })

            this.props.updateProject(this.state.project.projectId, value);
        }
    }

    imageStateChange = (value) => {
        var arrayBuffer = value.currentTarget.result;
        var imageSplit = arrayBuffer.split(",");
        this.setState({ image: imageSplit[1] });
        //return binaryString
    }

    imageUpload = (file) => {
        var reader = new FileReader();
        let imageName = file.name;
        reader.onloadend = this.imageStateChange;
        // function(value) {
        //     var arrayBuffer = value.currentTarget.result,
        //     array = new Uint8Array(arrayBuffer),
        //     binaryString = String.fromCharCode.apply(null, array);
        //     //this.setState({imageType:imageName,image:binaryString});
        //     return binaryString
        // }
        var dateCurrent = new Date();
        this.setState({ imageType: dateCurrent.toISOString() + imageName, imageSize: file.size });
        reader.readAsDataURL(file);
        this.setState({
            imageURL: URL.createObjectURL(file)
        })
    }

    componentDidMount() {
        const { match: { params: { id } } } = this.props
        this.props.getProject(id);
    }

    render() {
        return (
            <>
                {this.state.loader ?
                    <Loader />
                    : this.state.pickerView &&
                    <div className="pickerDiv" >
                        <div style={{ width: "100%", height: "100%", position: "absolute" }} onClick={() => { this.setState({ pickerView: false }) }}>

                        </div>
                        <div className="pickerDivContent">
                            <div style={{ display: "flex", justifyContent: 'flex-end', cursor: "pointer" }} onClick={() => { this.setState({ pickerView: false }) }}>
                                <div style={{ backgroundColor: "black", color: "white", borderRadius: 20, width: 25, paddingLeft: 6 }}>X</div>
                            </div>
                            <PhotoshopPicker
                                color={this.state.color}
                                onChangeComplete={(value) => { this.setState({ color: value.hex }); }}
                            /></div></div>}
                <div className={(this.state.navSide ? "content-div" : "content-div-full")}>
                    <div className="container container-form row">
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-4">
                                    <p>Project Code</p>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        type="text"
                                        id="projectCodeId"
                                        className="form-control"
                                        value={this.state.projectCode}
                                        disabled={true}
                                    />
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <p>Project Name<span style={{ color: "red" }}>*</span></p>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        type="text"
                                        id="projectName"
                                        className="form-control"
                                        value={this.state.projectName}
                                        onChange={(value) => {
                                            var stringRegex = new RegExp("(^[a-z A-Z0-9@_./-]*)$");
                                            if (value.target.value.trim() == "") {
                                                this.setState({
                                                    projectNameError: "Enter Project Name",
                                                    projectName: value.target.value
                                                });
                                            } else if (!stringRegex.test(value.target.value)) {
                                                this.setState({
                                                    projectNameError: "Enter Valid Project Name (using a-z ,0-9 ,@ or $  )",
                                                    projectName: value.target.value
                                                });
                                            } else {
                                                this.setState({ projectNameError: "", projectName: value.target.value });
                                            }
                                        }}
                                    />
                                    <span style={{ color: "red" }}>{this.state.projectNameError}</span>
                                </div>
                            </div>

                            <div className="row">
                                <div className="col-md-4">
                                    <p>Project Description<span style={{ color: "red" }}>*</span></p>
                                </div>
                                <div className="col-md-8">
                                    <textarea type="text" className="form-control"
                                        value={this.state.message}
                                        id="projectDescription"
                                        onChange={(value) => {
                                            var stringRegex = new RegExp("(^[a-z A-Z0-9@_./-]*)$");
                                            if (value.target.value.trim() == "") {
                                                this.setState({
                                                    messageError: "Enter Project Description",
                                                    message: value.target.value
                                                });
                                            } else if (!stringRegex.test(value.target.value)) {
                                                this.setState({
                                                    messageError: "Enter Valid Project Description (using a-z ,0-9 ,@ or $  )",
                                                    message: value.target.value
                                                });
                                            } else {
                                                this.setState({ messageError: "", message: value.target.value });
                                            }

                                        }}

                                    />
                                    <span style={{ color: "red" }}>{this.state.messageError}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <p>Start Date<span style={{ color: "red" }}>*</span></p>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        type="date"
                                        id="startDate"
                                        className="form-control"
                                        value={moment(this.state.startDate).format("YYYY-MM-DD")}
                                        onChange={(value) => {
                                            //Start date validation
                                            if (value.target.value.trim() == "") {
                                                this.setState({ startDateError: "Enter Start Date", startDate: value.target.value });
                                            } else if (new Date(value.target.value) > new Date(this.state.endDate)) {
                                                this.setState({ endDateError: "Enter Valid End Date" });
                                            } else {
                                                this.setState({ startDateError: "", startDate: value.target.value });
                                            }

                                        }}

                                    />
                                    <span style={{ color: "red" }}>{this.state.startDateError}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <p>End Date<span style={{ color: "red" }}>*</span></p>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        value={moment(this.state.endDate).format("YYYY-MM-DD")}
                                        type="date"
                                        id="endDate"
                                        className="form-control"
                                        onChange={(value) => {
                                            if (value.target.value.trim() == "") {
                                                this.setState({ endDateError: "Enter End Date" });
                                            } else if (new Date(value.target.value) < new Date(this.state.startDate)) {
                                                this.setState({ endDateError: "Enter Valid End Date" });
                                            } else {
                                                this.setState({ endDateError: "" });
                                            }
                                            this.setState({ endDate: value.target.value })
                                        }}
                                    />
                                    <span style={{ color: "red" }}>{this.state.endDateError}</span>
                                </div>

                            </div></div>
                        <div className="col-md-6">
                            <div className="row">
                                <div className="col-md-4">
                                    <p>Current Hours<span style={{ color: "red" }}>*</span></p>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        value={this.state.currentHours}

                                        type="number"
                                        className="form-control"
                                        id="currentHoursEdit"
                                        onChange={(value) => {
                                            var valueEnterd = parseInt(value.target.value);
                                            this.setState({ currentHours: valueEnterd });
                                            //Current Hours Validatio
                                            if (value.target.value == "") {
                                                this.setState({ currentHours: 0, currentHoursError: "Enter Current Hours" });
                                            } else if (valueEnterd < 0) {
                                                this.setState({ currentHoursError: "Enter Valid Current Hours" });
                                            } else if (this.state.expectedHours != 0 && valueEnterd > this.state.expectedHours) {
                                                this.setState({ currentHoursError: "Enter Valid Current Hours. Current Hours cannot be greater than Expected Hours" });
                                            } else {
                                                this.setState({ currentHoursError: "" });
                                            }
                                        }}

                                    />
                                    <span style={{ color: "red" }}>{this.state.currentHoursError}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <p>Expected Hours<span style={{ color: "red" }}>*</span></p>
                                </div>
                                <div className="col-md-8">
                                    <input
                                        value={this.state.expectedHours}
                                        id="expectedHoursEdit"
                                        type="number"
                                        className="form-control"
                                        onChange={(value) => {
                                            var valueEnterd = parseInt(value.target.value);
                                            this.setState({ expectedHours: valueEnterd });
                                            if (!valueEnterd || valueEnterd == 0) {
                                                this.setState({ expectedHoursError: "Enter Expected Hours" });
                                            } else if (valueEnterd < 0) {
                                                this.setState({ expectedHoursError: "Enter Valid Expected Hours" });
                                            } else if (this.state.currentHours > valueEnterd) {
                                                this.setState({ expectedHoursError: "Enter Valid Expected Hours. Expected Hours cannot be less than Current Hours" });
                                            } else {
                                                this.setState({ expectedHoursError: "" });
                                            }
                                        }}

                                    />
                                    <span style={{ color: "red" }}>{this.state.expectedHoursError}</span>
                                </div>
                            </div>
                            {/* <div className="row">
                                <div className="col-md-4">
                                    <p>Image<span style={{ color: "red" }}>*</span></p>
                                </div>
                                <div className="col-md-8">
                                    {this.state.imageURL && <img src={this.state.imageURL} height={80} style={{ marginBottom: 10 }} />}
                                    <input
                                        alt="Submit"
                                        accept="image/png, image/jpeg, image/jpg"
                                        type="file"
                                        className="form-control"
                                        onChange={(e) => {
                                            var error = this.state.error;
                                            if (e.target.files[0] == null) {
                                                error["image"] = false;
                                                this.setState({ imageError: "Upload Image", error: error });
                                            } else if ((e.target.files[0].size / 1024) / 1024 > 2) {
                                                error["image"] = false;
                                                this.setState({ imageError: "Maximum image size in 2MB", error: error });
                                            } else {
                                                error["image"] = true;
                                                this.imageUpload(e.target.files[0]);
                                                this.setState({ error: error });
                                            }

                                        }}
                                    />
                                    <span style={{ color: "red" }}>{this.state.imageError}</span>
                                </div> */}
                            {/* </div> */}
                            <div className="row">
                                <div className="col-md-4">
                                    <p>Color</p>
                                </div>
                                <div className="col-md-8">
                                    <div style={{ width: 100, height: 40, backgroundColor: this.state.color, cursor: "pointer", borderColor: "gray", border: 2 }} onClick={() => { (this.state.pickerView ? this.setState({ pickerView: false }) : this.setState({ pickerView: true })) }}></div>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12" style={{ paddingBottom: 20 }}>
                            <p>Comment<span style={{ color: "red" }}>*</span></p>
                            <TextArea id="reasonInput" row={10}
                                onChange={evt => {
                                    this.setState({ reasonError: "", reason: evt.target.value })
                                }}
                            />
                            <span style={{ color: "red" }}>{this.state.reasonError}</span>

                        </div>
                        <div style={{ display: "flex", width: "100%", paddingLeft: 15, paddingRight: 15 }}>
                            <div style={{ display: "flex", flex: 1, justifyContent: 'flex-start' }}>
                                <button className="btn-submit" style={{ backgroundColor: "#EF4224" }} onClick={() => {
                                    this.props.history.push("/Projects")
                                }}>Cancel</button>

                            </div>
                            <div style={{ display: "flex", flex: 1, justifyContent: 'flex-end' }}>
                                {this.props.loader == true ?
                                    <button className="btn-submit" style={{ minWidth: 60 }} onClick={() => { }}><i className="fa fa-circle-o-notch fa-spin"></i></button> :
                                    <button className="btn-submit" id="submitBtn" onClick={() => { this.submitForm() }}>Submit</button>
                                }

                            </div>
                        </div>

                    </div>
                </div>

            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        project: state.projectReducer.project,
        isUpdated: state.projectReducer.isUpdated,
        loader: state.projectReducer.editProjectLoader
    }
}



export default connect(mapStateToProps, projectActions)(EditProject);