import React, { useState, useEffect } from 'react';
import { Drawer, Typography, Select, Button, message } from 'antd';
import {
    UserAddOutlined
} from '@ant-design/icons';
import { Formik } from "formik"
import * as Yup from "yup"
import * as Actions from "../../actions/roleActions"
import { connect } from "react-redux"

const key = 'updatable';
const { Option } = Select;
const { Title, Text } = Typography;

const AddRole = ({ visible, onClose, users, teams, addDepartmentHead, departmentHeadList, getUsers, addRoleProps, departments, getDepartments , getUserDepartment, getUserDepartmentsSuccess}) => {
    const [user, setUser] = useState();
    const [addSuccess, setAddSuccess] = useState(null);
    const [addDepartment , setaddDepartment] = useState(null);

    useEffect(() => {
        if (getUserDepartmentsSuccess != undefined) {
            setaddDepartment(getUserDepartmentsSuccess)
        }
    }, [getUserDepartmentsSuccess])

    useEffect(() => {
        if (addRoleProps.addDepartmentHeadSuccess != addSuccess) {
            message.success({ content: 'Successfully added!', key, duration: 2 });
            setAddSuccess(addRoleProps.addDepartmentHeadSuccess)
        }
    }, [addRoleProps.addDepartmentHeadSuccess])

    const addPMValidationSchema = Yup.object({
        user: Yup.string()
            .required('Please select user'),
        department: Yup.string()
            .required("Please select department"),
        team: Yup.string()
            .required("Please select team")
    });

    return (
        <>

            <Drawer
                title="Add Department Head"
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
            >
                <Formik
                    initialValues={{ user: '', department: '', team: '' }}
                    validationSchema={addPMValidationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {

                        var user = JSON.parse(values.user);
                        var testUserId = departmentHeadList.findIndex((teamLeadValue) => teamLeadValue.userEmail == user.mail && values.department == teamLeadValue.department);
                        if (testUserId == -1) {
                            message.loading({ content: 'Loading...', key });
                            addDepartmentHead(user.mail, user.value, user.label.split("...")[0], values.team, values.department)
                            setSubmitting(false);
                            resetForm({ user: '', department: '', team: '' });
                        } else {
                            message.error({ content: "User already exists", duration: 2 });
                            setSubmitting(false);

                        }

                    }}>
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <form >
                            <Text type="secondary">User</Text>

                            <Select showSearch={true} style={{ width: "100%" }}
                                name="user"
                                onChange={(value) => { 
                                    const jsonObj = JSON.parse(value)
                                    setFieldValue("user", value)
                                    getUserDepartment(jsonObj.value) }}
                                onSearch={(value) => {
                                    getUsers(value);
                                }}
                                // onBlur={handleBlur}
                                value={values.user}
                            >
                               {users && users
                                .filter((item) => typeof item.mail === 'string' && item.mail.includes('millenniumitesp.com'))
                                .map((item) => {
                                    const name = item.label.split('.')[0]; 
                                    return <Option value={JSON.stringify(item)}>{name}</Option>;
                                })
                        }
                            </Select>
                            <Text type="danger">{errors.user && touched.user && errors.user}</Text>
                            <br />
                            <Text type="secondary">Department</Text>
                            <br/>
                            <Select showSearch={true} style={{ width: "100%" }}
                                name="department"
                                onChange={(value) => { setFieldValue("department", value) }}
                                onBlur={handleBlur}
                                value={values.department}>
                                {addDepartment &&
                                 <Option value={addDepartment}>{addDepartment}</Option>
                                }
                            </Select>
                            <Text type="danger">{errors.department && touched.department && errors.department}</Text>
                            <br />
                            <Text type="secondary">Team</Text>
                            <Select showSearch={true} style={{ width: "100%" }}
                                name="team"
                                onChange={(value) => { setFieldValue("team", value) }}
                                value={values.team}
                            >
                                {teams &&
                                    teams.map((item) => {
                                        return <Option value={item.teamid}>{item.teamName}</Option>
                                    })
                                }
                            </Select>
                            <Text type="danger">{errors.team && touched.team && errors.team}</Text>

                            <Button danger type="primary" onClick={handleSubmit} disabled={isSubmitting} style={{ marginTop: 10 }}>
                                <UserAddOutlined /> Add Department Head
                            </Button>
                        </form>
                    )}
                </Formik>
            </Drawer>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        addRoleProps: state.roleReducers,
        getUserDepartmentsSuccess: state.roleReducers.getUserDepartmentsSuccess
    }
}
export default connect(mapStateToProps, Actions)(AddRole)