import React, { Component } from "react"
import * as Actions from "../../actions/usersActions"
import { connect } from "react-redux"
import ReactTable from "react-table"
import Loader from "../../../containers/Loader/Loader"
import Table from "../Role/Table"
import AuthContext from "../../../services/Auth"

class Upload extends Component {
    constructor(props) {
        super(props);
        this.reactTable = React.createRef();
        this.state = {
            upload: [],
            loaderButton: false,
            error: "",
        }
    }


    uploadFile = (file) => {
        let projectArray = {
            projectAssigns: []
    }
        this.setState({ upload: [] })
        var reader = new FileReader();
        reader.onloadend = (valueRead) => {

            var arrayBuffer = valueRead.currentTarget.result;
            if (arrayBuffer) {
                var projectAssignArray = arrayBuffer.split("\n");
                for (var index = 1; index < projectAssignArray.length; index++) {
                    var projectDetailsArray = projectAssignArray[index].split(",");
                    if (projectDetailsArray[0] != "") {

                        var ProjectJsonValue = {
                            projectCode: projectDetailsArray[0],
                            userEmail: projectDetailsArray[1],
                            Time: projectDetailsArray[2],
                            role: 1,
                            assignedBy: AuthContext?.getCachedUser()?.userName
                        };

                        projectArray.projectAssigns.push(ProjectJsonValue);

                    }

                }

                this.props.AssignUsersUpload(projectArray)
                this.setState({upload : projectArray.projectAssigns})
            }



        }
        reader.readAsBinaryString(file)

    }
    onTableViewChange = () => {
        const current = this.reactTable.current;
        if (current) {
            const page = current.state.page;
            const pageSize = current.state.pageSize;
            const allData = current.getResolvedState().sortedData;
            const startIdx = page * pageSize;
            const currentData = allData.map((item) => item._original);
            this.setState({ studentsDowload: currentData })

        }
    }

    download = (items) => {
        var outputArray = [{ projectCode: "Project Code", userEmail: "Employee Email", status: "Status", time: "Time" }];
        for (var index in items) {
            outputArray.push({
                projectCode: items[index].projectCode,
                userEmail: items[index].userEmail,
                status: (items[index].status ? "Success" : "Already Exists"),
                time: items[index].Time
            })
        }
        // var jsonObject = JSON.stringify(outputArray);
        var csv = this.convertToCSV(outputArray);
        var exportedFilenmae = 'Employee Assign CSV Upload Output.csv';
        var blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
        if (navigator.msSaveBlob) { // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
        } else {
            var link = document.createElement("a");
            if (link.download !== undefined) { // feature detection
                // Browsers that support HTML5 download attribute
                var url = URL.createObjectURL(blob);
                link.setAttribute("href", url);
                link.setAttribute("download", exportedFilenmae);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    }

    convertToCSV = (array) => {
        var str = '';
        for (var i = 0; i < array.length; i++) {
            var line = '';
            for (var index in array[i]) {
                if (line != '') line += ',';
                line += array[i][index];
            }
            str += line + '\n';
        }
        return str;
    }

    static getDerivedStateFromProps = (nextProps, state) => {
        if (nextProps.upload.uploaded) {
            return {
                loaderButton: false,
                viewTable: true,
            }
        }

    }
    render() {
        const columns = [
            { Header: 'Project Code', filterable: true, accessor: 'projectCode' },
            { Header: 'User Email', filterable: true, accessor: 'userEmail' },
            { Header: 'Time', filterable: true, accessor: 'Time' },
            {
                Header: 'Status', filterable: true, accessor: 'status',
                Cell: props => {
                    return (
                        //props.value ?
                            <div className="status" style={{ backgroundColor: "green", borderRadius: 5, color: "white", paddingLeft: 5, paddingRight: 5 }}>Success</div> 
                            // :
                            // <div className="status" style={{ backgroundColor: "red", borderRadius: 5, color: "white", paddingLeft: 5, paddingRight: 5 }}>Already Exists</div>
                    )
                }
            }
        ]
        return (
            <>
                {this.props.loading &&
                    <Loader />
                }
                <div className="container csv_form">
                    <div className="col-md-12 row">
                        <div className="col-md-6" style={{ marginBottom: 20 }}>
                            <h6>Please Select a CSV File</h6>
                        </div>
                        <div className="col-md-6">
                            <a href={require("../../../assets/employeeAssign.csv")} >
                                Template for CSV
                            </a>
                        </div>
                        <div className="col-md-9" style={{ marginBottom: 20 }}>
                            <input type="file" className="form-control" accept=".csv" onChange={(e) => {
                                if (e.target.files[0]) {
                                    const name = e.target.files[0].name;
                                    const lastDot = name.lastIndexOf('.');
                                    const ext = name.substring(lastDot + 1);
                                    if (ext == "csv") {
                                        this.setState({ file: e.target.files[0] });
                                    } else {
                                        alert("Please upload csv File")
                                    }
                                }
                            }} />
                            <p style={{ color: "red" }}>{this.state.error}</p>
                        </div>

                        <div className="col-md-9" style={{ display: "flex", flexDirection: "row-reverse" }}>
                            {(this.state.loaderButton ?
                                <button className="btn-submit" style={{ width: 150 }}>

                                    <i className="fa fa-circle-o-notch fa-spin fa-1x fa-fw" style={{ color: "gray" }}></i>
                                </button>
                                :
                                <button className="btn-submit" onClick={() => {
                                    if (this.state.file) {
                                        this.setState({ loaderButton: true, error: "" });
                                        this.uploadFile(this.state.file);
                                    } else {
                                        this.setState({ error: "Please upload file" })
                                    }
                                }} style={{ width: 150, cursor: "pointer" }}>
                                    Upload</button>
                            )}
                        </div>
                    </div>
                    <div className="col-md-12 row">
                        {this.props.upload && (this.state.upload.length != 0 || this.props.upload.uploaded) &&
                            <>
                                <div class="col-md-12" style={{ paddingTop: 20 }}>
                                    <button className="btn-danger" onClick={() => {
                                        if (this.props.upload.uploaded) {
                                            this.download(this.props.upload.uploaded.concat(this.state.upload))
                                        } else {
                                            this.download(this.state.upload)
                                        }


                                    }} style={{ width: 150, borderColor: "#555555", backgroundColor: "#555555", cursor: "pointer" }}>
                                        Download csv</button>
                                </div>
                                <Table
                                    data={(this.props.upload.uploaded ? this.props.upload.uploaded.concat(this.state.upload) : this.state.upload)}
                                    columns={columns}
                                />
                                {/* <ReactTable style={{ width: "100%" }}
                                    data={(this.props.upload.uploaded ? this.props.upload.uploaded.concat(this.state.upload) : this.state.upload)}
                                    columns={columns}
                                    ref={this.reactTable}
                                    defaultPageSize={10}
                                    pageSize={10}
                                    onPageChange={this.onTableViewChange}
                                    onSortedChange={this.onTableViewChange}
                                    onExpandedChange={this.onTableViewChange}
                                    onFilteredChange={this.onTableViewChange}
                                    onResizedChange={this.onTableViewChange}
                                /> */}
                            </>
                        }

                    </div>

                </div>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        upload: state.usersReducers
    }
}


export default connect(mapStateToProps, Actions)(Upload);