import React, { useState, useEffect } from 'react';
import { Drawer, Typography, Input, Button, message, Select } from 'antd';
import {
    AppstoreAddOutlined
} from '@ant-design/icons';
import { Formik } from "formik"
import * as Yup from "yup"
import * as Actions from "../../actions/roleActions"
import {getAllTeamsDropdown} from "../../actions/teamsActions";
import { connect } from "react-redux"
import AuthContext from '../../../services/Auth'

const key = 'updatable';
const { Title, Text } = Typography;
const { Option } = Select;

const AddDepartment = ({ onClose, isAdminSuccess, getMyDepartment, getAllTeamsDropdown, visible, addDepartment, addDepartmentNBA, getDepartment, addDepartmentsNBASuccess, getDepartmentFollowers , addTeamNBA , getTeams }) => {
    const [formSubmitted, setFormSubmitted] = useState(null)
    const addPMValidationSchema = Yup.object({
        projectName: Yup.string()
            .required('Please enter activity name'),
        department: Yup.string()
            .required('Please select department'),
        description: Yup.string()
            .required('Please enter activity description')
    });

    useEffect(() => {
        if (isAdminSuccess) {
            getAllTeamsDropdown();
        } else {
            getTeams(AuthContext.getCachedUser() && AuthContext.getCachedUser().profile.oid);
        }
    }, [])

    useEffect(() => {
        if (addDepartmentsNBASuccess) {
            message.success({ content: 'Successfully added activity', key });
            setFormSubmitted(addDepartmentsNBASuccess);
        }
    }, [addDepartmentsNBASuccess])

    return (
        <>
            <Drawer
                title="Add Non Time Bound Activity for Department"
                placement="right"
                closable={false}
                onClose={onClose}
                visible={visible}
            >
                <Formik
                    initialValues={{ projectName: '', description: '', department: '' }}
                    validationSchema={addPMValidationSchema}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                        message.loading({ content: 'Loading...', key });
                        addDepartment(values.projectName, values.description, values.department);
                        setSubmitting(false);
                        resetForm({ projectName: '', description: '', department: '' });
                        setFormSubmitted(true);
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <form >
                            <Text type="secondary">Activity Name</Text>
                            <Input
                                name="projectName"
                                onChange={(value) => { setFieldValue("projectName", value.target.value) }}
                                value={values.projectName}
                            />
                            <Text type="danger">{errors.projectName && touched.projectName && errors.projectName}</Text><br />
                            <Text type="secondary">Department</Text>
                            <Select showSearch={true} style={{ width: "100%" }}
                                name="department"
                                onChange={(value) => { setFieldValue("department", value) }}
                                // onSearch={(value) => {
                                //     if (value.length > 2 && value.length % 2 == 0) {
                                //         getDepartmentFollowers(value);
                                //     }
                                // }}
                                onBlur={handleBlur}
                                value={values.department}>
                                {addTeamNBA.getTeamsSuccess &&
                                [...new Set(addTeamNBA.getTeamsSuccess.map((item) => item.department))].map((department) => {
                                    return <Option value={department}>{department}</Option>;
                                })
                            }
                            </Select>
                            <Text type="danger">{errors.department && touched.department && errors.department}</Text><br />
                            <Text type="secondary">Description</Text>
                            <Input
                                name="description"
                                onChange={(value) => { setFieldValue("description", value.target.value) }}
                                value={values.description}
                            />
                            <Text type="danger">{errors.description && touched.description && errors.description}</Text><br />
                            <Button danger type="primary" onClick={handleSubmit} disabled={isSubmitting} style={{ marginTop: 10 }}>
                                <AppstoreAddOutlined /> Add Activity
                                </Button>
                        </form>
                    )}
                </Formik>
            </Drawer>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        addDepartmentNBA: state.roleReducers,
        isAdminSuccess: state.usersReducers.isAdminSuccess,
        addTeamNBA: state.team
    }
}
export default connect(mapStateToProps, {Actions , getAllTeamsDropdown})(AddDepartment)