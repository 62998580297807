import React, { useState, useEffect } from 'react';
import Nav from "../../../containers/Navbar/Navbar"
import { connect } from 'react-redux';
import { getSupports } from "../../actions/supportActions"
import { activeProject, completeProject } from "../../actions/projectsActions"
import ReactTable from 'react-table'
// import 'react-table/react-table.css'
import NavbarTop from "../../../containers/Navbar/NavbarTop"
import { Link } from 'react-router-dom'
import SupportCard from '../../../containers/Project/SupportCard'
import Loader from "../../../containers/Loader/Loader"
import Alert from '../../../containers/Alert/Alert';

const Support = ({ getSupports, support, completeProject, activeProject }) => {
    const [loader, setLoader] = useState(false);
    const [user, setUsers] = useState([]);


    useEffect(() => {
        getSupports("");
    }, [])

    const rowFunction = (state, rowInfo, column) => {
        if (rowInfo != null) {
            return {
                onClick: (e, handleOriginal) => {
                    this.setState({ rowId: rowInfo.original.projectId, actionView: true, rowIndex: rowInfo.index, rowData: rowInfo.original });
                },
                style: {
                    backgroundColor: (rowInfo.original.userTime < rowInfo.original.currentTime ? (this.state.rowIndex == rowInfo.index ? "#bc8bf4" : "#ff9984") : (this.state.rowIndex == rowInfo.index ? "rgba(240, 202, 53, 0.35)" : "")),
                    cursor: "pointer",
                    border: (this.state.rowIndex == rowInfo.index ? "2px solid #dadada" : "")
                }
            }
        } else {
            return {}
        }
    }

    const activeSupport = (id, name) => {
        activeProject(id)
    }
    const completeSupport = (id, name) => {
        completeProject(id)
    }



    return (
        <>
            {
                support.getSupportsLoading &&
                <Loader />
            }

            <div className="table-container" >
                {/* {projects.projectStatusChange &&
                    <Alert alertMessage={projects.projectStatusChange} close={() => { closeAlert() }} />
                } */}

                <div className="row findbar">
                    <div className="col-md-6">
                        <input className="search_input" placeholder="Contract name" onChange={(value) => { getSupports(value.target.value) }} />
                        <icon className="fa fa-search" style={{ marginLeft: 10 }} />
                    </div>
                </div>

                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {
                        support.getSupports &&
                        support.getSupports.map((value, index) => {
                            return <SupportCard
                                stateChange={(id, status) => (status == 0 ? completeSupport(id) : activeSupport(id))}
                                setUserModalView={() => setUsers(value.users)}
                                support={value} index={index} />
                        })
                    }
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => {
    return {
        support: state.support
    }
}

export default connect(mapStateToProps, { getSupports, activeProject, completeProject })(Support);


