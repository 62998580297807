import React, { Component } from "react";
import * as Actions from "../../actions/usersActions"
import { connect } from 'react-redux';
import Nav from "../../../containers/Navbar/Navbar"
import NavbarTop from "../../../containers/Navbar/NavbarTop"
import ReactTable from 'react-table'
import { Link } from 'react-router-dom'

class Users extends Component {
    constructor(props) {
        super(props);
        this.state = {
            navSide: true,
            rowId: 0,
            actionView: false,
            rowIndex: 0,
            rowData: {}
        }
    }

    rowFunction = (state, rowInfo, column) => {
        if (rowInfo != null) {
            return {
                onClick: (e, handleOriginal) => {
                    this.setState({ rowId: rowInfo.original.projectId, actionView: true, rowIndex: rowInfo.index, rowData: rowInfo.original });
                },
                style: {
                    backgroundColor: (rowInfo.original.userTime < rowInfo.original.currentTime ? (this.state.rowIndex == rowInfo.index ? "#bc8bf4" : "#ff9984") : (this.state.rowIndex == rowInfo.index ? "rgba(240, 202, 53, 0.35)" : "")),
                    cursor: "pointer",
                    border: (this.state.rowIndex == rowInfo.index ? "2px solid #dadada" : "")
                }
            }
        } else {
            return {}
        }
    }

    componentDidMount() {
        this.props.getUsers();
    }
    render() {
        const columns = [
            {
                Header: 'Name',
                filterable: true, accessor: 'label'
            },
            {
                Header: 'Email',
                filterable: true, accessor: 'mail'
            }
        ]
        return (
            <div>

                <div className="table-container">
                    <div className="row">
                        {
                            this.state.actionView &&
                            <>
                                <div className="col-md-6" style={{ display: "flex", flexDirection: "row" }}>
                                    <Link to={`/HistoryUser/${this.state.rowData.value}/${this.state.rowData.mail}`} style={{ border: "none", appearance: "none" }}>
                                        <div style={{ width: 80, cursor: "pointer", height: 30, marginLeft: 20, backgroundColor: "black", flexWrap: "wrap", borderRadius: 10, color: "white", alignContent: "center", justifyContent: "center" }}>
                                            <span style={{ fontSize: 12 }}><icon className="fa fa-history" style={{ marginTop: 5, marginLeft: 17 }} /> History</span>
                                        </div>
                                    </Link>
                                </div>
                            </>
                        }
                    </div>
                    {this.props.users &&
                        <ReactTable
                            data={(this.props.users.users == null ? [] : this.props.users.users)}
                            defaultFilterMethod={(filter, row) => String(row[filter.id]).toLowerCase().startsWith(filter.value.toLowerCase())}
                            getTrProps={this.rowFunction}
                            columns={columns}
                            defaultPageSize='15'
                            sortable='true' />}
                </div>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return { users: state.usersReducers }
}

export default connect(mapStateToProps, Actions)(Users);